import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import Chart from 'admin-lte/plugins/chart.js/Chart.bundle';
import PropTypes from 'prop-types';

export function monthlyActiveUserDashboadChart(elementId, solo, repeat) {
  $(function () {
    var ticksStyle = {
      fontColor: '#495057',
      fontStyle: 'bold'
    };

    var mode = 'index';
    var intersect = true;

    var $salesChart = $(`#${elementId}`);
    new Chart($salesChart, {
      type: 'bar',
      data: {
        labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
        datasets: [
          {
            backgroundColor: '#ced4da',
            borderColor: '#ced4da',
            data: solo,
            order: 2 // L'ordre pour que les barres soient en dessous
          },
          {
            type: 'line', // Type de graphique pour ce dataset
            backgroundColor: '#235CBE',
            borderColor: '#235CBE',
            data: repeat,
            fill: false, // Pour éviter de remplir sous la ligne
            order: 1, // L'ordre pour que les barres soient en dessous
            lineTension: 0 // Pour rendre les lignes droites
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          mode: mode,
          intersect: intersect
        },
        hover: {
          mode: mode,
          intersect: intersect
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            gridLines: {
              display: true,
              lineWidth: '4px',
              color: 'rgba(0, 0, 0, .2)',
              zeroLineColor: 'transparent'
            },
          }],
          xAxes: [{
            display: true,
            gridLines: {
              display: false
            },
            ticks: ticksStyle
          }]
        }
      }
    });
  });
}

const MonthlyActiveUserDashboadChart = ({ elementId, solo, repeat}) => {

  useEffect(() => {
    monthlyActiveUserDashboadChart(elementId, solo, repeat);
  }, [elementId, solo, repeat]);

  return (
    <div className="card" style={{ borderRadius: '15px' }}>
      <div className="card-header border-0">
        <div className="card btn">
          <h4 className="flous-police-one">Monthly Active Solo Users</h4>
        </div>
      </div>
      <div className="card-body">

        <div className="position-relative mb-4">
          <canvas id={elementId} height="200"></canvas>
        </div>

        <div className="d-flex flex-row justify-content-end">
          <span className="mr-2">
            <i className="fas fa-square text-gray"></i> Solo
          </span>
          <span>
            <i className="fas fa-square flous-input-color"></i> Repeat Clients
          </span>
        </div>
      </div>
    </div>
  );
};

MonthlyActiveUserDashboadChart.propTypes = {
  elementId: PropTypes.string.isRequired,
};

export default MonthlyActiveUserDashboadChart;