import React from 'react';
import CurrentResidenceResumeSection from './section_residences/currentResidenceInfoSection';
import StatusResumeSection from './section_residences/statusInfoSection';
import PreviousAddressResumeSection from './section_residences/previousAddress_section';
import ImmigrationDocumentResumeSection from './section_residences/immigrationDocumentSection';

export default function ResidenceInfoResume(props) {

  return (
    <div id='evaluation'>
      <h3 className='flous-police-one flous-input-color text-center'>Residences</h3>
      <CurrentResidenceResumeSection currentResidence={props.residences.currentResidence} />
      <StatusResumeSection currentResidence={props.residences.currentResidence} statusInCountry={props.residences.statusInCountry} />
      {(props.residences.statusInCountry.currentStatus !== '' || props.residences.statusInCountry.currentStatus !=='NS') && (
        <ImmigrationDocumentResumeSection immigrationDocument={props.residences.immigrationDocument} />
      )}
      {props.residences.livedInDifferentAddress === 'Yes'  && (
        <PreviousAddressResumeSection previousAddresses={props.residences.previousAddresses} />
      )}
    </div>
  );
}
