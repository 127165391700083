import React, { useState } from 'react';
import CountrySelect from '../../../../general/CountrySelect';
import { countriesWithProvinces, provincesWithCities } from '../../../../../services/utils';
import ProvinceSelect from '../../../../general/ProvinceSelect';
import CitySelect from '../../../../general/CitySelect';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const CompanyDetailsSection = (props) => {
  const [formData, setFormData] = useState(props.companyDetail);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    props.sendCompanyDetail(updatedFormData);
  };

  {/*const handleCountryChange = (value) =>{
    const updatedFormData = {
      ...formData,
      country: value,
    };
    setFormData(updatedFormData);
    props.sendCompanyDetail(updatedFormData);
  };*/}

  const handleProvinceStateChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      provinceState: value,
    };
    setFormData(updatedFormData);
    props.sendCompanyDetail(updatedFormData);
  };

  const handleCityChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      city: value,
    };
    setFormData(updatedFormData);
    props.sendCompanyDetail(updatedFormData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Company Address</p>
        {/*<div className='row p-2'>
          <p htmlFor="country" className="col-sm-4 col-form-label">Country</p>
          <div className="col-sm-8">
            <CountrySelect value={formData.country} onChange={handleCountryChange} required />
          </div>
        </div>*/}
        <div className='row p-2'>
          <p htmlFor="provinceState" className="col-sm-4 col-form-label">{EnglishQuestion.jobOffer.provinceState}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <ProvinceSelect 
              value={formData.provinceState} 
              onChange={handleProvinceStateChange} 
              country={"CAN"} required
              />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="city" className="col-sm-4 col-form-label">{EnglishQuestion.jobOffer.city}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <CitySelect 
                value={formData.city} 
                onChange={handleCityChange} 
                province={formData.provinceState} 
                required
                />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="streetNumber" className="col-sm-4 col-form-label">{EnglishQuestion.jobOffer.streetNumber}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="streetNumber" 
              name="streetNumber" 
              value={formData.streetNumber} 
              onChange={handleChange} 
              placeholder="Street number"
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="postalCode" className="col-sm-4 col-form-label">{EnglishQuestion.jobOffer.postalCode}</p>
          <div className="col-sm-8">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="postalCode" 
              name="postalCode" 
              value={formData.postalCode} 
              onChange={handleChange} 
              placeholder="Postal code"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetailsSection;
