import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';
import { sortDictionaryByValues } from '../../../../services/utils';
import CountryShow from '../../../general/countryShow';


export default function ProfessionalExperienceResumeSection(props) {

  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
      const fetchProvinces = async() => {
          const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
      const fetchJob = async () =>{
        const response = await api.get('/professions/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setJobs(options);
      };
      fetchJob();
  
      fetchProvinces();
  }, []);

  const [jobs, setJobs] = useState([]);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  return (
    <div id='language'>
      <h3 className='flous-police-one flous-input-color text-center'>Professional Experience</h3>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className='mx-3'>
          {props.professionalExperience.professionalExperience === 'Yes' && (
            <div className="form-group">
            <div>
              <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='row'>
                  <div className='col-sm col-6 mt-2 text-center'>Title</div>
                  <div className='col-sm col-6 mt-2 text-center'>Company Name</div>
                  <div className='col-sm col-6 mt-2 text-center'>Start date</div>
                  <div className='col-sm col-6 mt-2 text-center'>Is Current Job</div>
                  <div className='col-sm col-6 mt-2 text-center'>Country</div>
                  <div className='col-sm col-6 mt-2 text-center'>Province</div>
                </div>
              </div>
              
              {props.allProfessionalExperience.map((profession, index) => (
                <div key={index} className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }}>
                  <div className='row'>
                    <div className='col-sm col-6 mt-2 text-center'>{jobs.find(option => option.value === profession.jobDetail.jobTitle)?.label}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{profession.companyInfo.companyName}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{profession.jobDetail.startDate}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{profession.jobDetail.currentJob}</div>
                    <div className='col-sm col-6 mt-2 text-center'><CountryShow value={profession.jobAddress.country}/></div>
                    {profession.jobAddress.country === 'CAN' ? (
                    <div className='col-sm col-6 mt-2 text-center'>{provinces.find(province => province.value === profession.jobAddress.province)?.label || ''}</div>
                    ): (<div className='col-sm col-6 mt-2 text-center'><div className='col-sm col-6 mt-2 text-center'>{profession.jobAddress.province}</div></div>)}
                  </div>
                </div>
              ))}
            </div>
            </div>
          )}
          {props.professionalExperience.professionalExperience !== 'Yes' && (
            <div className='text-center'>You don't have professional experience in the past 10 years</div>
          )}
        </div>
      </div>
    </div>
  );
}
