import api from "../api";



export const userForsStates = async () => {

    try{
        const response = await api.get(`/user/get-forms-states/`);
        return response;

    } catch (error){
         // console.error('Error when getting evaluations history:', error);
        throw error;
    }   
};


export const userGetEvaluationHistory = async () => {

    try{
        const response = await api.get(`/user/get-evaluation-history/`);
        return response;

    } catch (error){
         // console.error('Error when getting evaluations history:', error);
        throw error;
    }   
};

///////////////////////////////////////////////////////////////////////////
// Enterprise

export const EnterpriseCustomersStates = async () => {

    try{
        const response = await api.get(`/enterprise/get-customers-forms-states/`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const EnterpriseCustomersNationalities = async () => {

    try{
        const response = await api.get(`/enterprise/get-customers-nationalities/`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const EnterpriseDashBordStates = async () => {

    try{
        const response = await api.get(`/enterprise/get-dashbord-states/`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const EnterpriseCustomersEvolutions = async () => {

    try{
        const response = await api.get(`/enterprise/get-customers-evolutions/`);
        return response;

    } catch (error){
        throw error;
    }   
};

///////////////////////////////////////////////////////////////////////////////
// Advisor

export const AdvisorDashBordStates = async (code) => {

    try{
        const response = await api.get(`/advisor/get-dashbord-states/${code}`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const AdvisorDashBordNotifications = async (code) => {

    try{
        const response = await api.get(`/advisor/get-dashbord-notification/${code}`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const AdvisorCustomersEvolutions = async (code) => {

    try{
        const response = await api.get(`/advisor/get-customers-evolutions/${code}`);
        return response;

    } catch (error){
        throw error;
    }   
};

///////////////////////////////////////////////////////////////////////////////
// Flous

export const FlousDashBordStates = async () => {

    try{
        const response = await api.get(`/flous/get-dashbord-states`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const FlousCustomersEvolutions = async () => {

    try{
        const response = await api.get(`/flous/get-customers-evolutions`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const FlousProEvolutions = async () => {

    try{
        const response = await api.get(`/flous/get-pro-evolutions`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const FlousMonthlyRevenue = async () => {

    try{
        const response = await api.get(`/flous/get-monthly-revenue`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const FlousRevenueGrowth = async () => {

    try{
        const response = await api.get(`/flous/get-revenue-growth`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const FlousActiveUsers = async () => {

    try{
        const response = await api.get(`/flous/get-active-users`);
        return response;

    } catch (error){
        throw error;
    }   
};

export const FlousChurnRate = async () => {

    try{
        const response = await api.get(`/flous/get-churn-rate`);
        return response;

    } catch (error){
        throw error;
    }   
};