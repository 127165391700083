import React, {useEffect, useState} from 'react';
import { getCurrentItems } from '../../services/users/FormsServices';
import PaginationSection from '../Layout/PaginationSection';
import CreateNewAdvisorsModal from './modal/createNewAdvisorsModal';
import LoadingOverlay from '../general/LoadingOverlay';
import { useOutletContext } from 'react-router-dom';
import { fetchAdvisorsData } from '../../services/enterprise/AdvisorsServices';
import { toast, ToastContainer } from 'react-toastify';
import InfoBoxThirtyLink from '../general/InfoBoxThirtyLink';
import InfoBoxPrimaryLink from '../general/InfoBoxPrimaryLink';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import CustomerEvolutionCard from '../general/CustomersEvoluationsChart';
import { EnterpriseCustomersEvolutions, EnterpriseDashBordStates } from '../../services/general/DashbordServices';

export default function EntrepriseDashboard () {
  
  const [formsData, setFormsData] = useState({
    formsInProgress: 0,
    formsCompleted: 0,
    totalActiveAdvisors: 0,
    totalActiveCustomers: 0
  });

  const [thisYear, setThisYear] = useState([, , , , , , , , , , , ]);
  const [lastYear, setLastYear] = useState([, , , , , , , , , , , ]);
  const [labels, setLabels] = useState(['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']);

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };

  const {user} = useOutletContext();

  const [advisors, setAdvisors] = useState([]);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  useEffect( () => {
    let isMounted = true;

    const getDashbordStatesAdvisors = async () => {
      if (user.id) {
        try {
          const response_advisors = await fetchAdvisorsData();
          const response_states = await EnterpriseDashBordStates();
          const response_evolution = await EnterpriseCustomersEvolutions();
          // console.log(response_evolution);
          if (isMounted) {
            setAdvisors(response_advisors.data.data);
            setFormsData(response_states.data.data);
            setThisYear(response_evolution.data.data.thisYear)
            setLastYear(response_evolution.data.data.lastYear)
            setLabels(response_evolution.data.data.labels)
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setError(error.response.data.error);
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
              isMounted = false;
            }
          }
        }
      }
    };

    getDashbordStatesAdvisors();

    return () => {
      isMounted = false;
    };

  }, []);

  
  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }
  
  const currentItems = getCurrentItems(advisors, page, pageSize);

  

  return (
    <div>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 flous-police-one flous-input-color">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>
      {/* /.content-header */}

      <div className="content">
        <div className="container-fluid">
          {/* Small boxes (Stat box) */}
          <div className='card p-2 px-5 elevation-2' style={{ borderRadius: '15px' }}>
            <div className="row">
              <div className="col-lg-3 col-6">
                <InfoBoxPrimaryLink
                  value={formsData.totalActiveAdvisors}
                  text="Advisors"
                  link='More info'
                  href='/enterprise/advisors'
                />
              </div>
              <div className="col-lg-3 col-6">
                <InfoBoxThirtyLink
                  value={formsData.formsInProgress}
                  text="Forms in progress"
                  link='More info'
                  href='/enterprise/customers'
                />
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                <InfoBoxPrimaryLink
                  value={formsData.formsCompleted}
                  text="Completed forms"
                  link='More info'
                  href='/enterprise/customers'
                />
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                <InfoBoxThirtyLink
                  value={formsData.totalActiveCustomers}
                  text="Total Clients"
                  link='More info'
                  href='/enterprise/customers'
                />
              </div>
              {/* ./col */}
            </div>
          </div>
          {/* /.row */}

          <div className="row">
            <div className="col-lg-6">
            <CustomerEvolutionCard elementId="customers-evoluation" thisYear={thisYear} lastYear={lastYear} totalCustomers={formsData.totalActiveCustomers} labels={labels} />
              {/* /.card */}
            </div>

            <div className="col-lg-6">
              <div className="card">
                <div className="card-header border-0">
                  <div className="card btn">
                    <h4 className="flous-police-one">Advisors</h4>
                  </div>
                  <div className="text-right">
                    <div className="btn flous-bg-primary" data-toggle="modal" data-target="#newAdvisor">
                      Add New Advisor <i className="nav-icon fas fa-user"></i>
                    </div>
                  </div>
                  <CreateNewAdvisorsModal />
                </div>
                <div className="card-body">
                <div className='flous-bg-thirty' style={{ borderRadius: '15px' }}>
                      <div className='card-header p-1 mb-2 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                          <div className='row text-bold'>
                              <div className='col-sm col-6 mt-2 text-center'>ID</div>
                              <div className='col-sm col-6 mt-2 text-center'>First Name</div>
                              <div className='col-sm col-6 mt-2 text-center'>Last Name</div>
                              <div className='col-sm col-6 mt-2 text-center'>Number of Clients</div>
                              {/*<div className='col-sm col-6 mt-2 text-center'>Status</div> */}
                          </div>
                      </div>
                      {currentItems.length > 0 ? (
                        currentItems.map((advisor) => (
                        <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px' }}>
                          <div className='row'>
                              <div className='col-sm col-6 mt-2 text-center'>{advisor.code}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{advisor.firstName}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{advisor.lastName}</div>
                              <div className='col-sm col-6 mt-2 text-center'>{advisor.numberOfCustomers}</div>
                              {/*<div className='col-sm col-6 text-center'><div className={`btn ${advisor.status === "Active" ? "flous-bg-menu" : "btn-secondary"}`}>{advisor.status}</div></div>*/}
                          </div>
                        </div>
                        ))
                      ) : (
                        <NoItemsMessage />
                      )}
                  </div>
                </div>
                <PaginationSection
                    page={page}
                    pageSize={pageSize}
                    totalItems={advisors.length}
                    onPageChange={onPageChange}
                  />
                <div className="card-footer text-center">
                  <a className="btn flous-btn-primary" href="/enterprise/advisors">See more</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};