import React, { useState, useEffect } from 'react';
import EducationDetailSection from './section_eoc/EducationDetailSection';
import DateofStudySection from './section_eoc/DateofStudySection';
import EducationInstitutionSection from './section_eoc/EducationInstitutSection';
import EducationalCredentialAssessmentSection from './section_eoc/EducationalCredentialAssessmentSection';
import { toast, ToastContainer } from 'react-toastify';
import EquivalencyProcessQuebecSection from './section_eoc/EquivalencyProcessQuebecSection';
import LoadingOverlay from '../../../general/LoadingOverlay';
import RequiredIndicator from '../../../general/requiredIndicator';

import EnglishQuestion from "../questions/englishQuestions.json";

const EducationOutsideCountry = (props) => {
  const [educationOutsideCountry, setEducationOutsideCountry] = useState(props.educationOutsideCountry.educationOutsideCountry);
  const [allEducationOutsideCountry, setAllEducationOutsideCountry] = useState(props.allEducationOutsideCountry);
  const [educationDetail, setEducationDetail] = useState(props.educationOutsideCountry.educationDetail);
  const [dateofStudy, setDateofStudy] = useState(props.educationOutsideCountry.dateofStudy);
  const [educationInstitution, setEducationInstitution] = useState(props.educationOutsideCountry.educationInstitution);
  const [educationalCredentialAssessment, setEducationalCredentialAssessment] = useState(props.educationOutsideCountry.educationalCredentialAssessment);
  const [previousCredentials, setPreviousCredentials] = useState(props.educationOutsideCountry.previousCredentials);
  const [equivalencyProcessQuebec, setEquivalencyProcessQuebec] = useState(props.educationOutsideCountry.equivalencyProcessQuebec);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);


  const handleChange = (event) => {
    const { value } = event.target;
    setEducationOutsideCountry(value);
    setErrors({});
  };

  useEffect(() => {
    const educationOutsideCountryData = {
      educationOutsideCountry,
      educationalCredentialAssessment,
      previousCredentials,
      educationDetail,
      dateofStudy,
      educationInstitution,
      equivalencyProcessQuebec
    };
    props.sendEducationOutsideCountry(educationOutsideCountryData);
    props.onValidate('educationOutsideCountry', false);
  }, [educationOutsideCountry, educationalCredentialAssessment, educationDetail, 
    dateofStudy, educationInstitution, equivalencyProcessQuebec, previousCredentials, props]);
  
  useEffect(()=> {    
    props.sendAllEducationOutsideCountry(allEducationOutsideCountry);
    props.onValidate('educationOutsideCountry', false);
  }, [allEducationOutsideCountry]);

  const handleAllEducation = () => {
    if (check()){
      const educationOutsideCountryData = {
        educationalCredentialAssessment,
        previousCredentials,
        educationDetail,
        dateofStudy,
        educationInstitution,
        equivalencyProcessQuebec
      };
      setAllEducationOutsideCountry([...allEducationOutsideCountry, educationOutsideCountryData]);

      /* setEducationDetail({
        degreelevel: '',
        fieldOfStudy: '',
        programDuration: ''
      });
      setDateofStudy({
        startDate: '',
        endDate: ''
      });
      setEducationInstitution({
        institutionName: '',
        languageOfInstruction: '',
        fullTimeStudent: '',
        address: '',
        country: '',
        provinceState: '',
        city: '',
      });
      setEducationalCredentialAssessment({
        ecaObtained: '',
        issuingOrganization: '',
        recognizedCredential: '',
        recognizedDiplomaLevel: '',
      });
      setPreviousCredentials([]);
      setEquivalencyProcessQuebec({
        epqObtained: '',
        recognizedCredential: '',
        recognizedDiplomaLevel: '',
        provinceState: 'Québec'
      }); */
    }
  }

  const receivedEducationalCredentialAssessment = (data) => {setEducationalCredentialAssessment(data);};
  const receivedEducationDetail = (data) => {setEducationDetail(data);};
  const receivedDateofStudy = (data) => {setDateofStudy(data);};
  const receivedEducationInstitution = (data) => {setEducationInstitution(data);};
  const receivedEquivalencyProcessQuebec = (data) => {setEquivalencyProcessQuebec(data);};
  const receivedPreviousCredentials = (data) => {setPreviousCredentials(data);};

  const pressNext = () => {
    props.sendNext("Professional Experience");
  };

  const saveForm = () => {
    props.onSave();
    toast.success('Your form has been successfully saved !')
  };

  const checkEducationDetails = () => {
    if (!educationDetail.degreelevel || !educationDetail.fieldOfStudy || !educationDetail.programDuration){
      toast.error("Please complete the Education Detail block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        educationDetail: "Please complete the Education Detail block !"
      }));
      return false;
    } else {
      delete errors.educationDetail;
      return true;
    }
  }

  const checkDateOfStudy = () => {
    if (!dateofStudy.startDate || !dateofStudy.endDate){
      toast.error("Please complete the Date of Study block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        dateofStudy: "Please complete the Date of Study block !"
      }));
      return false;
    } else {
      delete errors.dateofStudy;
      return true;
    }
  }

  const checkEducationInstitution = () => {
    if (!educationInstitution.institutionName || !educationInstitution.country || !educationInstitution.provinceState || !educationInstitution.city || !educationInstitution.languageOfInstruction || !educationInstitution.fullTimeStudent){
      toast.error("Please complete the Education Institution block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        educationInstitution: "Please complete the Education Institution block !"
      }));
      return false;
    } else {
      delete errors.educationInstitution;
      return true;
    }
  }

  const checkValidECA = () =>  {
    if (educationalCredentialAssessment.ecaObtained === '' || 
      (educationalCredentialAssessment.ecaObtained === 'Yes' && (educationalCredentialAssessment.issuingOrganization === '' || educationalCredentialAssessment.recognizedCredential === '' || educationalCredentialAssessment.recognizedDiplomaLevel === ''))) {
        toast.error("Please complete the Education Credential Assessment block !");
        setErrors(prevErrors =>({
          ...prevErrors,
          educationalCredentialAssessment: "Please complete the Education Credential Assessment block !"
        }));
        return false;
    } else {
      if (educationalCredentialAssessment.ecaObtained === 'Yes') {
        const updatedData = [educationalCredentialAssessment];
        setPreviousCredentials(updatedData);
        console.log(updatedData);
      }
      delete errors.educationalCredentialAssessment;
      return true;
    }
  }

  const checkValidEPQ = () =>  {
    if (equivalencyProcessQuebec.epqObtained === '' || 
      (equivalencyProcessQuebec.epqObtained === 'Yes' && 
        (equivalencyProcessQuebec.recognizedCredential === '' && equivalencyProcessQuebec.recognizedDiplomaLevel === ''))) {
        toast.error("Please complete the Equivalency Process in Québec block !");
        setErrors(prevErrors =>({
          ...prevErrors,
          equivalencyProcessQuebec: "Please complete the Equivalency Process in Québec block !"
        }));
        return false;
    } else {
      delete errors.equivalencyProcessQuebec;
      return true;
    }
  }


  const check = () => {
    if (checkEducationDetails() && checkDateOfStudy() && checkEducationInstitution()
    && checkValidECA() && checkValidEPQ()){
      toast.success('This section has been successfully validated !')
      setErrors({});
      return true;
    } else {
      // // toast.error('Please correct the above errors !')
      return false;
    }
  }


  const validateSection = async () => {
    if (!educationOutsideCountry) {
      toast.error("Please select an option to start !");
      setErrors(prevErrors =>({
        ...prevErrors,
        equivalencyProcess: "Please select an option to start !"
      }));
      props.onValidate('educationOutsideCountry', false);
    } else if (educationOutsideCountry === 'No'){
      toast.success('This section has been successfully validated !')
      props.onValidate('educationOutsideCountry', true);
      setErrors({});
    } else {
      if  (allEducationOutsideCountry.length > 0){
        toast.success('This section has been successfully validated !')
        props.onValidate('educationOutsideCountry', true);
        setErrors({});
      }else {
        toast.error("You should have at least one Education registered !");
        setErrors(prevErrors =>({
          ...prevErrors,
          educationOutsideCountry: "You should have at least one Education registered !"
        }));
        props.onValidate('educationOutsideCountry', false);
      }
    }
  };

  return (
    <fieldset id="step5">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul> 
      {/*<legend>Informations personnelles</legend>*/}
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
          <p className='text-bold text-center'>Education Outside {props.evaluationCountry}</p>
          <div className='row p-2'>
            <p className="col-form-label text-center">{EnglishQuestion.educationOutsideCountry.educationOutsideCountry} {props.evaluationCountry}?<RequiredIndicator /></p>
            <div className="form-check ml-5">
              <input 
                type="radio"
                className="form-check-input"
                name="educationOutsideCountry"
                value="Yes"
                onChange={handleChange}
                checked={educationOutsideCountry === 'Yes'}
              />
              <p className="form-check-label">Yes</p>
            </div>
            <div className="form-check ml-5">
              <input 
                type="radio"
                className="form-check-input"
                name="educationOutsideCountry"
                value="No"
                onChange={handleChange}
                checked={educationOutsideCountry === 'No'}
              />
              <p className="form-check-label">No</p>
            </div>
            {/*{loading && <LoadingOverlay />*/}
          </div>
          {educationOutsideCountry === 'Yes' && (
            <div>
              <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='row'>
                  <div className='col-sm col-6 mt-2 text-center'>Level</div>
                  <div className='col-sm col-6 mt-2 text-center'>Name</div>
                  <div className='col-sm col-6 mt-2 text-center'>Fulltime</div>
                  <div className='col-sm col-6 mt-2 text-center'>Country</div>
                  <div className='col-sm col-6 mt-2 text-center'>Actions</div>
                </div>
              </div>
              <div>
                {allEducationOutsideCountry && allEducationOutsideCountry.length > 0 ? (
                  allEducationOutsideCountry.map((educationOut, index) => (
                    <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }} key={index}>
                      <div className='row'>
                        <div className='col-sm col-6 mt-2 text-center'>{educationOut.educationDetail.degreelevel}</div>
                        <div className='col-sm col-6 mt-2 text-center'>{educationOut.educationInstitution.institutionName}</div>
                        <div className='col-sm col-6 mt-2 text-center'>{educationOut.educationInstitution.fullTimeStudent}</div>
                        <div className='col-sm col-6 mt-2 text-center'>{educationOut.educationInstitution.country}</div>
                        <div className='col-sm col-6 mt-2 text-center'>
                          <button
                            className="btn btn-danger"
                            onClick={() => setAllEducationOutsideCountry(allEducationOutsideCountry.filter((_, i) => i !== index))}
                          >
                            <i className="nav-icon fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='text-center'>No education on file</div>
                )}
              </div>
            </div>          
          )}
        </div>
        {educationOutsideCountry === 'Yes' && (
            <div>
                <EducationDetailSection error={errors.educationDetail ? errors.educationDetail : ''}
                  sendEducationDetail={receivedEducationDetail} educationDetail={educationDetail} />
                <DateofStudySection error={errors.dateofStudy ? errors.dateofStudy : ''}
                  sendDateofStudy={receivedDateofStudy} dateofStudy={dateofStudy} />
                <EducationInstitutionSection error={errors.educationInstitution ? errors.educationInstitution : ''}
                  sendEducationInstitution={receivedEducationInstitution} educationInstitution={educationInstitution} />
                <EducationalCredentialAssessmentSection error={errors.educationalCredentialAssessment ? errors.educationalCredentialAssessment : ''}
                sendEducationalCredentialAssessment={receivedEducationalCredentialAssessment}
                educationalCredentialAssessment={educationalCredentialAssessment}
                previousCredentials={previousCredentials} sendPreviousCredentials={receivedPreviousCredentials}
                />
                {props.evaluationCountry ==='Canada' && (
                    <EquivalencyProcessQuebecSection error={errors.equivalencyProcessQuebec ? errors.equivalencyProcessQuebec : ''}
                      sendEquivalencyProcessQuebec={receivedEquivalencyProcessQuebec} equivalencyProcessQuebec={equivalencyProcessQuebec} />
                )}
            </div>
        )}
        {educationOutsideCountry === 'Yes' && (
          <div className='d-flex justify-content-center mt-2 m-2'>
            <button type='button' className='col-sm-10 btn btn-block btn-secondary rounded-pill' onClick={handleAllEducation}>Save Education</button>
          </div>
        )}
        <div className='d-flex justify-content-center'>
          <div className='col-sm-10'>
            <div className='d-flex justify-content-between'>
              <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save the form" onClick={saveForm}/>
              <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Next" onClick={pressNext}/>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value="Validate this section" onClick={validateSection}/>
        </div>
    </div>
      <ToastContainer />
    </fieldset>
  );
};

export default EducationOutsideCountry;