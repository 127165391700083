import api from "../api";



export const UpdateProfilePicture = async (data) => {
    try{
        const response = await api.post('/user/update-profile-picture/', data);
        return response;

    } catch (error){
        // console.error('Error when updating form:', error);
        throw error;
    }   
};


export const SendMessageToFlous = async (data) => {
    try{
        const response = await api.post('/user/send-getintouch-message/', data);
        return response;

    } catch (error){
        throw error;
    }   
};


export const UpdateUserInformation = async (data) => {
    try{
        const response = await api.post('/user/upadte-personal-information/', data);
        return response;

    } catch (error){
        throw error;
    }   
};


export const UpdatePasword = async (data) => {
    try{
        const response = await api.post('/user/upadte-password/', data);
        return response;

    } catch (error){
        throw error;
    }   
};


export const UpdateCompanyProfile = async (data) => {
    try{
        const response = await api.post('/enterprise/update-company-profile/', data);
        return response;

    } catch (error){
        throw error;
    }   
};