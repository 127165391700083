import React, {useState} from 'react';
import api from '../../services/api';

const ForgotPasswordPage = () => {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const response = await api.post('/auth/reset-password/', { email });
          if (!response){
            setMessage("Email with existing account must be provided.");
          } else if (response.status === 200){
            setMessage(response.data.message);
            setEmail('');
          } else if (response.status === 400){
            setMessage(response.data.error);
          }
      } catch (error) {
          setMessage(error.response.data.error);
      }
  };


  return (
    <div className='relative overflow-hidden bg-[linear-gradient(270deg,#7FA1F8_0%,#FDFEFE_88%)] min-h-screen'>
      <div className="top-[20px]  bottom-0">
        <img src={`${process.env.PUBLIC_URL}/flous/bg/login-bg.png`} alt="" className="w-[900px] ml-[35%] sm:ml-[60%]   object-cover z-0 " />
      </div>
      <div className='absolute top-0 left-0 right-0 bottom-0 p-2'>
        <div className="flex justify-start ml-5 mt-3">
          <a href="/">
            <img
              src={`${process.env.PUBLIC_URL}/flous/logos/trans_1.svg`}
              alt="Flous Logo"
              className="brand-image"
              style={{ opacity: 0.8, height: '150px', width: 'auto' }}
            />
          </a>
        </div>
        <div className="d-flex justify-center align-items-start min-h-screen pt-10">
          <div className="col-lg-6 mx-auto">
            <div className="card card-outline elevation-2 p-5" style={{ borderRadius: '15px' }}>
              <div className="card-header text-center">
                <h3 className="flous-police-one">Forgot Password?</h3>
              </div>

              {message && <div className="text-center mb-2 mt-2" style={{ color: 'green' }}>{message}</div>}

              <div className="card-body">
                <p className="text-center">Enter below the email address associated with your account</p>
                <form onSubmit={handleSubmit}>
                  <label className="input-group col-lg-8 mx-auto">Email</label>
                  <div className="input-group mb-3 col-lg-8 mx-auto">
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                      </div>
                    </div>
                    <input
                      type="email"
                      placeholder="Email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      required
                      autoComplete="email"
                    />
                  </div>

                  <div className="col-lg-8 mx-auto">
                    <button type="submit" className="btn flous-btn-gradient btn-block rounded-pill">Reset Password</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
