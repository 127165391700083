import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

const LoginPage = () => {

const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { login, user } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigate = useNavigate();

  const location = useLocation();
  const message = location.state?.message;

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      
      const response = await login(username, password);

      if (response && [200, 201].includes(response.status)) {
        // const data = await response.json();
        setErrorMessage('');
        setUsername('');
        setPassword('');
      }

    } catch (error) {
      if (error.response && [400, 401, 500].includes(error.response.status)){
        setErrorMessage('An error has occurred. Please try again later.');
        setPassword('');
      }
    }
  };

  
  useEffect(() => {
    if (user) {
      if (user.role === 'Customer' && user.self_analyst) {
        navigate('/user/dashboard', { state: { user: user } });
      } else if (user.role === 'Customer' && !user.self_analyst) {
        navigate('/client/forms', { state: { user: user } });
      } else if (user.role === 'Advisor') {
        navigate('/agent/dashboard', { state: { user: user } });
      } else if (user.role === 'AssociateAdmin') {
        //console.log(user);
        if (user.type_package === 'Enterprise') {
          navigate('/enterprise/dashboard', { state: { user: user } });
        } else {
          navigate('/professional/dashboard', { state: { user: user } });
        }
      } else if (user.role === 'Flous') {
        navigate('/flous/dashboard', { state: { user: user } });
      } else {
        navigate('/');
      }
     }
  }, [user, navigate]);

  return (
    <div className='relative overflow-hidden bg-[linear-gradient(270deg,#7FA1F8_0%,#FDFEFE_88%)] min-h-screen'>
      <div className="top-[20px]  bottom-0">
        <img src={`${process.env.PUBLIC_URL}/flous/bg/login-bg.png`} alt="" className="w-[900px] ml-[35%] sm:ml-[60%]   object-cover z-0 " />
      </div>
      <div className='absolute top-0 left-0 right-0 bottom-0 p-2'>
        <div className="flex justify-start sm:ml-5 mt-3">
          <a href="/">
            <img
              src={`${process.env.PUBLIC_URL}/flous/logos/trans_1.svg`}
              alt="Flous Logo"
              className="brand-image"
              style={{ opacity: 0.8, height: '150px', width: 'auto' }}
            />
          </a>
        </div>
        <div className="flex justify-center items-start min-h-screen pt-10">
          <div className="col-lg-6 mx-auto">
            {message && (
              <div className="text-center h4 mb-4" style={{ color: 'green' }}>
                {message}
              </div>
            )}
            <div className="card card-outline elevation-2 p-5" style={{ borderRadius: '15px' }}>
              <div className="card-header text-center">
                {message ? (
                  <h3 className="flous-police-one">Login</h3>
                ) : (
                  <h3 className="flous-police-one">Welcome back</h3>
                )}
              </div>
              <div className="card-body">
                {errorMessage && (
                  <div className="text-center mt-2 mb-2" style={{ color: 'red' }}>
                    {errorMessage}
                  </div>
                )}
                <form onSubmit={handleSubmit}>
                  <label className="input-group col-lg-8 mx-auto">Email</label>
                  <div className="input-group mb-3 col-lg-8 mx-auto">
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                      </div>
                    </div>
                    <input
                      type="email"
                      value={username}
                      placeholder="Email"
                      onChange={(e) => setUsername(e.target.value)}
                      className="form-control"
                      name="email"
                      required
                      autoComplete="email"
                    />
                  </div>
                  <label className="input-group col-lg-8 mx-auto">Password</label>
                  <div className="input-group mb-3 col-lg-8 mx-auto">
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-lock"></span>
                      </div>
                    </div>
                    <input
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      required
                      autoComplete="current-password"
                    />
                    <button
                      type="button"
                      style={{
                        position: 'absolute',
                        right: '10px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                      }}
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? (
                        <i className="nav-icon fas fa-eye-slash"></i>
                      ) : (
                        <i className="nav-icon fas fa-eye"></i>
                      )}
                    </button>
                  </div>
                  <div className="input-group mb-3 col-lg-8 mx-auto row">
                    <a className="btn col" href="/forgotpassword">
                      Forgot password ?
                    </a>
                  </div>
                  <div className="col-lg-8 mx-auto">
                    <button type="submit" className="btn flous-btn-gradient btn-block rounded-pill">
                      Continue
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
