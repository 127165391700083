import React, { useState } from 'react';
import { canadaStatesConverter } from '../../../../services/utils';
import { DeleteScenario } from '../../../../services/users/ResultsServices';
import LoadingOverlay from '../../../general/LoadingOverlay';
import { toast } from 'react-toastify';
import { useOutletContext } from 'react-router-dom';

export default function EvaluationPage(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [statesList, setProvinceList] = useState(props.statesList);
  const [principalResults, setPrincipalResults] = useState(props.principalResults);
  const [partnerResults, setPartnerResults] = useState(props.partnerResults);

  const [activeProvince, setActiveProvince] = useState(statesList[0]);
  const [principalPrograms, setPrincipalPrograms] = useState(principalResults[statesList[0]]);
  const [partnerPrograms, setPartnerPrograms] = useState(props.isCoApplication? partnerResults[statesList[0]]: []);

  const { user } = useOutletContext

  const handleProvinceClick = (province) => {
    setActiveProvince(province);
    setPrincipalPrograms(principalResults[province]);
    setPartnerPrograms(props.isCoApplication? partnerResults[province]: []);
  };


  const handleDeleteScenario = async (code) =>{
    setLoading(true);
    try{
      const response = await DeleteScenario(code);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
      window.location.reload();
      toast.success(response.data.message);
    }  catch (error){
      console.error('Error when deleting Program Evaluation:', error);
      setLoading(false);
      toast.error('Error when deleting Program Evaluation.');
      throw error;
    }
  }

  if (loading) {
    return <LoadingOverlay />
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div id='evaluation'>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <h4 className='text-bold'>General Information</h4>
        <hr />
        <div className='mx-3'>
          <div className="form-group row">
            <label htmlFor="inputCountry" className="col-sm-3 col-form-label">Country :</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputCountry" value={props.evaluationCountry} />
            </div>
            <label htmlFor="inputEvaluationNumber" className="col-sm-3 col-form-label">Evaluation code :</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputEvaluationNumber" value={props.appCode} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputDate" className="col-sm-3 col-form-label">Date :</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputDate" value={props.appCreationDate} />
            </div>
            <label htmlFor="inputTotalProg" className="col-sm-3 col-form-label">Total Programs :</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputTotalProg" value={props.totalProgram} />
            </div>
          </div>
        </div>
      </div>
      <div className="elevation-1 p-2 mb-3" style={{ borderRadius: '15px' }}>
        <div className="row">
          {statesList.map((element, index) => (
            <div className="col" key={index}>
              <div
                className={`btn btn-block ${activeProvince === element ? 'flous-bg-thirty text-bold flous-input-color' : ''}`}
                onClick={() => handleProvinceClick(element)}
              >
                {canadaStatesConverter(element)}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        {props.isCoApplication && 
        (
          <div className="card flous-bg-thirty p-3 m-1 mb-3" style={{ borderRadius: '15px' }}>
            <h4 className="pl-1 text-bold">Principal Applicant</h4>
          </div>
        )}
        <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
          <div className='row text-center text-bold'>
            <div className='col'>Program</div>
            <div className='col'>Score</div>
            <div className='col'>Points</div>
            <div className='col-3'>Actions</div>
          </div>
        </div>
        {principalPrograms.map((program, index) => (
          <div className='card p-2 m-1' style={{ borderRadius: '15px' }} key={index}>
            <div className='row'>
              <div className='col'>{program.name}</div>
              <div className='col text-center'>{program.score} %</div>
              <div className='col text-center'>{program.points === 0.0 ? '-':program.points}</div>
              <div className='col-3'>
                <a href={`/user/result/detail/${program.code}`} className="btn flous-bg-menu mr-2">
                  Detail <i className="nav-icon fas fa-eye"></i>
                </a>
                <button type="button" className="btn btn-secondary mr-2" onClick={()=>handleDeleteScenario(program.code)}>
                  <i className="nav-icon fas fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
        ))}
        {props.isCoApplication && (
          <>
          <div className="card flous-bg-thirty p-3 m-1 mt-3 mb-3" style={{ borderRadius: '15px' }}>
            <h4 className="pl-1 text-bold">Partner as first Applicant</h4>
          </div>
          <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
            <div className='row text-center text-bold'>
              <div className='col'>Program</div>
              <div className='col'>Score</div>
              <div className='col'>Points</div>
              <div className='col-3'>Actions</div>
            </div>
          </div>
          {partnerPrograms.map((program, index) => (
            <div className='card p-2 m-1' style={{ borderRadius: '15px' }} key={index}>
              <div className='row'>
                <div className='col'>{program.name}</div>
                <div className='col  text-center'>{program.score} %</div>
                <div className='col  text-center'>{program.max_points === 0.0 ? '-':program.points}</div>
                <div className='col-3  text-center'>
                  <a href={user.role === 'Customer' ? `/user/result/detail/${program.code}` : 
                    (user.type_package === 'Enterprise'? `/enterprise/customer/view/result/program/${program.code}` : `/professional/customer/view/result/program/${program.code}`)} className="btn flous-bg-secondary mr-2">
                    Detail <i className="nav-icon fas fa-eye"></i>
                  </a>
                  <button type="button" className="btn btn-secondary mr-2"  onClick={()=>handleDeleteScenario(program.code)}>
                    <i className="nav-icon fas fa-trash"></i>
                  </button>
                </div>
              </div>
            </div>
          ))}
          </>
        )}
      </div>
    </div>
  );
}
