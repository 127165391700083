import React, { useState, useEffect } from 'react';
import { sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import EnglishQuestion from "../../questions/englishQuestions.json";

const EducationDetailSection = (props) => {
  const [formData, setFormData] = useState(props.educationDetail);

  const [degreeLevels, setDegreeLevel] = useState([]);
  const [studyDurations, setStudyDurations] = useState([]);
  const [trainingFields, setTrainingFields] = useState([]);

  useEffect(() => {
    const fetchLevels = async () =>{
        const response = await api.get('/educationlevel/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setDegreeLevel(options);
    };
    const fetchStudyDuration = async () =>{
        const response = await api.get('/yearsofstudy/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setStudyDurations(options);
    };

    const fetchtrainingFields = async () =>{
        const response = await api.get('/trainingfieldoutside/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setTrainingFields(options);
    };

    fetchtrainingFields();
    fetchLevels();
    fetchStudyDuration();

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendEducationDetail(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Education Details</p>
        <div className='row p-2'>
          <p htmlFor="degreelevel" className="col-sm-6 col-form-label">{EnglishQuestion.educationOutsideCountry.degreelevel}<RequiredIndicator /></p>
          <Select
            name="degreelevel"
            className="react-select-container rounded-pill flous-input-color col-sm-6"
            classNamePrefix="react-select"
            value={degreeLevels.find(option => option.value === formData.degreelevel)}
            onChange={(selectedOption) => handleChange({ target: { name: 'degreelevel', value: selectedOption ? selectedOption.value : '' } })}
            options={degreeLevels}
            placeholder="Please select an option"
            isSearchable
            isClearable
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100%',
                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
              }),
            }}
            required
          />
        </div>
        <div className='row p-2'>
          <p htmlFor="fieldOfStudy" className="col-sm-6 col-form-label">{EnglishQuestion.educationOutsideCountry.generalFieldOfStudy}<RequiredIndicator /></p>
          <Select
            name="fieldOfStudy"
            className="react-select-container rounded-pill flous-input-color col-sm-6"
            classNamePrefix="react-select"
            value={trainingFields.find(option => option.value === formData.fieldOfStudy)}
            onChange={(selectedOption) => handleChange({ target: { name: 'fieldOfStudy', value: selectedOption ? selectedOption.value : '' } })}
            options={trainingFields}
            placeholder="Please select an option"
            isSearchable
            isClearable
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100%',
                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
              }),
            }}
            required
          />
        </div>
        <div className='row p-2'>
          <p htmlFor="programDuration" className="col-12 col-form-label">{EnglishQuestion.educationOutsideCountry.programDuration}<RequiredIndicator /><i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltip1" data-tooltip-content="Length of time for completing the study program, excluding any delays or interruptions."></i></p>
          <Tooltip id='tooltip1' />
          <Select
            name="programDuration"
            className="react-select-container rounded-pill flous-input-color col-sm-12"
            classNamePrefix="react-select"
            value={studyDurations.find(option => option.value === formData.programDuration)}
            onChange={(selectedOption) => handleChange({ target: { name: 'programDuration', value: selectedOption ? selectedOption.value : '' } })}
            options={studyDurations}
            placeholder="Please select an option"
            isSearchable
            isClearable
            styles={{
              control: (provided) => ({
                ...provided,
                width: '100%',
                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
              }),
            }}
            required
          />
        </div>
      </div>
    </div>
  );
};

export default EducationDetailSection;
