import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { assignCustomerToAdvisor } from "../../../services/enterprise/CustomersServices";
import LoadingOverlay from "../../general/LoadingOverlay";
import Select from 'react-select';

const AssignToAdvisorsModal = ({ customer, advisors }) => {
  const [selectedAdvisor, setSelectedAdvisor] = useState("");
  const selectedCustomer = customer.code
  const [loading, setLoading] = useState(false);

  const handleAdvisorChange = (e) => {
    setSelectedAdvisor(e.target.value);
  };

  const handleAssignTo = async () =>{
      // setLoading(true);
      try{
        const response = await assignCustomerToAdvisor(selectedCustomer, selectedAdvisor);
        if (response && response.status===200) {
            // await new Promise((resolve) => setTimeout(resolve, 500));
            // setLoading(false);
            window.location.reload();
        }

      }  catch (error){
          if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
              // setLoading(false);
          }
      }
  }

  if (loading) {
      return <LoadingOverlay />;
  }

  return (
    <div id="assignToAdvisor" className="modal fade" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="modalTitle">Assignment - {customer.firstName} {customer.lastName}</h4>
            <button type="button" className="close" data-dismiss="modal">&times;</button>
          </div>
          <div className="modal-body">
            <div className="col">
              <div className="form-group">
                <label htmlFor="advisors">Choose the advisor</label>
                <Select
                  name="advisors"
                  id="advisors"
                  classNamePrefix="react-select"
                  value={advisors.find(option => option.value === selectedAdvisor)}
                  // onChange={handleAdvisorChange}
                  onChange={(selectedOption) =>
                    handleAdvisorChange({ target: { name: 'advisors', value: selectedOption? selectedOption.value: ''} })
                  }
                  options={advisors}
                  placeholder="Select an advisor"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: '100%',
                      borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                      borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                    }),
                  }}
                />
              </div>
            </div>
            <div className="col">
              <button className="btn flous-bg-primary" onClick={handleAssignTo}>
                Assign now
              </button>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default" data-dismiss="modal">
              Close
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AssignToAdvisorsModal;
