import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const PreferredLanguage = (props) => {
  const [preferredLanguage, setPreferredLanguage] = useState(props.preferredLanguage);

  const languageOptions = [
    { value: 'FR', label: 'French' },
    { value: 'EN', label: 'English' }
  ];

  const handleLanguageChange = (event) => {
    setPreferredLanguage(event.target.value);
    props.sendPreferredLanguage(event.target.value);
  };

  return (
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
          <div className=''>
            <p htmlFor="preferredLanguage" className=''>{EnglishQuestion.personalInfo.preferredLanguage}<RequiredIndicator /></p>
            <div className=''>
              <Select
                id="preferredLanguage"
                name="preferredLanguage"
                className="react-select-container rounded-pill flous-input-color"
                classNamePrefix="react-select"
                value={languageOptions.filter(option => preferredLanguage.includes(option.value))}
                onChange={(selectedOptions) =>
                  handleLanguageChange({
                    target: {
                      name: 'preferredLanguage',
                      value: selectedOptions ? selectedOptions.map(option => option.value) : []
                    }
                  })
                }
                options={languageOptions}
                placeholder="Please select preferred languages"
                isSearchable
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                  }),
                }}
                isMulti
                required
              />
            </div>
          </div>
        </div>
      </div>
  );
};

export default PreferredLanguage;
