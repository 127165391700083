import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from '../../../../../services/api';
import CountryShow from '../../../../general/countryShow';


export default function PreviousAddressResumeSection(props) {

  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
      const fetchProvinces = async() => {
        
          const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
        
      };
  
      fetchProvinces();
  }, []);

  return (<div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className='mx-3'>
            <div className="form-group">
              <div>
                <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                  <div className='row'>
                    <div className='col-sm col-6 mt-2 text-center'>Start Date</div>
                    <div className='col-sm col-6 mt-2 text-center'>End Date</div>
                    <div className='col-sm col-6 mt-2 text-center'>Country</div>
                    <div className='col-sm col-6 mt-2 text-center'>Province/State</div>
                    <div className='col-sm col-6 mt-2 text-center'>City</div>
                  </div>
                </div>

                {props.previousAddresses.map((address, index) => (
                  <div key={index} className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }}>
                    <div className='row'>
                      <div className='col-sm col-6 mt-2 text-center'>{address.startDate}</div>
                      <div className='col-sm col-6 mt-2 text-center'>{address.endDate}</div>
                      <div className='col-sm col-6 mt-2 text-center'><CountryShow value={address.country}/></div>
                      {address.country === 'CAN' ? (
                      <div className='col-sm col-6 mt-2 text-center'>{provinces.find(province => province.value === address.provinceState)?.label || ''}</div>
                      ): (<div className='col-sm col-6 mt-2 text-center'>{address.provinceState}</div>)}
                      <div className='col-sm col-6 mt-2 text-center'>{address.city}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
        </div>
      </div>
  );
}
