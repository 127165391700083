import React, {useEffect, useState} from 'react';
import { getCurrentItems } from '../../services/users/FormsServices';
import PaginationSection from '../Layout/PaginationSection';
import CreateNewCustomersModal from './modal/createNewCustomersModal';
import { fetchCustomersData } from '../../services/enterprise/CustomersServices';
import { useOutletContext } from 'react-router-dom';
import LoadingOverlay from '../general/LoadingOverlay';
import { toast, ToastContainer } from 'react-toastify';
import InfoBoxThirtyLink from '../general/InfoBoxThirtyLink';
import InfoBoxPrimaryLink from '../general/InfoBoxPrimaryLink';
import EnterpriseDashbordChart from './item/DashBordChart';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import { EnterpriseCustomersEvolutions, EnterpriseCustomersStates } from '../../services/general/DashbordServices';
import CustomerEvolutionCard from '../general/CustomersEvoluationsChart';
import GoToBackButton from '../general/GoBackLink';

export default function EntrepriseCustomers() {
  
  const [formsData, setFormsData] = useState({
    formsInProgress: 0,
    formsCompleted: 0,
    formsInactive: 0,
    totalActiveCustomers: 0
  });

  const [thisYear, setThisYear] = useState([, , , , , , , , , , , ]);
  const [lastYear, setLastYear] = useState([, , , , , , , , , , , ]);
  const [labels, setLabels] = useState(['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']);

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };

  const {user} = useOutletContext();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [customers, setCustomers] = useState([]);
  
  useEffect( () => {
    let isMounted = true;
  
    const getEnterpriseCustomersStates = async () => {
      if (user.id) {
        try {
          const response = await EnterpriseCustomersStates();
          const response_evolution = await EnterpriseCustomersEvolutions();
          if (isMounted) {
            if (response && response.status === 200){
              setFormsData(response.data.data);
              setThisYear(response_evolution.data.data.thisYear)
              setLastYear(response_evolution.data.data.lastYear)
              setLabels(response_evolution.data.data.labels)
              setLoading(false);
              isMounted = false;
            }
          }
        } catch (error) {
          if (isMounted){
            setError(error.response.data.error);
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
              isMounted = false;
            }
          }
        }
      }
    };
  
    getEnterpriseCustomersStates();
  
    return () => {
      isMounted = false;
    };
  
  }, [user]);
  
  
  useEffect( () => {
    let isMounted = true;

    const getCustomers = async () => {
      if (user.id) {
        try {
          const response = await fetchCustomersData();
          if (isMounted) {
            console.log(response);
            setCustomers(response.data.data);
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setError(error);
            setLoading(false);
            throw error;
          }
        }
      }
    };

    getCustomers();

    return () => {
      isMounted = false;
    };

  }, [user]);
  
  const currentItems = getCurrentItems(customers, page, pageSize);

  
  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div className="content">
      <div className="container-fluid">
        {/* Content Header */}
        <div className="content-header">
          <div className="mb-2">
              <GoToBackButton />
              <div className="col-sm-6">
                <h1 className="flous-police-one flous-input-color m-0">Clients</h1>
              </div>
          </div>
        </div>
        {/* /.content-header */}

        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col">
                <div className='card p-2 px-5 elevation-2' style={{ borderRadius: '15px' }}>
                  <div className="row">
                    <div className="col-lg-3 col-6">
                      <InfoBoxPrimaryLink
                        value={formsData.formsInProgress}
                        text="Forms in progress"
                        link='More info'
                        href='/enterprise/customers/list'
                      />
                    </div>
                    <div className="col-lg-3 col-6">
                      <InfoBoxThirtyLink
                        value={formsData.formsCompleted}
                        text="Forms completed"
                        link='More info'
                        href='/enterprise/customers/list'
                      />
                    </div>
                    {/* ./col */}
                    <div className="col-lg-3 col-6">
                      <InfoBoxPrimaryLink
                        value={formsData.totalActiveCustomers}
                        text="Total Clients"
                        link='More info'
                        href='/enterprise/customers/list'
                      />
                    </div>
                    {/* ./col */}
                    <div className="col-lg-3 col-6">
                      <InfoBoxThirtyLink
                        value={formsData.formsInactive}
                        text="Total inactive Clients"
                        link='More info'
                        href='/enterprise/customers/list'
                      />
                    </div>
                  </div>
                </div>

                {/* Charts and Tables */}
                <div className="row">
                  <div className="col-lg-6">
                  <CustomerEvolutionCard 
                    elementId="customers-evoluation" 
                    thisYear={thisYear} lastYear={lastYear} 
                    totalCustomers={formsData.totalActiveCustomers} labels={labels} />
                  </div>

                  <div className="col-lg-6">
                    <div className="card elevation-2">
                      <div className="card-header">
                        <div className="card btn">
                          <h4 className="flous-police-one">Clients</h4>
                        </div>
                        <div className="text-right">
                          <button type="button" className="btn flous-bg-primary" data-toggle="modal" data-target="#newCustomers">
                            Add New Client <i className="nav-icon fas fa-user"></i>
                          </button>
                        </div>
                        <CreateNewCustomersModal />
                      </div>
                      {/* /.card-header */}
                      <div className="card-body">
                        <div className='flous-bg-thirty' style={{ borderRadius: '15px' }}>
                          <div className='card-header p-1 mb-2 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                              <div className='row text-bold'>
                                  <div className='col-sm col-6 mt-2 text-center'>Code</div>
                                  <div className='col-sm col-6 mt-2 text-center'>Date</div>
                                  <div className='col-sm col-6 mt-2 text-center'>First Name</div>
                                  <div className='col-sm col-6 mt-2 text-center'>Last Name</div>
                                  <div className='col-sm col-6 mt-2 text-center'>Advisors</div>
                              </div>
                          </div>
                          {currentItems.length > 0 ? (
                            currentItems.map((customer) => (
                            <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px' }}>
                              <div className='row'>
                                  <div className='col-sm col-6 mt-2 text-center'>{customer.code}</div>
                                  <div className='col-sm col-6 mt-2 text-center'>{customer.date}</div>
                                  <div className='col-sm col-6 mt-2 text-center'>{customer.firstName}</div>
                                  <div className='col-sm col-6 mt-2 text-center'>{customer.lastName}</div>
                                  <div className='col-sm col-6 mt-2 text-center'>{customer.advisor}</div>
                              </div>
                            </div>
                            ))
                          ) : (
                            <NoItemsMessage />
                          )}
                        </div>
                      </div>
                      <PaginationSection
                        page={page}
                        pageSize={pageSize}
                        totalItems={customers.length}
                        onPageChange={onPageChange}
                      />
                      {/* /.card-body */}
                      <div className="card-footer text-center">
                        <a href="/enterprise/customers/list" className="btn flous-btn-primary">See more</a>
                      </div>
                    </div>
                    {/* /.card */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /.content */}
      </div>
      <ToastContainer />
    </div>
  );
};