import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

const TopMenu = ({userprofile}) => {

  const [isPushMenuActive, setIsPushMenuActive] = useState(false);
  const { user } = useOutletContext();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('unread'); // "all" or "unread"
  const [selectedMessage, setSelectedMessage] = useState(null);

  const allMessages = [
    { id: 1, title: 'Message 1', content: 'Contenu du message 1', read: true },
    { id: 2, title: 'Message 2', content: 'Contenu du message 2', read: false },
    { id: 3, title: 'Message 3', content: 'Contenu du message 3', read: true },
  ];

  const filteredMessages = allMessages.filter((message) =>
    activeTab === 'all' ? true : !message.read
  );

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    setSelectedMessage(null)
  };

  useEffect(() => {
    const handlePushMenuToggle = () => {
      setIsPushMenuActive(prevState => !prevState);
    };

    // Assurez-vous que l'élément avec data-widget="pushmenu" déclenche cette fonction
    document.querySelector('[data-widget="pushmenu"]').addEventListener('click', handlePushMenuToggle);

    return () => {
      // document.querySelector('[data-widget="pushmenu"]').removeEventListener('click', handlePushMenuToggle);
    };
  }, []);


  return (
    <>
    <nav
      className={`main-header navbar navbar-expand navbar-white navbar-light shadow flous-bg-topmenu ${
        isPushMenuActive ? 'w-full' : 'col-lg-10 col-12'
      }`}
      style={{ borderRadius: '15px', position: 'fixed', top: 0, width: '100%', zIndex: 1000 }}
    >
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <a className="nav-link" data-widget="pushmenu" href="/notfound/" role="button"><i className="fas fa-bars"></i></a>
        </li>
          {(user.role === 'Customer' && !user.self_analyst)? (
              <>
                <li className="nav-item d-none d-sm-inline-block">
                  <a href="https://portal.myflous.com" className="nav-link">Home</a>
                </li>
              </>
          ):(
              <>
                <li className="nav-item d-none d-sm-inline-block">
                  <a href="https://www.myflous.com" className="nav-link">Home</a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                  <a href="https://www.myflous.com/pricing/" className="nav-link">Pricing</a>
                </li>
              </>
          )} 
      </ul>

      {/* Right navbar links  Uncomment if needed */}
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <a className="nav-link" data-widget="fullscreen" href="#" role="button">
            <i className="fas fa-expand-arrows-alt"></i>
          </a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#"
            onClick={toggleSidebar}
            //onMouseEnter={() => setIsSidebarOpen(true)}
            >
            <i className="far fa-bell"></i>
            <span className="badge flous-bg-menu navbar-badge">15</span>
          </a>
          <div
            className={`fixed top-0 right-0 h-full w-96 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
              isSidebarOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <div className="p-4">
              <div className="fixed top-0 right-0 h-full w-96 bg-white shadow-lg p-4">
                <div className="flex justify-between items-center">
                  <h3 className="text-lg font-bold text-gray-800">Notifications</h3>
                  <button
                    className="text-gray-500 hover:text-red-500"
                    onClick={toggleSidebar}
                  >
                    ✖
                  </button>
                </div>
                {/* Tabs */}
                <div className="flex border-b">
                  <button
                    className={`flex-1 py-2 text-center ${
                      activeTab === 'all' ? 'border-b-4 border-blue-500 font-semibold' : ''
                    }`}
                    onClick={() => {
                      setActiveTab('all');
                      setSelectedMessage(null);
                    }}
                  >
                    All
                  </button>
                  <button
                    className={`flex-1 py-2 text-center ${
                      activeTab === 'unread' ? 'border-b-4 border-blue-500 font-semibold' : ''
                    }`}
                    onClick={() => {
                      setActiveTab('unread');
                      setSelectedMessage(null);
                    }}
                  >
                    Unread
                  </button>
                </div>
                {/* Messages List */}
                {!selectedMessage && (
                  <div className="mt-4">
                    {filteredMessages.length > 0 ? (
                      <ul>
                        {filteredMessages.map((message) => (
                          <li
                            key={message.id}
                            className="border-b py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => setSelectedMessage(message)}
                          >
                            <h4 className="text-sm font-bold">{message.title}</h4>
                            <p className="text-xs text-gray-500 truncate">{message.content}</p>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p className="text-gray-500">Vous n'avez aucun message.</p>
                    )}
                  </div>
                )}
                {/* Selected Message */}
                {selectedMessage && (
                  <div className="mt-4">
                    <h3 className="text-lg font-bold">{selectedMessage.title}</h3>
                    <p className="text-sm text-gray-700 mt-2">{selectedMessage.content}</p>
                    <button
                      className="btn flous-bg-primary text-sm mb-2"
                      onClick={() => setSelectedMessage(null)}
                    >
                      Retour à la liste
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </li>
        
        {/* <li className="nav-item">
          <a className="nav-link" data-widget="control-sidebar" data-slide="true" href="/notfound/" role="button">
            <i className="fas fa-th-large"></i>
          </a>
        </li> */}
      </ul>
    </nav>
    <div style={{ height: '70px' }}></div>
    </>
  );
};

export default TopMenu;
