const formData = {
    forms: [
      {
        country_cca3: "CAN",
        code: "HJD0152245",
        creation_date: "2024-04-01",
        title: "Formulaire 1",
        state: "CP"
      }, 
      {
        country_cca3: "AUS",
        code: "HJD0152246",
        creation_date: "2024-04-01",
        title: "Formulaire 2",
        state: "IP"
      },
      {
        country_cca3: "FRA",
        code: "HJD0152247",
        creation_date: "2024-04-01",
        title: "Formulaire 3",
        state: "IP"
      },
      {
        country_cca3: "CAN",
        code: "HJD0152248",
        creation_date: "2024-04-01",
        title: "Formulaire 1",
        state: "IP"
      },
      {
        country_cca3: "NZL",
        code: "HJD0152249",
        creation_date: "2024-04-01",
        title: "Formulaire 3",
        state: "AB"
      },
      {
        country_cca3: "USA",
        code: "HJD0152210",
        creation_date: "2024-04-01",
        title: "Formulaire 3",
        state: "IP"
      },
      {
        country_cca3: "CAN",
        code: "HJD0152211",
        creation_date: "2024-04-01",
        title: "Formulaire 1",
        state: "CP"
      },
      {
        country_cca3: "USA",
        code: "HJD0152212",
        creation_date: "2024-04-01",
        title: "Formulaire 3",
        state: "IP"
      },
      {
        country_cca3: "CAN",
        code: "HJD0152213",
        creation_date: "2024-04-01",
        title: "Formulaire 1",
        state: "CP"
      },       
    ],
    flousfiles: [
      {
        id: 1,
        company_name: "Global Immigration",
        logo_url: `https://i.pinimg.com/236x/bc/50/33/bc5033780fdd9c63b53ca73dcccc7b0b.jpg`,
        country_cca3: 'CAN',
        type: "Law Firm",
        languages: "FR, EN, ES",
        contact: "+1 000 000 0000",
        linkedin: 'Linkedin',
        instagram: '@GlobalImmigration',
        website: 'globalimmigration.com',
        about: 'Global Immigration provides comprehensive immigration services to individuals and businesses. We specialize in visa applications, legal consultations, and more.',
        package: "Enterprise",
        totalSeats: 500,
        registeredSeats: 480,
        status: "Active"
      },
      {
        id: 2,
        company_name: "Flous Immigration",
        logo_url: `${process.env.PUBLIC_URL}/flous/logos/logo_2.svg`,
        country_cca3: 'USA',
        type: "Law & Consultant Firm",
        languages: "FR, EN",
        contact: "+1 000 000 0001",
        linkedin: 'Linkedin',
        instagram: '@myflous',
        website: 'myflous.com',
        about: 'Global Immigration provides comprehensive immigration services to individuals and businesses. We specialize in visa applications, legal consultations, and more.',
        package: "Agent",
        totalSeats: 200,
        registeredSeats: 150,
        status: "Active"
      },
      {
        id: 3,
        company_name: "aGlobal Immigration",
        logo_url: `https://i.pinimg.com/236x/bc/50/33/bc5033780fdd9c63b53ca73dcccc7b0b.jpg`,
        country_cca3: 'AUS',
        type: "Law Firm",
        languages: "FR, EN, ES",
        contact: "+1 000 000 0000",
        linkedin: 'Linkedin',
        instagram: '@GlobalImmigration',
        website: 'globalimmigration.com',
        state: "IP",
        about: 'Global Immigration provides comprehensive immigration services to individuals and businesses. We specialize in visa applications, legal consultations, and more.',
        package: "Enterprise",
        totalSeats: 300,
        registeredSeats: 290,
        status: "Active"
      },
      {
        id: 4,
        company_name: "aFlous Immigration",
        logo_url: `${process.env.PUBLIC_URL}/flous/logos/logo_2.svg`,
        country_cca3: 'CAN',
        type: "Law Firm",
        languages: "FR, EN",
        contact: "+1 000 000 0001",
        linkedin: 'Linkedin',
        instagram: '@myflous',
        website: 'myflous.com',
        about: 'Global Immigration provides comprehensive immigration services to individuals and businesses. We specialize in visa applications, legal consultations, and more.',
        package: "Agent",
        totalSeats: 100,
        registeredSeats: 75,
        status: "Inactive"
      },
      {
        id: 5,
        company_name: "Flous Immigration",
        logo_url: `${process.env.PUBLIC_URL}/flous/logos/logo_1.svg`,
        country_cca3: 'FRA',
        type: "Consultant Firm",
        languages: "FR, EN",
        contact: "+1 000 000 0001",
        linkedin: 'Linkedin',
        instagram: '@myflous',
        website: 'myflous.com',
        about: 'Global Immigration provides comprehensive immigration services to individuals and businesses. We specialize in visa applications, legal consultations, and more.',
        package: "Enterprise",
        totalSeats: 450,
        registeredSeats: 400,
        status: "Active"
      }
    ]
  };
  
  export default formData;
  