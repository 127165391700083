// CalendlyLinkWidget.jsx
import React, { useEffect } from 'react';

const CalendlyLinkWidget = () => {
  useEffect(() => {
    const link = document.createElement('link');
    link.href = 'https://assets.calendly.com/assets/external/widget.css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);

    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.head.removeChild(link);
      document.body.removeChild(script);
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    if (window.Calendly) {
      window.Calendly.initPopupWidget({ url: 'https://calendly.com/ktapsoba80/30min' });
    }
  };

  return (
    <a href="/notfound/" onClick={handleClick}>
      Schedule Meeting
    </a>
  );
};

export default CalendlyLinkWidget;

// eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNzE5NDM4MzIxLCJqdGkiOiJkMWIyZWM0MC1jZWNkLTRmNTItODcwOC0xM2QxMTAyNjQ5YzAiLCJ1c2VyX3V1aWQiOiIyZWY0MGY1Mi02NzMxLTRiNjMtYmU3MS1jNTg0NmY1YmFmN2QifQ.adbFu42SrNuKq6EXNjQIlBY60fWPqy96MetkLu0yTDFWJfvyz3L4L2SLhS-2X0LDd6-AkDKr4qdlv5l_efbGCg

// Calendly developper

//client ID: oj1SYSwfE3lhPI7p5PEjwHYZOkIoIFfjT4-K0fxpdrQ
//client secret : 1tvFjmzs5L4LzL1tCOAYTEPMki5nfXW1fu5-4KvQHm4
//Webhook signing key : 5AHlONKOtGyuxcjWdGJEjYutQFCfEec6f_iXgBP8IPo

// https://auth.calendly.com/oauth/authorize?client_id=oj1SYSwfE3lhPI7p5PEjwHYZOkIoIFfjT4-K0fxpdrQ&response_type=code&redirect_uri=http://localhost:3000/login