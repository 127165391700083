import React, { useState } from 'react';
import RequiredIndicator from '../../../../../general/requiredIndicator';
import 'react-tooltip/dist/react-tooltip.css';

import EnglishQuestion from "../../../questions/englishQuestions.json";

const ManagementExperienceSection = (props) => {
  const [formData, setFormData] = useState(props.ownershipDetail);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendOwnershipDetail(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Management Experience</p>
        <div className='row p-2'>{EnglishQuestion.entrepreunarialExperience.managementExperience}</div>
        <div className='row p-2'>
          <p htmlFor="managementStartDate" className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.managementStartDate}</p>
          <div className="col-sm-6">
            <input 
              type="date" 
              className="form-control rounded-pill flous-input-color"
              id='managementStartDate'
              name="managementStartDate"
              value={formData.managementStartDate}
              onChange={handleChange} max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="managementEndDate" className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.managementEndDate}</p>
          <div className="col-sm-6">
            <input 
              type="date" 
              className="form-control rounded-pill flous-input-color"
              id='managementEndDate'
              name="managementEndDate"
              value={formData.managementEndDate}
              onChange={handleChange} max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagementExperienceSection;