import React, {useState, useEffect} from 'react';
import { getCurrentItems } from '../../services/users/FormsServices';
import PaginationSection from '../Layout/PaginationSection';
import FlagComponent from '../general/FlagComponent';
import InfoBoxPrimaryLink from '../general/InfoBoxPrimaryLink';
import InfoBoxThirtyLink from '../general/InfoBoxThirtyLink';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { fetchSoloData } from '../../services/flous/SoloServices';
import { toast } from 'react-toastify';
import LoadingOverlay from '../general/LoadingOverlay';
import CustomerEvolutionCard from '../general/CustomersEvoluationsChart';
import GoToBackButton from '../general/GoBackLink';
import { FlousCustomersEvolutions } from '../../services/general/DashbordServices';

export default function FlousSelfSufficient(){

  const [thisYear, setThisYear] = useState([, , , , , , , , , , , ]);
  const [lastYear, setLastYear] = useState([, , , , , , , , , , , ]);
  const [labels, setLabels] = useState(['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']);

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useOutletContext();

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };

  useEffect( () => {
    let isMounted = true;

    const getSoloDataList = async () => {
      if (user.id) {
        try {
          const response = await fetchSoloData();
          const response_evolution = await FlousCustomersEvolutions();
          console.log(response_evolution.data.data)
          if (isMounted) {
            setCustomers(response.data.data);
            setThisYear(response_evolution.data.data.thisYear)
            setLastYear(response_evolution.data.data.lastYear)
            setLabels(response_evolution.data.data.labels)
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)) {
              setError(error);
              toast.error(error.response.data.error);
            }
          }
        }
      }
    };

    getSoloDataList();

    return () => {
      isMounted = false;
    };

  }, []);
  
  const currentItems = getCurrentItems(customers, page, pageSize);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="content-header">
          <div className="container-fluid">
            <div className="mb-2">
            <GoToBackButton />
              <div className="col-sm-6">
                <h1 className="m-0 flous-police-one flous-input-color">Solo Accounts</h1>
              </div>
            </div>
          </div>
        </div>

        <section className="content">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col">
                <div className='card p-2 px-5 elevation-2' style={{ borderRadius: '15px' }}>
                  <div className="row">
                    <div className="col-lg-4 col-6">
                      <InfoBoxPrimaryLink
                        value="58"
                        text="Total compagnies"
                        link="More info"
                        href="/flous/compagnies/list"
                      />
                    </div>
                    <div className="col-lg-4 col-6">
                      <InfoBoxThirtyLink
                        value="15"
                        text="Total Clients SelfEvaluator"
                        link="More info"
                        href="/flous/self/list"
                      />
                    </div>
                    <div className="col-lg-4 col-6">
                      <InfoBoxPrimaryLink
                        value="2"
                        text="Total Never Actived Account"
                        link="More info"
                        href="/flous/compagnies/list"
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6">
                    <CustomerEvolutionCard elementId="customers-evoluation" thisYear={thisYear} lastYear={lastYear} totalCustomers={customers.length} labels={labels} title='Total Self Clients Evolutions' />
                  </div>

                  <div className="col-lg-6">
                    <div className="card elevation-2" style={{ borderRadius: '15px' }}>
                      <div className="card-header border-0">
                        <div className="card btn">
                          <h4 className="flous-police-one">List of Self Account</h4>
                        </div>
                        <div className="text-right">
                          <div className="btn flous-bg-primary" data-toggle="modal" data-target="#newAdvisors">Add new Self account</div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className='flous-bg-thirty' style={{ borderRadius: '15px' }}>
                            <div className='card-header p-2 mb-2 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                                <div className='row text-bold'>
                                    <div className='col-sm col-6 mt-2 text-center'>First Name</div>
                                    <div className='col-sm col-6 mt-2 text-center'>Last Name</div>
                                    <div className='col-sm col-6 mt-2 text-center'>Country of Request</div>
                                    <div className='col-sm col-6 mt-2 text-center'>Country of Application</div>
                                </div>
                            </div>
                            {currentItems.length > 0 ? (
                              currentItems.map((customer) => (
                              <div className='card-header p-3 mb-2 shadow bg-white' style={{ borderRadius: '15px' }}>
                                <div className='row'>
                                    <div className='col-sm col-6 mt-2 text-center'>{customer.firstName}</div>
                                    <div className='col-sm col-6 mt-2 text-center'>{customer.lastName}</div>
                                    <div className='col-sm col-6 mt-2 text-center'>
                                        <FlagComponent cca3={customer.country_cca3} />
                                    </div>
                                    <div className='col-sm col-6 mt-2 text-center'>
                                        <FlagComponent cca3={'CAN'} />
                                    </div>
                                </div>
                              </div>
                            ))) : (
                              <NoItemsMessage />
                            )}
                        </div>
                        <PaginationSection
                            page={page}
                            pageSize={pageSize}
                            totalItems={customers.length}
                            onPageChange={onPageChange}
                          />
                      </div>
                      <div className="card-footer text-center">
                        <a href="/flous/self/list" className="btn flous-btn-primary">See more</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};