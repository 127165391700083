import React, { useState, useEffect } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const EquivalencyApplicationStatusSection = (props) => {
  const [applicationProcessed, setApplicationProcessed] = useState(props.applicationProcessed);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setApplicationProcessed(value);
    props.sendApplicationProcessed(value);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
      <p className='text-bold text-center'>Equivalency Application Status</p>
        <div className='row p-2'>
          <p htmlFor="applicationProcessed" className="col-form-label">{EnglishQuestion.educationInCountry.applicationProcessed}<RequiredIndicator /></p>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input" 
              name="applicationProcessed"
              value="Yes"
              onChange={handleChange}
              checked={applicationProcessed === "Yes"}
            />
            <p htmlFor="applicationProcessedYes" className="form-check-p">Yes</p>
          </div>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input" 
              name="applicationProcessed"
              value="No"
              onChange={handleChange}
              checked={applicationProcessed === "No"}
            />
            <p htmlFor="applicationProcessedNo" className="form-check-p">No</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquivalencyApplicationStatusSection;