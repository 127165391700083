import React from 'react';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function EnglishLanguageTestResumeSection(props) {
  // Define the English language test information
  const takenEnglishTest = props.englishLanguageProficiency.englishtest; //  
  const testType = props.englishLanguageProficiency.testType; //  
  const testDate = props.englishLanguageProficiency.testDate; //  
  const speakingScore = props.englishLanguageProficiency.speakingScore; //  
  const listeningScore = props.englishLanguageProficiency.listeningScore; //  
  const writingScore = props.englishLanguageProficiency.writingScore; //  
  const readingScore = props.englishLanguageProficiency.readingScore; //  

  const completedEducationInEnglish = props.englishLanguageProficiency.frenchEducation; //  
  const speakingLevel = props.englishLanguageProficiency.speakingLevel; //  
  const listeningLevel = props.englishLanguageProficiency.listeningLevel; //  
  const writingLevel = props.englishLanguageProficiency.writingLevel; //  
  const readingLevel = props.englishLanguageProficiency.readingLevel; //  

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>English Language Test</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputTakenEnglishTest" className="col-sm-6 col-form-label">
          {EnglishQuestion.language.englishtest}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputTakenEnglishTest" value={takenEnglishTest} />
          </div>
        </div>
        {props.englishLanguageProficiency.englishtest === 'Yes' && (
        <>
        <div className="form-group row">
          <label htmlFor="inputTestType" className="col-sm-3 col-form-label">{EnglishQuestion.language.testType}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputTestType" value={testType} />
          </div>
          <label htmlFor="inputTestDate" className="col-sm-3 col-form-label">{EnglishQuestion.language.testDate}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputTestDate" value={testDate} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputSpeakingScore" className="col-sm-3 col-form-label">{EnglishQuestion.language.speaking}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputSpeakingScore" value={speakingScore} />
          </div>
          <label htmlFor="inputListeningScore" className="col-sm-3 col-form-label">{EnglishQuestion.language.listening}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputListeningScore" value={listeningScore} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputWritingScore" className="col-sm-3 col-form-label">{EnglishQuestion.language.writing}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputWritingScore" value={writingScore} />
          </div>
          <label htmlFor="inputReadingScore" className="col-sm-3 col-form-label">{EnglishQuestion.language.reading}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputReadingScore" value={readingScore} />
          </div>
        </div>
        </>
        )}
      </div>
      {props.englishLanguageProficiency.englishtest === 'Yes' && (
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputCompletedEducationInEnglish" className="col-sm-6 col-form-label">
          {EnglishQuestion.language.englishEducation}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputCompletedEducationInEnglish" value={completedEducationInEnglish} />
          </div>
        </div>
        {props.englishLanguageProficiency.englishEducation === 'Yes' && (
        <>
        <div className="form-group row">
          <label htmlFor="inputSpeakingLevel" className="col-sm-3 col-form-label">{EnglishQuestion.language.speaking}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputSpeakingLevel" value={speakingLevel} />
          </div>
          <label htmlFor="inputListeningLevel" className="col-sm-3 col-form-label">{EnglishQuestion.language.listening}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputListeningLevel" value={listeningLevel} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputWritingLevel" className="col-sm-3 col-form-label">{EnglishQuestion.language.writing}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputWritingLevel" value={writingLevel} />
          </div>
          <label htmlFor="inputReadingLevel" className="col-sm-3 col-form-label">{EnglishQuestion.language.reading}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputReadingLevel" value={readingLevel} />
          </div>
        </div>
        </>
        )}
      </div>
      )}
    </div>
  );
}