import React from 'react';

const AuthLayoutPage = ({ children }) => {
  return (
    <>
      {/*<head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Flous Login Page</title>
      </head>*/}
      <div className='flous-police-two'>
         

        <section className='content'>
          {children}
        </section>

      </div>
    </>
  );
};

export default AuthLayoutPage;
