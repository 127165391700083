import React, { useEffect, useState } from 'react';
import { getScenarioDetail } from '../../../services/users/ResultsServices';
import { useParams } from 'react-router-dom';
import LoadingOverlay from '../../general/LoadingOverlay';
import { evaluationCountryConverterCodeToName } from '../../../services/utils';
import GoToBackButton from '../../general/GoBackLink';
import { toast } from 'react-toastify';

export default function ProgramResultsDetails() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const {scenario_code} = useParams();

  const [programName, setProgramName] = useState('');
  const [creationDate, setCreationDate] = useState('');
  const [country, setCountry] = useState('');
  const [evaluationType, setEvaluationType] = useState('');
  const [legislation, setLegislation] = useState('');
  const [score, setScore] = useState(0.0);
  const [points, setPoints] = useState(0.0);
  const [maxPoints, setMaxPoints] = useState(0.0);

  const [improvementsAreas, setImprovementsAreas] = useState([]);

  const setEvaluationDetails = async (response) => {
    setProgramName(response.data.name);
    setCreationDate(response.data.date);
    setEvaluationType(response.data.evaluation_type);
    setLegislation(response.data.legislation);
    setScore(response.data.score);
    setPoints(response.data.points);
    setMaxPoints(response.data.max_points);
    setImprovementsAreas(response.data.comments.comments);
    setCountry(evaluationCountryConverterCodeToName(response.data.country_cca3));
  }

  useEffect(() =>{
    let isMounted = true;

    const loadProgramEvaluationDetail = async () => {
      try {
        if (isMounted) {
          const response = await getScenarioDetail(scenario_code);
  
          if (response && response.status === 200) {
            setEvaluationDetails(response.data.data);
            setLoading(false);
          }
  
        } 
      } catch (error) {
        setLoading(false);
        if (error.response && [400, 500].includes(error.response.status)){
          setError(error.response.data.error);
          toast.error(error.response.data.error);
          isMounted = false;
        };
      }
      
    }

    loadProgramEvaluationDetail();
  }, []);

  const [expandedIndex, setExpandedIndex] = useState(null);

  // Fonction pour gérer le clic sur le bouton
  const handleToggle = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle l'état de la section
  };


  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div className="content">
      <div className="container-fluid">
        {/* Content Header */}
        <div className="content-header">
          <div className="mb-2">
            <GoToBackButton />
            <div className="col-sm-6">
              <h1 className="flous-police-one flous-input-color m-0">My Results</h1>
            </div>
          </div>
        </div>

        {/* Main content */}
        <div className="card p-3" style={{ borderRadius: '15px' }}>
          <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
            <h4 className='text-bold'>Program Detail</h4>
            <hr />
            <div className='mx-3'>
              <div className="form-group row">
                <label htmlFor="inputEvaluationNumber" className="col-sm-3 col-form-label">Name of Program :</label>
                <div className="col-sm-9">
                  <input type="text" className="form-control" id="inputEvaluationNumber" value={programName} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="inputDate" className="col-sm-3 col-form-label">Date :</label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="inputDate" value={creationDate} />
                </div>
                <label htmlFor="inputCountry" className="col-sm-3 col-form-label">Country :</label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="inputCountry" value={country} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="inputTotalProg" className="col-sm-3 col-form-label">Type of Evaluation :</label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="inputTotalProg" value={evaluationType} />
                </div>
                <label htmlFor="inputDate" className="col-sm-3 col-form-label">Legislation :</label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="inputDate" value={legislation} />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="inputDate" className="col-sm-3 col-form-label">Total obtained points :</label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="inputDate" value={maxPoints === 0.0 ? 'Not applicable':points} />
                </div>
                <label htmlFor="inputTotalProg" className="col-sm-3 col-form-label">Percentage of conditions met :</label>
                <div className="col-sm-3">
                  <input type="text" className="form-control" id="inputTotalProg" value={`${score} %`} />
                </div>
              </div>
            </div>
          </div>
          {/* Card 2: Piste d'amélioration */}
          <div className="card elevation-2 flous-bg-thirty" style={{ borderRadius: '15px' }}>
            <div className="card flous-bg-thirty p-3 m-1" style={{ borderRadius: '15px' }}>
              <h4 className="pl-1 text-bold">Areas for Improvement</h4>
            </div>
            <div className="card-body">
              {Object.entries(improvementsAreas).map(([title, comments], index) => (
                <div key={index} className='card'>
                  <button
                    className="btn text-left btn-block m-2"
                    role="button"
                    aria-expanded={expandedIndex === index}
                    aria-controls={`improvementCollapse${index}`}
                    onClick={() => handleToggle(index)}
                  >
                                       
                    {title}{' '}
                    <i className={`text-right expandable-table-caret fas fa-fw ${expandedIndex === index ? 'fa-caret-down' : 'fa-caret-left'}`}></i>
                  </button>
                  <div id={`improvementCollapse${index}`} className={`${expandedIndex === index ? 'block' : 'hidden'}`}>
                    <div className="card-body">
                      <ul className="list-disc pl-5">
                        {Array.isArray(comments) && comments.length > 0 ? (
                          comments.map((task, taskIndex) => (
                            <li key={taskIndex}  className="text-gray-800">
                              {task}
                            </li>
                          ))
                        ) : (
                          <li className="text-gray-500">No comment !</li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
