import React from 'react';
import CountryShow from '../../../general/countryShow';


export default function FamilyMembersResumeSection(props) {

  const hasDependantChildren = props.children.dependantChildren; //  
  const totalDependantChildren = props.children.totalDependantChildren; //  
  const childrenUnder22 = props.children.childrenUnder22; // 

  return (
    <div id='language'>
      <h3 className='flous-police-one flous-input-color text-center'>Family Members</h3>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className="form-group row">
          <label htmlFor="inputHasDependantChildren" className="col col-form-label">Do you have dependant children?</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputHasDependantChildren" value={hasDependantChildren} />
          </div>
        </div>
        {hasDependantChildren === 'Yes' && (
        <div className="form-group row">
          <label htmlFor="inputTotalDependantChildren" className="col-sm-3 col-form-label">Total number of dependant children</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputTotalDependantChildren" value={totalDependantChildren} />
          </div>
          <label htmlFor="inputChildrenUnder22" className="col-sm-3 col-form-label">Number of children under 22 years old</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputChildrenUnder22" value={childrenUnder22} />
          </div>
        </div>)}
      </div>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className='mx-3'>
            {(!props.familyMembers || props.familyMembers.length === 0) ? 
            (
              <div className='text-center'>You don't have family members added</div>
            ):(
            <div className="form-group">
            <div>
              <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='row'>
                    <div className="col-sm col-6 mt-2 text-center">First name</div>
                    <div className="col-sm col-6 mt-2 text-center">Last name</div>
                    <div className="col-sm col-6 mt-2 text-center">Nationality</div>
                    <div className="col-sm col-6 mt-2 text-center">Address</div>
                    <div className="col-sm col-6 mt-2 text-center">Birthday</div>
                    <div className="col-sm col-6 mt-2 text-center">Is Dead</div>
                </div>
              </div>
              
              {props.familyMembers.map((member, index) => (
                <div key={index} className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }}>
                  <div className='row'>
                    <div className="col-sm col-6 mt-2 text-center">{member.firstname}</div>
                    <div className="col-sm col-6 mt-2 text-center">{member.lastname}</div>
                    <div className="col-sm col-6 mt-2 text-center"><CountryShow value={member.nationality}/></div>
                    <div className="col-sm col-6 mt-2 text-center"><CountryShow value={member.country}/></div>
                    <div className="col-sm col-6 mt-2 text-center">{member.birthday}</div>
                    <div className="col-sm col-6 mt-2 text-center">{member.deathday ? 'Yes' : 'No'}</div>
                  </div>
                </div>
              ))}
            </div>
            </div>
            )}
        </div>
      </div>
    </div>
  );
}
