import React from 'react';
import FlousFilesDetail from '../client/FlousFileDetail';
import { useParams } from 'react-router-dom';

export default function FlousCompagniesDetail () {

  const { code } = useParams();
  console.log(code);

  return (
    <FlousFilesDetail code={code}/>
  );
};