import React, { useState, useEffect } from 'react';
import { countriesWithProvinces, provincesWithCities, sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';
import CitySelect from '../../../../general/CitySelect';
import ProvinceSelect from '../../../../general/ProvinceSelect';
import CountrySelect from '../../../../general/CountrySelect';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import EnglishQuestion from "../../questions/englishQuestions.json";

const InstitutionSection = (props) => {
  const [formData, setFormData] = useState(props.institution);

  const [publicInstitution, setPublicInstitution] = useState([]);
  const [generalStudyFields, setGeneralStudyFields] = useState([]);
  const [studyFields, setStudyFields] = useState([]);
  const [studyLanguages, setStudyLanguages] = useState([
    {value: 'FR', label: 'French'}, 
    {value: 'EN', label: 'English'}, 
  ]);
  const [studyDurations, setStudyDurations] = useState([]);

  useEffect(() => {
    const fetchGeneralStudyField = async () =>{
      const response = await api.get('/generaltrainingfield/');
      const sorted = sortDictionaryByValues(response.data);
      const options = getOptions(sorted);
      setGeneralStudyFields(options);
    };  
    const fetchStudyField = async () =>{
        const response = await api.get('/trainingfieldinside/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setStudyFields(options);
    };
    /*const fetchStudyLanguage = async () =>{
        const response = await api.get('/languages/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        console.log(options)
        setStudyLanguages(options);
    }; */
    const fetchStudyDuration = async () =>{
        const response = await api.get('/yearsofstudy/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setStudyDurations(options);
    };

    fetchGeneralStudyField();
    fetchStudyField();
    // fetchStudyLanguage();
    fetchStudyDuration();

  }, []);

  useEffect(() => {
    const fetchCanadaPublicInstitution = async () =>{
      const response = await api.post('/canada/public-institutions/', {
        'province': formData.provinceState
      });
      const sorted = sortDictionaryByValues(response.data);
      const options = getOptions(sorted);
      setPublicInstitution(options);
    }; 
    fetchCanadaPublicInstitution();
  }, [formData.provinceState])

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updateData = {
      ...formData,
      [name]: value,
    }
    setFormData(updateData);
    props.sendInstitution(updateData);
  };

  {/*const handleCountryChange = (value) =>{
    const updatedFormData = {
      ...formData,
      country: value,
    };
    setFormData(updatedFormData);
    props.sendInstitution(updatedFormData);
  }; */}

  const handleProvinceStateChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      provinceState: value,
    };
    setFormData(updatedFormData);
    props.sendInstitution(updatedFormData);
  };

  const handleCityChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      city: value,
    };
    setFormData(updatedFormData);
    props.sendInstitution(updatedFormData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
      <p className='text-bold text-center'>Institution</p>
        <div className='row p-2'>
          <p htmlFor="address" className="col-form-label">{EnglishQuestion.educationInCountry.address}<RequiredIndicator /></p>
        </div>
        {/*<div className='row p-2'>
          <p htmlFor="country" className="col-sm-4 col-form-label">Country</p>
          <div className="col-sm-8">
            <CountrySelect value={formData.country} onChange={handleCountryChange} required /> 
          </div>
        </div>*/}
        <div className='row p-2'>
          <p htmlFor="provinceState" className="col-sm-6 col-form-label">{EnglishQuestion.educationInCountry.provinceState}<RequiredIndicator /></p>
          <div className="col-sm-6">
            {countriesWithProvinces.includes(formData.country) ? 
              (<ProvinceSelect 
                value={formData.provinceState} 
                onChange={handleProvinceStateChange} 
                country={formData.country} required
                />
              ):
              (<input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="provinceState" 
                name="provinceState" 
                value={formData.provinceState} 
                onChange={handleChange} 
                placeholder="Province / State"
              />
              )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="city" className="col-sm-6 col-form-label">{EnglishQuestion.educationInCountry.city}<RequiredIndicator /></p>
          <div className="col-sm-6">
            {countriesWithProvinces.includes(formData.country) ? 
              (<CitySelect 
                value={formData.city} 
                onChange={handleCityChange} 
                province={formData.provinceState} 
                required
                />
              ):
              (
                <input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="city" 
                name="city" 
                value={formData.city} 
                onChange={handleChange} 
                placeholder="City"
              />
              )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="institutionName" className="col-sm-6 col-form-label">{EnglishQuestion.educationInCountry.institutionName}<RequiredIndicator /></p>
          <div className="col-sm-6">
            {formData.provinceState === 'BC' ? (
              <Select
              name="institutionName"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={publicInstitution.find(option => option.value === formData.institutionName)}
              onChange={(selectedOption) => handleChange({ target: { name: 'institutionName', value: selectedOption ? selectedOption.value : '' } })}
              options={publicInstitution}
              placeholder="Please select an option"
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
            ) : (
              <input 
                type="text" 
                name="institutionName"
                className="form-control rounded-pill flous-input-color"
                onChange={handleChange}
                value={formData.institutionName}
              />
            )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="languageOfInstruction" className="col-sm-6 col-form-label">{EnglishQuestion.educationInCountry.languageOfInstruction}<RequiredIndicator /></p>
          <div className="col-sm-6">
          <Select
              name="languageOfInstruction"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={studyLanguages.find(option => option.value === formData.languageOfInstruction)}
              onChange={(selectedOption) => handleChange({ target: { name: 'languageOfInstruction', value: selectedOption ? selectedOption.value : '' } })}
              options={studyLanguages}
              placeholder="Please select an option"
              isSearchable
              isClearable
              required
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="fullTimeStudent" className="col-sm-8 col-form-label">{EnglishQuestion.educationInCountry.fullTimeStudent}<RequiredIndicator /></p>
          <div className='col-sm-4'>
            <div className='row'>
              <div className="col form-check ml-5">
                <input 
                  type="radio"
                  id="fullTimeStudentYes"
                  name="fullTimeStudent"
                  className="form-check-input" 
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.fullTimeStudent === "Yes"}
                />
                <p htmlFor="fullTimeStudentYes" className="form-check-p">Yes</p>
              </div>
              <div className="col form-check ml-5">
                <input 
                  type="radio"
                  id="fullTimeStudentNo"
                  name="fullTimeStudent"
                  className="form-check-input" 
                  value="No"
                  onChange={handleChange}
                  checked={formData.fullTimeStudent === "No"}
                />
                <p htmlFor="fullTimeStudentNo" className="form-check-p">No</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="generalFieldOfStudy" className="col-sm-6 col-form-label">{EnglishQuestion.educationInCountry.generalFieldOfStudy}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <Select
              name="generalFieldOfStudy"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={generalStudyFields.find(option => option.value === formData.generalFieldOfStudy)}
              onChange={(selectedOption) => handleChange({ target: { name: 'generalFieldOfStudy', value: selectedOption ? selectedOption.value : '' } })}
              options={generalStudyFields}
              placeholder="Please select an option"
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="fieldOfStudy" className="col-sm-6 col-form-label">{EnglishQuestion.educationInCountry.fieldOfStudy}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <Select
              name="fieldOfStudy"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={studyFields.find(option => option.value === formData.fieldOfStudy)}
              onChange={(selectedOption) => handleChange({ target: { name: 'fieldOfStudy', value: selectedOption ? selectedOption.value : '' } })}
              options={studyFields}
              placeholder="Please select an option"
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="studyDuration" className="col-sm-6 col-form-label">{EnglishQuestion.educationInCountry.studyDuration}<RequiredIndicator /><i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltip1" data-tooltip-content="Length of time for completing the study program, excluding any delays or interruptions."></i></p>
          <Tooltip id='tooltip1' />
          <div className="col-sm-6">
          <Select
              name="studyDuration"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={studyDurations.find(option => option.value === formData.studyDuration)}
              onChange={(selectedOption) => handleChange({ target: { name: 'studyDuration', value: selectedOption ? selectedOption.value : '' } })}
              options={studyDurations}
              placeholder="Please select an option"
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstitutionSection;
