import React, {useState, useEffect} from 'react';
import CurrentResidenceSection from './section_residences/CurrentResidenceSection';
import StatusInCountrySection from './section_residences/StatusInCountrySection';
import ImmigrationDocumentSection from './section_residences/ImmigrationDocumentSection';
import PreviousAddressSection from './section_residences/PreviousAddressSection';
import { toast, ToastContainer } from 'react-toastify';
import RequiredIndicator from '../../../general/requiredIndicator';

import EnglishQuestion from "../questions/englishQuestions.json";

const Residences = (props) => {
  const [currentResidence, setCurrentResidence] = useState(props.residences.currentResidence);
  const [statusInCountry, setStatusInCountry] = useState(props.residences.statusInCountry);
  const [immigrationDocument, setImmigrationDocument] = useState(props.residences.immigrationDocument);
  const [livedInDifferentAddress, setLivedInDifferentAddress] = useState(props.residences.livedInDifferentAddress);
  const [previousAddresses, setPreviousAddress] = useState(props.residences.previousAddresses);

  const [isSameResidence, setIsSameResidence] = useState(props.isSameResidence);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const residences = {
      currentResidence,
      statusInCountry,
      immigrationDocument,
      livedInDifferentAddress,
      previousAddresses,
    };
    props.sendResidences(residences);
    props.onValidate('residences', false);
  }, [currentResidence, statusInCountry, immigrationDocument, 
    livedInDifferentAddress, previousAddresses, props]);

  useEffect(() => {
    props.sendIsSameResidence(isSameResidence);
    props.onValidate('residences', false);
  }, [isSameResidence, props]);

  const receivedCurrentResidence = (data) => {setCurrentResidence(data);};
  const receivedStatusInCountry = (data) => {setStatusInCountry(data);};
  const receivedImmigrationDocument = (data) => {setImmigrationDocument(data);};
  const receivedPreviousAddress = (data) => {setPreviousAddress(data);};
  const receivedLivedInDifferentAddress = (data) => {setLivedInDifferentAddress(data);};

  const pressNext = ()=> {
    props.sendNext("Language Proficiency");
  };

  const handleStatus = (event)=> {
    const { name, value } = event.target;
    const updatedFormData = {
      ...currentResidence,
      [name]: value,
    };
    setCurrentResidence(updatedFormData);
    setErrors({});
  }

  const sendIsSame = (event)=> {
    const { name, value } = event.target;
    const updatedFormData = {
      ...isSameResidence,
      [name]: value,
    };
    setIsSameResidence(updatedFormData);
    setErrors({});
  };

  const checkCurrentStatus = () =>{
    if (!currentResidence.haveStatus ||
      (!statusInCountry.currentStatus || (statusInCountry.currentStatus !== 'NS' && !checkImmigrationDocument()))){

      toast.error("Please Complete Status In Canada block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        statusInCountry: "Please Complete Status In Canada block !"
      }));
      return false;
    } else {
      delete errors.statusInCountry;
      return true;
    }
  }

  const checkCurrentResidence = () =>{
    if (currentResidence.startDate === '' || 
      currentResidence.country === '' || 
      currentResidence.provinceState === '' || 
      currentResidence.city === '' ||
        currentResidence.streetNumber === ''){

      toast.error("Please complete the Current Residence block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        currentResidence: "Please complete the Current Residence block !"
      }));
      return false;
    } else {
      delete errors.currentResidence;
      return true;
    }
  }

  const checkImmigrationDocument = () => {
    if (immigrationDocument.dateOfIssue === '' || immigrationDocument.dateOfExpiration === ''){
      toast.error("Please complete the Immigration Document block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        immigrationDocument: "Please complete the Immigration Document block !"
      }));
      return false;
    } else {
      delete errors.immigrationDocument;
      return true;
    }

  }

  const checkSameCurrentResidence = () => {
    if ((props.actor === 'Partner') && (isSameResidence.isSamecurrentResidence === '' || 
        (isSameResidence.isSamecurrentResidence === 'No' && !checkCurrentResidence()))) {
        toast.error("Please add the informations about your current residence !");
        setErrors(prevErrors =>({
          ...prevErrors,
          option: "Please add the informations about your current residence !"
        }));
        return false;

    } else if (props.actor === 'Principal' && !checkCurrentResidence()){
      return false;
    } else {
      setErrors({});
      return true;
    }
  }
  

  const checkPreviousResidences = () => {
    if (!livedInDifferentAddress){
      toast.error("Please select an option for previous residences !");
      setErrors(prevErrors =>({
        ...prevErrors,
        option: "Please select an option for previous residences !"
      }));
      return false;
    } else if (livedInDifferentAddress === 'Yes' && props.actor === 'Partner') {
      if (isSameResidence.isSamepreviousAddress === '') {
        toast.error("Please tell us more about your previous addresses !");
        setErrors(prevErrors =>({
          ...prevErrors,
          isSamepreviousAddress: "Please tell us more about your previous addresses !"
        }));
        return false;
      } else if (isSameResidence.isSamepreviousAddress === 'No' && previousAddresses.length === 0){
        toast.error("Please add at least one past address !");
        setErrors(prevErrors =>({
          ...prevErrors,
          isSamepreviousAddress: "Please add at least one past address !"
        }));
        return false;
      } else if (isSameResidence.isSamepreviousAddress === 'Yes'){
        setErrors({});
        return true;
      }
    } else if (livedInDifferentAddress === 'Yes' && props.actor === 'Principal'){
      if (previousAddresses.length === 0) {
        toast.error("Please add at least one past address !");
        setErrors(prevErrors =>({
          ...prevErrors,
          previousAddresses: "Please add at least one past address !"
        }));
        return false;
      } else {
        setErrors({});
        return true;
      }
    }
  }

  const check = () =>{
    if(checkSameCurrentResidence() && checkCurrentStatus() && checkPreviousResidences()){
      
    props.onValidate('residences', true);
    setErrors({});
    toast.success('This section has been successfully validated !')
    } else {
      props.onValidate('residences', false);
    }
  }

  const validateSection = () => {
    props.onValidate('residences', false);
    check();
  };

  const saveForm = () => {
    props.onSave();
    toast.success('Your form has been successfully saved !')
  };

  return (
    <fieldset id="step2">
      {/*
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul> 
      <legend>Informations personnelles</legend>
      */}
      {props.actor === 'Partner' && (
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <div className='row p-2'>
        <p className='col-sm-8 col-form-label'>Does you and your partner living in the same current residence ?</p>
        <div className='col-sm-4'>
          <div className='row'>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input rounded-pill"
                id="isSamecurrentResidenceYes"
                name="isSamecurrentResidence"
                value="Yes"
                onChange={sendIsSame}
                checked={isSameResidence.isSamecurrentResidence === "Yes"}
              />
              <label className="form-check-label" htmlFor="isSamecurrentResidenceYes">Yes</label>
            </div>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input rounded-pill"
                id="isSamecurrentResidenceNo"
                name="isSamecurrentResidence"
                value="No"
                onChange={sendIsSame}
                checked={isSameResidence.isSamecurrentResidence === "No"}
              />
              <label className="form-check-label" htmlFor="isSameResidenceNo">No</label>
            </div>
          </div>
        </div>
        </div>
      </div>
      )}
      <div className="form-card">
        {(props.actor === 'Principal' || isSameResidence.isSamecurrentResidence === 'No') && (
          <>
            < CurrentResidenceSection error={errors.currentResidence ? errors.currentResidence : ''}
            sendCurrentResidence={receivedCurrentResidence} 
            currentResidence={currentResidence} sendIsSame={sendIsSame}
            />
          </>
        ) }

        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
          {errors.statusInCountry && (
            <>
                <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                    {errors.statusInCountry}
                </p>
            </>
          )}
          <div className='row p-2'>
          <p className='col-sm-8 col-form-label'>{EnglishQuestion.residences.haveStatus} {props.evaluationCountry} ? <RequiredIndicator /></p>
          <div className='col-sm-4'>
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input rounded-pill"
                  name="haveStatus"
                  value="Yes"
                  onChange={handleStatus}
                  checked={currentResidence.haveStatus === "Yes"}
                />
                <label className="form-check-label">Yes </label>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input rounded-pill"
                  name="haveStatus"
                  value="No"
                  onChange={handleStatus}
                  checked={currentResidence.haveStatus === "No"}
                />
                <label className="form-check-label">No </label>
              </div>
            </div>
          </div>
          </div>
        </div>
        
        {/*{((props.actor === 'Partner' && props.principalCurrentResidence.country ==='CAN' && isSameResidence.isSamecurrentResidence === 'Yes') || currentResidence.country === 'CAN' || currentResidence.haveStatus ==='Yes') && ( */}
        {(currentResidence.haveStatus ==='Yes' &&
        <>
          < StatusInCountrySection error={errors.statusInCountry ? errors.statusInCountry : ''}
          sendStatusInCountry={receivedStatusInCountry} 
          statusInCountry={statusInCountry} 
          countryE={props.evaluationCountry}
          />
          {(statusInCountry.currentStatus !== 'NS' && statusInCountry.currentStatus !== '') && (
            < ImmigrationDocumentSection error={errors.immigrationDocument ? errors.immigrationDocument : ''}
            sendImmigrationDocument={receivedImmigrationDocument} 
            immigrationDocument={immigrationDocument}  
            countryE={props.evaluationCountry}
            />
          )}
        </>
        )}
        <>
          < PreviousAddressSection error={errors.previousAddresses ? errors.previousAddresses : ''}
          sendLivedInDifferentAddress={receivedLivedInDifferentAddress} 
          livedInDifferentAddress={livedInDifferentAddress} 
          sendPreviousAddresses={receivedPreviousAddress} 
          previousAddresses={previousAddresses} sendIsSame={sendIsSame} 
          isSamepreviousAddress={isSameResidence.isSamepreviousAddress} 
          actor={props.actor}
          />
        </>
        <div className='d-flex justify-content-center'>
                <div className='col-sm-10'>
                    <div className='d-flex justify-content-between'>
                      <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save the form" onClick={saveForm}/>
                      <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Next" onClick={pressNext}/>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value="Validate this section" onClick={validateSection}/>
                </div>
        </div>
        <ToastContainer />
    </fieldset>
  );
};

export default Residences;
