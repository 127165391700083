import React, { useState } from 'react';

import EnglishQuestion from "../../../questions/englishQuestions.json";

const InnovativeActivities = (props) => {
  const [formData, setFormData] = useState(props.innovativeActivities);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendInnovativeActivities(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Innovative Activities</p>
        <div className='row p-2'>
          <p className="col-form-label text-center">{EnglishQuestion.entrepreunarialExperience.innovative}</p>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input"
              name="innovative"
              value="Yes"
              onChange={handleChange}
              checked={formData.innovative === "Yes"}
            />
            <p className="form-check-label">Yes</p>
          </div>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input"
              name="innovative"
              value="No"
              onChange={handleChange}
              checked={formData.innovative === "No"}
            />
            <p className="form-check-label">No</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnovativeActivities;