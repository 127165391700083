


export const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
  
    if (password.length < minLength) {
      return 'The password must contain at least 8 characters.';
    } else if (!hasUpperCase) {
      return 'The password must contain at least one capital letter.';
    } else if (!hasLowerCase) {
      return 'The password must contain at least one lowercase letter.';
    } else if (!hasNumber) {
      return 'The password must contain at least one digit.';
    } else if (!hasSpecialChar) {
      return 'The password must contain at least one special character.';
    }
    return '';
  };
  