import React, {useEffect, useState} from 'react';
import { getCurrentItems } from '../../services/users/FormsServices';
import PaginationSection from '../Layout/PaginationSection';
import FlousInboxItem from './item/FlousInboxItem';
import FlousHistoricalInboxItem from './item/FlousHistoricalInboxItem';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import FlousGetInTouchInboxItem from './item/FlousGetiInTouchInboxItem';
import GoToBackButton from '../general/GoBackLink';
import { fetchFlousContactUsMessages, fetchFlousGetInTouchMessages, 
  fetchFlousTechnicalGetInTouchMessages, fetchFlousUrgentGetInTouchMessages, 
  fetchFlousSuggestionGetInTouchMessages, fetchFlousHistoricalGetInTouchMessages } from '../../services/flous/inboxServices';
import LoadingOverlay from '../general/LoadingOverlay';

export default function FlousInboxPage() {

  const [messages, setMessages] = useState([]);
  const [contactUs, setContactUs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('All');
  const [isHistorical, setIsHistorical] = useState(false);
      
  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };

  useEffect( () => {
    let isMounted = true;
    setLoading(true);

    const getMessages = async () => {
        try {
          const response1 = await fetchFlousGetInTouchMessages();
          if (isMounted) {
            setMessages(response1.data.data);
          }
          const response2 = await fetchFlousContactUsMessages();
          if (isMounted) {
            setContactUs(response2.data.data);
          }
          setLoading(false);
        } catch (error) {
          if (isMounted){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)) {
              setError(error.response.data.error);
            }
          }
        }
    };

    getMessages();

    return () => {
      isMounted = false;
    };

  }, []);

  const handleFilterChange = (e) =>{
    const {value} = e.target;
    setFilter(value);
  }

  useEffect(()=>{
    setLoading(true);
    const getFilteredMessages = async () => {
      try {
        if (filter === 'All') {
          if (!isHistorical){
            const response = await fetchFlousGetInTouchMessages();
            if (response && response.status === 200) {
              setMessages(response.data.data);
            }
          }else {
            const response = await fetchFlousHistoricalGetInTouchMessages();
            if (response && response.status === 200) {
              setMessages(response.data.data);
            }
          }
        } else if (filter === 'Technical') {
          const response = await fetchFlousTechnicalGetInTouchMessages(isHistorical);
          if (response && response.status === 200) {
            setMessages(response.data.data);
          }
        } else if (filter === 'Urgent') {
          const response = await fetchFlousUrgentGetInTouchMessages(isHistorical);
          if (response && response.status === 200) {
            setMessages(response.data.data);
          }
        } else if (filter === 'Comment/Suggestion') {
          const response = await fetchFlousSuggestionGetInTouchMessages(isHistorical);
          if (response && response.status === 200) {
            setMessages(response.data.data);
          }
        }

        setLoading(false);
      } catch (error) {
          setLoading(false);
          if (error.response && [400, 500].includes(error.response.status)) {
            setError(error);
          }
        }
    };

    getFilteredMessages();
  }, [filter]);

  const handleGetHistoricalGetInTouchMessages = async () =>{
    setLoading(true);
    setIsHistorical(true);
    setFilter('All');
    try {
      const response = await fetchFlousHistoricalGetInTouchMessages();
      if (response && response.status === 200) {
        setMessages(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && [400, 500].includes(error.response.status)) {
        setError(error);
      }
    }
  }

  const handleGetCurrentGetInTouchMessages = async () =>{
    setLoading(true);
    setIsHistorical(false);
    setFilter('All');
    try {
      const response = await fetchFlousGetInTouchMessages();
      if (response && response.status === 200) {
        setMessages(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error.response && [400, 500].includes(error.response.status)) {
        setError(error);
      }
    }
  }
  
  const currentItems = getCurrentItems(messages, page, pageSize);
    
  const [pageContactUs, setPageContactUs] = useState(1);
  const pageSizeContactUs = 5;
  const onPageChangeContactUs = (pageContactUs) => {
  setPageContactUs(pageContactUs + 1);
  };

  const currentItemsContactUs = getCurrentItems(contactUs, page, pageSize);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div className="content">
      <div className="container-fluid">
        <div className="content-header">
          <div className="container-fluid">
            <div className="mb-2">
              <GoToBackButton />
              <div className="col-sm-6">
                <h1 className="m-0 flous-police-one flous-input-color">{isHistorical? "Historical":"New"} Messages</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="card elevation-2" style={{ borderRadius: '15px' }}>
                <div className="card-header">
                    <div className="row">
                      <div className='col-sm col-12 row ml-3'>
                        <div className="col form-check">
                            <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            id="subjectUrgent"
                            name="subject"
                            value="All"
                            onChange={handleFilterChange}
                            checked={filter === "All"}
                            />
                            <label className="form-check-label" htmlFor="subjectUrgent">All</label>
                        </div>
                        <div className="col form-check">
                            <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            id="subjectUrgent"
                            name="subject"
                            value="Urgent"
                            onChange={handleFilterChange}
                            checked={filter === "Urgent"}
                            />
                            <label className="form-check-label" htmlFor="subjectUrgent">Urgent</label>
                        </div>
                        <div className="col form-check">
                            <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            id="subjectTechnical"
                            name="subject"
                            value="Technical"
                            onChange={handleFilterChange}
                            checked={filter === "Technical"}
                            />
                            <label className="form-check-label" htmlFor="subjectTechnical">Technical</label>
                        </div>
                        <div className="col form-check">
                            <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            id="subjectComment"
                            name="subject"
                            value="Comment/Suggestion"
                            onChange={handleFilterChange}
                            checked={filter === "Comment/Suggestion"}
                            />
                            <label className="form-check-label" htmlFor="subjectComment">Comment/Suggestion</label>
                        </div>
                      </div>
                      <div className='col-md-2 col-6 btn flous-input-color'><i className="nav-icon fas fa-sort"></i> Sort</div>
                      {isHistorical ? (
                        <div onClick={handleGetCurrentGetInTouchMessages} className='col-md-2 col-6 btn flous-bg-menu'><i className="nav-icon fas fa-history"></i> New Messages</div>
                      ):(
                        <div onClick={handleGetHistoricalGetInTouchMessages} className='col-md-2 col-6 btn flous-bg-menu'><i className="nav-icon fas fa-history"></i> Historical Messages</div>
                      )}
                      
                    </div>
                </div>
                <div className="card-body flous-bg-thirty">
                  <div className='' style={{ borderRadius: '15px' }}>
                  {currentItems.length > 0 ? (
                    currentItems.map((message) => (
                      isHistorical ? (
                        <FlousHistoricalInboxItem message={message}/>
                      ):(
                        <FlousInboxItem message={message}/>
                      )
                    ))
                  ) : (
                    <NoItemsMessage />
                  )}
                  </div>
                </div>
                <PaginationSection
                      page={page}
                      pageSize={pageSize}
                      totalItems={messages.length}
                      onPageChange={onPageChange}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Parti Historique */}
      <div className="container-fluid">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 flous-police-one flous-input-color">Contact Us Messages</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="card elevation-2" style={{ borderRadius: '15px' }}>
                <div className="card-header">
                    <div className="row">
                      {/* 
                      <div className='col-sm col-12 row ml-3'>
                        <div className="col form-check">
                            <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            id="subjectUrgent"
                            name="subject"
                            value="All"
                            />
                            <label className="form-check-label" htmlFor="subjectUrgent">All</label>
                        </div>
                        <div className="col form-check">
                            <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            id="subjectUrgent"
                            name="subject"
                            value="Urgent"
                            />
                            <label className="form-check-label" htmlFor="subjectUrgent">Urgent</label>
                        </div>
                        <div className="col form-check">
                            <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            id="subjectTechnical"
                            name="subject"
                            value="Technical"
                            />
                            <label className="form-check-label" htmlFor="subjectTechnical">Technical</label>
                        </div>
                        <div className="col form-check">
                            <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            id="subjectComment"
                            name="subject"
                            value="Technical"
                            />
                            <label className="form-check-label" htmlFor="subjectComment">Comment/Suggestion</label>
                        </div>
                      </div>*/}
                      <div className='col-sm-2 col-12 btn flous-input-color'><i className="nav-icon fas fa-sort"></i> Sort</div>
                    </div>
                </div>
                <div className="card-body flous-bg-thirty">
                  <div className='' style={{ borderRadius: '15px' }}>
                  {currentItemsContactUs.length > 0 ? (
                    currentItemsContactUs.map((message) => (
                      <FlousGetInTouchInboxItem message={message}/>
                      ))
                    ) : (
                      <NoItemsMessage />
                    )}
                  </div>
                </div>
                <PaginationSection
                      page={pageContactUs}
                      pageSize={pageSizeContactUs}
                      totalItems={contactUs.length}
                      onPageChange={onPageChangeContactUs}
                    />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};