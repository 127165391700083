import React, { useState, useEffect } from 'react';
import api from '../../services/api';
// import {sortDictionaryByValues} from '../../services/utils';
import Select from 'react-select';


const CitySelect = ({ value, onChange, province, className="react-select-container" }) => {
  const [cities, setCities] = useState([]);


  useEffect( () => {
    const fetchCities = async() => {
      if (province) {
        const response = await api.get(`/province-cities/${province}/`);
        // const response = await api.get(`/quebeccities`);
        const cityOptions = Object.entries(response.data).map(([key, val]) => ({
          value: key,
          label: val,
        }));
        setCities(cityOptions);
      }
    };

    fetchCities();
    
  }, [province]);

  return (
    <Select
      classNamePrefix="react-select"
      className={className}
      value={cities.find(city => city.value === value)}
      onChange={(selectedOption) => onChange({ target: { name: 'city', value: selectedOption ? selectedOption.value :''} })}
      options={cities}
      placeholder="Select City"
      isSearchable
      isClearable
      styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
          borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
          borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
        }),
      }}
      required
    />
  );
};

export default CitySelect;
