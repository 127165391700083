import React from 'react';

const Footer = () => {
  return (
    <footer className="main-footer">
      {/* To the right */}
      <div className="float-right d-none d-sm-inline">
        Version: 1.0.0
      </div>
      {/* Default to the left */}
      <strong>Copyright &copy; 2024 <a href="https://myflous.com">Flous</a>.</strong> All rights reserved.
    </footer>
  );
};

export default Footer;
