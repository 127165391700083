import React, { useState, useEffect } from 'react';
import { userForsStates, userGetEvaluationHistory } from '../../services/general/DashbordServices';
import LoadingOverlay from '../general/LoadingOverlay';
import { useOutletContext } from 'react-router-dom';
import { canadaStatesConverter, evaluationCountryConverterCodeToName } from '../../services/utils';
import { toast, ToastContainer } from 'react-toastify';
import InfoBoxPrimaryLink from '../general/InfoBoxPrimaryLink';
import InfoBoxThirtyLink from '../general/InfoBoxThirtyLink';

export default function UserDashboard() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { user } = useOutletContext();
  
  const [formsData, setFormsData] = useState({
    initiated: 0,
    inprogress: 0,
    completed: 0,
    abandoned: 0,
  });
  
  
  const [evaluationsData, setEvaluationData] = useState({
    dates: [
      {
        date : '',
        country: [
          {
            country: '',
            elements: []
          },
        ]
      }
    ],
  });

  const [selectedEvaluation, setSelectedEvaluation] = useState();
  
  useEffect( () => {
    let isMounted = true;
  
    const getDashBordDetails = async () => {
      if (user.id) {
        try {
          const _formsData = await userForsStates();
          const _evaluationsData = await userGetEvaluationHistory();
          await new Promise((resolve) => setTimeout(resolve, 1000));

          if (isMounted) {
            setEvaluationData(_evaluationsData.data.data);
            setFormsData(_formsData.data.data);
            setSelectedEvaluation(evaluationsData.dates[0].country[0]);
            setLoading(false);
          }
        } catch (error) {
          if (isMounted){
            setError(error.response.data.error);
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
            }
          }
        }
      }
    };
  
    getDashBordDetails();
  
    return () => {
      isMounted = false;
    };
  
  }, []);
  
  if (loading) {
    return <LoadingOverlay />;
  }
  
  if (error) {
    return <div>Error : {error.message}</div>;
  }

  const getScoreColor = (score) => {
    let colorClass = 'flous-bg-secondary';
    return colorClass;
  };

  const handleEvaluationClick = (country) => {
    setSelectedEvaluation(country);
  };

  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
          <div className="col-sm-6">
              <h1 className="flous-police-one flous-input-color m-0">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container-fluid">
          {/* Small boxes (Stat box) */}
          <div className='card p-2 px-5 elevation-2' style={{ borderRadius: '15px' }}>
            <div className="row">
              <div className="col-lg-3 col-6">
                <InfoBoxPrimaryLink
                  value={formsData.initiated}
                  text="Forms initiated"
                  link="More info"
                  href="/user/forms"
                />
              </div>
              <div className="col-lg-3 col-6">
                <InfoBoxThirtyLink
                  value={formsData.inprogress}
                  text="Forms in progress"
                  link="More info"
                  href="/user/forms"
                />
              </div>
              <div className="col-lg-3 col-6">
                <InfoBoxPrimaryLink
                  value={formsData.completed}
                  text="Completed forms"
                  link="More info"
                  href="/user/forms"
                />
              </div>
              <div className="col-lg-3 col-6">
                <InfoBoxThirtyLink
                  value={formsData.abandoned}
                  text="Abandoned forms"
                  link="More info"
                  href="/user/forms"
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="card elevation-2" style={{ borderRadius: '15px' }}>
                <div className="card-header">
                  <h4 className="flous-police-one text-center">Rating History</h4>
                  <div className='col-12 row'>
                    <div className='col'>Province</div>
                    <div className='col text-right'>Score</div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="timeline timeline-inverse">
                    {evaluationsData.dates.map((eva, index) => (
                      <React.Fragment key={index}>
                        <div className="bg-white">
                          <span className="btn flous-bg-thirty shadow" style={{ borderRadius: '15px' }}>{eva.date}</span>
                        </div>
                        {eva.country.map((country, index) => (
                          <div key={index} onClick={() => handleEvaluationClick(country)} style={{ cursor: 'pointer' }}>
                            <i className="fas fa-clock bg-primary"></i>
                            <div className="timeline-item shadow" style={{ borderRadius: '15px' }}>
                              <p>
                                <div className="btn">
                                  Evaluation for {evaluationCountryConverterCodeToName(country.country)}
                                </div>
                              </p>
                            </div>
                          </div>
                        ))}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="card elevation-2" style={{ borderRadius: '15px' }}>
                <div className="card-header">
                  <h4 className="flous-police-one text-center">Recommendations provinces</h4>
                  <div className='col-12 row'>
                    <div className='col'>Province</div>
                    <div className='col text-right'>Score</div>
                  </div>
                </div>
                <div className="card-body">
                  {selectedEvaluation.scoresData && selectedEvaluation.scoresData.map((item, index) => (
                    <div className="progress-group m-3" key={index}>
                      {canadaStatesConverter(item.province)}
                      <span className="float-right"><b>{item.score}</b>/100</span>
                      <div className="progress progress-sm rounded-pill">
                        <div className={`progress-bar rounded-pill ${getScoreColor(item.score)}`} style={{ width: `${item.score}%` }}></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </>
  );
}
