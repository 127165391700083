import React, { useState } from 'react';

export default function PaymentSection() {
  const [cardInfo, setCardInfo] = useState({
    cardNumber: '',
    nameOnCard: '',
    expiryDate: '',
    securityCode: ''
  });

  // Gestion des changements dans les champs de saisie
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCardInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handleModifyCardInfo = () => {
    // Logique pour modifier les informations de la carte
    console.log("Modifier les infos de la carte:", cardInfo);
  };

  const handleAddAnotherCard = () => {
    // Logique pour ajouter une autre carte
    console.log("Ajouter une autre carte:", cardInfo);
  };

  return (
    <div id='Payment'>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className='text-center text-bold'>Card Information</div>
        <hr />
        <div className='mx-3'>
          <div className="form-group row">
            <label htmlFor="inputCard" className="col-sm-4 col-form-label">Credit/Debit Card Number :</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control rounded-pill"
                id="inputCard"
                placeholder="**** **** **** 2852"
                name="cardNumber"
                value={cardInfo.cardNumber} // Liaison avec l'état
                onChange={handleChange} // Gestionnaire d'événements
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputNameOnCard" className="col-sm-4 col-form-label">Name on Card</label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control rounded-pill"
                id="inputNameOnCard"
                placeholder="Name On Card"
                name="nameOnCard"
                value={cardInfo.nameOnCard} // Liaison avec l'état
                onChange={handleChange} // Gestionnaire d'événements
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputExpiryDate" className="col-sm-3 col-form-label">Expiry Date :</label>
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control rounded-pill"
                id="inputExpiryDate"
                placeholder="MM/YY"
                name="expiryDate"
                value={cardInfo.expiryDate} // Liaison avec l'état
                onChange={handleChange} // Gestionnaire d'événements
                required
              />
            </div>
            <label htmlFor="inputCode" className="col-sm-3 col-form-label">Security code :</label>
            <div className="col-sm-3">
              <input
                type="text"
                className="form-control rounded-pill"
                id="inputCode"
                placeholder="CVV"
                name="securityCode"
                value={cardInfo.securityCode} // Liaison avec l'état
                onChange={handleChange} // Gestionnaire d'événements
                required
              />
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col'>
            <button type="button" className="btn flous-btn-gradient btn-block rounded-pill" onClick={handleModifyCardInfo}>
              Modify Card Info
            </button>
          </div>
          <div className='col'>
            <button type="button" className="btn flous-btn-gradient btn-block rounded-pill" onClick={handleAddAnotherCard}>
              Add Another Card
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}