import React, {useState} from 'react';
import { MessageStatesConverter, truncateMessage } from '../../../services/utils';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from '../../general/LoadingOverlay';
import { toast, ToastContainer } from 'react-toastify';
import { archiveGetInTouchMessage } from '../../../services/flous/inboxServices';

const FlousHistoricalInboxItem = ({ message }) => {

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleArchiveMessage = async (id) =>{
        setLoading(true);
        try{
            const response = await archiveGetInTouchMessage(id);
            if (response && response.status === 200) {
                setLoading(false);
                window.location.reload();
            }
        } catch (error){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              setError(error.response.data.error);
              toast.error(error.response.data.error);
            }
        }
    }
    
    const navigate = useNavigate();
    
    const viewMore = () =>{
        navigate(`/flous/inbox/message/old/${message.id}`, { state: {data: message} });
    }

    if (loading) {
        return <LoadingOverlay />;
    }

    if (error) {
        return <div>Error : {error.message}</div>;
    }    
    
    return (
        <div className='card-header p-3 mb-2 shadow bg-white' style={{ borderRadius: '15px' }}>
            <div className='row text-bold'>
                <div className='col-sm-1 col-3 text-center'>
                    {message.logoUrl ? (
                        <img src={`${process.env.PUBLIC_URL}/avatar/${message.logoUrl}`} className="rounded-circle" alt="User Image" width="50" height="50"/>
                    ):(
                        <img src={`${process.env.PUBLIC_URL}/avatar/avatar.png`} className="rounded-circle" alt="User Image" width="50" height="50"/>
                    )}
                </div>
                <div className='col-sm-2 col-9 mt-2 ml-3'>{message.firstName} {message.lastName}</div>
                <div className='col-sm col-12 mt-2'>
                {message.subject} - <span className='font-weight-normal'>{truncateMessage(message.message)}</span>
                </div>
                <div className='col-sm-2 col-6 mt-2 text-center'>{message.date}</div>
                <div className={`col-sm-2 col-6 mt-2 text-center ${message.state === 'DN' ? 'flous-input-color' : ''}`}>{MessageStatesConverter(message.state)}</div>
                <div className='col-sm-2 col-6 mt-2 text-center'>
                    <span onClick={viewMore} className="btn flous-bg-menu">
                        <i className="nav-icon fas fa-eye"></i>
                    </span>
                    <span onClick={() => handleArchiveMessage(message.id)} className="btn btn-secondary m-1">
                        <i className="nav-icon fas fa-archive"></i>
                    </span>
                </div>
            </div>
            <ToastContainer />
        </div>
    );
}

export default FlousHistoricalInboxItem;