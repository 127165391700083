import React, { useState } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const NotableAchievements = (props) => {
  const [formData, setFormData] = useState(props.notableAchievements);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendNotableAchievements(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <p className='text-bold text-center'>Notable Achievements</p>
        <div className='row p-2'>
          <p className="col-form-label text-center">{EnglishQuestion.professionalExpertise.notableAchievements}<RequiredIndicator /></p>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input"
              name="recognition"
              value="Yes"
              checked={formData.recognition === "Yes"}
              onChange={handleChange}
            />
            <p className="form-check-label">Yes</p>
          </div>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input"
              name="recognition"
              value="No"
              checked={formData.recognition === "No"}
              onChange={handleChange}
            />
            <p className="form-check-label">No</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotableAchievements;
