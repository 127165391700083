import React, { useState, useEffect } from 'react';
import api from '../../../../../services/api';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function CompanyAddressResumeSection(props) {
  // Define the job offer information
  const companyAddress = {
      hasJobOffer: 'Yes', //  
      country: props.companyDetail.country, //  
      province: props.companyDetail.provinceState, //  
      city: props.companyDetail.city, //  
      streetNumber: props.companyDetail.streetNumber, //  
      postalCode: props.companyDetail.postalCode //  
  };

  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
      const fetchProvinces = async() => {
          const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
  
      fetchProvinces();
  }, []);

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Company Address</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputHasJobOffer" className="col-sm-3 col-form-label">
          {EnglishQuestion.jobOffer.jobOffer} Canada?
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputHasJobOffer" value={companyAddress.hasJobOffer} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputProvince" className="col-sm-3 col-form-label">{EnglishQuestion.jobOffer.provinceState}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputProvince" value={provinces.find(province => province.value === companyAddress.province)?.label || ''} />
          </div>
          <label htmlFor="inputCity" className="col-sm-3 col-form-label">{EnglishQuestion.jobOffer.city}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputCity" value={companyAddress.city} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputStreetNumber" className="col-sm-3 col-form-label">{EnglishQuestion.jobOffer.streetNumber}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputStreetNumber" value={companyAddress.streetNumber} />
          </div>
          <label htmlFor="inputPostalCode" className="col-sm-3 col-form-label">{EnglishQuestion.jobOffer.postalCode}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputPostalCode" value={companyAddress.postalCode} />
          </div>
        </div>
      </div>
    </div>
  );
}