import React, { useEffect, useState } from 'react';
import { sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function ProfessionalDetailsResumeSection(props) {
  // Define the professional details information
  const professionalDetails = {
    professionalField: props.professionalDetail.professionalField, // 
    hasAuthorization: props.professionalDetail.authorizationToPractice, // 
    inProcessOfObtainingAuthorization: props.professionalDetail.processOfObtainingAuthorization, // 
    hasDiploma: props.professionalDetail.diplomaAuthorization // 
  };

  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJob = async () =>{
        const response = await api.get('/professions/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setJobs(options);
    };
    fetchJob();

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Professional Details</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputProfessionalField" className="col-sm-6 col-form-label">{EnglishQuestion.jobOffer.professionalField}</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputProfessionalField" value={jobs.find(option => option.value === professionalDetails.professionalField)?.label} />
          </div>
        </div>
        {props.professionalDetail.isRegulated && (
          <div className="form-group row">
            <label htmlFor="inputHasAuthorization" className="col-sm-6 col-form-label">{EnglishQuestion.jobOffer.authorizationToPractice}</label>
            <div className="col-sm-6">
              <input type="text" className="form-control" id="inputHasAuthorization" value={professionalDetails.hasAuthorization} />
            </div>
          </div>
        )}
        {props.professionalDetail.isRegulated && props.professionalDetail.authorizationToPractice === 'No' && (
        <div className="form-group row">
          <label htmlFor="inputInProcessOfObtainingAuthorization" className="col-sm-6 col-form-label">{EnglishQuestion.jobOffer.processOfObtainingAuthorization}</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputInProcessOfObtainingAuthorization" value={professionalDetails.inProcessOfObtainingAuthorization} />
          </div>
        </div>
        )}
        {props.professionalDetail.isRegulated && props.professionalDetail.processOfObtainingAuthorization ==='No' && (
        <div className="form-group row">
          <label htmlFor="inputHasDiploma" className="col-sm-6 col-form-label">{EnglishQuestion.jobOffer.diplomaAuthorization}</label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputHasDiploma" value={professionalDetails.hasDiploma} />
          </div>
        </div>
        )}
      </div>
    </div>
  );
}
