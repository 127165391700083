import React, { useState, useEffect } from 'react';
import CompanyDetailsSection from './section_jo/CompanyDetailSection';
import JobDetailJOSection from './section_jo/JobDetailJOSection';
import ProfessionalDetailSection from './section_jo/ProfessionalDetailSection';
import JobCompensationSection from './section_jo/JobCompensationSection';
import CompanyInformationSection from './section_jo/CompanyInformationSection';
import { toast, ToastContainer } from 'react-toastify';
import RequiredIndicator from '../../../general/requiredIndicator';

import EnglishQuestion from "../questions/englishQuestions.json";

const JobOffer = (props) => {
  const [jobOffer, setJobOffer] = useState(props.jobOffer.jobOffer);
  const [companyDetail, setCompanyDetail] = useState(props.jobOffer.companyDetail);
  const [jobDetail, setJobDetail] = useState(props.jobOffer.jobDetail);
  const [professionalDetail, setProfessionalDetail] = useState(props.jobOffer.professionalDetail);
  const [jobCompensation, setJobCompensation] = useState(props.jobOffer.jobCompensation);
  const [companyInfo, setCompanyInfo] = useState(props.jobOffer.companyInfo);
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const {value} = event.target;
    setJobOffer(value);
    const JobOfferParent = {
      jobOffer: value,
      companyDetail,
      jobDetail,
      professionalDetail,
      jobCompensation,
      companyInfo
    };
    props.sendJobOffer(JobOfferParent);
    setErrors({});
  };

  useEffect(() => {
    const JobOfferParent = {
        jobOffer,
        companyDetail,
        jobDetail,
        professionalDetail,
        jobCompensation,
        companyInfo
    };
    props.sendJobOffer(JobOfferParent);
    props.onValidate('jobOffer', false);
  }, [jobOffer, companyDetail, jobDetail, professionalDetail, jobCompensation, companyInfo]);

  const receivedCompanyDetail = (data) => {setCompanyDetail(data);};
  const receivedJobDetail = (data) => {setJobDetail(data);};
  const receivedProfessionalDetail = (data) => {setProfessionalDetail(data);};
  const receivedJobCompensation = (data) => {setJobCompensation(data);};
  const receivedCompanyInfo = (data) => {setCompanyInfo(data);};

  const pressNext = () => {
    props.sendNext("Entrepreneurial Experience");
  };

  const saveForm = () => {
    props.onSave();
    toast.success('Your form has been successfully saved !')
  };

  const checkCompanyDetail = () => {
    if (props.jobOffer.companyDetail.country === '' || 
      props.jobOffer.companyDetail.provinceState === '' || 
      props.jobOffer.companyDetail.city === ''){
      toast.error("Please complete the Company Address block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        companyDetail: "Please complete the Company Address block !"
      }));
      return false;
    } else {
      delete errors.companyDetail;
      return true;
    }
  }

  const checkJobDetail = () => {
    if (props.jobOffer.jobDetail.dateOfIssued === '' || props.jobOffer.jobDetail.currentJob === '' || 
      props.jobOffer.jobDetail.lmiaExempt === '' || props.jobOffer.jobDetail.provinceValidated === ''){
      toast.error("Please complete the Job Details block !");
      delete errors.companyDetail;
      setErrors(prevErrors =>({
        ...prevErrors,
        jobDetail: "Please complete the Job Details block !"
      }));
      return false;
    } else {
      delete errors.jobDetail;
      return true;
    }
  }

  const checkProfessionalDetail = () => {
    if (props.jobOffer.professionalDetail.professionalField === '' || props.jobOffer.professionalDetail.jobInAISector === '' || 
      (props.jobOffer.professionalDetail.authorizationToPractice === 'No' && props.jobOffer.professionalDetail.processOfObtainingAuthorization === '')){
      toast.error("Please complete the Professional Details block !");
      delete errors.jobDetail;
      setErrors(prevErrors =>({
        ...prevErrors,
        professionalDetail: "Please complete the Professional Details block !"
      }));
      return false;
    } else {
      delete errors.professionalDetail;
      return true
    }
  }

  const checkJobCompensation = () => {
    if (props.jobOffer.jobCompensation.hourlyRate === '' && props.jobOffer.jobCompensation.annualSalary === ''){
      toast.error("Please complete the Job Compensation block !");
      delete errors.professionalDetail;
      setErrors(prevErrors =>({
        ...prevErrors,
        jobCompensation: "Please complete the Job Compensation block !"
      }));
      return false;
    } else {
      delete errors.jobCompensation;
      return true
    }
  }

  const checkCompanyInfo = () => {
    if (!props.jobOffer.companyInfo.publicCompany || !props.jobOffer.companyInfo.companyName || props.jobOffer.companyInfo.numberOfEmployed === '' 
      || props.jobOffer.companyInfo.annualGrossRevenue === ''  || props.jobOffer.companyInfo.foundingDate === ''){
        toast.error("Please complete the Company Information block !");
        delete errors.jobCompensation;
        setErrors(prevErrors =>({
          ...prevErrors,
          companyInfo: "Please complete the Company Information block !"
        }));
      return false;
    } else {
      delete errors.companyInfo;
      return true
    }
  }

  const validateSection = () => {

    if (jobOffer === ''){

      props.onValidate('jobOffer', false)
      toast.error('Please select an option to start !')
    }
    else if (jobOffer === 'Yes' && 
      (checkCompanyDetail() && checkJobDetail() && checkJobCompensation() && 
      checkProfessionalDetail() && checkCompanyInfo())){
      props.onValidate('jobOffer', true)
      toast.success('This section has been successfully validated !');
      setErrors({});
    }
    else if (jobOffer === 'No'){
      props.onValidate('jobOffer', true)
      toast.success('This section has been successfully validated !');
      setErrors({});
    }
  };

  return (
    <fieldset id="step8">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul>
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
          <p className='text-bold text-center'>Job Offer</p>
          <div className='row p-2'>
            <p className="col-form-label text-center">{EnglishQuestion.jobOffer.jobOffer} {props.evaluationCountry}?<RequiredIndicator /></p>
            <div className="form-check ml-5">
              <input 
                type="radio"
                className="form-check-input"
                name="jobOffer"
                value="Yes"
                checked={jobOffer === "Yes"}
                onChange={handleChange}
              />
              <label className="form-check-label">Yes</label>
            </div>
            <div className="form-check ml-5">
              <input 
                type="radio"
                className="form-check-input"
                name="jobOffer"
                value="No"
                checked={jobOffer === "No"}
                onChange={handleChange}
              />
              <label className="form-check-label">No</label>
            </div>
          </div>
        </div>
        {jobOffer === 'Yes' && (
            <div>
                <CompanyDetailsSection error={errors.companyDetail ? errors.companyDetail : ''}
                  sendCompanyDetail={receivedCompanyDetail} companyDetail={companyDetail}  />
                <JobDetailJOSection error={errors.jobDetail ? errors.jobDetail : ''}
                   sendJobDetail={receivedJobDetail} jobDetail={jobDetail} />
                <ProfessionalDetailSection error={errors.professionalDetail ? errors.professionalDetail : ''}
                  sendProfessionalDetail={receivedProfessionalDetail} professionalDetail={professionalDetail} />
                <JobCompensationSection error={errors.jobCompensation ? errors.jobCompensation : ''}
                  sendJobCompensation={receivedJobCompensation} jobCompensation={jobCompensation} />
                <CompanyInformationSection error={errors.companyInfo ? errors.companyInfo : ''}
                  sendCompanyInfo={receivedCompanyInfo} companyInfo={companyInfo} province={companyDetail.provinceState}/>
            </div>
        )}
        
        <div className='d-flex justify-content-center'>
                  <div className='col-sm-10'>
                    <div className='d-flex justify-content-between'>
                      <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save the form" onClick={saveForm}/>
                      <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Next" onClick={pressNext}/>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value="Validate this section" onClick={validateSection}/>
                </div>
            </div>
            <ToastContainer />
    </fieldset>
  );
};

export default JobOffer;