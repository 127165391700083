import React, { useState } from 'react';
import { usePrincipalFormStates } from './../data/principalStates';
import { usePartnerFormStates } from '../data/partnerStates';
import { saveFormDataStructure } from '../../../../services/users/FormsServices';
import { toast } from 'react-toastify';

const DataFileUpload = () => {
  
  const [message, setMessage] = useState("");

  const [evaluationCountry, setevaluationCountry] = useState("Canada");
  const [formTitle, setFormTitle] = useState("");
  const [isSameResidence, setIsSameResidence] = useState({
    isSamecurrentResidence: '',
    isSamepreviousAddress: ''
  });
  const [isSameEExperience, setIsSameEExperience] = useState('');
  const [isCoApplication, setIsCoApplication] = useState(false);

  const principalFormStates = usePrincipalFormStates();
  const partnerFormStates = usePartnerFormStates();


  const handleSavedData = async () =>{

    try {
      
      const data = {
        isCoApplication: isCoApplication,
        evaluationCountry: evaluationCountry,
        formTitle:formTitle,
        principalApplicantData: principalFormStates,
        principalApplicantPartnerData: partnerFormStates,
        isSameEExperience: isSameEExperience,
        isSameResidence: isSameResidence,
      }

      const response = await saveFormDataStructure({ 
        data: data,
      });
      setMessage(response.data.message);
      toast.success(response.data.message);

    } catch (error) {
        console.log(error);
    }

  }


  return (
    <div className='text-center mt-5'>
      <h2>Uploader les données Principal JSON</h2>
      <p>{message}</p>
      <button className='btn flous-btn-primary' onClick={handleSavedData}>Envoyer au backend</button>
    </div>
  );
};

export default DataFileUpload;
