import api from '../api';

let isLoading = false;

export const fetchBlogData = async () => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-all-posts/`);
        return response;

    } catch (error){
        console.error('Error when getting all solo data:', error);
        throw error;
    } finally {
        isLoading = false;
    }  
};