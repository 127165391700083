import React, { useState, useEffect } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const DiplomaRecognitionSection = (props) => {
  const [formData, setFormData] = useState(props.diplomaRecognition);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updateData = {
      ...formData,
      [name]: value,
    }
    setFormData(updateData);
    props.sendDiplomaRecognition(updateData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
      <p className='text-bold text-center'>Diploma Recognition</p>
      <div className='row p-2'>
        <p className='col-sm-10 col-form-label'>{EnglishQuestion.educationInCountry.equivalentProcessCompleted}<RequiredIndicator /></p>
        <div className='col-sm-2'>
          <div className='row'>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input"
                id="equivalentProcessCompletedYes"
                name="equivalentProcessCompleted"
                value="Yes"
                onChange={handleChange}
                checked={formData.equivalentProcessCompleted === "Yes"}
              />
              <label className="form-check-label" htmlFor="equivalentProcessCompletedYes">Yes</label>
            </div>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input"
                id="equivalentProcessCompletedNo"
                name="equivalentProcessCompleted"
                value="No"
                onChange={handleChange}
                checked={formData.equivalentProcessCompleted === "No"}
              />
              <label className="form-check-label" htmlFor="equivalentProcessCompletedNo">No</label>
            </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default DiplomaRecognitionSection;
