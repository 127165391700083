import React, { useEffect, useState } from 'react';
import CountrySelect from '../../../../general/CountrySelect';
import ProvinceSelect from '../../../../general/ProvinceSelect';
import { countriesWithProvinces } from '../../../../../services/utils';
import CitySelect from '../../../../general/CitySelect';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const PreviousAddressSection = (props) => {
  const [livedInDifferentAddress, setLivedInDifferentAddress] = useState(props.livedInDifferentAddress);
  const [formData, setFormData] = useState({
    startDate: '',
    endDate: '',
    country: '',
    provinceState: '',
    city: '',
    streetNumber: '',
    postalCode: '',
  });

  const [errors, setErrors] = useState({})

  const [previousAddresses, setPreviousAddresses] = useState(props.previousAddresses);
  const [addClicked, setAddClicked] = useState(false);

  const handleLivedInDifferentAddress = (event) => {
    setLivedInDifferentAddress(event.target.value);
    setErrors({});
  };
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({});
  };

  const handleCountryChange = (e) =>{
    const value = e.target.value;
    const updatedFormData = {
      ...formData,
      country: value,
    };
    setFormData(updatedFormData);
    setErrors({});
  };

  const handleProvinceStateChange = (e) =>{
    const value = e.target.value;
    const updatedFormData = {
      ...formData,
      provinceState: value,
    };
    setFormData(updatedFormData);
    setErrors({});
  };

  const handleCityChange = (e) =>{
    const value = e.target.value;
    const updatedFormData = {
      ...formData,
      city: value,
    };
    setFormData(updatedFormData);
    setErrors({});
  }

  const handleAddAddress = () => {
    const {
      startDate,
      endDate,
      country,
      provinceState,
      city,
      streetNumber,
      postalCode
    } = formData;
  
    // Vérification des champs non vides
    if (!startDate || !endDate || !country || !provinceState || !city) {
      // toast.error("All fields must be completed before submitting the address.");
      setErrors(prevErrors =>({
        ...prevErrors,
        addAddressError: "All fields must be completed before submitting the address."
      }));
      return;
    }
  
    const updatedFormData = [
      ...previousAddresses,
      {
        startDate: formData.startDate,
        endDate: formData.endDate,
        country: formData.country,
        provinceState: formData.provinceState,
        city: formData.city,
        streetNumber: formData.streetNumber,
        postalCode: formData.postalCode,
      },
    ];
    setPreviousAddresses(updatedFormData);    
    setErrors({});
    setAddClicked(false);
  };

  const handleCancelAddAdress = () => {
    setAddClicked(false);
    delete errors.addAddressError;
  }

  useEffect(()=>{
    props.sendLivedInDifferentAddress(livedInDifferentAddress);
  }, [livedInDifferentAddress, props]);

  useEffect(()=>{
    props.sendPreviousAddresses(previousAddresses);
    setFormData({
      startDate: '',
      endDate: '',
      country: '',
      provinceState: '',
      city: '',
      streetNumber: '',
      postalCode: '',
    });
  }, [previousAddresses]);
  

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
      <p className='text-bold text-center'>Previous Addresses</p>
        <div className='row p-2'>
          <p htmlFor="livedInDifferentAddress" className="col-form-label text-center">
            {EnglishQuestion.residences.livedInDifferentAddress} <RequiredIndicator />
          </p>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input" 
              id="livedInDifferentAddressYes"
              name="livedInDifferentAddress"
              value="Yes"
              onChange={handleLivedInDifferentAddress}
              checked={livedInDifferentAddress === "Yes"}
            />
            <p htmlFor="livedInDifferentAddressYes" className="form-check-p">Yes</p>
          </div>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input" 
              id="livedInDifferentAddressNo"
              name="livedInDifferentAddress"
              value="No"
              onChange={handleLivedInDifferentAddress}
              checked={livedInDifferentAddress === "No"}
            />
            <p htmlFor="livedInDifferentAddressNo" className="form-check-p">No</p>
          </div>
        </div>
        {livedInDifferentAddress === 'Yes' && (
          <div>
            <ul style={{ color: 'red' }}>
              {Object.values(errors).map((value, index) => {
                  if (value !== '') {
                      return <li key={index}>{value}</li>;
                  }
                  return null;
              })}
            </ul> 
            {props.actor !== 'Principal' && (
              <div className='row p-2'>
              <p className='col-sm-8 col-form-label'>Does your partner live in the same past address with you ? <RequiredIndicator /></p>
              <div className='col-sm-4'>
                <div className='row'>
                  <div className="col form-check">
                    <input 
                      type="radio"
                      className="form-check-input rounded-pill"
                      id="isSamepreviousAddressYes"
                      name="isSamepreviousAddress"
                      value="Yes"
                      onChange={props.sendIsSame}
                      checked={props.isSamepreviousAddress === "Yes"}
                    />
                    <label className="form-check-label" htmlFor="isSamepreviousAddressYes">Yes</label>
                  </div>
                  <div className="col form-check">
                    <input 
                      type="radio"
                      className="form-check-input rounded-pill"
                      id="isSamepreviousAddressNo"
                      name="isSamepreviousAddress"
                      value="No"
                      onChange={props.sendIsSame}
                      checked={props.isSamepreviousAddress === "No"}
                    />
                    <label className="form-check-label" htmlFor="isSamepreviousAddressNo">No</label>
                  </div>
                </div>
              </div>
            </div>
            )}
            
            {(props.actor === 'Principal' || props.isSamepreviousAddress === 'No') && (
              <>
              <div>
                <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                  <div className='row'>
                    <div className='col-sm col-6 mt-2 text-center'>{EnglishQuestion.residences.startDate}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{EnglishQuestion.residences.endDate}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{EnglishQuestion.residences.country}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{EnglishQuestion.residences.provinceState}</div>
                    <div className='col-sm col-6 mt-2 text-center'>{EnglishQuestion.residences.city}</div>
                    <div className='col-sm col-6 mt-2 text-center'>Actions</div>
                  </div>
                </div>
                <div>
                  {previousAddresses && previousAddresses.length > 0 ? (
                    previousAddresses.map((address, index) => (
                      <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }}>
                        <div className='row' key={index}>
                          <div className='col-sm col-6 mt-2 text-center'>{address.startDate}</div>
                          <div className='col-sm col-6 mt-2 text-center'>{address.endDate}</div>
                          <div className='col-sm col-6 mt-2 text-center'>{address.country}</div>
                          <div className='col-sm col-6 mt-2 text-center'>{address.provinceState}</div>
                          <div className='col-sm col-6 mt-2 text-center'>{address.city}</div>
                          <div className='col-sm col-6 mt-2 text-center'>
                          <button 
                            className="btn btn-secondary" 
                            onClick={() => {
                              setPreviousAddresses(previousAddresses.filter((_, i) => i !== index));
                            }}
                          >
                            <i className="nav-icon fas fa-trash"></i>
                          </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      <div className='text-center'>You don't currently have a past address on file</div>
                    </>
                  )}
                  {!addClicked &&
                        <div className='mt-3'>
                            <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{setAddClicked(true)}}>Add Previous Adress</button>
                        </div>
                  }
                </div>
              </div>
              <div className="mt-2">
                {addClicked && (
                  <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                  <div className='row p-2'>
                    <p htmlFor="startDate" className="col-sm-4 col-form-label">{EnglishQuestion.residences.startDate}<RequiredIndicator /></p>
                    <div className="col-sm-8">
                      <input 
                        type="date"
                        id="startDate"
                        name="startDate"
                        className="form-control rounded-pill flous-input-color"
                        onChange={handleChange}
                        value={formData.startDate} max={new Date().toISOString().split("T")[0]}
                      />
                    </div>
                  </div>
                  <div className='row p-2'>
                    <p htmlFor="endDate" className="col-sm-4 col-form-label">{EnglishQuestion.residences.endDate}<RequiredIndicator /></p>
                    <div className="col-sm-8">
                      <input 
                        type="date"
                        id="endDate"
                        name="endDate"
                        className="form-control rounded-pill flous-input-color"
                        onChange={handleChange}
                        value={formData.endDate} max={new Date().toISOString().split("T")[0]}
                      />
                    </div>
                  </div>
                  <div className='row p-2'>
                    <p htmlFor="country" className="col-sm-4 col-form-label">{EnglishQuestion.residences.country}<RequiredIndicator /></p>
                    <div className="col-sm-8">
                      <CountrySelect value={formData.country} onChange={handleCountryChange} required /> 
                    </div>
                  </div>
                  <div className='row p-2'>
                    <p htmlFor="provinceState" className="col-sm-4 col-form-label">{EnglishQuestion.residences.provinceState}</p>
                    <div className="col-sm-8">
                      {countriesWithProvinces.includes(formData.country) ? 
                      (<ProvinceSelect 
                        value={formData.provinceState} 
                        onChange={handleProvinceStateChange} 
                        country={formData.country} required
                        />
                      ):
                      (<input 
                        type="text" 
                        className="form-control rounded-pill flous-input-color" 
                        id="provinceState" 
                        name="provinceState" 
                        value={formData.provinceState} 
                        onChange={handleChange} 
                        placeholder="Province / State"
                      />
                      )}
                    </div>
                  </div>
                  <div className='row p-2'>
                    <p htmlFor="city" className="col-sm-4 col-form-label">{EnglishQuestion.residences.city}<RequiredIndicator /></p>
                    <div className="col-sm-8">
                      {countriesWithProvinces.includes(formData.country) ? 
                        (<CitySelect 
                          value={formData.city} 
                          onChange={handleCityChange} 
                          province={formData.provinceState} 
                          required
                          />
                        ):
                        (
                          <input 
                          type="text" 
                          className="form-control rounded-pill flous-input-color" 
                          id="city" 
                          name="city" 
                          value={formData.city} 
                          onChange={handleChange} 
                          placeholder="City"
                        />
                        )}
                    </div>
                  </div>
                  <div className='row p-2'>
                    <p htmlFor="streetNumber" className="col-sm-4 col-form-label">{EnglishQuestion.residences.streetNumber}<RequiredIndicator /></p>
                    <div className="col-sm-8">
                      <input 
                        type="text" 
                        id="streetNumber"
                        name="streetNumber"
                        className="form-control rounded-pill flous-input-color" 
                        onChange={handleChange} 
                        value={formData.streetNumber}
                        placeholder="Street number"
                      />
                    </div>
                  </div>
                  <div className='row p-2'>
                    <p htmlFor="postalCode" className="col-sm-4 col-form-label">{EnglishQuestion.residences.postalCode}</p>
                    <div className="col-sm-8">
                      <input 
                        type="text" 
                        id="postalCode"
                        name="postalCode"
                        className="form-control rounded-pill flous-input-color"
                        onChange={handleChange} 
                        value={formData.postalCode}
                        placeholder="Postal code"
                      />
                    </div>
                  </div>
                            
                  <div className='d-flex justify-content-center'>
                    <div className='col-sm-10'>
                      <div className='d-flex justify-content-between'>
                        <input type="button" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save Address" onClick={handleAddAddress}/>
                        <input type="button" className="col-6 btn btn-block btn-secondary rounded-pill mx-1" value="Cancel" onClick={handleCancelAddAdress}/>
                      </div>
                    </div>
                  </div>
                </div>
                )}
              </div>
            
              {/*<button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={handleAddAddress}>Add</button> */}
            </>
          )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PreviousAddressSection;