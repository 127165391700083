import React, { useEffect, useState } from 'react';
import { sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const ProfessionalDetailSection = (props) => {
  const [formData, setFormData] = useState(props.professionalDetail);

  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJob = async () =>{
        const response = await api.get('/professions/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setJobs(options);
    };
    fetchJob();

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendProfessionalDetail(updatedData);
  };

  const fetchRegulation = async () =>{
    try{
      const data = {
        cnp: formData.professionalField,
      }

      const response = await api.post('/professions/isregulated/', data);
      const updatedData = {
        ...formData,
        isRegulated: response.data.isRegulated,
      };
  
      setFormData(updatedData);
      props.sendProfessionalDetail(updatedData);

    } catch (error){
      throw(error);
    }
    
  };

  useEffect(() => {
    if (formData.professionalField) {
      fetchRegulation();
    }
  }, [formData.professionalField]);

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Professional Details</p>
        <div className='row p-2'>
          <p className="col-sm-4 col-form-label">{EnglishQuestion.jobOffer.professionalField}</p>
          <div className="col-sm-8">
            <Select
              name="professionalField"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={jobs.find(option => option.value === formData.professionalField)}
              onChange={(selectedOption) => handleChange({ target: { name: 'professionalField', value: selectedOption ? selectedOption.value : '' } })}
              options={jobs}
              placeholder="Please select an option"
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-form-label text-center">Can your job offer be classified in the artificial intelligence sector?</p>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input"
              name="jobInAISector"
              value="Yes"
              checked={formData.jobInAISector === 'Yes'}
              onChange={handleChange}
            />
            <p className="form-check-label">Yes</p>
          </div>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input"
              name="jobInAISector"
              value="No"
              checked={formData.jobInAISector === 'No'}
              onChange={handleChange}
            />
            <p className="form-check-label">No</p>
          </div>
        </div>
        {formData.isRegulated && (
        <div className='row p-2'>
          <p className="col-form-label text-center">{EnglishQuestion.jobOffer.authorizationToPractice}<RequiredIndicator /></p>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input"
              name="authorizationToPractice"
              value="Yes"
              checked={formData.authorizationToPractice === 'Yes'}
              onChange={handleChange}
            />
            <p className="form-check-label">Yes</p>
          </div>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input"
              name="authorizationToPractice"
              value="No"
              checked={formData.authorizationToPractice === 'No'}
              onChange={handleChange}
            />
            <p className="form-check-label">No</p>
          </div>
        </div>
        )}
        {formData.isRegulated && formData.authorizationToPractice === 'No' && (
            <div className='row p-2'>
            <p className="col-form-label text-center">{EnglishQuestion.jobOffer.processOfObtainingAuthorization}<RequiredIndicator /></p>
            <div className="form-check ml-5">
              <input 
                type="radio"
                className="form-check-input"
                name="processOfObtainingAuthorization"
                value="Yes"
                checked={formData.processOfObtainingAuthorization === 'Yes'}
                onChange={handleChange}
              />
              <p className="form-check-label">Yes</p>
            </div>
            <div className="form-check ml-5">
              <input 
                type="radio"
                className="form-check-input"
                name="processOfObtainingAuthorization"
                value="No"
                checked={formData.processOfObtainingAuthorization === 'No'}
                onChange={handleChange}
              />
              <p className="form-check-label">No</p>
            </div>
          </div>
        )}
        {formData.isRegulated && formData.processOfObtainingAuthorization ==='No' && (
            <div className='row p-2'>
            <p className="col-form-label text-center">{EnglishQuestion.jobOffer.diplomaAuthorization}<RequiredIndicator /></p>
            <div className="form-check ml-5">
              <input 
                type="radio"
                className="form-check-input"
                name="diplomaAuthorization"
                value="Yes"
                checked={formData.diplomaAuthorization === 'Yes'}
                onChange={handleChange}
              />
              <p className="form-check-label">Yes</p>
            </div>
            <div className="form-check ml-5">
              <input 
                type="radio"
                className="form-check-input"
                name="diplomaAuthorization"
                value="No"
                checked={formData.diplomaAuthorization === 'No'}
                onChange={handleChange}
              />
              <p className="form-check-label">No</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfessionalDetailSection;
