import React, { useState } from 'react';
import RequiredIndicator from '../../../../../general/requiredIndicator';

const SupportbySpecializedO = (props) => {
  const [formData, setFormData] = useState(props.supportby);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendSupportBySpecializedO(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Support by Specifized Organization</p>
        <div className='row p-2'>
          <p className="col-form-label text-center">Have you received a service offer from a Specialized organization in {props.evaluationCountry} for your Business project?<RequiredIndicator /></p>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input"
              name="receivedServiceOffer"
              value="Yes"
              onChange={handleChange}
              checked={formData.receivedServiceOffer === "Yes"}
            />
            <p className="form-check-label">Yes</p>
          </div>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input"
              name="receivedServiceOffer"
              value="No"
              onChange={handleChange}
              checked={formData.receivedServiceOffer === "No"}
            />
            <p className="form-check-label">No</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportbySpecializedO;