import api from '../api';

let isLoading = false;

export const fetchInboxMessagesData = async () => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/user/get-all-getintouch-messages`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const setGetInTouchMessageRating = async (id, data) => {
    try{
        const response = await api.post(`/user/set-getintouch-message-rating/${id}/`, data);
        return response;

    } catch (error){
        throw error;
    }   
};

export const DeleteGetInTouchMessage = async (id) => {
    try{
        const response = await api.get(`/user/delete-getintouch-message/${id}`);
        return response;

    } catch (error){
        throw error;
    }   
};