import React, { useEffect, useState } from 'react';
import DegreeDiplomaSection from './section_eic/DegreeDiplomaSection';
import InstitutionSection from './section_eic/InstitutionSection';
import DiplomaRecognitionSection from './section_eic/DiplomaRecognitionSection';
import EquivalencyApplicationStatusSection from './section_eic/EquivalencyApplicationStatusSection';
import ProvinceEquivalencyProcessSection from './section_eic/ProvinceEquivalencyProcessSection';
import SpecialProgramSection from './section_eic/SpecialProgramSection';
import { toast, ToastContainer } from 'react-toastify';
import RequiredIndicator from '../../../general/requiredIndicator';

import EnglishQuestion from "../questions/englishQuestions.json";

const EducationInCountry = (props) => {
  const [currentlyStudy, setCurrentlyStudy] = useState(props.educationInCountry.currentlyStudy);
  const [educationInCountry, setEducationInCountry] = useState(props.educationInCountry.educationInCountry);
  const [allEducationInCountry, setAllEducationInCountry] = useState(props.allEducationInCountry); 
  const [applicationProcessed, setApplicationProcessed] = useState(props.educationInCountry.applicationProcessed);
  const [degreeDiploma, setDegreeDiploma] = useState(props.educationInCountry.degreeDiploma);
  const [institution, setInstitution] = useState(props.educationInCountry.institution);
  const [diplomaRecognition, setDiplomaRecognition] = useState(props.educationInCountry.diplomaRecognition);
  const [provinceProcessed, setProvinceProcessed] = useState(props.educationInCountry.provinceProcessed);
  const [recognitionProvince, setRecognitionProvince] = useState(props.educationInCountry.recognitionProvince);
  const [specialProgram, setSpecialProgram] = useState(props.specialProgram);
  const [errors, setErrors] = useState({});
  const [addClicked, setAddClicked] = useState(false);

  const handleCurrentlyStudyChange = (event) => {
    const { value } = event.target;
    setCurrentlyStudy(value);
    setEducationInCountry("");
    setErrors({});
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEducationInCountry(value);
    setErrors({});
  };

  useEffect(()=>{
    const educationInCountryParent = {
        currentlyStudy,
        educationInCountry,
        applicationProcessed,
        degreeDiploma,
        institution,
        diplomaRecognition,
        provinceProcessed,
        recognitionProvince,
    }
    props.sendEducationInCountry(educationInCountryParent);
    props.onValidate('educationInCountry', false);
  }, [currentlyStudy, educationInCountry, applicationProcessed, degreeDiploma, 
    institution, diplomaRecognition, provinceProcessed, 
    recognitionProvince, props]);

  useEffect(()=>{
    props.sendSpecialProgram(specialProgram);
    props.onValidate('educationInCountry', false);
  }, [specialProgram]);

  useEffect(()=> {
    props.sendAllEducationInCountry(allEducationInCountry);
    props.onValidate('educationInCountry', false);
  }, [allEducationInCountry]);

  const handleAllEducation = () => {
    if (validateFields()) {
      const educationInCountryParent = {
        applicationProcessed,
        degreeDiploma,
        institution,
        diplomaRecognition,
        provinceProcessed,
        recognitionProvince
      }
      setAllEducationInCountry([...allEducationInCountry, educationInCountryParent]); 
      /*setApplicationProcessed('');
      setDegreeDiploma({
        degreeDiploma: '',
        startDate: '',
        endDate: ''
      });
      setInstitution({
        institutionName: '',
        languageOfInstruction: '',
        fullTimeStudent: '',
        fieldOfStudy: '',
        studyDuration: '',
        address: '',
        country: 'CAN',
        provinceState: '',
        city: '',
      });
      setDiplomaRecognition({
        diplomaRecognized: '',
        equivalentProcessCompleted: ''
      });
      setProvinceProcessed([]);
      setRecognitionProvince({
        provinceState: '',
      })
      const educationInCountryParentEmpty = {
        applicationProcessed: "",
        degreeDiploma: {
          degreeDiploma: '',
          startDate: '',
          endDate: ''
        },
        institution: {
          institutionName: '',
          publicInstitution: '',
          languageOfInstruction: '',
          fullTimeStudent: '',
          generalFieldOfStudy: '',
          fieldOfStudy: '',
          studyDuration: '',
          address: '',
          country: 'CAN',
          provinceState: '',
          city: '',
        },
        diplomaRecognition: {
          equivalentProcessCompleted: ''
        },
        provinceProcessed: [],
        recognitionProvince: {
          provinceState: '',
        },
      };
      props.sendEducationInCountry(educationInCountryParentEmpty);*/
    }
  };

  const receivedApplicationProcessed = (data) => {setApplicationProcessed(data);};
  const receivedDegreeDiploma = (data) => {setDegreeDiploma(data);};
  const receivedInstitution = (data) => {setInstitution(data);};
  const receivedDiplomaRecognition = (data) => {setDiplomaRecognition(data);};
  const receivedProvinceProcessed = (data) => {setProvinceProcessed(data);};
  const receivedRecognitionProvince = (data) => {setRecognitionProvince(data);};
  const receivedSpecialProgram = (data) => {setSpecialProgram(data);};

  const pressNext = ()=> {
    props.sendNext("Education outside country");
  };

  const saveForm = () => {
    props.onSave();
    toast.success('Your form has been successfully saved !')
  };

  const checkDegreeDiploma = () =>{
    if (!degreeDiploma.degreeDiploma || !degreeDiploma.startDate || (currentlyStudy ==='No' && !degreeDiploma.endDate)) {
      setErrors(prevErrors =>({
        ...prevErrors,
        degreeDiploma: "Please complete the Degree/Diploma block !"
      }));
      toast.error('Please complete the Degree/Diploma block !');
      return false;
    } else {
      delete errors.degreeDiploma;
      return true;
    }
  }

  const checkInstitution = () =>{
    if (!institution.institutionName || !institution.publicInstitution || !institution.languageOfInstruction || !institution.fullTimeStudent ||
      !institution.fieldOfStudy || !institution.studyDuration || /*!institution.country ||*/
      !institution.provinceState || !institution.city
    ) {
      console.log(institution)
      setErrors(prevErrors =>({
        ...prevErrors,
        institution: "Please complete the Institution block !"
      }));
      toast.error('Please complete the Institution block !');
      return false;
    } else {
      delete errors.institution;
      return true;
    }
  }

  const checkDiplomaRecognition = () =>{
    if (!diplomaRecognition.equivalentProcessCompleted || 
      (diplomaRecognition.equivalentProcessCompleted === 'Yes' && provinceProcessed.length === 0)
    ) {
      setErrors(prevErrors =>({
        ...prevErrors,
        diplomaRecognition: "Please complete the Recognitions blocks !"
      }));
      toast.error('Please complete the Recognitions blocks !');
      return false;
    } else {
      delete errors.diplomaRecognition;
      return true;
    }
  }

  const checkSpecialPrograms = () =>{
    if (!specialProgram.homeCareProgram ||
      (specialProgram.homeCareProgram === 'No' && !specialProgram.proposeProgram)
    ) {
      setErrors(prevErrors =>({
        ...prevErrors,
        specialProgram: "Please complete the Special Program block !"
      }));
      toast.error('Please complete the Special Program block !');
      return false;
    } else {
      delete errors.specialProgram;
      return true;
    }
  }

  const validateFields = () => {
    setErrors({});
    if (checkDegreeDiploma() && checkInstitution() && 
    checkDiplomaRecognition()) {
      toast.success('Diploma added successfully !');
      setErrors({});
      return true;
    } else {
      // // toast.error('Please correct the above errors !');
      return false;
    }
  }

  const checkEducation = () => {
    if (( (currentlyStudy ==='Yes' && allEducationInCountry.length !==0) ||
      ((educationInCountry === 'Yes' && allEducationInCountry.length !==0) ||
        educationInCountry === 'No')) && checkSpecialPrograms()){
      props.onValidate('educationInCountry', true);
      setErrors({});
      toast.success('This section has been successfully validated !!')
      return true; 
    } else if (!educationInCountry){
      setErrors(prevErrors =>({
        ...prevErrors,
        educationInCountry: "Please select an option to start !"
      }));
      props.onValidate('educationInCountry', false);
      toast.error('Please select an option to start !');
      return false;
    }else{
      setErrors(prevErrors =>({
        ...prevErrors,
        error: "Please correct the above erros !"
      }));
      props.onValidate('educationInCountry', false);
      // toast.error('Please correct the above erros !');
      return false;
    }
  }

  const validateSection = () => {
    props.onValidate('educationInCountry', false);
    checkEducation();
  };

  return (
    <fieldset id="step4">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul> 
      {/*<legend>Informations personnelles</legend>*/}
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <p className='text-bold text-center'>Education in {props.evaluationCountry}</p> 
          <div className="form-group p-3 px-4 mb-4" style={{ borderRadius: '15px' }}>
            <div className='row p-2'>
              <p className="col-form-label col-sm-8">{EnglishQuestion.educationInCountry.currentlyStudy} {props.evaluationCountry} ?<RequiredIndicator /> </p>
              <div className="form-check ml-5">
                <input 
                  type="radio"
                  name="currentlyStudy"
                  className="form-check-input"
                  value="Yes"
                  onChange={handleCurrentlyStudyChange}
                  checked={currentlyStudy === "Yes"}
                />
                <label className="form-check-label">Yes</label>
              </div>
              <div className="form-check ml-5">
                <input 
                  type="radio"
                  name="currentlyStudy"
                  className="form-check-input"
                  value="No"
                  onChange={handleCurrentlyStudyChange}
                  checked={currentlyStudy === "No"}
                />
                <label className="form-check-label">No</label>
              </div>
            </div>{currentlyStudy === "No" && (
            <div className='row p-2'>
              <p className="col-form-label col-sm-8">{EnglishQuestion.educationInCountry.educationInCountry} {props.evaluationCountry} ?<RequiredIndicator /> </p>
              <div className="form-check ml-5">
                <input 
                  type="radio"
                  name="educationInCountry"
                  className="form-check-input"
                  value="Yes"
                  onChange={handleChange}
                  checked={educationInCountry === "Yes"}
                />
                <label className="form-check-label">Yes</label>
              </div>
              <div className="form-check ml-5">
                <input 
                  type="radio"
                  name="educationInCountry"
                  className="form-check-input"
                  value="No"
                  onChange={handleChange}
                  checked={educationInCountry === "No"}
                />
                <label className="form-check-label">No</label>
              </div>
            </div>)}
          </div>
          {(currentlyStudy === 'Yes' || (currentlyStudy === 'No' && educationInCountry === 'Yes')) && (
            <div className='mt-2'>
              <div className="form-group p-3 px-4 mb-4" style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                  <div className='row'>
                    <div className='col-sm col-6 mt-2 text-center'>Level</div>
                    <div className='col-sm col-6 mt-2 text-center'>Name</div>
                    <div className='col-sm col-6 mt-2 text-center'>Fulltime</div>
                    <div className='col-sm col-6 mt-2 text-center'>Country</div>
                    <div className='col-sm col-6 mt-2 text-center'>Actions</div>
                  </div>
                </div>
              {allEducationInCountry && allEducationInCountry.length > 0 ? (
                allEducationInCountry.map((educationIn, index) => (
                  <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }} key={index}>
                    <div className='row'>
                      <div className='col-sm col-6 mt-2 text-center'>{educationIn.degreeDiploma.degreeDiploma}</div>
                      <div className='col-sm col-6 mt-2 text-center'>{educationIn.institution.institutionName}</div>
                      <div className='col-sm col-6 mt-2 text-center'>{educationIn.institution.fullTimeStudent ? 'Yes' : 'No'}</div>
                      <div className='col-sm col-6 mt-2 text-center'>{educationIn.institution.country}</div>
                      <div className='col-sm col-6 mt-2 text-center'>
                        <button
                          className="btn btn-danger"
                          onClick={() => setAllEducationInCountry(allEducationInCountry.filter((_, i) => i !== index))}
                        >
                          <i className="nav-icon fas fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className='text-center'>No education on file</div>
              )}
              
              {!addClicked &&
                <div className='mt-3'>
                    <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{setAddClicked(true)}}>Add Study</button>
                </div>
              }
            </div>
          </div> 
          )}
        </div>
        {addClicked && (
          <>
          {currentlyStudy === "Yes" && (
              <div>
                  <InstitutionSection error={errors.institution ? errors.institution : ''}
                    sendInstitution={receivedInstitution} institution={institution}/>
                  <DegreeDiplomaSection error={errors.degreeDiploma ? errors.degreeDiploma : ''}
                    sendDegreeDiploma={receivedDegreeDiploma} degreeDiploma={degreeDiploma} isCurrent={"Yes"} />
              </div>
          )}
          {educationInCountry === 'Yes' && (
            <div>
                <InstitutionSection error={errors.institution ? errors.institution : ''}
                  sendInstitution={receivedInstitution} institution={institution}/>
                <DegreeDiplomaSection error={errors.degreeDiploma ? errors.degreeDiploma : ''}
                  sendDegreeDiploma={receivedDegreeDiploma} degreeDiploma={degreeDiploma} isCurrent={"No"} />
                <DiplomaRecognitionSection error={errors.diplomaRecognition ? errors.diplomaRecognition : ''}
                  sendDiplomaRecognition={receivedDiplomaRecognition} diplomaRecognition={diplomaRecognition}/>
            </div>
          )}
          {(educationInCountry === 'Yes' && diplomaRecognition.equivalentProcessCompleted === 'No') && (
            <div>
                <EquivalencyApplicationStatusSection sendApplicationProcessed={receivedApplicationProcessed} applicationProcessed={applicationProcessed}/>
            </div>
          )}
          {educationInCountry === 'Yes' && (diplomaRecognition.equivalentProcessCompleted === 'Yes' || (diplomaRecognition.equivalentProcessCompleted === 'No' && applicationProcessed === 'Yes')) && (
            <div>
              <ProvinceEquivalencyProcessSection 
              sendProvinceProcessed={receivedProvinceProcessed} provinceProcessed={provinceProcessed} 
              evaluationCountry={props.evaluationCountry} 
              sendRecognitionProvince={receivedRecognitionProvince} recognitionProvince={recognitionProvince}
              />
            </div>
          )}
          {(educationInCountry === 'Yes' || currentlyStudy === 'Yes') && (
            <>
            {/*<div className='d-flex justify-content-center mt-1 mb-4 m-2'>
              <button type='button' className='col-sm-10 btn btn-block btn-secondary rounded-pill' onClick={handleAllEducation}>Save Education</button>
            </div>*/}
              
            <div className='d-flex justify-content-center'>
            <div className='col-sm-10'>
                <div className='d-flex justify-content-between'>
                  <input type="button" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save Eduction" onClick={handleAllEducation}/>
                  <input type="button" className="col-6 btn btn-block btn-secondary rounded-pill mx-1" value="Cancel" onClick={()=>{setAddClicked(false)}}/>
                </div>
              </div>
            </div>
        </>
          )}
          </>
        )}
        <SpecialProgramSection error={errors.specialProgram ? errors.specialProgram : ''}
          sendSpecialProgram={receivedSpecialProgram} specialProgram={specialProgram} />
              
        <div className='d-flex justify-content-center'>
          <div className='col-sm-10'>
            <div className='d-flex justify-content-between'>
              <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save the form" onClick={saveForm}/>
              <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Next" onClick={pressNext}/>
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-center'>
          <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value="Validate this section" onClick={validateSection}/>
        </div>
      </div>
      <ToastContainer />
    </fieldset>
  );
};

export default EducationInCountry;
