import React from 'react';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function StatusResumeSection(props) {
  // Define the Canada status information
  const hasStatus = props.currentResidence.haveStatus; //  
  const currentStatus = props.statusInCountry.currentStatus; //  

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Canada Status</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputHasStatus" className="col-sm-6 col-form-label">
          {EnglishQuestion.residences.haveStatus} Canada?
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputHasStatus" value={hasStatus} />
          </div>
        </div>
        {props.currentResidence.haveStatus === 'Yes' && (
        <div className="form-group row">
          <label htmlFor="inputCurrentStatus" className="col-sm-6 col-form-label">
          {EnglishQuestion.residences.currentStatus} Canada?
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputCurrentStatus" value={currentStatus} />
          </div>
        </div>
        )}
      </div>
    </div>
  );
}
