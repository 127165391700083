import React, { useState, useEffect } from 'react';
import CountrySelect from '../../../../general/CountrySelect';
import ProvinceSelect from '../../../../general/ProvinceSelect';
import CitySelect from '../../../../general/CitySelect';
import Select from 'react-select';
import { countriesWithProvinces, sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';
import { toast, ToastContainer } from 'react-toastify';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import EnglishQuestion from "../../questions/englishQuestions.json";
import RequiredIndicator from '../../../../general/requiredIndicator';

const FamilyMembersInfoSection = (props) => {
    const [relationships, setRelationships] = useState([]);

    const [memebersListIsValid, setMembersListIsValid] = useState(false);

    const [childrenData, setChildrenData] = useState(props.children);
    const [familyInfo, setFamilyInfo] = useState(props.familyInfo);

    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        nationality: '',
        permanent: '',
        birthday: '',
        familyRelationship: '',
        liveWith: '',
        country: '',
        provinceState: '',
        city: '',
        streetNumber: '',
        postalCode: '',
        deathday: '',
        startDate: '',
    });
    const [members, setMembers] = useState(props.familyMembers);
    const [errors, setErrors] = useState({});

    const [addMemberClicked, setAddMemberClicked] = useState(false);

    useEffect(() => {
        const fetchRelationship = async () => {
            const response = await api.get('/familyrelationship/');
            const sorted = sortDictionaryByValues(response.data);
            const options = getOptions(sorted);
            setRelationships(options);
        };
        fetchRelationship();
    }, []);

    const getOptions = (data) => {
        return Object.entries(data).map(([key, val]) => ({
            value: key,
            label: val,
        }));
    };

    const handleChildrenDataChange = (event) => {
        const { name, value } = event.target;
        const updatedFormData = {
          ...childrenData,
          [name]: value,
        };
        setChildrenData(updatedFormData);
        delete errors.childrenData;
    };

    const handleNumberChange = (event) =>{
        const { name, value } = event.target;
        const updatedFormData = {
          ...familyInfo,
          [name]: value,
        };
        setFamilyInfo(updatedFormData);
    }

    useEffect(()=>{
        props.sendChildren(childrenData);
        props.sendFamilyInfo(familyInfo);
        
      }, [childrenData, familyInfo])
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
        if (name === "permanent" && value === "Yes"){
            setFormData((prevFormData) => ({
                ...prevFormData,
                nationality: "CAN",
            }));
        }
    };

    const handleCountryChange = (e) =>{
        const value = e.target.value;
        const updatedFormData = {
          ...formData,
          country: value,
        };
        setFormData(updatedFormData);
    };

    const handleNationalityChange = (e) =>{
        const { name, value } = e.target;
        const updatedFormData = {
          ...formData,
          nationality: value,
        };
        setFormData(updatedFormData);
    };

    const handleProvinceStateChange = (e) => {
        const value = e.target.value
        setFormData((prevFormData) => ({
            ...prevFormData,
            provinceState: value,
        }));
    };

    const handleCityChange = (e) => {
        const value = e.target.value
        setFormData((prevFormData) => ({
            ...prevFormData,
            city: value,
        }));
        //props.sendFamilyMembers(formData);
    };

    const handleAddMembers = () => {
        if(checkFormData()) {
            setMembers((prevMembers) => [...prevMembers, formData]);
            setFormData({
                firstname: '',
                lastname: '',
                nationality: formData.nationality,
                permanent: '',
                birthday: '',
                familyRelationship: formData.familyRelationship,
                liveWith: '',
                country: formData.country,
                provinceState: formData.provinceState,
                city: '',
                streetNumber: '',
                postalCode: '',
                deathday: '',
                startDate: '',
            });
            setAddMemberClicked(false);
            delete errors.formData;
        }else{
            toast.error("Please complete all required fields!");
        }
    };

    const [validationMessage, setValidationMessage] = useState('');

    const parentRelationships = ["Father", "Stepfather", "Mother", "Stepmother"];
    const siblingRelationships = ["Sister", "Stepsister", "Brother", "Stepbrother"];
    const canadianOrResidentPermanent = ["Yes"];

    const validateFamilyList = () => {
        const parentCount = members.filter(member => parentRelationships.includes(member.familyRelationship)).length;
        const siblingCount = members.filter(member => siblingRelationships.includes(member.familyRelationship)).length;
        const canOrRpCount = members.filter(member => canadianOrResidentPermanent.includes(member.permanent)).length;

        if (parentCount >= 2 && ((familyInfo.totalSibling - siblingCount) <= 0 && (familyInfo.totalCanadianOrPResident - canOrRpCount) <= 0)) {
            setMembersListIsValid(true);
            setValidationMessage('The criteria have been met.');
        } else {
            setMembersListIsValid(false);
            let msg = 'Please it\'s mandatory to include : ';

            if (parentCount < 2) {
                msg += `${2 - parentCount} parent(s) (${parentRelationships.join(', ')}), `;
            }
            if (familyInfo.totalSibling >= 1 && ((familyInfo.totalSibling - siblingCount) > 0)) {
                msg += ` ${familyInfo.totalSibling} sibling (${siblingRelationships.join(', ')}), `;
            }
            if (familyInfo.totalCanadianOrPResident >= 1 && (familyInfo.totalCanadianOrPResident - canOrRpCount) > 0) {
                msg += `At least ${familyInfo.totalCanadianOrPResident} Family member Canadian or Permanent Resident.`;
            }
            setValidationMessage(msg);
        }
    };

    const removeMember = (index) => {
        const updatedFamilyList = members.filter((_, i) => i !== index);
        setMembers(updatedFamilyList);
    };

    useEffect(() => {
        validateFamilyList();
    }, [members, familyInfo]);

    const pressNext = ()=> {
        props.sendNext("Residences");
    };
    
    const saveForm = () => {
        props.onSave();
        toast.success('Your form has been successfully saved !')
    };

    const checkFormData = () => {
        if (
            // formData.firstname === '' ||
            // formData.lastname === '' ||
            // formData.nationality === '' ||
            formData.permanent === '' ||
            // formData.birthday === '' ||
            formData.familyRelationship === '' ||
            formData.liveWith === '' ||
            (formData.liveWith === 'No' && (
                formData.country === '' ||
                formData.provinceState === '' ||
                formData.city === ''
                //formData.streetNumber === '' ||
                //formData.postalCode === '' ||
                //formData.deathday === '' ||
            )) || (formData.country === 'CAN' && formData.startDate === '')
        ) {
            setErrors(prevErrors => ({
                ...prevErrors,
                formData: "Please complete all required fields!"
            }));
            return false;
        } else {
            delete errors.formData;
            return true;
        }
    };

    const checkDependantChildren = () =>{
        if (
            childrenData.dependantChildren === '' ||
            ( childrenData.dependantChildren === 'Yes' && 
                (childrenData.totalDependantChildren === '' || childrenData.childrenUnder22 === ''))
        ){
            setErrors(prevErrors => ({
                ...prevErrors,
                childrenData: "Please complete all required fields!"
            }));
            return false;

        } else {
            delete errors.childrenData;
            return true;
        }
    }

    const checkFamilyInfo = () =>{
        if (
            familyInfo.totalSibling === '' ||
            familyInfo.totalCanadianOrPResident === ''){
            setErrors(prevErrors => ({
                ...prevErrors,
                familyInfo: "Please provide more info about your family members !"
            }));
            return false;

        } else {
            delete errors.familyInfo;
            return true;
        }
    }

    const membersRequirements = () => {
        if (!memebersListIsValid){
            setErrors(prevErrors => ({
                ...prevErrors,
                membersRequirements: "Please complete all the requirements listed below !"
            }));
            return false;
        } else {
            delete errors.membersRequirements;
            return true;
        }
    }

    const validateSection = () => {
        props.onValidate('familyMembers', false);
        if(checkFamilyInfo() && (membersRequirements() && checkDependantChildren())) {
            props.onValidate('familyMembers', true);
            toast.success('This section has been successfully validated !');
            setErrors({});
        } else {
            toast.error("Please complete all the requirements !");
            props.onValidate('familyMembers', false);
        }
      };

    useEffect(()=>{
        props.sendFamilyMembers(members);
        props.onValidate('familyMembers', false);
    }, [members, props])

    return (
        <div className="form-card">
            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                
                {errors.childrenData && (
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                            {errors.childrenData}
                        </p>
                    </>
                )}
                <div className='row p-2'>
                    <p className='col-sm-8 col-form-label'>Do you have dependant children? <RequiredIndicator /></p>
                    <div className='col-sm-4'>
                    <div className='row'>
                        <div className="col form-check">
                        <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            name="dependantChildren"
                            value="Yes"
                            onChange={handleChildrenDataChange}
                            checked={childrenData.dependantChildren === "Yes"}
                        />
                        <label className="form-check-label">Yes</label>
                        </div>
                        <div className="col form-check">
                        <input 
                            type="radio"
                            className="form-check-input rounded-pill"
                            name="dependantChildren"
                            value="No"
                            onChange={handleChildrenDataChange}
                            checked={childrenData.dependantChildren === "No"}
                        />
                        <label className="form-check-label">No</label>
                        </div>
                    </div>
                    </div>
                </div>
                {childrenData.dependantChildren === 'Yes' && (
                    <div>
                        <div className='row p-2'>
                        <p htmlFor="totalDependantChildren" className="col-sm-6 col-form-label">Total number of dependant children <RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <input 
                            type="number" 
                            className="form-control rounded-pill flous-input-color" 
                            id="totalDependantChildren" 
                            name="totalDependantChildren"
                            placeholder="Total number of dependant children"
                            value={childrenData.totalDependantChildren}
                            onChange={handleChildrenDataChange} min={1}
                            />
                        </div>
                        </div>
                        <div className='row p-2'>
                        <p htmlFor="childrenUnder22" className="col-sm-6 col-form-label">Number of children under 22 years old <RequiredIndicator /></p>
                        <div className="col-sm-6">
                            <input 
                            type="number" 
                            className="form-control rounded-pill flous-input-color" 
                            id="childrenUnder22" 
                            name="childrenUnder22"
                            placeholder="Number of children under 22 years old"
                            value={childrenData.childrenUnder22}
                            onChange={handleChildrenDataChange} min={1}
                            />
                        </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                <p className="text-bold text-center">Family Members Information</p>
                {errors.familyInfo && (
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                            {errors.familyInfo}
                        </p>
                    </>
                )}
                <div className='row p-2'>
                    <p htmlFor="childrenUnder22" className="col-sm-6 col-form-label">How many sibling relation do you have ? <RequiredIndicator />
                        <i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltip1" 
                        data-tooltip-content="Sister, Stepsister, Brother, Stepbrother.">
                        </i>
                    </p>
                    <Tooltip id='tooltip1' />
                    <div className="col-sm-6">
                        <input 
                        type="number" 
                        className="form-control rounded-pill flous-input-color" 
                        id="totalSibling" 
                        name="totalSibling"
                        placeholder="Number of sibling relation"
                        value={familyInfo.totalSibling}
                        onChange={handleNumberChange} min={0}
                        />
                    </div>
                </div>
                <div className='row p-2'>
                    <p htmlFor="childrenUnder22" className="col-sm-6 col-form-label">How many members of your immediate family are Canadian or Permanent Residents ?<RequiredIndicator />
                        <i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltip2" 
                        data-tooltip-content={`Immediate means : Father, Stepfather, Mother, Stepmother,\n Sister, Stepsister, Brother, Stepbrother, Grandfather, Grandmother, Nephew, Niece.`}>
                        </i>
                    </p>
                    <Tooltip id='tooltip2' />
                    <div className="col-sm-6">
                        <input 
                        type="number" 
                        className="form-control rounded-pill flous-input-color" 
                        id="totalCanadianOrPResident" 
                        name="totalCanadianOrPResident"
                        placeholder="Number Canadian or Permanent Resident"
                        value={familyInfo.totalCanadianOrPResident}
                        onChange={handleNumberChange} min={0}
                        />
                    </div>
                </div>
                {errors.membersRequirements && (
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                            {errors.membersRequirements}
                        </p>
                    </>
                )}
                {memebersListIsValid ?(
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'green'}}>
                            {validationMessage}
                        </p>
                    </>
                ):(
                    <>
                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                            {validationMessage}
                        </p>
                    </>
                )}
                <div>
                    <div className="card-header p-1 mb-1 shadow bg-white" style={{ borderRadius: '15px 15px 0px 0px' }}>
                        <div className="row text-bold">
                            <div className="col-sm col-6 mt-2 text-center">Relation</div>
                            <div className="col-sm col-6 mt-2 text-center">First name</div>
                            <div className="col-sm col-6 mt-2 text-center">Last name</div>
                            <div className="col-sm col-6 mt-2 text-center">Nationality</div>
                            <div className="col-sm col-6 mt-2 text-center">Address</div>
                            <div className="col-sm col-6 mt-2 text-center">Birthday</div>
                            <div className="col-sm col-6 mt-2 text-center">Is Canadian Or PR</div>
                            <div className="col-sm col-6 mt-2 text-center">Actions</div>
                        </div>
                    </div>
                    {!members || members.length === 0 ? (
                        <>
                        <p className="text-center">No family members added yet.</p>
                        <p className="text-center">Please click on button below to add a new family member</p>
                        </>
                    ) : (
                    members.map((member, index) => (
                        <div className="card-header p-1 mb-1 shadow bg-white" style={{ borderRadius: '15px' }}>
                            <div className="row text-center" key={index}>
                                <div className="col-sm col-6 mt-2">{member.familyRelationship}</div>
                                <div className="col-sm col-6 mt-2">{member.firstname}</div>
                                <div className="col-sm col-6 mt-2">{member.lastname}</div>
                                <div className="col-sm col-6 mt-2">{member.nationality}</div>
                                <div className="col-sm col-6 mt-2">{member.country? member.country: 'N.A.'}</div>
                                <div className="col-sm col-6 mt-2">{member.birthday}</div>
                                <div className="col-sm col-6 mt-2">{member.permanent}</div>
                                <div className="col-sm col-6 mt-2">
                                    <button type='button' className="btn btn-secondary btn-sm"
                                        onClick={() => removeMember(index)}><i className="nav-icon fas fa-trash"></i></button>
                                </div>
                            </div>
                        </div>
                    )))}
                    {!addMemberClicked &&
                        <div className='mt-3'>
                            <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={()=>{setAddMemberClicked(true)}}>Add New Member</button>
                        </div>
                    }
                </div>
                {addMemberClicked && (
                    <>
                        
                        <div className="mt-2">
                            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                                
                                {errors.formData && (
                                    <>
                                        <p style={{ marginTop: '20px', padding: '10px', color: 'red'}}>
                                            {errors.formData}
                                        </p>
                                    </>
                                )}
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{EnglishQuestion.familyMembers.firstname}</p>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            id="firstname"
                                            name="firstname"
                                            className="form-control rounded-pill flous-input-color"
                                            onChange={handleChange}
                                            value={formData.firstname}
                                        />
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{EnglishQuestion.familyMembers.lastname}</p>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            id="lastname"
                                            name="lastname"
                                            className="form-control rounded-pill flous-input-color"
                                            onChange={handleChange}
                                            value={formData.lastname}
                                        />
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{EnglishQuestion.familyMembers.nationality}</p>
                                    <div className="col-sm-6">
                                        <CountrySelect
                                            value={formData.nationality}
                                            onChange={handleNationalityChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{EnglishQuestion.familyMembers.dateofbirth}</p>
                                    <div className="col-sm-6">
                                        <input
                                            type="date"
                                            id="birthday"
                                            name="birthday"
                                            className="form-control rounded-pill flous-input-color"
                                            onChange={handleChange}
                                            value={formData.birthday}
                                        />
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{EnglishQuestion.familyMembers.deathday}</p>
                                    <div className="col-sm-6">
                                        <input
                                            type="date"
                                            id="deathday"
                                            name="deathday"
                                            className="form-control rounded-pill flous-input-color"
                                            onChange={handleChange}
                                            value={formData.deathday}
                                        />
                                    </div>
                                </div>
                                
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{EnglishQuestion.familyMembers.familyRelationship} <RequiredIndicator /></p>
                                    <div className="col-sm-6">
                                        <Select
                                            id="familyRelationship"
                                            name="familyRelationship"
                                            className="react-select-container rounded-pill flous-input-color"
                                            classNamePrefix="react-select"
                                            value={relationships.find((option) => option.value === formData.familyRelationship)}
                                            onChange={(selectedOption) => handleChange({ target: { name: 'familyRelationship', value: selectedOption ? selectedOption.value : '' } })}
                                            options={relationships}
                                            placeholder="Please select an option"
                                            isSearchable
                                            isClearable
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    width: '100%',
                                                    borderRadius: '20px',
                                                    borderColor: '#ccc',
                                                }),
                                            }}
                                            required
                                        />
                                    </div>
                                </div>
                                {(formData.familyRelationship !== '') && (
                                <div className='row p-2'>
                                    <p className='col-sm-10 col-form-label'>Is the {formData.familyRelationship} a Canadian permanent resident?<RequiredIndicator /></p>
                                    <div className='col-sm-2'>
                                    <div className='row'>
                                        <div className="col form-check">
                                        <input 
                                            type="radio"
                                            className="form-check-input"
                                            id="permanentYes"
                                            name="permanent"
                                            value="Yes"
                                            onChange={handleChange}
                                            checked={formData.permanent === "Yes"}
                                        />
                                        <label className="form-check-label" htmlFor="permanentYes">Yes</label>
                                        </div>
                                        <div className="col form-check">
                                        <input 
                                            type="radio"
                                            className="form-check-input"
                                            id="permanentNo"
                                            name="permanent"
                                            value="No"
                                            onChange={handleChange}
                                            checked={formData.permanent === "No"}
                                        />
                                        <label className="form-check-label" htmlFor="permanentNo">No</label>
                                        </div>
                                    </div>
                                    </div>
                                </div>)}
                            </div>
                            {(formData.familyRelationship !== '') && ( 
                            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                                <div className='row p-2'>
                                    <p className='col-sm-10 col-form-label'>Does the {formData.familyRelationship} live with you?<RequiredIndicator /></p>
                                    <div className='col-sm-2'>
                                    <div className='row'>
                                        <div className="col form-check">
                                        <input 
                                            type="radio"
                                            className="form-check-input"
                                            id="liveWithYes"
                                            name="liveWith"
                                            value="Yes"
                                            onChange={handleChange}
                                            checked={formData.liveWith === "Yes"}
                                        />
                                        <label className="form-check-label" htmlFor="liveWithYes">Yes</label>
                                        </div>
                                        <div className="col form-check">
                                        <input 
                                            type="radio"
                                            className="form-check-input"
                                            id="liveWithNo"
                                            name="liveWith"
                                            value="No"
                                            onChange={handleChange}
                                            checked={formData.liveWith === "No"}
                                        />
                                        <label className="form-check-label" htmlFor="liveWithNo">No</label>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                {(formData.liveWith === "No") && (
                                <>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{EnglishQuestion.familyMembers.country}<RequiredIndicator /></p>
                                    <div className="col-sm-6">
                                        <CountrySelect
                                            value={formData.country}
                                            onChange={handleCountryChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{EnglishQuestion.familyMembers.provinceState}<RequiredIndicator /></p>
                                    <div className="col-sm-6">
                                        {countriesWithProvinces.includes(formData.country) ? (
                                            <ProvinceSelect
                                                value={formData.provinceState}
                                                onChange={handleProvinceStateChange}
                                                country={formData.country}
                                                required
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                className="form-control rounded-pill flous-input-color"
                                                id="provinceState"
                                                name="provinceState"
                                                value={formData.provinceState}
                                                onChange={handleChange}
                                                placeholder="Province / State"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{EnglishQuestion.familyMembers.city}<RequiredIndicator /></p>
                                    <div className="col-sm-6">
                                        {countriesWithProvinces.includes(formData.country) ? (
                                            <CitySelect
                                                value={formData.city}
                                                onChange={handleCityChange}
                                                province={formData.provinceState}
                                                required
                                            />
                                        ) : (
                                            <input
                                                type="text"
                                                className="form-control rounded-pill flous-input-color"
                                                id="city"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleChange}
                                                placeholder="City"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{EnglishQuestion.familyMembers.streetNumber}</p>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            id="streetNumber"
                                            name="streetNumber"
                                            className="form-control rounded-pill flous-input-color"
                                            onChange={handleChange}
                                            value={formData.streetNumber}
                                            placeholder="Street number"
                                        />
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <p className="col-sm-6 col-form-label">{EnglishQuestion.familyMembers.postalCode}</p>
                                    <div className="col-sm-6">
                                        <input
                                            type="text"
                                            id="postalCode"
                                            name="postalCode"
                                            className="form-control rounded-pill flous-input-color"
                                            onChange={handleChange}
                                            value={formData.postalCode}
                                            placeholder="Postal Code"
                                        />
                                    </div>
                                </div>
                                {(formData.country === 'CAN') && (
                                    <div className="row p-2">
                                        <p className="col-sm-6 col-form-label">{EnglishQuestion.familyMembers.startDate}</p>
                                        <div className="col-sm-6">
                                            <input
                                                type="date"
                                                id="startDate"
                                                name="startDate"
                                                className="form-control rounded-pill flous-input-color"
                                                onChange={handleChange}
                                                value={formData.startDate}
                                                placeholder="Start Date"
                                            />
                                        </div>
                                    </div>
                                )}
                                </>
                                )}
                            </div>)}
                            </div>
                            
                            <div className='d-flex justify-content-center'>
                                <div className='col-sm-10'>
                                    <div className='d-flex justify-content-between'>
                                        <input type="button" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save family member" onClick={handleAddMembers}/>
                                        <input type="button" className="col-6 btn btn-block btn-secondary rounded-pill mx-1" value="Cancel" onClick={()=>{setAddMemberClicked(false)}}/>
                                    </div>
                                </div>
                            </div>
                            {/*
                            <button type='button' className='btn btn-block btn-secondary rounded-pill' onClick={handleAddMembers}>Save Family Member</button>
                        
                            */}
                            
                    </>
                )}
            </div>
            <div className='d-flex justify-content-center'>
                <div className='col-sm-10'>
                    <div className='d-flex justify-content-between'>
                        <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save the form" onClick={saveForm}/>
                        <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Next" onClick={pressNext}/>
                    </div>
                </div>
            </div>
            <div className='d-flex justify-content-center'>
                <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value="Validate this section" onClick={validateSection}/>
            </div>
            <ToastContainer />
        </div>
    );
};

export default FamilyMembersInfoSection;
