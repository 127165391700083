import React from 'react';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function ContactInfoResumeSection(props) {
  // Define the contact information
  const email = props.contactInfo.email; //  
  const phoneNumber = props.contactInfo.phone; //  

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Contact Information</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputEmail" className="col-sm-3 col-form-label">{EnglishQuestion.personalInfo.email}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputEmail" value={email} />
          </div>
          <label htmlFor="inputPhoneNumber" className="col-sm-3 col-form-label">{EnglishQuestion.personalInfo.phone}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputPhoneNumber" value={phoneNumber} />
          </div>
        </div>
      </div>
    </div>
  );
}
