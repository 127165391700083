import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';

const SummaryPage = (props) => {

  // const [actor, setActor] = useState(props.actor)

  /*useEffect(()=>{
    setActor(props.actor);
  }, [])*/

  // const allTrue = obj => Object.values(obj).every(value => value === true);
  const [steps, setSteps] = useState([
    { id: "step-td1", label: "Personal Information", validated: props.summary.personalInformation },
    { id: "step-td2", label: "Family Members", validated: props.summary.familyMembers },
    { id: "step-td3", label: "Residences", validated: props.summary.residences },
    { id: "step-td4", label: "Language Proficiency", validated: props.summary.languageProficiency },
    { id: "step-td5", label: "Education in country", validated: props.summary.educationInCountry },
    { id: "step-td6", label: "Education outside country", validated: props.summary.educationOutsideCountry },
    { id: "step-td7", label: "Professional Experience", validated: props.summary.professionalExperience },
    { id: "step-td8", label: "Professional Expertise", validated: props.summary.professionalExpertise },
    { id: "step-td9", label: "Job offer", validated: props.summary.jobOffer },
    { id: "step-td10", label: "Entrepreneurial Experience", validated: props.summary.entrepreunarialExperience },
  ])

  useEffect(()=> {
    setSteps([
      { id: "step-td1", label: "Personal Information", validated: props.summary.personalInformation },
      { id: "step-td2", label: "Family Members", validated: props.summary.familyMembers },
      { id: "step-td3", label: "Residences", validated: props.summary.residences },
      { id: "step-td4", label: "Language Proficiency", validated: props.summary.languageProficiency },
      { id: "step-td5", label: "Education in country", validated: props.summary.educationInCountry },
      { id: "step-td6", label: "Education outside country", validated: props.summary.educationOutsideCountry },
      { id: "step-td7", label: "Professional Experience", validated: props.summary.professionalExperience },
      { id: "step-td8", label: "Professional Expertise", validated: props.summary.professionalExpertise },
      { id: "step-td9", label: "Job offer", validated: props.summary.jobOffer },
      { id: "step-td10", label: "Entrepreneurial Experience", validated: props.summary.entrepreunarialExperience },
    ]);
  }, [props]);

  /*useEffect(()=>{
    if (actor === 'Principal'){
      const updatedSteps = [
        ...steps,
        { id: "step-td10", label: "Partner", validated: allTrue(props.summary)}
      ];
      setSteps(updatedSteps);
    }
  }, [actor]); */


  const pressNext = (label) => {
    props.sendNext(label);
  };

  const saveForm = () => {
    props.onSave();
    toast.success('Your form has been successfully saved !');
  };

  return (
    <fieldset id="step10">
      <legend className='flous-police-one'>Summary</legend>
      <hr></hr>
      <div className="form-card">
        <table className="table table-borderless table-hover">
          <tbody>
            {steps.map((step) => (
              <tr 
                id={step.id} 
                className="step-td" 
                key={step.id} 
                onClick={() => pressNext(step.label)}
              >
                <td>{step.label}</td>
                <td>{step.validated ? 'Validated' : 'In progress'}</td>
                <td>
                  {step.validated ? (
                    <img src={`${process.env.PUBLIC_URL}/flous/svg/check_on.svg`} alt=""/>
                  ) : (
                    <img src={`${process.env.PUBLIC_URL}/flous/svg/check_off.svg`} alt=""/>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className='d-flex justify-content-center'>
          <div className='col-sm-10'>
            <div className='d-flex justify-content-between'>
              <input type="button" name="previous" className="col-5 btn btn-secondary rounded-pill" value="Previous" onClick={() => props.sendNext("Entrepreneurial Experience")} />
              <input type="button" name="save" className="col-6 btn flous-btn-primary rounded-pill" value="Save the form" onClick={saveForm}/>
            </div>
          </div>
      </div>
      <ToastContainer />
    </fieldset>
  );
};

export default SummaryPage;