import React, { useState } from 'react';
import RequiredIndicator from '../../../../../general/requiredIndicator';

import EnglishQuestion from "../../../questions/englishQuestions.json";

const CompanyFinancySection = (props) => {
  const [formData, setFormData] = useState(props.companyFinances);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendCompanyFinances(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Company Finance</p>
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.companyValue}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              name="companyValue"
              value={formData.companyValue}
              onChange={handleChange} 
              placeholder='$' min={0}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.startupExpenses}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              name="startupExpenses"
              value={formData.startupExpenses}
              onChange={handleChange} 
              placeholder='$' min={0}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.operatingExpenses}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              name="operatingExpenses"
              value={formData.operatingExpenses}
              onChange={handleChange} 
              placeholder='$' min={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyFinancySection;