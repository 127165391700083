import React, { useState, useEffect} from 'react';
import PersonalInformation from './sections/section_onePage';
import Residences from './sections/section_twoPage';
import LanguageProficiency from './sections/section_threePage';
import EducationInCountry from './sections/section_fourPage';
import EducationOutsideCountry from './sections/section_fivePage';
import ProfessionalExperienceSection from './sections/section_sixPage';
import ProfessionalExpertiseSection from './sections/section_sevenPage';
import JobOffer from './sections/section_heigthPage';
import EntrepreunarialExperience from './sections/section_ninePage';
import SummaryPage from './sections/summary_page';
import { usePrincipalFormStates } from './data/principalStates';
import { usePartnerFormStates } from './data/partnerStates';
import { fetchLanguageProficiency } from '../../../services/levelconvertertoscore';
import { createNewForm, getExistingFormData, UpdateForm } from '../../../services/users/FormsServices';
import { useParams } from 'react-router-dom';
import GoToBackButton from '../../general/GoBackLink';
import FamilyMembersInfoSection from './sections/section_fm/familyMembersSection';

export default function CreateEditFormPage () {

  const {newform, rawSurveyCode} = useParams();
  const [formCode, setFormCode] = useState(rawSurveyCode)

  const [activeBtn, setActiveBtn] = useState('Personal Information');
  const [actor, setActor] = useState('Principal');
  const [evaluationCountry, setevaluationCountry] = useState("");
  const [formTitle, setFormTitle] = useState("");
  const [isSameResidence, setIsSameResidence] = useState({
    isSamecurrentResidence: '',
    isSamepreviousAddress: ''
  });
  const [isSameEExperience, setIsSameEExperience] = useState('');
  const [isCoApplication, setIsCoApplication] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleActor = () => {
    setActor(actor === 'Principal' ? 'Partner' : 'Principal');
    setActiveBtn('Summary');
  };

  const principalFormStates = usePrincipalFormStates();
  const partnerFormStates = usePartnerFormStates();

  const setFormData = async (response) =>{
    setevaluationCountry(response.data.data.evaluationCountry);
    setFormTitle(response.data.data.formTitle);
    setIsCoApplication(response.data.data.isCoApplication);
    setIsSameEExperience(response.data.data.isSameEExperience);
    setIsSameResidence(response.data.data.isSameResidence);

    principalFormStates.setPersonalInformation(response.data.data.principalApplicantData.personalInformation);
    principalFormStates.setResidences(response.data.data.principalApplicantData.residences);
    principalFormStates.setLanguageProficiency(response.data.data.principalApplicantData.languageProficiency);
    principalFormStates.setEducationInCountry(response.data.data.principalApplicantData.educationInCountry);
    principalFormStates.setAllEducationInCountry(response.data.data.principalApplicantData.allEducationInCountry);
    principalFormStates.setEducationOutsideCountry(response.data.data.principalApplicantData.educationOutsideCountry);
    principalFormStates.setAllEducationOutsideCountry(response.data.data.principalApplicantData.allEducationOutsideCountry);
    principalFormStates.setProfessionalExperience(response.data.data.principalApplicantData.professionalExperience);
    principalFormStates.setAllProfessionalExperience(response.data.data.principalApplicantData.allProfessionalExperience);
    principalFormStates.setProfessionalExpertise(response.data.data.principalApplicantData.professionalExpertise);
    principalFormStates.setJobOffer(response.data.data.principalApplicantData.jobOffer);
    principalFormStates.setEntrepreunarialExperience(response.data.data.principalApplicantData.entrepreunarialExperience);
    principalFormStates.setSummary(response.data.data.principalApplicantData.summary);
    principalFormStates.setSpecialProgram(response.data.data.principalApplicantData.specialProgram);
    principalFormStates.setFamilyMembers(response.data.data.principalApplicantData.familyMembers);
    principalFormStates.setChildren(response.data.data.principalApplicantData.children);
    principalFormStates.setFamilyInfo(response.data.data.principalApplicantData.familyInfo);

    partnerFormStates.setPersonalInformation(response.data.data.principalApplicantPartnerData.personalInformation);
    partnerFormStates.setResidences(response.data.data.principalApplicantPartnerData.residences);
    partnerFormStates.setLanguageProficiency(response.data.data.principalApplicantPartnerData.languageProficiency);
    partnerFormStates.setEducationInCountry(response.data.data.principalApplicantPartnerData.educationInCountry);
    partnerFormStates.setAllEducationInCountry(response.data.data.principalApplicantPartnerData.allEducationInCountry);
    partnerFormStates.setEducationOutsideCountry(response.data.data.principalApplicantPartnerData.educationOutsideCountry);
    partnerFormStates.setAllEducationOutsideCountry(response.data.data.principalApplicantPartnerData.allEducationOutsideCountry);
    partnerFormStates.setProfessionalExperience(response.data.data.principalApplicantPartnerData.professionalExperience);
    partnerFormStates.setAllProfessionalExperience(response.data.data.principalApplicantPartnerData.allProfessionalExperience);
    partnerFormStates.setProfessionalExpertise(response.data.data.principalApplicantPartnerData.professionalExpertise);
    partnerFormStates.setJobOffer(response.data.data.principalApplicantPartnerData.jobOffer);
    partnerFormStates.setEntrepreunarialExperience(response.data.data.principalApplicantPartnerData.entrepreunarialExperience);
    partnerFormStates.setSummary(response.data.data.principalApplicantPartnerData.summary);
    partnerFormStates.setSpecialProgram(response.data.data.principalApplicantPartnerData.specialProgram);
    partnerFormStates.setFamilyMembers(response.data.data.principalApplicantPartnerData.familyMembers);
    partnerFormStates.setChildren(response.data.data.principalApplicantPartnerData.children);
    partnerFormStates.setFamilyInfo(response.data.data.principalApplicantPartnerData.familyInfo);

  }

  useEffect(() =>{
    let isMounted = true;

    const openForm = async () => {
      try {
        if (isMounted) {
          if (newform === 'new'){
            const response = await createNewForm('Canada');
            setFormCode(response.data['raw_survey_code']);
  
            if (response) {
              try{
                await setFormData(response);
              } catch (error) {
                throw error;
              }
            } else { return;}
          
          }else {
            const response = await getExistingFormData(rawSurveyCode);
  
            if (response) {
              try{
                await setFormData(response);
              } catch (error) {
                throw error;
              }
            } else { return;}
  
          }
        
          setLoading(false);
  
        }
      } catch (error) {
        if (isMounted){
          setError(error);
          setLoading(false);
          throw error;
        }
      }
      
    }

    openForm();
  }, []);

  const formStates = actor === 'Principal' ? principalFormStates : partnerFormStates;
  
  const {
    personalInformation, setPersonalInformation,
    children, setChildren,
    familyInfo, setFamilyInfo,
    familyMembers, setFamilyMembers,
    residences, setResidences,
    languageProficiency, setLanguageProficiency,
    educationInCountry, setEducationInCountry,
    specialProgram, setSpecialProgram,
    allEducationInCountry, setAllEducationInCountry,
    educationOutsideCountry, setEducationOutsideCountry,
    allEducationOutsideCountry, setAllEducationOutsideCountry,
    professionalExperience, setProfessionalExperience,
    allProfessionalExperience, setAllProfessionalExperience,
    professionalExpertise, setProfessionalExpertise,
    jobOffer, setJobOffer,
    entrepreunarialExperience, setEntrepreunarialExperience,
    summary, setSummary,
    
  } = formStates;

  useEffect(() => {
    // Sauvegarde des données selon l'acteur actuel (Principal ou Partner)
    if (actor === 'Principal') {
      principalFormStates.setPersonalInformation(personalInformation);
      principalFormStates.setFamilyMembers(familyMembers);
      principalFormStates.setChildren(children);
      principalFormStates.setFamilyInfo(familyInfo);
      principalFormStates.setResidences(residences);
      principalFormStates.setLanguageProficiency(languageProficiency);
      principalFormStates.setEducationInCountry(educationInCountry);
      principalFormStates.setSpecialProgram(specialProgram);
      principalFormStates.setAllEducationInCountry(allEducationInCountry);
      principalFormStates.setEducationOutsideCountry(educationOutsideCountry);
      principalFormStates.setAllEducationOutsideCountry(allEducationOutsideCountry);
      principalFormStates.setProfessionalExperience(professionalExperience);
      principalFormStates.setAllProfessionalExperience(allProfessionalExperience);
      principalFormStates.setProfessionalExpertise(professionalExpertise);
      principalFormStates.setJobOffer(jobOffer);
      principalFormStates.setEntrepreunarialExperience(entrepreunarialExperience);
      principalFormStates.setSummary(summary);

      setIsCoApplication(principalFormStates.personalInformation.personalDetail.personIncluded === 'Yes' ? true : false );
    } else if (actor === 'Partner') {
      partnerFormStates.setPersonalInformation(personalInformation);
      partnerFormStates.setFamilyMembers(familyMembers);
      partnerFormStates.setChildren(children);
      partnerFormStates.setFamilyInfo(familyInfo);
      partnerFormStates.setResidences(residences);
      partnerFormStates.setLanguageProficiency(languageProficiency);
      partnerFormStates.setEducationInCountry(educationInCountry);
      partnerFormStates.setSpecialProgram(specialProgram);
      partnerFormStates.setAllEducationInCountry(allEducationInCountry);
      partnerFormStates.setEducationOutsideCountry(educationOutsideCountry);
      partnerFormStates.setAllEducationOutsideCountry(allEducationOutsideCountry);
      partnerFormStates.setProfessionalExperience(professionalExperience);
      partnerFormStates.setAllProfessionalExperience(allProfessionalExperience);
      partnerFormStates.setProfessionalExpertise(professionalExpertise);
      partnerFormStates.setJobOffer(jobOffer);
      partnerFormStates.setEntrepreunarialExperience(entrepreunarialExperience);
      partnerFormStates.setSummary(summary);

    }
  }, [actor, isCoApplication, formTitle, evaluationCountry, personalInformation, residences, languageProficiency, 
    educationInCountry, specialProgram, allEducationInCountry, educationOutsideCountry, allEducationOutsideCountry, 
    professionalExperience, professionalExpertise, jobOffer, entrepreunarialExperience, allProfessionalExperience, partnerFormStates, 
    principalFormStates, familyMembers, children, familyInfo, summary]);

  const [message, setMessage] = useState('')

  const handleSavedData = async () =>{

    try {

        setIsCoApplication(principalFormStates.personalInformation.personalDetail.personIncluded === 'Yes' ? true : false );
        
        principalFormStates.setLanguageProficiency(
          await fetchLanguageProficiency(principalFormStates.languageProficiency)
        );
        partnerFormStates.setLanguageProficiency(isCoApplication? 
          await fetchLanguageProficiency(partnerFormStates.languageProficiency) : 
          partnerFormStates.languageProficiency
        );
      
      const partnerData = isCoApplication ? partnerFormStates : {};
      const data = {
        isCoApplication: isCoApplication,
        evaluationCountry: evaluationCountry,
        formTitle:formTitle,
        principalApplicantData: principalFormStates,
        principalApplicantPartnerData: partnerData,
        isSameEExperience: isSameEExperience,
        isSameResidence: isSameResidence,
      }

      const response = await UpdateForm({ 
        code: formCode,
        data: data,
      });
      setMessage(response.data.message);

    } catch (error) {
        // console.log(error);
    }

  }

  const handleValidationChange = (key, isValid) =>{
    if (actor === 'Principal') {
      principalFormStates.setSummary(prevStatus => ({
        ...prevStatus,
        [key]: isValid
      }));
    }
    else if (actor === 'Partner') {
      partnerFormStates.setSummary(prevStatus => ({
        ...prevStatus,
        [key]: isValid
      }));
    }
  }

  const handleBtnClick = (section) => {
    if (evaluationCountry || section === 'Personal Information' || section === 'Summary') {
      setActiveBtn(section);
    } else {
      // toast.error('Please select the evaluation country !!');
    }
  };

  const renderButton = (label, btnName, isDisabled, summaryKey) => (
    <button
      className={`text-left mb-2 step-btn btn btn-light ${activeBtn === btnName ? 'flous-bg-secondary text-bold text-black' : ''} btn-block`}
      onClick={() => handleBtnClick(btnName)}
      disabled={isDisabled}
    >
      <div className='row'>
        <div className='col-10'>{label}</div>
        <div className='col-2'>
          {summary[summaryKey] ? (
            <img src={`${process.env.PUBLIC_URL}/flous/svg/check_on.svg`} alt="" />
          ) : (
            <>
            {/*<img src={`${process.env.PUBLIC_URL}/flous/svg/check_off.svg`} alt="" />*/}
            </>
          )}
        </div>
      </div>
    </button>
  );


  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }
  
  // Fonction pour rendre le contenu en fonction de activeSection
  const renderContent = () => {
    switch (activeBtn) {
      case 'Personal Information':
        return <PersonalInformation actor={actor}
          sendNext={handleBtnClick}
          sendevaluationCountry={setevaluationCountry}
          evaluationCountry={evaluationCountry}
          sendFormTitle={setFormTitle}
          formTitle={formTitle}
          formCode={formCode}
          sendPersonalInformation={setPersonalInformation}
          personalInformation={personalInformation}
          onSave={handleSavedData}
          onValidate={handleValidationChange}
        />;
      case 'Family Members':
        return <FamilyMembersInfoSection actor={actor}
          sendNext={handleBtnClick}
          evaluationCountry={evaluationCountry}

          sendChildren={setChildren}
          children={children}

          sendFamilyInfo={setFamilyInfo}
          familyInfo={familyInfo}
          
          familyMembers={familyMembers}
          sendFamilyMembers={setFamilyMembers}
          onSave={handleSavedData}
          onValidate={handleValidationChange}
          />;
      case 'Residences':
        return <Residences actor={actor}
          sendIsSameResidence={setIsSameResidence} isSameResidence={isSameResidence}
          sendNext={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendResidences={setResidences}
          residences={residences}
          onSave={handleSavedData}
          onValidate={handleValidationChange}

          principalCurrentResidence={principalFormStates.residences.currentResidence}
        />;
      case 'Language Proficiency':
        return <LanguageProficiency actor={actor}
          sendNext={handleBtnClick}
          sendLanguageProficiency={setLanguageProficiency}
          languageProficiency={languageProficiency}
          onSave={handleSavedData}
          onValidate={handleValidationChange}
        />;
      case 'Education in country':
        return <EducationInCountry actor={actor}
          sendNext={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendEducationInCountry={setEducationInCountry}
          educationInCountry={educationInCountry}
          sendSpecialProgram={setSpecialProgram}
          specialProgram={specialProgram}
          onSave={handleSavedData}
          onValidate={handleValidationChange}
          //
          sendAllEducationInCountry={setAllEducationInCountry}
          allEducationInCountry={allEducationInCountry}
        />;
      case 'Education outside country':
        return <EducationOutsideCountry actor={actor}
          sendNext={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendEducationOutsideCountry={setEducationOutsideCountry}
          educationOutsideCountry={educationOutsideCountry}
          onSave={handleSavedData}
          onValidate={handleValidationChange}
          //
          sendAllEducationOutsideCountry={setAllEducationOutsideCountry}
          allEducationOutsideCountry={allEducationOutsideCountry}
        />;
      case 'Professional Experience':
        return <ProfessionalExperienceSection actor={actor}
          sendNext={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendProfessionalExperience={setProfessionalExperience}
          professionalExperience={professionalExperience}
          onSave={handleSavedData}
          onValidate={handleValidationChange}
          sendAllProfessionalExperience={setAllProfessionalExperience}
          allProfessionalExperience={allProfessionalExperience}
        />;
      case 'Professional Expertise':
        return <ProfessionalExpertiseSection actor={actor}
          sendNext={handleBtnClick}
          sendProfessionalExpertise={setProfessionalExpertise}
          professionalExpertise={professionalExpertise}
          onSave={handleSavedData}
          onValidate={handleValidationChange}
        />;
      case 'Job offer':
        return <JobOffer actor={actor}
          sendNext={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendJobOffer={setJobOffer}
          jobOffer={jobOffer}
          onSave={handleSavedData}
          onValidate={handleValidationChange}
        />;
      case 'Entrepreneurial Experience':
        return <EntrepreunarialExperience actor={actor} sendIsSameEExperience={setIsSameEExperience} isSameEExperience={isSameEExperience}
          sendNext={handleBtnClick}
          evaluationCountry={evaluationCountry}
          sendEntrepreunarialExperience={setEntrepreunarialExperience}
          entrepreunarialExperience={entrepreunarialExperience}
          onSave={handleSavedData}
          onValidate={handleValidationChange}

          principalEE={principalFormStates.entrepreunarialExperience}
        />;
      case 'Summary':
        return <SummaryPage actor={actor}
          sendNext={handleBtnClick}
          summary={summary}
          onSave={handleSavedData}
        />;
      case 'Partner':
        return <div>You must complete the form with your partner's data.</div>;
      default:
        return null;
    }
  };

  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="mb-2">
            <GoToBackButton />
            <div className="col-sm-6">
              <h2 className="flous-police-one flous-input-color m-3" >My Forms</h2>
            </div>
          </div>
        </div>
      </div>
      {/* /.content-header */}

      <div className="content">
        <div className="container-fluid">
          {/* Small boxes (Stat box) */}
          <div className="row">
            <div className="col-md-4">
              <div className='card p-4 elevation-2 mx-2' style={{ borderRadius: '15px' }}>
                <h4 className='mb-4 flous-police-one'>{actor=== 'Principal' ? '' : 'Partner'} Sections</h4>
                {renderButton('Personal Information', 'Personal Information', false, 'personalInformation')}
                {renderButton('Family Members', 'Family Members', false, 'familyMembers')}
                {renderButton('Residences', 'Residences', evaluationCountry === '', 'residences')}
                {renderButton('Language Proficiency', 'Language Proficiency', evaluationCountry === '', 'languageProficiency')}
                {renderButton(`Education in ${evaluationCountry === '' ? 'country' : evaluationCountry}`, 'Education in country', evaluationCountry === '', 'educationInCountry')}
                {renderButton(`Education outside ${evaluationCountry === '' ? 'country' : evaluationCountry}`, 'Education outside country', evaluationCountry === '', 'educationOutsideCountry')}
                {renderButton('Professional Experience', 'Professional Experience', evaluationCountry === '', 'professionalExperience')}
                {renderButton('Professional Expertise', 'Professional Expertise', evaluationCountry === '', 'professionalExpertise')}
                {renderButton('Job offer', 'Job offer', evaluationCountry === '', 'jobOffer')}
                {renderButton('Entrepreneurial Experience', 'Entrepreneurial Experience', evaluationCountry === '', 'entrepreunarialExperience')}
                <button className={`text-left mb-2 step-btn btn btn-light ${activeBtn === 'Summary' ? 'flous-bg-secondary text-bold' : ''} btn-block`} onClick={() => handleBtnClick('Summary')}>
                Summary
                </button>
                
                {principalFormStates.personalInformation.personalDetail.personIncluded === 'Yes' && (
                  <button
                    className={`text-left mb-2 step-btn btn btn-light ${actor === 'Partner' ? 'flous-bg-primary text-bold' : 'flous-bg-menu text-bold'} btn-block`}
                    onClick={handleActor}
                  >
                    <div className='row'>
                      <div className='col-10'>{actor === 'Partner' ? 'Go back to my sections ' : 'Go to my partner sections '}</div>
                      <div className='col-2'>
                        <i className="fas fa-arrow-circle-right"></i>
                      </div>
                    </div>
                  </button>
                )}
              </div>
            </div>
            <div className='col-md-8'>
              {/*{message && <div className="text-center mb-2 mt-2" style={{ color: 'green' }}>{message}</div>}*/}
              <div className='card p-3 elevation-2 mx-2' style={{ borderRadius: '15px' }}>
                  <h2 className="text-center flous-input-color flous-police-one">Evaluation form</h2>
                  <p className="text-center">Please complete the evaluation form step by step</p>
                  <p className="text-center">(<span style={{ color: 'red' }}>*</span>) : is required</p>
                  <form className='m-3'>
                    {renderContent()}
                  </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
