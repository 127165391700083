import React, { useState, useEffect } from 'react';
import api from '../../../../../services/api';
import CountryShow from '../../../../general/countryShow';

export default function EducationInCountryInfoResumeSection(props) {

  const [provinces, setProvinces] = useState([]);

  useEffect(() => {
      const fetchProvinces = async() => {
          const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
      };
  
      fetchProvinces();
  }, []);
  
  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Education in - Country</div>
      <div className='mx-3'>
        <div className="form-group">
        <div>
          <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
            <div className='row'>
              <div className='col-sm col-6 mt-2 text-center'>Level</div>
              <div className='col-sm col-6 mt-2 text-center'>Name</div>
              <div className='col-sm col-6 mt-2 text-center'>Fulltime</div>
              <div className='col-sm col-6 mt-2 text-center'>Country</div>
              <div className='col-sm col-6 mt-2 text-center'>Province</div>
            </div>
          </div>

          {props.allEducationInCountry.map((educationIn, index) => (
            <div key={index} className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }}>
              <div className='row'>
                <div className='col-sm col-6 mt-2 text-center'>{educationIn.degreeDiploma.degreeDiploma}</div>
                <div className='col-sm col-6 mt-2 text-center'>{educationIn.institution.institutionName}</div>
                <div className='col-sm col-6 mt-2 text-center'>{educationIn.institution.fullTimeStudent ? 'Yes' : 'No'}</div>
                <div className='col-sm col-6 mt-2 text-center'><CountryShow value={educationIn.institution.country}/></div>
                {educationIn.institution.country === 'CAN' ? (
                  <div className='col-sm col-6 mt-2 text-center'>{provinces.find(province => province.value === educationIn.institution.province)?.label || ''}</div>
                  ): (<div className='col-sm col-6 mt-2 text-center'><div className='col-sm col-6 mt-2 text-center'>{educationIn.institution.province}</div></div>)}
              </div>
            </div>
          ))}
        </div>
        </div>
      </div>
    </div>
  );
}
