import axios from 'axios';

const isDevMode = process.env.NODE_ENV !== 'production'

const api = axios.create({
    baseURL: isDevMode ? 'http://127.0.0.1:8000/api' : 'https://backend.myflous.com/api'
});


let isRefreshing = false;
let refreshSubscribers = [];

function onRrefreshed(token) {
    refreshSubscribers.map(callback => callback(token));
}

function addRefreshSubscriber(callback) {
    refreshSubscribers.push(callback);
}

api.interceptors.request.use(
    config => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers['Authorization'] = `JWT ${accessToken}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


api.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;
        if (error.response.status === 401 && !originalRequest._retry) {
            if (isRefreshing) {
                return new Promise((resolve) => {
                    addRefreshSubscriber((token) => {
                        originalRequest.headers['Authorization'] = `JWT ${token}`;
                        resolve(api(originalRequest));
                    });
                });
            }
            
            originalRequest._retry = true;
            isRefreshing = true;

            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
                try {
                    const response = await axios.post(`${api.baseURL}/auth/refresh/`, {
                        refresh: refreshToken,
                    });
                    localStorage.setItem('accessToken', response.data.access);
                    api.defaults.headers.common['Authorization'] = `JWT ${response.data.access}`;
                    isRefreshing = false;
                    onRrefreshed(response.data.access);
                    refreshSubscribers = [];
                    return api(originalRequest);
                } catch (err) {
                    // console.error('Failed to refresh token:', err);
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('refreshToken');
                    isRefreshing = false;
                    refreshSubscribers = [];
                }
            }
        }
        return Promise.reject(error);
    }
);


export default api;