import React from 'react';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function CompanyFinanceResumeSection(props) {
  // Define the company finance information
  const companyFinance = {
    companyValue: props.companyFinances.companyValue, //  
    startupExpenses: props.companyFinances.startupExpenses, //  
    operatingExpenses: props.companyFinances.operatingExpenses //  
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Company Finance</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputCompanyValue" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.companyValue}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputCompanyValue" value={companyFinance.companyValue} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputStartupExpenses" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.startupExpenses}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputStartupExpenses" value={companyFinance.startupExpenses} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputOperatingExpenses" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.operatingExpenses}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputOperatingExpenses" value={companyFinance.operatingExpenses} />
          </div>
        </div>
      </div>
    </div>
  );
}
