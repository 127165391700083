import React, { useState, useEffect } from 'react';

const ChangeProfilePictureModal = ({ isOpen, onClose, onSave, onFileSelected }) => {

  const [file, setFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  useEffect(()=>{
    if (file) {
      onFileSelected(file);
    }
  }, [file])



  if (!isOpen) {
    return null;
  }

  return (
      <div id="newPicture" className="modal fade" tabindex="-1" aria-labelledby="newPictureLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="flous-police-one flous-input-color">Profile Picture</h4>
              <button type="button" className="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div className="modal-body">
                <div className="col">
                  <div className="form-group" lang="en">
                    <label htmlFor="picture">New picture</label>
                    <input
                      name="picture"
                      type="file"
                      className="form-control"
                      onChange={handleFileChange}
                      lang="en"
                    />
                  </div>
                </div>
                <div className="col">
                  <button type="button" className="btn btn-lg text-bold flous-bg-menu rounded-pill px-5" data-dismiss="modal" onClick={onSave}>Save now</button>
                </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default"
                data-dismiss="modal"
                onClick={onClose}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ChangeProfilePictureModal;
