import React from 'react';

import EnglishQuestion from "../../forms/questions/englishQuestions.json";

export default function ProfessionalExpertiseResumeSection(props) {
  // Define the professional expertise information
  const professionalExpertise = {
    workedInSameProfession: props.professionalExpertise.professionalExpertise.workedSameProfession, //  
    exceptionalExpertise: props.professionalExpertise.professionalExpertise.exceptionalExpertise, //  
    notableAchievements: props.professionalExpertise.notableAchievements.recognition, //  
    impactsOfExpertise: props.professionalExpertise.impactofExpertise.impact //  
  };

  return (
    <div id='expertise'>
        <h3 className='flous-police-one flous-input-color text-center'>Professionnal Expertise</h3>
        <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className='mx-3'>
            <div className="form-group row">
                <label htmlFor="inputWorkedInSameProfession" className="col-sm-6 col-form-label">
                {EnglishQuestion.professionalExpertise.workedSameProfession}
                </label>
                <div className="col-sm-6">
                    <input type="text" className="form-control" id="inputWorkedInSameProfession" value={professionalExpertise.workedInSameProfession} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="inputExceptionalExpertise" className="col-sm-6 col-form-label">
                {EnglishQuestion.professionalExpertise.exceptionalExpertise}
                </label>
                <div className="col-sm-6">
                    <input type="text" className="form-control" id="inputExceptionalExpertise" value={professionalExpertise.exceptionalExpertise} />
                </div>
            </div>
            {props.professionalExpertise.professionalExpertise.exceptionalExpertise === 'Yes' && (
            <>
            <div className="form-group row">
                <label htmlFor="inputNotableAchievements" className="col-sm-6 col-form-label">
                {EnglishQuestion.professionalExpertise.notableAchievements}
                </label>
                <div className="col-sm-6">
                    <input type="text" className="form-control" id="inputNotableAchievements" value={professionalExpertise.notableAchievements} />
                </div>
            </div>
            {props.professionalExpertise.notableAchievements.recognition === 'Yes' && (
            <div className="form-group row">
                <label htmlFor="inputImpactsOfExpertise" className="col-sm-6 col-form-label">
                {EnglishQuestion.professionalExpertise.impactofExpertise}
                </label>
                <div className="col-sm-6">
                    <input type="text" className="form-control" id="inputImpactsOfExpertise" value={professionalExpertise.impactsOfExpertise} />
                </div>
            </div>
            )}
            </>
            )}
        </div>
        </div>
    </div>
  );
}
