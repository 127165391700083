import api from '../api';

let isLoading = false;

export const fetchFlousGetInTouchMessages = async () => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-all-getintouch-messages`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const fetchFlousTechnicalGetInTouchMessages = async (isHistorical) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-all-tech-getintouch-messages/${isHistorical}`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const fetchFlousUrgentGetInTouchMessages = async (isHistorical) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-all-urg-getintouch-messages/${isHistorical}`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const fetchFlousSuggestionGetInTouchMessages = async (isHistorical) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-all-sugg-getintouch-messages/${isHistorical}`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const fetchFlousHistoricalGetInTouchMessages = async () => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-all-hist-getintouch-messages`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const getMessageData = async (message) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-user-getintouch-message/${message}`);
        return response;

    } catch (error){
        // console.error('Error when getting message data:', error);
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const getUserHistoricalData = async (user) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-user-hist-getintouch-messages/${user}`);
        return response;

    } catch (error){
        // console.error('Error when getting user historical message data:', error);
        throw error;
    } finally {
        isLoading = false;
    }
};

export const updateMessage = async (id, data) => {
    try{
        const response = await api.post(`/flous/set-getintouch-message/${id}/`, data);
        return response;

    } catch (error){
        // console.error('Error when sending message:', error);
        throw error;
    }   
};

export const archiveGetInTouchMessage = async (id) => {
    try{
        const response = await api.get(`/flous/archive-getintouch-message/${id}`);
        return response;

    } catch (error){
        // console.error('Error when sending message:', error);
        throw error;
    }   
};

////////////////////////////////////////// 
// Demo Request / Contact Us

export const fetchFlousContactUsMessages = async () => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-all-contactus-messages`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const deleteContactUsMessage = async (id) => {
    try{
        const response = await api.get(`/flous/delete-contactus-message/${id}`);
        return response;

    } catch (error){
        // console.error('Error when sending message:', error);
        throw error;
    }   
};

export const updateContactUsMessage = async (id, data) => {
    try{
        const response = await api.post(`/flous/set-contactus-message/${id}/`, data);
        return response;

    } catch (error){
        throw error;
    }   
};

export const fetchFlousGetDemoRequests = async () => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-all-demo-requests`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};