import React, { useState } from 'react';
import FlagComponent from '../../general/FlagComponent';
import { DeleteForm, DuplicateFormFromAdvisor, mapStateToDescription } from '../../../services/users/FormsServices';
import LoadingOverlay from '../../general/LoadingOverlay';
import { evaluateForm } from '../../../services/users/ResultsServices';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const AgentCustomersFormItem = ({ item, userType="enterprise" }) => {
  const [loading, setLoading] = useState(false);

  const newform = 'edit';
  const pageTypeForm = "form"

  const stateList = ['CP'];
  const navigate = useNavigate();

  const handleDeleteForm = async (code) =>{
    setLoading(true);
    try{
          const response = await DeleteForm(code);
          // await new Promise((resolve) => setTimeout(resolve, 2000));

          if (response && response.status === 200){
            setLoading(false);
            window.location.reload();
          }
      }  catch (error){
          setLoading(false);
          if (error.response && [400, 500].includes(error.response.status)){
            toast.error(error.response.data.error);
          }
    }
  }

  const handleDuplicateForm = async (code) =>{
    setLoading(true);
    try{
          const response = await DuplicateFormFromAdvisor(code);

          if (response && [200, 201].includes(response.status)){
            setLoading(false);
            window.location.reload();
          }
      }  catch (error){
          setLoading(false);
          if (error.response && [400, 500].includes(error.response.status)){
            toast.error(error.response.data.error);
          }
    }
  }

  const handleEditForm = (code) => {
    navigate(`/${userType}/customer/form/${newform}/${code}`);
  };

  const handleEvaluateForm = async(code) => {
    setLoading(true);
    try{
      const response = await evaluateForm(code);
      await new Promise((resolve) => setTimeout(resolve, 2000));

      if (response && response.status === 200){
        setLoading(false);
        window.location.reload();
      }

    }  catch (error){
      setLoading(false);
      if (error.response && [400, 500].includes(error.response.status)){
        toast.error(error.response.data.error);
      }
  }
  };

  if (loading) {
      return <LoadingOverlay />;
  }

  return (
      <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px' }}>
        <div key={item.code} className='row'>
            <div className='col-sm col-6 mt-2 text-center'>{item.creation_date}</div>
            <div className='col-sm col-6 mt-2 text-center'><FlagComponent cca3={item.country_cca3} /></div>
            <div className='col-sm col-6 mt-2 text-center'>{item.title}</div>
            <div className='col-sm col-6 mt-2 text-center'>
                  <div
                    style={{ pointerEvents: 'none' }}
                    className={`btn ${
                    item.state === 'CR'
                        ? 'flous-bg-thirty'
                        : item.state === 'IP'
                        ? 'flous-bg-secondary'
                        : item.state === 'AB'
                        ? 'btn-secondary'
                        : item.state === 'CP'
                        ? 'flous-bg-menu'
                        : 'btn-secondary'
                    }`}
                  >
                    {mapStateToDescription(item.state)}
                  </div>
            </div>
            <div className='col-sm col-12 mt-2 text-center'>
              <a href={`/${userType}/customer/view/${pageTypeForm}/${item.code}`} className="btn flous-bg-menu mr-1"><i className="nav-icon fas fa-eye"></i></a>
              {stateList.includes(item.state) ? (
              <span onClick={() => handleEvaluateForm(item.code)} className="btn flous-bg-primary mr-1" data-toggle="modal" data-target="#evaluateModal">
                  <span >Evaluate</span>
              </span>
              ) : (
                  <span className="btn flous-bg-primary mr-1 disabled" aria-disabled="true">Evaluate</span>
              )}
              <a href={`/${userType}/customer/form/${newform}/${item.code}`} onClick={() => handleEditForm(item.code)}> {/* data-toggle="modal" data-target="#updateModal"*/}
                  <span className="btn flous-bg-secondary mr-1 mt-1">
                      <i className="nav-icon fas fa-edit"></i>
                  </span>
              </a>
              <span onClick={() => handleDuplicateForm(item.code)}>
                  <span className="btn btn-secondary mr-1">
                      <i className="nav-icon fas fa-copy"></i>
                  </span>
              </span>
              <span onClick={() => handleDeleteForm(item.code)}>
                  <span className="btn btn-secondary mr-1">
                      <i className="nav-icon fas fa-trash"></i>
                  </span>
              </span>
            </div>
        </div>
        <ToastContainer />
      </div>
  );
}

export default AgentCustomersFormItem;