import React, { useState } from 'react';
import GoToBackButton from '../../general/GoBackLink';
import ProvinceSelect from '../../general/ProvinceSelect';
import api from "../../../services/api";

export default function FlousCreateBlogsPage() {
    const [formData, setFormData] = useState({
        province: '',
        publish_date: '',
        end_publish_date: '',
        blogENTitle: '',
        blogFRTitle: '',
        blogESTitle: '',
        blogENDescription: '',
        blogFRDescription: '',
        blogESDescription: '',
        image: null
    });

    const handleProvinceStateChange = (e) => {
        const { name, value } = e.target;
        console.log(name)
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({
            ...prevData,
            image: file
        }));
    };

    const validateForm = () => {
        //console.log(formData.provinceState)
        const requiredFields = [
            'province',
            'publish_date',
            'end_publish_date',
            'blogENTitle',
            'blogFRTitle',
            'blogESTitle',
            'blogENDescription',
            'blogFRDescription',
            'blogESDescription',
            'image'
        ];
        for (const field of requiredFields) {
            if (!formData[field]) {
                alert(`Please fill in the field: ${field}`);
                return false;
            }
        }
        return true;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;
    
        try {
            // Créer une instance de FormData
            const transformedData = new FormData();
            transformedData.append("image", formData.image); // Ajout du fichier directement
            transformedData.append(
                "title",
                JSON.stringify({
                    en: formData.blogENTitle,
                    fr: formData.blogFRTitle,
                    es: formData.blogESTitle,
                })
            );
            transformedData.append("startDate", formData.publish_date);
            transformedData.append("endDate", formData.end_publish_date);
            transformedData.append(
                "description",
                JSON.stringify({
                    en: formData.blogENDescription,
                    fr: formData.blogFRDescription,
                    es: formData.blogESDescription,
                })
            );
            transformedData.append("province", formData.province);
            console.log(transformedData)
            // Envoyer les données via API
            const response = await api.post("/flous/save-post/", transformedData, {
                headers: {
                    "Content-Type": "multipart/form-data", // Indiquer que les données sont multipartites
                },
            });
    
            console.log(response);
    
            // Gérer la réponse
            if (response.status >= 200 && response.status < 300) {
                const data = response.data; // Pas besoin d'attendre une promesse ici
                console.log("Data successfully submitted:", data);
                alert("Blog successfully created!");
            } else {
                console.error("Failed to submit data:", response.statusText);
                alert("Error while submitting data.");
            }
        } catch (error) {
            console.error("An error occurred while submitting data:", error);
            alert("An unexpected error occurred.");
        }
    };    
    

    return (
        <div className="content">
            <div className="container-fluid">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="mb-2">
                            <GoToBackButton />
                            <div className="col-sm-6">
                                <h1 className="m-0 flous-police-one flous-input-color">Add new Blog</h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="content">
                    <div className="container-fluid">
                        <div>
                            {/* Province Selection */}
                            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                                <div className="row">
                                    <div className="col-md">
                                        <p>Which Province is your target?</p>
                                        <ProvinceSelect
                                            value={formData.provinceState}
                                            onChange={handleProvinceStateChange}
                                            name="province"
                                            country="CAN"
                                            required
                                        />
                                    </div>
                                    <div className="col-md">
                                        <p>Publish Date?</p>
                                        <input
                                            type="date"
                                            className="col-md form-control rounded-pill flous-input-color"
                                            id="publish_date"
                                            name="publish_date"
                                            value={formData.publish_date}
                                            onChange={handleInputChange}
                                            placeholder="Please enter your blog publish date!"
                                            required
                                        />
                                    </div>
                                    <div className="col-md">
                                        <p>End Publish Date?</p>
                                        <input
                                            type="date"
                                            className="col-md form-control rounded-pill flous-input-color"
                                            id="end_publish_date"
                                            name="end_publish_date"
                                            value={formData.end_publish_date}
                                            onChange={handleInputChange}
                                            placeholder="Please enter your blog end publish date!"
                                            required
                                        />
                                    </div>
                                    <div className="col-md">
                                        <p>Add an image</p>
                                        <input
                                            type="file"
                                            className="form-control-file flous-input-color"
                                            placeholder='Please select an image'
                                            id="image"
                                            name="image"
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* Blog Titles */}
                            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                                <p>Could you please give a title to your blog?</p>
                                <div className="row">
                                    <input
                                        type="text"
                                        className="col-md form-control rounded-pill flous-input-color m-2"
                                        id="blogENTitle"
                                        name="blogENTitle"
                                        value={formData.blogENTitle}
                                        onChange={handleInputChange}
                                        placeholder="Please enter your blog title in English!"
                                        required
                                    />
                                    <input
                                        type="text"
                                        className="col-md form-control rounded-pill flous-input-color m-2"
                                        id="blogFRTitle"
                                        name="blogFRTitle"
                                        value={formData.blogFRTitle}
                                        onChange={handleInputChange}
                                        placeholder="Please enter your blog title in French!"
                                        required
                                    />
                                    <input
                                        type="text"
                                        className="col-md form-control rounded-pill flous-input-color m-2"
                                        id="blogESTitle"
                                        name="blogESTitle"
                                        value={formData.blogESTitle}
                                        onChange={handleInputChange}
                                        placeholder="Please enter your blog title in Spanish!"
                                        required
                                    />
                                </div>
                            </div>

                            {/* Blog Descriptions */}
                            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                                <p>Could you please give a description to your blog?</p>
                                <textarea
                                    className="form-control flous-input-color m-2"
                                    id="blogENDescription"
                                    name="blogENDescription"
                                    value={formData.blogENDescription}
                                    onChange={handleInputChange}
                                    placeholder="Please enter your blog description in English!"
                                    rows="5"
                                    required
                                />
                                <textarea
                                    className="form-control flous-input-color m-2"
                                    id="blogFRDescription"
                                    name="blogFRDescription"
                                    value={formData.blogFRDescription}
                                    onChange={handleInputChange}
                                    placeholder="Please enter your blog description in French!"
                                    rows="5"
                                    required
                                />
                                <textarea
                                    className="form-control flous-input-color m-2"
                                    id="blogESDescription"
                                    name="blogESDescription"
                                    value={formData.blogESDescription}
                                    onChange={handleInputChange}
                                    placeholder="Please enter your blog description in Spanish!"
                                    rows="5"
                                    required
                                />
                            </div>
                        </div>
                        <div className='text-center mb-3'>
                        <button className="btn btn-lg flous-bg-primary col-sm-6 col-12" onClick={handleSubmit}>Publish a new blog now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}