import React from 'react';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function AdditionalInfoResumeSection(props) {
  // Define the financial information
  const netWorth = props.addInfo.netWorth; //  
  const provincialNomination = props.addInfo.provincialNomination; //   (Yes/No)

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Additional Information</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputNetWorth" className="col-sm-3 col-form-label">
          {EnglishQuestion.personalInfo.netWorth}
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputNetWorth" value={netWorth} />
          </div>
          <label htmlFor="earningsHistory" className="col-sm-3 col-form-label">
          {EnglishQuestion.personalInfo.earningsHistory}
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="earningsHistory" value={props.addInfo.earningsHistory} />
          </div>
        </div>
      </div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="totalCurrentAssets" className="col-sm-3 col-form-label">
          {EnglishQuestion.personalInfo.totalCurrentAssets}
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="totalCurrentAssets" value={props.addInfo.totalCurrentAssets} />
          </div>
          <label htmlFor="inputNomination" className="col-sm-3 col-form-label">
          {EnglishQuestion.personalInfo.provincialNomination} Canada ?
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputNomination" value={provincialNomination} />
          </div>
        </div>
      </div>
    </div>
  );
}
