import React from 'react';

const NoItemsMessage = () => {
  return (
    <div className="text-center p-3 flous-police-one">
      No items to display
    </div>
  );
};

export default NoItemsMessage;