import React from 'react';
import FlagComponent from '../../general/FlagComponent';

const FlousSelfItem = ({ customer }) => {

    const flousCode = "Taps";
    
    return (
        <div className='card-header p-3 mb-2 shadow bg-white' style={{ borderRadius: '15px' }}>
            <div className='row'>
                <div className='col-sm col-6 mt-2 text-center'>{customer.firstName}</div>
                <div className='col-sm col-6 mt-2 text-center'>{customer.lastName}</div>
                <div className='col-sm col-6 mt-2 text-center'>
                    <FlagComponent cca3={customer.country_cca3} />
                </div>
                <div className='col-sm col-6 mt-2 text-center'>{customer.forms}</div>
                <div className='col-sm col-12 mt-2 text-center'>
                    <div className={`btn ${customer.status === true ? 'flous-bg-menu' : 'bg-secondary'}`}>
                    {customer.status === true ? 'Active' : 'Deactive'}
                    </div>
                </div>
                <div className='col-sm-2 col-12 mt-2 text-center'>
                    <a href={`/flous/self/${flousCode}/${customer.code}`} className="btn flous-bg-primary mr-2"><i className="nav-icon fas fa-eye"></i></a>
                    <button type="button" className="btn btn-secondary mr-2">
                        Deactivate
                    </button>
                </div>
            </div>
        </div>
    );
}

export default FlousSelfItem;