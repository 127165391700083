import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import Chart from 'admin-lte/plugins/chart.js/Chart.bundle';
import PropTypes from 'prop-types';

export function enterpriseDashboadChart(elementId, thisYear, lastYear, labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']) {
  $(function () {
    var ticksStyle = {
      fontColor: '#495057',
      fontStyle: 'bold'
    };

    var mode = 'index';
    var intersect = true;

    var $salesChart = $(`#${elementId}`);
    new Chart($salesChart, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            backgroundColor: '#007bff',
            borderColor: '#007bff',
            data: thisYear
          },
          {
            backgroundColor: '#ced4da',
            borderColor: '#ced4da',
            data: lastYear
          }
        ]
      },
      options: {
        maintainAspectRatio: false,
        tooltips: {
          mode: mode,
          intersect: intersect
        },
        hover: {
          mode: mode,
          intersect: intersect
        },
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            gridLines: {
              display: true,
              lineWidth: '4px',
              color: 'rgba(0, 0, 0, .2)',
              zeroLineColor: 'transparent'
            },
          }],
          xAxes: [{
            display: true,
            gridLines: {
              display: false
            },
            ticks: ticksStyle
          }]
        }
      }
    });
  });
}

const CustomerEvolutionCard = ({ elementId, thisYear, lastYear, totalCustomers, labels, title = "Total Clients evolutions" }) => {
  
    const [evolution, setEvolution] = useState();

  useEffect(() => {
    enterpriseDashboadChart(elementId, thisYear, lastYear, labels);
    setEvolution(() => {
      if (thisYear.length < 2) {
          return 0;
      }
      
      const lastValue = thisYear[thisYear.length - 1];
      const secondLastValue = thisYear[thisYear.length - 2];
      
      return (lastValue - secondLastValue) / secondLastValue;
  });
  }, [elementId, thisYear, lastYear]);

  return (
    <div className="card" style={{ borderRadius: '15px' }}>
      <div className="card-header border-0">
        <div className="card btn">
          <h4 className="flous-police-one">{title}</h4>
        </div>
      </div>
      <div className="card-body">
        <div className="d-flex">
          <p className="d-flex flex-column">
            <span className="text-bold text-lg">{totalCustomers}</span>
            <span>Total Clients</span>
          </p>
          <p className="ml-auto d-flex flex-column text-right">
            <span className={evolution > 0 ? "text-success" : "text-danger"}>
                <i className={`fas fa-arrow-${evolution > 0 ? "up" : "down"}`}></i> {(Math.abs(evolution) * 100).toFixed(1)}%
            </span>
            <span className="text-muted">Since last month</span>
          </p>
        </div>

        <div className="position-relative mb-4">
          <canvas id={elementId} height="200"></canvas>
        </div>

        <div className="d-flex flex-row justify-content-end">
          <span className="mr-2">
            <i className="fas fa-square text-primary"></i> This year
          </span>
          <span>
            <i className="fas fa-square text-gray"></i> Last year
          </span>
        </div>
      </div>
    </div>
  );
};

CustomerEvolutionCard.propTypes = {
  elementId: PropTypes.string.isRequired,
};

export default CustomerEvolutionCard;