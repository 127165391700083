import React from 'react';

export default function EnterprisePricingSection() {

    return <div id='pricing'>
        <div className='row mt-1'>
            <div className='col'>
                <div className='flous-bg-thirty p-3 elevation-2 mb-3 text-center' style={{ borderRadius: '15px' }}>
                <h3 className='flous-police-one flous-input-color'>Solo</h3>
                <div className='btn btn-light btn-block elevation-1 '>
                    <h4 className='text-bold'>$ 59.99</h4>
                    <div>1 Person - 8 Forms</div>
                </div>
                <div>Professional plan: Tailored for immigration professional seeking advanced tools and resources</div>
                <ul className='text-left'>
                    <li className='m-3'>Acess to appear in the FlousFiles database</li>
                    <li className='m-3'>Networking opportunities </li>
                    <li className='m-3'>Priority support</li>
                </ul>
                <div className='flous-input-color m-3'>Explore new opportunities in another country for a new immigration process!</div>
                <div className="col-8 btn flous-btn-gradient rounded-pill mx-1">Purchase new form</div>
                </div>
            </div>
            <div className='col'>
                <div className='flous-bg-thirty p-3 elevation-2 mb-3 text-center' style={{ borderRadius: '15px' }}>
                <h3 className='flous-police-one flous-input-color'>Annual plan</h3>
                <div className='btn btn-light btn-block elevation-1 '>
                    <h4 className='text-bold'>$ 99.99</h4>
                    <div>1 Person - 20 Forms</div>
                </div>
                <div>Professional plan: Tailored for immigration professional seeking advanced tools and resources</div>
                <ul className='text-left'>
                    <li className='m-3'>Acess to appear in the FlousFiles database</li>
                    <li className='m-3'>Networking opportunities </li>
                    <li className='m-3'>Priority support</li>
                </ul>
                <div className='flous-input-color m-3'>Explore new opportunities in another country for a new immigration process!</div>
                <div className="col-8 btn flous-btn-gradient rounded-pill mx-1">Purchase new form</div>
                </div>
            </div>
            <div className='col'>
                <div className='flous-bg-thirty p-3 elevation-2 mb-3 text-center' style={{ borderRadius: '15px' }}>
                <h3 className='flous-police-one flous-input-color'>Annual plan</h3>
                <div className='btn btn-light btn-block elevation-1 '>
                    <h4 className='text-bold'>$ XXX</h4>
                    <div>xxx /per month /per user</div>
                </div>
                <div>Professional plan: Tailored for immigration professional seeking advanced tools and resources</div>
                <ul className='text-left'>
                    <li className='m-3'>Acess to appear in the FlousFiles database</li>
                    <li className='m-3'>Networking opportunities </li>
                    <li className='m-3'>Priority support</li>
                </ul>
                <div className='flous-input-color m-3'>Explore new opportunities in another country for a new immigration process!</div>
                <div className="col-8 btn flous-btn-gradient rounded-pill mx-1">Purchase new form</div>
                </div>
            </div>
        </div>
    </div>;
}