import React from 'react';
import { useNavigate } from 'react-router-dom';

const GoToBackButton = ({ text }) => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-start items-center mb-3">
      <button
        className="flex items-center text-[#808080]"
        onClick={() => navigate(-1)}
      >
        <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5 1L1 5.5L5 10" stroke="#808080" strokeLinecap="round" />
        </svg>
        <span className="text-[15px] ml-[1px]">Go back</span>
      </button>
    </div>
  );
};

export default GoToBackButton;
