import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import api from '../../services/api';

const ProvinceSelect = ({ value, onChange, country, className="react-select-container" }) => {
  const [provinces, setProvinces] = useState([]);

  useEffect( () => {
    const fetchProvinces = async() => {
      if (country === "CAN") {
        const response = await api.get('/canada-provinces/');
        const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
          value: key,
          label: val,
        }));
        setProvinces(provinceOptions);
      }
    };

    fetchProvinces();
    
  }, [country]);

  return (
    <Select
      classNamePrefix="react-select"
      className={className}
      value={provinces.find(province => province.value === value)}
      onChange={(selectedOption) => onChange({ target: { name: 'province', value: selectedOption ? selectedOption.value :''} })}
      options={provinces}
      placeholder="Select Province"
      isSearchable
      isClearable
      styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
          borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
          borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
        }),
      }}
      required
    />
  );
};

export default ProvinceSelect;
