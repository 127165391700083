import React, {useContext} from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate, useOutletContext } from 'react-router-dom';

const LeftMenu = ({ userprofile, page }) => {

  const {logout} = useContext(AuthContext);

  const { user } = useOutletContext();

  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };



  return (
    <aside className="main-sidebar sidebar-dark-primary flous-bg-menu elevation-4 fixed-sidebar" style={{position: 'fixed'}}>
      {/* Brand Logo */}
      <a href="/" className="d-flex justify-content-center m-4">
        <img src={`${process.env.PUBLIC_URL}/flous/logos/logo_from_design.svg`} alt="Flous Logo" className="brand-image" style={{ opacity: .8 }} height={'50px'}/>
      </a>

      {/* Sidebar */}
      <div className="sidebar ">
        {/* Sidebar user panel (optional) */}
        <a href={`/${userprofile}/profile`}>
          <div className="mt-3 pb-3 mb-3 flous-bg-thirty m-2 p-2 text-center" style={{ borderRadius: '15px' }}>
            <div className="flex justify-center">
              {user.profile_picture_url ? (
                <>
                <img
                  src={user.profile_picture_url}
                  className="rounded-full"
                  alt="User profile"
                  width="140"
                  height="140"
                />
                </>
              ):(
                <>
                <img
                  src={`${process.env.PUBLIC_URL}/avatar/avatar.png`}
                  className="rounded-full"
                  alt="User profile"
                  width="140"
                  height="140"
                />
                </>
              )}
            </div>
            <div className="info mt-3">
              <div className="d-block text-white flous-police-one">{user.first_name} {user.last_name}</div>
            </div>
          </div>
        </a>
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar flex-column flous-bg-thirty m-2 p-2" style={{ borderRadius: '15px' }} data-widget="treeview" role="menu" data-accordion="false">
            {userprofile === "user" || userprofile === "client" ? (
              <>
              {user.self_analyst ?(
                <>
                  <div className={`btn btn-block ${page === "dashbord" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'dashbord' ? '#FFFFFF' : '' }}>
                    <a href="/user/dashboard">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-chart-pie"></i></div>
                        <div className='col text-white text-left'>Dashboard</div>
                      </div>
                    </a>
                  </div>
                  <div className={`btn btn-block ${page === "forms" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'forms' ? '#FFFFFF' : '' }}>
                    <a href="/user/forms">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-edit"></i></div>
                        <div className='col text-white text-left'>My Forms</div>
                      </div>
                    </a>
                  </div>
                  <div className={`btn btn-block ${page === "results" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'results' ? '#FFFFFF' : '' }}>
                    <a href="/user/results">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-th"></i></div>
                        <div className='col text-white text-left'>My Results</div>
                      </div>
                    </a>
                  </div>
                  <div className={`btn btn-block ${page === "asking" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'asking' ? '#FFFFFF' : '' }}>
                    <a href="/user/asking">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-folder"></i></div>
                        <div className='col text-white text-left'>FlousFiles</div>
                      </div>
                    </a>
                  </div>
                  <div className={`btn btn-block ${page === "inbox" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'inbox' ? '#FFFFFF' : '' }}>
                    <a href="/user/inbox">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-envelope"></i></div>
                        <div className='col text-white text-left'>Inbox</div>
                      </div>
                    </a>
                  </div>
                </>
              ):(<>
                <div className={`btn btn-block ${page === "forms" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'forms' ? '#FFFFFF' : '' }}>
                  <a href="/client/forms">
                    <div className='row'>
                      <div className='col-2 text-white'><i className="nav-icon fas fa-edit"></i></div>
                      <div className='col text-white text-left'>My Forms</div>
                    </div>
                  </a>
                </div>
              </>)}
                
              </>
            ) : userprofile === "agent" ? (
              <>
                <div className={`btn btn-block ${page === "dashbord" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'dashbord' ? '#FFFFFF' : '' }}>
                  <a href="/agent/dashboard">
                    <div className='row'>
                      <div className='col-2 text-white'><i className="nav-icon fas fa-chart-pie"></i></div>
                      <div className='col text-white text-left'>Dashboard</div>
                    </div>
                  </a>
                </div>
                <div className={`btn btn-block ${page === "customers" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'customers' ? '#FFFFFF' : '' }}>
                  <a href="/agent/customers">
                    <div className='row'>
                      <div className='col-2 text-white'><i className="nav-icon fas fa-users"></i></div>
                      <div className='col text-white text-left'>My Clients</div>
                    </div>
                  </a>
                </div>
                {/*
                <div className={`btn btn-block ${page === "request" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'request' ? '#FFFFFF' : '' }}>
                  <a href="/agent/customers/request">
                    <div className='row'>
                      <div className='col-2 text-white'><i className="nav-icon fas fa-file-alt"></i></div>
                      <div className='col text-white text-left'>Clients request</div>
                    </div>
                  </a>
                </div> 
                */}
                <div className={`btn btn-block ${page === "inbox" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'inbox' ? '#FFFFFF' : '' }}>
                  <a href="/agent/inbox">
                    <div className='row'>
                      <div className='col-2 text-white'><i className="nav-icon fas fa-envelope"></i></div>
                      <div className='col text-white text-left'>Inbox</div>
                    </div>
                  </a>
                </div>
              </>
            ) : (userprofile === "enterprise" || userprofile === "professional") ? (
              <>
                {user.type_package === 'Enterprise' ? (
                  <>
                  <div className={`btn btn-block ${page === "dashbord" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'dashbord' ? '#FFFFFF' : '' }}>
                    <a href="/enterprise/dashboard">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-chart-pie"></i></div>
                        <div className='col text-white text-left'>Dashboard</div>
                      </div>
                    </a>
                  </div>
                  <div className={`btn btn-block ${page === "advisors" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'advisors' ? '#FFFFFF' : '' }}>
                    <a href="/enterprise/advisors">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-user"></i></div>
                        <div className='col text-white text-left'>Advisors</div>
                      </div>
                    </a>
                  </div>
                  <div className={`btn btn-block ${page === "customers" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'customers' ? '#FFFFFF' : '' }}>
                    <a href="/enterprise/customers">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-users"></i></div>
                        <div className='col text-white text-left'>Clients</div>
                      </div>
                    </a>
                  </div>
                  {/*
                  <div className={`btn btn-block ${page === "request" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'request' ? '#FFFFFF' : '' }}>
                    <a href="/enterprise/customers/request">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-file-alt"></i></div>
                        <div className='col text-white text-left'>Clients request</div>
                      </div>
                    </a>
                  </div>
                  <div className={`btn btn-block ${page === "availability" ? 'flous-bg-primary' : ''}`} style={{ color: page === 'availability' ? '#FFFFFF' : '' }}>
                    <a href="https://auth.calendly.com/oauth/authorize?client_id=oj1SYSwfE3lhPI7p5PEjwHYZOkIoIFfjT4-K0fxpdrQ&response_type=code&redirect_uri=http://localhost:3000/login" target="_blank">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-calendar-check"></i></div>
                        <div className='col text-white text-left'>Availabilities</div>
                      </div>
                    </a>
                  </div>
                  */}
                  <div className={`btn btn-block ${page === "inbox" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'inbox' ? '#FFFFFF' : '' }}>
                    <a href="/enterprise/inbox">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-envelope"></i></div>
                        <div className='col text-white text-left'>Inbox</div>
                      </div>
                    </a>
                  </div>
                  </>
                ):(
                  <>
                  <div className={`btn btn-block ${page === "dashbord" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'dashbord' ? '#FFFFFF' : '' }}>
                    <a href="/professional/dashboard">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-chart-pie"></i></div>
                        <div className='col text-white text-left'>Dashboard</div>
                      </div>
                    </a>
                  </div>
                  <div className={`btn btn-block ${page === "customers" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'customers' ? '#FFFFFF' : '' }}>
                    <a href="/professional/customers">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-users"></i></div>
                        <div className='col text-white text-left'>My Clients</div>
                      </div>
                    </a>
                  </div>
                  {/*
                  <div className={`btn btn-block ${page === "request" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'request' ? '#FFFFFF' : '' }}>
                    <a href="/enterprise/customers/request">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-file-alt"></i></div>
                        <div className='col text-white text-left'>Clients request</div>
                      </div>
                    </a>
                  </div>
                  <div className={`btn btn-block ${page === "availability" ? 'flous-bg-primary' : ''}`} style={{ color: page === 'availability' ? '#FFFFFF' : '' }}>
                    <a href="https://auth.calendly.com/oauth/authorize?client_id=oj1SYSwfE3lhPI7p5PEjwHYZOkIoIFfjT4-K0fxpdrQ&response_type=code&redirect_uri=http://localhost:3000/login" target="_blank">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-calendar-check"></i></div>
                        <div className='col text-white text-left'>Availabilities</div>
                      </div>
                    </a>
                  </div>
                  */}
                  <div className={`btn btn-block ${page === "inbox" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'inbox' ? '#FFFFFF' : '' }}>
                    <a href="/professional/inbox">
                      <div className='row'>
                        <div className='col-2 text-white'><i className="nav-icon fas fa-envelope"></i></div>
                        <div className='col text-white text-left'>Inbox</div>
                      </div>
                    </a>
                  </div>
                  </>
                )}
                
              </>
            ) : userprofile === "flous" && (
              <>
                <div className={`btn btn-block ${page === "dashbord" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'dashbord' ? '#FFFFFF' : '' }}>
                  <a href="/flous/dashboard">
                    <div className='row'>
                      <div className='col-2 text-white'><i className="nav-icon fas fa-chart-pie"></i></div>
                      <div className='col text-white text-left'>Dashboard</div>
                    </div>
                  </a>
                </div>
                <div className={`btn btn-block ${page === "compagnies" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'compagnies' ? '#FFFFFF' : '' }}>
                  <a href="/flous/compagnies">
                    <div className='row'>
                      <div className='col-2 text-white'><i className="nav-icon fas fa-user"></i></div>
                      <div className='col text-white text-left'>Pro Accounts</div>
                    </div>
                  </a>
                </div>
                <div className={`btn btn-block ${page === "self" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'self' ? '#FFFFFF' : '' }}>
                  <a href="/flous/self">
                    <div className='row'>
                      <div className='col-2 text-white'><i className="nav-icon fas fa-users"></i></div>
                      <div className='col text-white text-left'>Self Accounts</div>
                    </div>
                  </a>
                </div>
                <div className={`btn btn-block ${page === "inbox" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'inbox' ? '#FFFFFF' : '' }}>
                  <a href="/flous/inbox">
                    <div className='row'>
                      <div className='col-2 text-white'><i className="nav-icon fas fa-envelope"></i></div>
                      <div className='col text-white text-left'>Inbox</div>
                    </div>
                  </a>
                </div>
                <div className={`btn btn-block ${page === "demo" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'demo' ? '#FFFFFF' : '' }}>
                  <a href="/flous/demorequest">
                    <div className='row'>
                      <div className='col-2 text-white'><i className="nav-icon fas fa-file-alt"></i></div>
                      <div className='col text-white text-left'>Demo Request</div>
                    </div>
                  </a>
                </div>
                <div className={`btn btn-block ${page === "blogs" ? 'flous-bg-secondary text-bold' : ''}`} style={{ color: page === 'blogs' ? '#FFFFFF' : '' }}>
                  <a href="/flous/blogs">
                    <div className='row'>
                      <div className='col-2 text-white'><i className="nav-icon fas fa-blog"></i></div>
                      <div className='col text-white text-left'>Blogs</div>
                    </div>
                  </a>
                </div>
              </>
            )}
          </ul>
        </nav>
        
        <div className="sidebar-footer">
          <button onClick={handleLogout} type="button" className="btn btn-block btn-outline-danger bg-white">Sign out</button>
        </div>

      </div>
      
      {/* /.sidebar */}
    </aside>
  );
};

export default LeftMenu;
