import React from 'react';
import AgentDashboard from '../agent/AgentDashbord';

export default function EntrepriseAdvisorsDetail () {

  return (
    <>
      <AgentDashboard/>
    </>
  );
};