import api from '../api';

export const GetAdvisorCompanyDetail = async (code) => {
    try{
        const response = await api.get(`/advisor/get-company-detail/${code}`);
        return response;

    } catch (error){
        throw error;
    }   
};