import React, { useState } from 'react';
import RequiredIndicator from '../../../../../general/requiredIndicator';

import EnglishQuestion from "../../../questions/englishQuestions.json";

const RoleAndExperienceSection = (props) => {
  const [formData, setFormData] = useState(props.roleAndExperience);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendRoleAndExperience(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Role and Experience</p>
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.position}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color"
              name="position"
              value={formData.position}
              onChange={handleChange} 
            />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.startDate}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="date" 
              className="form-control rounded-pill flous-input-color"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange} max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.operatingPeriod}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="number" 
              className="form-control rounded-pill flous-input-color"
              name="operatingPeriod"
              value={formData.operatingPeriod}
              onChange={handleChange} min={0}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleAndExperienceSection;
