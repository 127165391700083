import React, { useState } from 'react';
import { MessageStatesConverter, MessageTypesConverter, truncateMessage } from '../../../services/utils';
import { toast } from 'react-toastify';
import LoadingOverlay from '../../general/LoadingOverlay';
import { DeleteGetInTouchMessage } from '../../../services/general/MessagesServices';

const UserMessageItem = ({ message, index, sendSelectedIndex }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleDeleteMessage = async (id) => {
        try {
          setLoading(true)
          const data = {
            rating: index+1
          }
          const response = await DeleteGetInTouchMessage(id);
          if (response && response.status === 200) {
            setLoading(false);
            window.location.reload();
          }
        } catch (error) {
          setLoading(false);
          if (error.response && [400,, 401, 404, 500].includes(error.response.status)) {
            setError(error);
            toast.error(error.response.data.error);
          }
        }
    }

    const handleSendID = async (index) =>{
        sendSelectedIndex(index);
    }

    if (loading) {
      return <LoadingOverlay />;
    }
  
    if (error) {
      return <div>Error : {error.message}</div>;
    }
    
    return (
        <div className='card-header p-3 mb-2 shadow bg-white' style={{ borderRadius: '15px' }}>
            <div className='row text-bold'>
                <div className='col-sm-1 col-12 mt-2 text-center'>
                    {MessageTypesConverter(message.type)}
                </div>
                <div className='col-sm-1 col-12 mt-2 text-center'>
                    {message.subject}
                </div>
                <div className='col-sm-3 col-12 mt-2 text-center'>
                    <span className='font-weight-normal'>{truncateMessage(message.message)}</span>
                </div>
                <div className='col-sm-3 col-12 mt-2 text-center'>
                <span className='font-weight-normal'>{message.answer ? truncateMessage(message.answer): 'N.A.'}</span>
                </div>
                <div className='col-sm-1 col-6 mt-2 text-center'>{message.date}</div>
                <div className='col-sm-1 col-6 mt-2 text-center'>{MessageStatesConverter(message.state)}</div>
                <div className='col-sm-2 col-12 mt-2 text-center'>
                    <button type='button' onClick={()=>handleSendID(index)} className="btn flous-bg-menu mx-1"> {/* href={`/${user}/inbox/${message.id}`} */}
                        <i className="nav-icon fas fa-eye"></i>
                    </button>
                    <button type="button" className="btn btn-secondary mx-2" onClick={()=>handleDeleteMessage(message.id)}>
                        <i className="nav-icon fas fa-trash"></i>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default UserMessageItem;