import React, {useState, useEffect } from 'react';
import formData from '../forms/formsData';
import UserResultItem from './userResultItem';
import { DeleteEvaluation, fetchEvaluationsData } from '../../../services/users/ResultsServices';
import { useOutletContext } from 'react-router-dom';
import LoadingOverlay from '../../general/LoadingOverlay';
import { toast } from 'react-toastify';
import { getCurrentItems } from '../../../services/users/FormsServices';
import PaginationSection from '../../Layout/PaginationSection';
import CountrySelect from '../../general/CountrySelect';
import NoItemsMessage from '../../general/NoItemsToDisplaysItem';
import GoToBackButton from '../../general/GoBackLink';

export default function UserResults () {
  const [evaluations, setEvaluations] = useState(formData.forms);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { user } = useOutletContext();

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  const [selectedCountry, setSelectedCountry] = useState(null);

  const handleChangeCountry = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const queryParams = new URLSearchParams({
      country: selectedCountry || ''
    }).toString();
    filterItems()
    //console.log(queryParams);
  };

  const filterItems = async () => {
    let isMounted = true;
    if (user.id) {
        try {
          const data = await fetchEvaluationsData();
          if (isMounted) {
            if (!selectedCountry) {
              setEvaluations(data);
              setLoading(false);
              return;
            }
            const filteredItems = data.filter((item) => {
              const matchesCountry = selectedCountry ? item.country_cca3 === selectedCountry : true;
              return matchesCountry;
            });
            setEvaluations(filteredItems);
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setError(error);
            setLoading(false);
            throw error;
          }
        }
      }
  };

  useEffect( () => {
    let isMounted = true;

    const getUserFormsData = async () => {
      if (user.id) {
        try {
          const data = await fetchEvaluationsData();
          if (isMounted) {
            setEvaluations(data);
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setError(error);
            setLoading(false);
            throw error;
          }
        }
      }
    };

    getUserFormsData();

    return () => {
      isMounted = false;
    };

  }, []);


  const handleDeleteEvaluation = async (code) =>{
    setLoading(true);
    try{
      const response = await DeleteEvaluation(code);
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
      window.location.reload();
      toast.success(response.data.message);
    }  catch (error){
      console.error('Error when deleting form:', error);
      setLoading(false);
      toast.error('Error when deleting form.');
      throw error;
    }
  }
  const onPageChange = (page) => {
    setPage(page + 1);
  };
  
  const currentItems = getCurrentItems(evaluations, page, pageSize);

 

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div className="content">
      <div className="container-fluid">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="mb-2">
              <GoToBackButton />
              <div className="col-sm-6">
                <h1 className="flous-police-one flous-input-color m-0">My Results</h1>
              </div>
            </div>
          </div>
        </div>
        {/* /.content-header */}

        <div className="row">
          <div className="col-12">
            <div className="card elevation-2" style={{ borderRadius: '15px' }}>
              <div className="card-header shadow">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm col-12">
                    <CountrySelect
                      required
                      onChange={handleChangeCountry}
                    />
                  </div>
                  <div className="col-sm-2 col-6">
                    <button type="submit" className="btn flous-input-color">
                      <i className="nav-icon fas fa-search"></i> Search
                    </button>
                  </div>
                  <div className="col-sm-1 col-6 btn flous-input-color">
                    <i className="nav-icon fas fa-sort"></i> Sort
                  </div>
                </div>
              </form>
              </div>
              {/* /.card-header */}
              <div className="card-body p-2">
                <div className='flous-bg-thirty m-2' style={{ borderRadius: '15px' }}>
                    <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                        <div className='row text-bold'>
                            <div className='col-sm-3 col-6 mt-2'>Country</div>
                            <div className='col-sm-3 col-6 mt-2 text-center'>Date</div>
                            <div className='col-sm-3 col-12 mt-2 text-center'>Title</div>
                            <div className='col-sm-3 col-12 mt-2 text-center'>Actions</div>
                        </div>
                    </div>
                    {currentItems.length > 0 ? (
                      currentItems.map(item => (
                        <UserResultItem key={item.code} item={item} deleteEvaluation={handleDeleteEvaluation} />
                      ))
                    ) : (
                      <NoItemsMessage />
                    )}
                </div>
              </div>
              {/* /.card-body */}
              <PaginationSection
                page={page}
                pageSize={pageSize}
                totalItems={evaluations.length}
                onPageChange={onPageChange}
              />
            </div>
            {/* /.card */}
          </div>
        </div>
      </div>

    </div>
  );
};