import React, { useState, useEffect } from 'react';
import api from '../../../../../services/api';
import CountryShow from '../../../../general/countryShow';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function CurrentResidenceResumeSection(props) {
  // Define the current residence information
  const country = props.currentResidence.country; //  
  const provinceState = props.currentResidence.provinceState; //  
  const city = props.currentResidence.city; //  
  const streetNumber = props.currentResidence.streetNumber; //  
  const postalCode = props.currentResidence.postalCode; //  

  const [provinces, setProvinces] = useState([]);
  const countryName = CountryShow({ value: country });

  useEffect(() => {
      const fetchProvinces = async() => {
        if (country === "CAN") {
          const response = await api.get('/canada-provinces/');
          const provinceOptions = Object.entries(response.data).map(([key, val]) => ({
            value: key,
            label: val,
          }));
          setProvinces(provinceOptions);
        }
      };
  
      fetchProvinces();
  }, []);

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Current Residence</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputCountry" className="col-sm-3 col-form-label">
          {EnglishQuestion.residences.country}
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputCountry" value={countryName} />
          </div>
          <label htmlFor="inputProvinceState" className="col-sm-3 col-form-label">
          {EnglishQuestion.residences.provinceState}
          </label>
          <div className="col-sm-3">
            {country === 'CAN' ? (
              <input 
                type="text" 
                className="form-control" 
                id="inputProvinceState" 
                value={provinces.find(province => province.value === provinceState)?.label || ''} 
              />
            ) : (
              <input 
                type="text" 
                className="form-control" 
                id="inputProvinceState" 
                value={provinceState || ''} 
              />
            )}
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputCity" className="col-sm-3 col-form-label">
          {EnglishQuestion.residences.city}
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputCity" value={city} />
          </div>
          <label htmlFor="inputStreetNumber" className="col-sm-3 col-form-label">
          {EnglishQuestion.residences.streetNumber}
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputStreetNumber" value={streetNumber} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputPostalCode" className="col-sm-3 col-form-label">
          {EnglishQuestion.residences.postalCode}
          </label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputPostalCode" value={postalCode} />
          </div>
        </div>
      </div>
    </div>
  );
}
