import React from 'react';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function TakeoverBidResumeSection(props) {
  // Define the takeover bid information
  const takeoverBidInfo = {
    hasMadeBid: props.takeoverBid //  
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Takeover Bid</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputHasMadeBid" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.takeoverBid}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputHasMadeBid" value={takeoverBidInfo.hasMadeBid} />
          </div>
        </div>
      </div>
    </div>
  );
}
