import React from 'react';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function JobDetailResumeSection(props) {
  // Define the job offer information
  const jobDetail = {
      dateOfIssued: props.jobDetail.dateOfIssued, //  
      isCurrentJob: props.jobDetail.currentJob, //  
      lmiaSupported: props.jobDetail.lmiaExempt, //  
      validatedByProvince: props.jobDetail.provinceValidated, //  
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Job Detail</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputDateOfIssued" className="col-sm-3 col-form-label">{EnglishQuestion.jobOffer.dateOfIssued}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputDateOfIssued" value={jobDetail.dateOfIssued} />
          </div>
          <label htmlFor="inputIsCurrentJob" className="col-sm-3 col-form-label">{EnglishQuestion.jobOffer.currentJob}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputIsCurrentJob" value={jobDetail.isCurrentJob} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputLmiaSupported" className="col-sm-3 col-form-label">{EnglishQuestion.jobOffer.lmiaExempt}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputLmiaSupported" value={jobDetail.lmiaSupported} />
          </div>
          <label htmlFor="inputValidatedByMifi" className="col-sm-3 col-form-label">{EnglishQuestion.jobOffer.provinceValidated}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputValidatedByMifi" value={jobDetail.validatedByProvince} />
          </div>
        </div>
      </div>
    </div>
  );
}