import React, { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { DeleteForm, DuplicateFormFromCustomer, mapStateToDescription } from '../../../services/users/FormsServices';
import FlagComponent from '../../general/FlagComponent';
import LoadingOverlay from '../../general/LoadingOverlay';
import { toast } from 'react-toastify';

const UserFormItem = ({ item, editForm, evaluateForm}) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [newform, setNewForm] = useState('edit');
    const pageType = "form"

    const stateList = ['CP']
    const { user } = useOutletContext();

    const handleDuplicateForm = async (code) =>{
      setLoading(true);
      try{
            const response = await DuplicateFormFromCustomer(code);
  
            if (response && [200, 201].includes(response.status)){
              setLoading(false);
              window.location.reload();
            }
        }  catch (error){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
            }
      }
    }


    const handleDeleteForm = async (code) =>{
      setLoading(true);
      try{
            const response = await DeleteForm(code);
            setLoading(false);
            window.location.reload();
        }  catch (error){
            setLoading(false);
            throw error;
      }
    }

    if (loading) {
        return <LoadingOverlay />;
    }

    if (error) {
        return <div>Error : {error.message}</div>;
    }

    return (
        <div className='card p-3 mb-3 mx-1' style={{ borderRadius: '15px' }}>
            <div className='row'>
                <div className='col-sm col-6 mt-2 text-center'>
                    <FlagComponent cca3={item.country_cca3} />
                </div>
                <div className='col-sm col-6 mt-2 text-center'>{item.creation_date}</div>
                <div className='col-sm col-12 mt-2 text-center'>{item.title}</div>
                <div className='col-sm col-12 mt-2 text-center'>
                    <div
                        className={`btn ${
                        item.state === 'CR'
                            ? 'flous-bg-thirty'
                            : item.state === 'IP'
                            ? 'flous-bg-secondary'
                            : item.state === 'AB'
                            ? 'btn-secondary'
                            : item.state === 'CP'
                            ? 'flous-bg-menu'
                            : 'btn-secondary'
                        }`}
                    >
                        {mapStateToDescription(item.state)}
                    </div>
                </div>
                <div className='col-sm-3 col-12 mt-2 text-center'>
                    {(user.role === 'Customer' && !user.self_analyst)? (
                        <>
                            <a href={`/client/form/${newform}/${item.code}`}>
                                <span className="btn flous-bg-secondary mr-2">
                                    <i className="nav-icon fas fa-edit"></i>
                                </span>
                            </a>
                            <a href={`/client/detail/${pageType}/${item.code}`} className="btn flous-bg-menu mr-2">
                                <i className="nav-icon fas fa-eye"></i>
                            </a>
                        </>
                    ):(
                        <>
                            <a href={`/user/detail/${pageType}/${item.code}`} className="btn flous-bg-menu mr-2"><i className="nav-icon fas fa-eye"></i></a>
                            {stateList.includes(item.state) ? (
                            <span onClick={() => evaluateForm(item.code)} className="btn flous-bg-primary mr-2" data-toggle="modal" data-target="#evaluateModal">
                                <span >Evaluate</span>
                            </span>
                            ) : (
                                <span className="btn flous-bg-primary mr-2 disabled" aria-disabled="true">Evaluate</span>
                            )}
                            <a href={`/user/form/${newform}/${item.code}`} onClick={() => editForm(item.code)} data-toggle="modal" data-target="#updateModal">
                                <span className="btn flous-bg-secondary mr-2">
                                    <i className="nav-icon fas fa-edit"></i>
                                </span>
                            </a>
                            <span onClick={() => handleDuplicateForm(item.code)}>
                                <span className="btn btn-secondary mr-1">
                                    <i className="nav-icon fas fa-copy"></i>
                                </span>
                            </span>
                            <span onClick={() => handleDeleteForm(item.code)}>
                                <span className="btn btn-secondary mr-2">
                                    <i className="nav-icon fas fa-trash"></i>
                                </span>
                            </span>
                        </>
                    )} 
                </div>
            </div>
        </div>
    );
};

export default UserFormItem;
