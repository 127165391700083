import React from 'react';
import AgentCustomers from '../agent/AgentCustomers';

export default function EntrepriseAdvisorsCustomers () {

  return (
    <>
      <AgentCustomers/>
    </>
  );
};