import React from 'react';

const ChooseActorPage = () => {
  return (
    <div className='relative bg-[linear-gradient(270deg,#7FA1F8_0%,#FDFEFE_88%)] min-h-screen overflow-hidden'>
      {/* Background image */}
      <div className="absolute inset-0">
        <img 
          src={`${process.env.PUBLIC_URL}/flous/bg/background-test.svg`} 
          alt="" 
          className="w-full h-full object-cover opacity-20"
        />
      </div>

      {/* Content */}
      <div className='relative z-10 p-2 min-h-screen'>
        {/* Logo */}
        <div className="flex justify-start ml-5 mt-3">
          <a href="/">
            <img
              src={`${process.env.PUBLIC_URL}/flous/logos/trans_1.svg`}
              alt="Flous Logo"
              className="brand-image"
              style={{ opacity: 0.8, height: '150px', width: 'auto' }}
            />
          </a>
        </div>

        {/* Main content */}
        <div className="flex justify-center items-start min-h-screen">
          <div className="col-lg-8">
            <div className="text-center mb-4">
              <h3 className="flous-police-one">
                Before we get started,<br />please choose your profile
              </h3>
            </div>
            <div className="card-body text-center">
              <div className="row">
                {/* Profile options */}
                <div className="col-sm col-12">
                  <a href="/createaccount/self">
                    <div className="card card-body mx-3" style={{ borderRadius: '15px' }}>
                      <label>Self</label>
                      <img alt="" src={`${process.env.PUBLIC_URL}/flous/svg/profile_3.svg`} />
                      <p>I am an individual seeking immigration assistance</p>
                    </div>
                  </a>
                </div>

                <div className="col-sm col-12">
                  <a href="/createaccount/pro">
                    <div className="card card-body mx-3" style={{ borderRadius: '15px' }}>
                      <label>Professional</label>
                      <img alt="" src={`${process.env.PUBLIC_URL}/flous/svg/profile_2.svg`} />
                      <p>I am a single professional providing immigration services</p>
                    </div>
                  </a>
                </div>

                <div className="col-sm col-12">
                  <a href="/createaccount/enterprise">
                    <div className="card card-body mx-3" style={{ borderRadius: '15px' }}>
                      <label>Enterprise</label>
                      <img alt="" src={`${process.env.PUBLIC_URL}/flous/svg/profile_1.svg`} />
                      <p>We are a company offering immigration services</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseActorPage;
