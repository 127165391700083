import React from 'react';

const EvaluateModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal fade" id="evaluateModal" tabindex="-1" aria-labelledby="evaluateModalLabel" aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content" style={{ borderRadius: '15px' }}>
                <div className="modal-header">
                    <h5 className="modal-title" id="evaluateModalLabel">Purchase to begin your Journey</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body text-center">
                    <div className='d-flex justify-content-center'>
                        <div className='col-10'>
                            <input 
                                type="text" 
                                className="form-control text-bold text-black text-center" 
                                id="" 
                                name="" 
                                value={"Price of New Form 15 $"}
                                placeholder="Price of New Form 15 $" disabled
                            />
                        </div>
                    </div>
                    <div className=''>Payment Information</div>
                    <hr/>
                    <div className='row p-2'>
                        <p htmlFor="cardnumber" className="col-sm-4 col-form-label text-bold">Card Number: </p>
                        <div className="col-sm-8">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="cardnumber" 
                            name="cardnumber" 
                            placeholder="**** **** **** 2938"
                        />
                        </div>
                    </div>
                    <div className='row p-2'>
                        <p htmlFor="expirydate" className="col-sm-4 col-form-label text-bold">Expiry Date: </p>
                        <div className="col-sm-8">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="expirydate" 
                            name="expirydate" 
                            placeholder="12/24"
                        />
                        </div>
                    </div>
                    <div className='row p-2'>
                        <p htmlFor="securitycode" className="col-sm-4 col-form-label text-bold">Security Code: </p>
                        <div className="col-sm-8">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="securitycode" 
                            name="securitycode" 
                            placeholder="***"
                        />
                        </div>
                    </div>
                    <div className='row p-2'>
                        <p htmlFor="cardnumber" className="col-sm-4 col-form-label text-bold">Name on Card: </p>
                        <div className="col-sm-8">
                        <input 
                            type="text" 
                            className="form-control" 
                            id="cardnumber" 
                            name="cardnumber" 
                            placeholder="Juanito Perez"
                        />
                        </div>
                    </div>
                    <div className='text-bold m-3'>Confirm purchase of new Form for 15$ ?</div>
                    <button type="button" className="btn btn-lg text-bold flous-bg-menu rounded-pill px-5" data-dismiss="modal" onClick={onConfirm}>Confirm Purchase</button>
                </div>
                <div className="modal-footer d-flex justify-content-center">
                    <button type="button" className="btn btn-lg flous-input-color flous-bg-thirty rounded-pill px-5" data-dismiss="modal" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    </div>
  );
};

export default EvaluateModal;
