import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom'; // Pour créer des liens internes
import formData from './formsData';
import UserFormItem from './userFormItem';
import { fetchUserFormsData, getCurrentItems } from '../../../services/users/FormsServices';
import NewFormModal from '../Modals/newFormModal';
import UpdateCreditModal from '../Modals/updateCreditModal';
import EvaluateModal from '../Modals/evaluateModal';
import PaginationSection from '../../Layout/PaginationSection';
import CountrySelect from '../../general/CountrySelect';
import { evaluateForm } from '../../../services/users/ResultsServices';
import LoadingOverlay from '../../general/LoadingOverlay';
import NoItemsMessage from '../../general/NoItemsToDisplaysItem';
import GoToBackButton from '../../general/GoBackLink';
import Select from 'react-select';

export default function UserForms () {
  const [forms, setForms] = useState(formData.forms);
  const [sorted, setSorted] = useState({ column: null, direction: null });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { user } = useOutletContext();
  const navigate = useNavigate();
  const [newform, setNewForm] = useState('new');
  const [rawSurveyCode, setRawSurveyCode] = useState('noCode');
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isNewFormModalOpen, setIsNewFormModalOpen] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const formsStates = [
    {abbr: 'AB', name: 'ABANDONED'},
    {abbr: 'CR', name: 'CREATED'},
    {abbr: 'IP', name: 'IN PROGRESS'},
    {abbr: 'CP', name: 'COMPLETETD'},
    {abbr: 'EV', name: 'EVALUATED'},
  ]

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };
  
  const currentItems = getCurrentItems(forms, page, pageSize);

 

  const handleCreateNewForm = () => {
    setNewForm('new');
    setRawSurveyCode('new');
    setIsNewFormModalOpen(true);
    // navigate(`/user/form/${newform}/${rawSurveyCode}`)
  }

  const handleNewFormModalClose = () => {
    setIsNewFormModalOpen(false);
  };

  const handleNewFormModalConfirm = () => {
    setIsNewFormModalOpen(false);
    navigate(`/user/form/${newform}/${rawSurveyCode}`);
  };

  
  const handleEditForm = (code) =>{
    // e.prventDefault();
    setRawSurveyCode(code);
    setNewForm('edit');
    setIsUpdateModalOpen(true);
  };

  const handleUpdateModalConfirm = () => {
    setIsUpdateModalOpen(false);
    navigate(`/user/form/${newform}/${rawSurveyCode}`);
  };

  const handleUpdateModalClose = () => {
    setIsUpdateModalOpen(false);
  };

  
  const handleEvaluateForm = (code) =>{
    // e.prventDefault();
    setRawSurveyCode(code);
    setIsPaymentModalOpen(true);
  };


  const handlePaymentModalClose = () => {
    setIsPaymentModalOpen(false);
  };

  const handlePaymentModalConfirm = async() => {
    setLoading(true);
    try{
      setIsPaymentModalOpen(false);
      const response = await evaluateForm(rawSurveyCode);
      await new Promise((resolve) => setTimeout(resolve, 5000));
      setLoading(false);
      navigate(`/user/results`, {replace: true});
      window.location.reload();
    }  catch (error){
      setLoading(false);
      setIsPaymentModalOpen(false);
      throw error;
  }
  };

  const handleChangeCountry = (e) => {
    setSelectedCountry(e.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const queryParams = new URLSearchParams({
      country: selectedCountry || '',
      status: selectedStatus || ''
    }).toString();
    filterItems();
    //console.log(queryParams);
  };

  const filterItems = async () => {
    let isMounted = true;
    if (user.id) {
        try {
          const data = await fetchUserFormsData();
          if (isMounted) {
            if (!selectedCountry && !selectedStatus) {
              setForms(data);
              setLoading(false);
              return;
            }
            const filteredItems = data.filter((item) => {
              const matchesCountry = selectedCountry ? item.country_cca3 === selectedCountry : true;
              const matchesStatus = selectedStatus ? item.state === selectedStatus : true;
              return matchesCountry && matchesStatus;
            });
            setForms(filteredItems);
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setError(error);
            setLoading(false);
            throw error;
          }
        }
      }
  };

  useEffect( () => {
    let isMounted = true;

    const getUserFormsData = async () => {
      if (user.id) {
        try {
          const data = await fetchUserFormsData();
          if (isMounted) {
            setForms(data);
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setError(error);
            setLoading(false);
            throw error;
          }
        }
      }
    };

    getUserFormsData();

    return () => {
      isMounted = false;
    };

  }, []);

  const sortData = (column) => {
    const direction = sorted.column === column && sorted.direction === 'asc' ? 'desc' : 'asc';
    const sortedData = [...forms].sort((a, b) => {
      if (a[column] < b[column]) return direction === 'asc' ? -1 : 1;
      if (a[column] > b[column]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    setForms(sortedData);
    setSorted({ column, direction });
  };

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }


  return (
    <div className="content">
      <div className="container-fluid">
        {/* Content Header (Page header) */}
        <div className="content-header">
          <div className="container-fluid">
            <div className="mb-2">
              <GoToBackButton />
              <div className="col-sm-6">
                <h1 className="flous-police-one flous-input-color m-0">My Forms</h1>
              </div>
            </div>
          </div>
        </div>
        {/* /.content-header */}

        <div className="row">
          <div className="col-12">
            {(user.role === 'Customer' && user.self_analyst)? (
              <>
                <div className="row">
                  <a href={`/user/form/${newform}/${rawSurveyCode}`} onClick={handleCreateNewForm} data-toggle="modal" data-target="#newFormModal"
                  className="btn btn-lg flous-bg-primary mx-2 col-lg-6 text-bold">Create a new form <i className="nav-icon fas fa-edit"></i></a>
                </div>
              </>
            ):(
              <>
              </>
            )} 
            <br />
            <div className="card elevation-2" style={{ borderRadius: '15px' }}>
              <div className="card-header shadow">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm col-12 mb-2">
                    <CountrySelect
                      required
                      onChange={handleChangeCountry}
                    />
                  </div>
                  <div className="col-sm col-12">
                    <div className="form-group">
                      <Select
                        name="status"
                        classNamePrefix="react-select"
                        value={formsStates.find(state => state.value === selectedStatus)}
                        onChange={(selectedOption) =>
                          setSelectedStatus(selectedOption ? selectedOption.value : '')
                        }
                        options={formsStates.map(state => ({
                          value: state.abbr,
                          label: state.name
                        }))}
                        placeholder="Select form status"
                        isSearchable={false}
                        isClearable
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                            borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                          }),
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-sm-2 col-6">
                    <button type="submit" className="btn flous-input-color">
                      <i className="nav-icon fas fa-search"></i> Search
                    </button>
                  </div>
                </div>
              </form>
              </div>
              {/* /.card-header */}
              <div className="card-body p-2">
                <div className='flous-bg-thirty m-2' style={{ borderRadius: '15px' }}>
                    <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                        <div className='row text-bold'>
                            <div className='col-sm col-6 mt-2'>Country</div>
                            <div className='col-sm col-6 mt-2 text-center'>Date</div>
                            <div className='col-sm col-12 mt-2 text-center'>Title</div>
                            <div className='col-sm col-12 mt-2 text-center'>Status</div>
                            <div className='col-sm-3 col-12 mt-2 text-center'>Actions</div>
                        </div>
                    </div>
                    {currentItems.length > 0 ? (
                      currentItems.map(item => (
                        <UserFormItem key={item.code} item={item} 
                        editForm={handleEditForm} 
                        evaluateForm={handleEvaluateForm}
                        />
                    ))) : (
                      <NoItemsMessage />
                    )}
                </div>
              </div>
              {/* /.card-body */}
              <PaginationSection
                page={page}
                pageSize={pageSize}
                totalItems={forms.length}
                onPageChange={onPageChange}
              />
            </div>
            {/* /.card */}
          </div>
        </div>
      </div>

      {/* Modal */}
        <EvaluateModal
          isOpen={isPaymentModalOpen} 
          onClose={handlePaymentModalClose} 
          onConfirm={handlePaymentModalConfirm} 
        />
        
        <UpdateCreditModal 
          isOpen={isUpdateModalOpen} 
          onClose={handleUpdateModalClose} 
          onConfirm={handleUpdateModalConfirm} 
        />

        <NewFormModal
          isOpen={isNewFormModalOpen} 
          onClose={handleNewFormModalClose} 
          onConfirm={handleNewFormModalConfirm} 
        />
    </div>
  );
};