import React, {useState, useEffect} from 'react';
import { GetAdvisorCompanyDetail } from '../../services/agents/AccountDetails'

export default function AgentCompanyInfoSection({user}) {

    const [data, setData] = useState({})
    const [error, setError] = useState('')

    useEffect(()=>{
        let isMounted = true;
        const getCompanyData = async () =>{
            try {
                const response = await GetAdvisorCompanyDetail(user.code);
                if (isMounted){
                    if (response && response.status === 200){
                        setData(response.data.data);
                        isMounted = false;
                    }
                }
            } catch (error){
                
                if (isMounted){
                    if (error.response && [400, 500].includes(error.response.status)){
                        setError(error.response.data.error);
                        isMounted = false;
                    }
                }
            }
        }

        getCompanyData();

        return () => {
          isMounted = false;
        };

    }, [user]);

    return <div id='Company Info'>
        <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
            <div className='text-center text-bold'>Company Information</div>
            <div className='text-center' style={{color:'red'}}>{error}</div>
            <hr/>
            <div className='mx-3'>
                <dl className="row">
                    <dt className="col-sm-5">Company Name</dt>
                    <dd className="col-sm-7">{data.companyName? data.companyName: 'Undefined'} </dd>
                    <hr/>
                    <dt className="col-sm-5">Register Number</dt>
                    <dd className="col-sm-7">{data.registerNumber? data.registerNumber: 'Undefined'} </dd>
                    <hr/>
                    <dt className="col-sm-5">Immigration Consultant Licence Number</dt>
                    <dd className="col-sm-7">{data.consultantLicence? data.consultantLicence: 'Undefined'} </dd>
                    <hr/>
                    <dt className="col-sm-5">Lawyer Licence Number</dt>
                    <dd className="col-sm-7">{data.LawyerLicence? data.LawyerLicence: 'Undefined'} </dd>
                    <hr/>
                    <dt className="col-sm-5">Administrator Email</dt>
                    <dd className="col-sm-7">{data.administratorEmail? data.administratorEmail: 'Undefined'} </dd>
                    <hr/>
                    <dt className="col-sm-5">Phone</dt>
                    <dd className="col-sm-7">{data.administratorPhone? data.administratorPhone: 'Undefined'} </dd>
                    <hr/>
                </dl>
            </div>
        </div>
    </div>;
}