import React, { useState } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';

import EnglishQuestion from "../../questions/englishQuestions.json";

const AddInfoSection = ( props) => {
  const [addInfo, setAddInfo] = useState(props.addInfo);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...addInfo,
      [name]: value,
    }
    setAddInfo(updatedFormData);
    props.sendAddInfo(updatedFormData);
  };

  return (
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Additional Information</p>
          <div className=''>
            <p htmlFor="netWorth" className=''>{EnglishQuestion.personalInfo.netWorth}<RequiredIndicator /><i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltip1" data-tooltip-content="The total value of all your and your partner’s assets, including property, investments, and valuables, minus any debts or liabilities."></i></p>
            <Tooltip id='tooltip1' />
            <div className="mb-2">
              <input
                type="text"
                className="form-control rounded-pill flous-input-color"
                id="netWorth"
                name="netWorth"
                placeholder="$ (CAD)"
                value={addInfo.netWorth}
                onChange={handleChange}
              />
            </div>
            <p htmlFor="earningsHistory" className=''>
              {EnglishQuestion.personalInfo.earningsHistory}<RequiredIndicator /><i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltip2" data-tooltip-content="Total income earned in a year, including wages, salaries, and other earnings."></i>
            </p>
            <Tooltip id='tooltip2' />
            <div className="mb-2">
              <input
                type="number"
                className="form-control rounded-pill flous-input-color"
                id="earningsHistory"
                name="earningsHistory"
                placeholder="$ (CAD)"
                value={addInfo.earningsHistory}
                onChange={handleChange}
              />
            </div>
            <p htmlFor="totalCurrentAssets" className=''>
            {EnglishQuestion.personalInfo.totalCurrentAssets}<RequiredIndicator /><i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltip3" data-tooltip-content="The combined amount of cash and easily accessible funds, such as bank account balances or liquid investments, for you and your partner."></i>
            </p>
            <Tooltip id='tooltip3' />
            <div className="mb-2">
              <input
                type="number"
                className="form-control rounded-pill flous-input-color"
                id="totalCurrentAssets"
                name="totalCurrentAssets"
                placeholder="$ (CAD)"
                value={addInfo.totalCurrentAssets}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className='row p-2'>
            <p className='col-sm-10 col-form-label'>{EnglishQuestion.personalInfo.provincialNomination} {props.evaluationCountry}?<RequiredIndicator /><i className="nav-icon fas fa-info-circle"  data-tooltip-id="tooltip4" data-tooltip-content="A provincial nomination is a province's approval for your permanent residency application in Canada."></i></p>
            <Tooltip id='tooltip4' />
            <div className='col-sm-2'>
              <div className='row'>
                <div className="col form-check">
                  <input 
                    type="radio"
                    className="form-check-input"
                    id="provincialNominationYes"
                    name="provincialNomination"
                    value="Yes"
                    onChange={handleChange}
                    checked={addInfo.provincialNomination === "Yes"}
                  />
                  <label className="form-check-label" htmlFor="provincialNominationYes">Yes</label>
                </div>
                <div className="col form-check">
                  <input 
                    type="radio"
                    className="form-check-input"
                    id="provincialNominationNo"
                    name="provincialNomination"
                    value="No"
                    onChange={handleChange}
                    checked={addInfo.provincialNomination === "No"}
                  />
                  <label className="form-check-label" htmlFor="provincialNominationNo">No</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default AddInfoSection;