import React, { useState } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const ImmigrationDocumentSection = (props) => {
  const [formData, setFormData] = useState(props.immigrationDocument);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    props.sendImmigrationDocument(updatedFormData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
      <p className='text-bold text-center'>Immigration Document</p>
        <div className='row p-2'>
          <p htmlFor="dateOfIssue" className="col-sm-4 col-form-label">{EnglishQuestion.residences.dateOfIssue}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="date"
              className="form-control rounded-pill flous-input-color"
              id="dateOfIssue"
              name="dateOfIssue"
              value={formData.dateOfIssue}
              onChange={handleChange}
              placeholder="Date of Issue" max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="dateOfExpiration" className="col-sm-4 col-form-label">{EnglishQuestion.residences.dateOfExpiration}<RequiredIndicator /></p>
          <div className="col-sm-8">
            <input 
              type="date"
              className="form-control rounded-pill flous-input-color"
              id="dateOfExpiration"
              name="dateOfExpiration"
              value={formData.dateOfExpiration}
              onChange={handleChange}
              placeholder="Date of Expiration"
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="dateOfEntry" className="col-sm-4 col-form-label">{EnglishQuestion.residences.dateOfEntry} {props.countryE} </p>
          <div className="col-sm-8">
            <input 
              type="date"
              className="form-control rounded-pill flous-input-color"
              id="dateOfEntry"
              name="dateOfEntry"
              value={formData.dateOfEntry}
              onChange={handleChange}
              placeholder="Date of Entry in the country" max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImmigrationDocumentSection;