import React, { useState, useEffect } from 'react';
import api from '../../../../../../services/api';
import { sortDictionaryByValues } from '../../../../../../services/utils';
import Select from 'react-select';
import RequiredIndicator from '../../../../../general/requiredIndicator';

import EnglishQuestion from "../../../questions/englishQuestions.json";

const CompanyActivitesSection = (props) => {
  const [formData, setFormData] = useState(props.companyActivites);
  //const [businessSector, setBusinessSector] = useState(props.companyActivites);
  const [businessSectors, setBusinessSectors] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendCompanyActivities(updatedData);
  };

  useEffect(() => {
    const fetchBusinessSector = async () => {
        const response = await api.get('/canada/industries-sectors/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setBusinessSectors(options);
    };
    fetchBusinessSector();
}, []);

const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
        value: key,
        label: val,
    }));
};

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Company Activities</p>
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.businessSector}<RequiredIndicator /></p>
          <div className="col-sm-6">
          <Select
              id="businessSector"
              name="businessSector"
              className="react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={businessSectors.find((option) => option.value === formData.businessSector)}
              onChange={(selectedOption) =>
                  handleChange({
                      target: { name: 'businessSector', value: selectedOption ? selectedOption.value : '' },
                  })
              }
              options={businessSectors}
              placeholder="Please select an option"
              isSearchable
              isClearable
              styles={{
                  control: (provided) => ({
                      ...provided,
                      width: '100%',
                      borderRadius: '20px',
                      borderColor: '#ccc',
                  }),
              }}
              required
          />
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-10 col-form-label">{EnglishQuestion.entrepreunarialExperience.paydayLoans}<RequiredIndicator /></p>
          <div className="col-sm-2">
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="paydayLoans"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.paydayLoans === "Yes"}
                />
                <p className="form-check-label">Yes</p>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="paydayLoans"
                  value="No"
                  onChange={handleChange}
                  checked={formData.paydayLoans === "No"}
                />
                <p className="form-check-label">No</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-10 col-form-label">{EnglishQuestion.entrepreunarialExperience.explicitContent}<RequiredIndicator /></p>
          <div className="col-sm-2">
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="explicitContent"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.explicitContent === "Yes"}
                />
                <p className="form-check-label">Yes</p>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="explicitContent"
                  value="No"
                  onChange={handleChange}
                  checked={formData.explicitContent === "No"}
                />
                <p className="form-check-label">No</p>
              </div>
            </div>
          </div>
        </div>
        <div className='row p-2'>
          <p className="col-sm-10 col-form-label">{EnglishQuestion.entrepreunarialExperience.realEstate}<RequiredIndicator /></p>
          <div className="col-sm-2">
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="realEstate"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.realEstate === "Yes"}
                />
                <p className="form-check-label">Yes</p>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  name="realEstate"
                  value="No"
                  onChange={handleChange}
                  checked={formData.realEstate === "No"}
                />
                <p className="form-check-label">No</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyActivitesSection;