import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import { UpdatePasword, UpdateUserInformation } from '../../services/general/ProfileServices';
import { validatePassword } from '../auth/validation';

export default function SettingsSection() {

  const {logout} = useContext(AuthContext);

  const navigate = useNavigate();

  const {user} = useOutletContext();

  const [userInfo, setUserInfo] = useState({
    email: user.email,
    phone: user.cell_phone_number
  });

  const [passwordInfo, setPasswordInfo] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setconfirmPasswordError] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };  
  
  const handleUserChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }));
  };


  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordInfo((prevInfo) => ({
      ...prevInfo,
      [name]: value
    }));
  };

  const handleSaveUserInfo = async (e) => {
    e.preventDefault();
    if (!userInfo.email || !userInfo.phone) {
      toast.error("Please complete the form update your information !")
      return;
    } else {
      try {
        const response = await UpdateUserInformation(userInfo);
        if (response && response.status === 200) {
          await logout();
          navigate('/login', { state: { message: "Your personal information has been successfully updated, \nplease log in below." } })
        }
      } catch (error) {
        if (error.response && [400, 401, 500].includes(error.response.status)){
          toast.error(error.response.data.error);
        }
      }
    }
  };

  
  useEffect(() =>{
    if (passwordInfo.newPassword !== passwordInfo.confirmPassword){
        setconfirmPasswordError("The passwords are different !");
    }else {
        setconfirmPasswordError('');
    }

}, [passwordInfo]);

  const handleSavePassword = async (e) => {
    e.preventDefault();
    if (!passwordInfo.oldPassword || !passwordInfo.newPassword || !passwordInfo.confirmPassword) {
      toast.error("Please complete the form update your password !")
      return;
    } else {
      setPasswordError(validatePassword(passwordInfo.newPassword));
      if (validatePassword(passwordInfo.newPassword) !== '') {
        return;
      } else {
        try {
          const response = await UpdatePasword(passwordInfo);
          if (response && response.status === 200) {
            await logout();
            navigate('/login', { state: { message: "Your password has been successfully updated, \nplease log in below." } })
          }
        } catch (error) {
          if (error.response && [400, 500].includes(error.response.status)){
            console.log(error);
            setMessage(error.response.data.error);
            toast.error(error.response.data.error);
          }
        }
      }
    }
  };

  return (
    <div id='Settings'>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className='text-center text-bold'>Update my Information</div>
        <hr />
        <div className='mx-3'>
          <div className="form-group row">
            <label htmlFor="inputEmail" className="col-sm-4 col-form-label">Email Address :</label>
            <div className="col-sm-8">
              <input
                type="email"
                className="form-control rounded-pill"
                id="inputEmail"
                placeholder="Email"
                name="email"
                value={userInfo.email}
                onChange={handleUserChange}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputName2" className="col-sm-4 col-form-label">Phone Number :</label>
            <div className="col-sm-8">
              <input
                type="phone"
                className="form-control rounded-pill"
                id="inputName2"
                placeholder="Phone"
                name="phone"
                value={userInfo.phone}
                onChange={handleUserChange}
                required
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button type="button" className="col-4 btn flous-btn-gradient btn-block rounded-pill" onClick={handleSaveUserInfo}>
            Save changes
          </button>
        </div>
      </div>

      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className='text-center text-bold'>Change my Password</div>
        <hr />
        {message && <div className="text-center mb-2 mt-2" style={{ color: 'red' }}>{message}</div>}
        <div className='mx-3'>
          <div className="form-group row">
            <label htmlFor="oldpassword" className="col-sm-4 col-form-label">Old Password</label>
            <div className="col-sm-8">
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control rounded-pill"
                id="oldpassword"
                placeholder="Old password"
                name="oldPassword"
                value={passwordInfo.oldPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            {passwordError && <p className="error text-center mb-1 mt-1">{passwordError}</p>}
            <label htmlFor="newpassword" className="col-sm-4 col-form-label">New Password</label>
            <div className="col-sm-8">
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control rounded-pill"
                id="newpassword"
                placeholder="New password"
                name="newPassword"
                value={passwordInfo.newPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            {confirmPasswordError && <p className="error text-center mb-1 mt-1">{confirmPasswordError}</p>}
            <label htmlFor="confirmpassword" className="col-sm-4 col-form-label">Confirm new Password</label>
            <div className="col-sm-8">
              <input
                type={showPassword ? 'text' : 'password'}
                className="form-control rounded-pill"
                id="confirmpassword"
                placeholder="Confirm password"
                name="confirmPassword"
                value={passwordInfo.confirmPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-4 col-form-label">

            </div>
            <div className="col-sm-8">
              <div className="icheck-primary">
                <input type="checkbox" id="remember" name="remember" onClick={togglePasswordVisibility} />
                <label htmlFor="remember"> Show Password </label>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button type="button" className="col-4 btn flous-btn-gradient btn-block rounded-pill" onClick={handleSavePassword}>
            Save changes
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}