import React, {useEffect, useState} from 'react';
import { getCurrentItems } from '../../services/users/FormsServices';
import PaginationSection from '../Layout/PaginationSection';
import CreateNewCompanyModal from './modal/createNewCompanyAccount';
import FlousCompanyItem from './item/FlousCompanyItem';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import { toast } from 'react-toastify';
import { fetchCompaniesData, fetchFilteredCompanies } from '../../services/flous/ProfesionnalServices';
import LoadingOverlay from '../general/LoadingOverlay';
import GoToBackButton from '../general/GoBackLink';
import Select from 'react-select';

export default function FlousCompagniesList() {

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [options, setOptions] = useState({
    companies: [
      { value: 'N.A', label: 'Compagny name' },
    ],
    packages: [
      { value: 'N.A', label: 'Select Package' },
      { value: 'Professional', label: 'Professional' },
      { value: 'Enterprise', label: 'Enterprise' },
    ],
    statuses: [
      { value: 'N.A', label: 'Select Status' },
      { value: 'Active', label: 'Active' },
      { value: 'Inactive', label: 'Inactive' },
    ],
  });
  const [selectedCompany, setSelectedCompany] = useState('N.A');
  const [selectedPackage, setSelectedPackage] = useState('N.A');
  const [selectedStatus, setSelectedStatus] = useState('N.A');

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };

  useEffect( () => {
    let isMounted = true;

    const getCompaniesDataList = async () => {
        try {
          const response = await fetchCompaniesData();
          if (isMounted) {
            setCompanies(response.data.data);
            const updatedCompanies = response.data.data.map(company => ({
              value: company.code,
              label: company.name,
            }));
        
            setOptions(prevOptions => ({
              ...prevOptions,
              companies: [{ value: 'N.A', label: 'Compagnie name' }, ...updatedCompanies],
            }));

            setLoading(false);
          }
        } catch (error) {
          if (isMounted){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)) {
              setError(error);
              toast.error(error.response.data.error);
            }
          }
        }
    };

    getCompaniesDataList();

    return () => {
      isMounted = false;
    };

  }, []);

  const handleFilteredCompanies = async () => {
    try {
      const response = await fetchFilteredCompanies(
        selectedCompany, selectedPackage, selectedStatus);
      if (response && response.status===200) {
        setCompanies(response.data.data);
        setLoading(false);
      }
    }
    catch (error) {
      if (error.response && [400, 500].includes(error.response.status)){
        setError(error.response.data.error);
        toast.error(error.response.data.error);
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const queryParams = new URLSearchParams({
      company: selectedCompany || 'N.A',
      package: selectedPackage || 'N.A',
      status: selectedStatus || 'N.A'
    }).toString();
    filterItems();
    //console.log(queryParams);
  };

  const filterItems = async () => {
    let isMounted = true;
    try {
        const response = await fetchCompaniesData();
        if (isMounted) {
          if (selectedCompany === 'N.A' && selectedPackage=== 'N.A' && selectedStatus=== 'N.A') {
            setCompanies(response.data.data);
            setLoading(false);
            return;
          }
          const filteredItems = response.data.data.filter((item) => {
          const matchesCompany = selectedCompany !== 'N.A' ? item.code === selectedCompany : true;
          const matchesPackage = selectedPackage !== 'N.A' ? item.package === selectedPackage : true;
          const matchesStatus = selectedStatus !== 'N.A' ? item.status === selectedStatus : true;
          return matchesCompany && matchesPackage && matchesStatus;
          });
          setCompanies(filteredItems);
          setLoading(false);
        }
      } catch (error) {
        // console.error('Error when preparing data:', error);
        if (isMounted){
          setError(error);
          setLoading(false);
          throw error;
        }
      }
  };
  
  const currentItems = getCurrentItems(companies, page, pageSize);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }
  
  return (
    <div className="content">
      <div className="container-fluid">
        <div className="content-header">
          <div className="container-fluid">
            <div className="mb-2">
              <GoToBackButton />
              <div className="col-sm-6">
                <h1 className="m-0 flous-police-one flous-input-color">Professionnal Account</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="row">
                  <div className="btn btn-lg flous-bg-primary col-sm-6 col-12" data-toggle="modal" data-target="#newCompany">Add a new compagny account</div>
                  <CreateNewCompanyModal request={null}/>
                </div>
                <br />
                <div className="card elevation-2">
                  <div className="card-header">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-sm col-12">
                        <div className="form-group">
                          <Select
                            name="company"
                            classNamePrefix="react-select"
                            value={options.companies.find(option => option.value === selectedCompany)}
                            onChange={(selectedOption) => setSelectedCompany(selectedOption ? selectedOption.value : 'N.A')}
                            options={options.companies}
                            placeholder="Compagnie name"
                            isSearchable={true}
                            isClearable
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm col-12">
                        <div className="form-group">
                          <Select
                            name="package"
                            classNamePrefix="react-select"
                            value={options.packages.find(option => option.value === selectedPackage)}
                            onChange={(selectedOption) => setSelectedPackage(selectedOption ? selectedOption.value : 'N.A')}
                            options={options.packages}
                            placeholder="Select Package"
                            isSearchable={false}
                            isClearable
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm col-12">
                        <div className="form-group">
                          <Select
                            name="status"
                            classNamePrefix="react-select"
                            value={options.statuses.find(option => option.value === selectedStatus)}
                            onChange={(selectedOption) => setSelectedStatus(selectedOption ? selectedOption.value : 'N.A')}
                            options={options.statuses}
                            placeholder="Select Status"
                            isSearchable={false}
                            isClearable
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                width: '100%',
                                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <div className='col-sm-2 col-6'>
                        <button type="submit" className="btn flous-input-color">
                          <i className="nav-icon fas fa-search"></i> Search
                        </button>
                      </div>
                      <div className='col-sm-2 col-6 btn flous-input-color'>
                        <i className="nav-icon fas fa-sort"></i> Sort
                      </div>
                    </div>
                  </form>
                  </div>
                  <div className="card-body">
                    <div className='flous-bg-thirty' style={{ borderRadius: '15px' }}>
                        <div className='card-header p-2 mb-2 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                            <div className='row text-bold'>
                                <div className='col-sm col-12 mt-2 text-center'>Location</div>
                                <div className='col-sm col-6 mt-2 text-center'>Name</div>
                                <div className='col-sm col-6 mt-2 text-center'>Package</div>
                                <div className='col-sm col-6 mt-2 text-center'>Total seats</div>
                                <div className='col-sm col-6 mt-2 text-center'>Registred seats</div>
                                <div className='col-sm col-12 mt-2 text-center'>Status</div>
                                <div className='col-sm-4 col-12 mt-2 text-center'>Actions</div>
                            </div>
                        </div>
                        {currentItems.length > 0 ? (
                          currentItems.map((company) => (
                            <FlousCompanyItem key={company.code} company={company}/>
                          ))) : (
                            <NoItemsMessage />
                        )}
                    </div>
                    <PaginationSection
                      page={page}
                      pageSize={pageSize}
                      totalItems={companies.length}
                      onPageChange={onPageChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}