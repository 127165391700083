import React, { useState, useEffect } from 'react';
import { sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const DegreeDiplomaSection = (props) => {
  const [formData, setFormData] = useState(props.degreeDiploma);

  const [degreeDiplomas, setDegreeDiplomas] = useState([]);

  useEffect(() => {
    const fetchDiploma = async () =>{
        const response = await api.get('/educationlevel/');
        const sorted = sortDictionaryByValues(response.data);
        const options = Object.entries(sorted).map(([key, val]) => ({ value: key, label: val }));
        setDegreeDiplomas(options);
    };
    fetchDiploma();

  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updateData = {
      ...formData,
      [name]: value,
    }
    setFormData(updateData);
    props.sendDegreeDiploma(updateData);
  };


  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
      <p className='text-bold text-center'>Degree/Diploma</p>
        <div className='row p-2'>
          <p htmlFor="degreeDiploma" className="col-sm-6 col-form-label">{EnglishQuestion.educationInCountry.degreeDiploma}<RequiredIndicator /></p>
          <Select
              name="degreeDiploma"
              className="col react-select-container rounded-pill flous-input-color"
              classNamePrefix="react-select"
              value={degreeDiplomas.find(option => option.value === formData.degreeDiploma)}
              onChange={selectedOption => handleChange({ target: { name: 'degreeDiploma', value: selectedOption ? selectedOption.value : '' } })}
              options={degreeDiplomas}
              placeholder="Please select an option"
              isSearchable
              isClearable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
              required
            />
        </div>
        <div className='row p-2'>
          <p htmlFor="startDate" className="col-sm-6 col-form-label">{EnglishQuestion.educationInCountry.startDate}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="date" 
              name="startDate"
              className="form-control rounded-pill flous-input-color"
              onChange={handleChange}
              value={formData.startDate} max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        {props.isCurrent ==="No" && (
        <div className='row p-2'>
          <p htmlFor="endDate" className="col-sm-6 col-form-label">{EnglishQuestion.educationInCountry.endDate}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="date" 
              name="endDate"
              className="form-control rounded-pill flous-input-color"
              onChange={handleChange}
              value={formData.endDate} max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        )}
        <div className='row p-2'>
          <p htmlFor="pgwp" className="col-sm-10 col-form-label">{EnglishQuestion.educationInCountry.pgwpEligibility}<RequiredIndicator /></p>
          <div className='col-sm-2'>
            <div className=''>
              <div className="col form-check ml-5">
                <input 
                  type="radio"
                  id="pgwp-yes"
                  name="pgwpEligibility"
                  className="form-check-input" 
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.pgwpEligibility === "Yes"}
                />
                <label htmlFor="pgwp-yes" className="form-check-label">Yes</label>
              </div>
              <div className="col form-check ml-5">
                <input 
                  type="radio"
                  id="pgwp-no"
                  name="pgwpEligibility"
                  className="form-check-input" 
                  value="No"
                  onChange={handleChange}
                  checked={formData.pgwpEligibility === "No"}
                />
                <label htmlFor="pgwp-no" className="form-check-label">No</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DegreeDiplomaSection;