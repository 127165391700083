export const sortDictionaryByValues = (dict) => {
    return Object.fromEntries(
      Object.entries(dict).sort(([, a], [, b]) => a.localeCompare(b))
    );
};


export const countriesWithProvinces = ['CAN'];
export const provincesWithCities = ['QC'];

export const levelChoices = {
  "A1":"A1",
  "A2":"A2",
  "B1":"B1",
  "B2":"B2",
  "C1":"C1",
  "C2":"C2"
}

export const evaluationCountryConverter = (evaluationCountry) =>{

  const country_name_to_code = {
    'Canada': 'CAN',
    'New Zealand': 'NZW',
  }
  
  return country_name_to_code[evaluationCountry]
}

export const evaluationCountryConverterCodeToName = (code) =>{

  const country_name_to_code = {
    'CAN': 'Canada',
    'NZL': 'New Zealand',
  }
  
  return country_name_to_code[code]
}

export const canadaStatesConverter = (stateCode) =>{

  const state_code_to_name = {
    'QC': 'Québec',
    'ON': 'Ontario',
    'FD': 'Fédéral',
  }
  
  return state_code_to_name[stateCode]
}

export const languageConverter = (languageCode) => {
  const language_code_to_name = {
    'FR': 'French',
    'EN': 'English',
    'ES': 'Spanish',
    // Ajoutez d'autres codes de langue et noms selon vos besoins
  };
  
  return language_code_to_name[languageCode];
};

export const MessageStatesConverter = (state) => {
  const statesConverter = {
    'PD': 'PENDING',
    'DN': 'DONE',
  };
  
  return statesConverter[state];
};

export const MessageTypesConverter = (type) => {
  const typesConverter = {
    'URG': 'URGENT',
    'TEC': 'TECHNICAL',
    'COM': 'COMMENT/SUGGESTION',
  };
  
  return typesConverter[type];
};

export const FirmTypesConverter = (type) => {
  const typesConverter = {
    'LF': 'Law Firm',
    'CF': 'Consultant Firm',
    'LCF': 'Law & Consultant Firm',
  };
  
  return typesConverter[type];
};

export const OccupationConverter = (occupation) => {
  const occupationConverter = {
    'IML': 'Immigration Lawyer',
    'IMC': 'Immigration Consultant',
    'NAT': 'Not A Request',
  };
  
  return occupationConverter[occupation];
};

export const truncateMessage = (str, limit=15) => {
  return str.length > limit ? str.substring(0, limit) + '...' : str;
};

export const createShortName = (firstName, lastName) => {
  // Helper function to get letters from the name or duplicate if not enough
  const getLetters = (name, requiredLength) => {
    let result = name.substring(0, requiredLength);
    while (result.length < requiredLength) {
      result += name.charAt(result.length % name.length);
    }
    return result;
  };

  // Try to get 3 letters from firstName, if not enough, take from lastName
  let shortFirstName = getLetters(firstName, 3);
  if (shortFirstName.length < 3) {
    shortFirstName += getLetters(lastName, 3 - shortFirstName.length);
  }

  // Try to get 2 letters from lastName, if not enough, take from firstName
  let shortLastName = getLetters(lastName, 2);
  if (shortLastName.length < 2) {
    shortLastName += getLetters(firstName, 2 - shortLastName.length);
  }

  return shortFirstName + shortLastName;
}