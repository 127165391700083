import React, { useState, useEffect } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const SpecialProgramSection = (props) => {
  const [formData, setFormData] = useState(props.specialProgram);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updateData = {
      ...formData,
      [name]: value,
    }
    setFormData(updateData);
    props.sendSpecialProgram(updateData);
  };

  const handleAssistanceProgramChange = (event) =>{
    const value = event.target.value;
    // const updateData = {}
    if (value === 'Yes'){
      const updateData = {
        homeCareProgram: value,
        proposeProgram: 'Yes',
      }
      setFormData(updateData);
      props.sendSpecialProgram(updateData);
    } else {
      const updateData = {
        homeCareProgram: value,
        proposeProgram: '',
      }
      setFormData(updateData);
      props.sendSpecialProgram(updateData);
    }
  }

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
      <p className='text-bold text-center'>Special Program</p>
        <div className='row p-2'>
          <p htmlFor="homeCareProgram" className="col-form-label">
          {EnglishQuestion.educationInCountry.homeCareProgram}<RequiredIndicator />
          </p>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input" 
              name="homeCareProgram"
              value="Yes"
              onChange={handleAssistanceProgramChange}
              checked={formData.homeCareProgram === 'Yes'}
            />
            <p htmlFor="homeCareProgramYes" className="form-check-p">Yes</p>
          </div>
          <div className="form-check ml-5">
            <input 
              type="radio"
              className="form-check-input" 
              name="homeCareProgram"
              value="No"
              onChange={handleAssistanceProgramChange}
              checked={formData.homeCareProgram === 'No'}
            />
            <p htmlFor="homeCareProgramNo" className="form-check-p">No</p>
          </div>
        </div>
        
        {formData.homeCareProgram === 'No' && (
          <div>
            <div className='row p-2'>
              <p htmlFor="proposeProgram" className="col-form-label">
              {EnglishQuestion.educationInCountry.proposeProgram}<RequiredIndicator />
              </p>
              <div className="form-check ml-5">
                <input 
                  type="radio"
                  className="form-check-input" 
                  name="proposeProgram"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.proposeProgram === 'Yes'}
                />
                <p htmlFor="proposeProgramYes" className="form-check-p">Yes</p>
              </div>
              <div className="form-check ml-5">
                <input 
                  type="radio"
                  className="form-check-input" 
                  name="proposeProgram"
                  value="No"
                  onChange={handleChange}
                  checked={formData.proposeProgram === 'No'}
                />
                <p htmlFor="proposeProgramNo" className="form-check-p">No</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpecialProgramSection;
