import React from 'react';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function RoleAndExperienceResumeSection(props) {
  // Define the role and experience information
  const roleExperience = {
    position: props.roleAndExperience.position, // Replace with actual position
    startDate: props.roleAndExperience.startDate, // Replace with actual start date
    minOperatingPeriod: props.roleAndExperience.operatingPeriod // Replace with actual minimum operating period
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Role and Experience</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputPosition" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.position}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputPosition" value={roleExperience.position} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputStartDate" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.startDate}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputStartDate" value={roleExperience.startDate} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputMinOperatingPeriod" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.operatingPeriod}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputMinOperatingPeriod" value={roleExperience.minOperatingPeriod} />
          </div>
        </div>
      </div>
    </div>
  );
}
