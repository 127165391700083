import api from '../api';

let isLoading = false;

export const fetchCompaniesData = async () => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-all-companies`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const fetchFilteredCompanies = async (code, selectedPackage, status) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-filtered-companies/${code}/${selectedPackage}/${status}`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const getCompanyData = async (company) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-company/${company}`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const createNewCompany = async (data) => {
    try{
        const response = await api.post('/flous/create-new-company/', data);
        return response;

    } catch (error){
        console.error('Error when creating company:', error);
        throw error;
    }   
};

export const activateCompany = async (company) => {

    try{
        const response = await api.get(`/flous/activate-company/${company}`);
        return response;

    } catch (error){
        console.error('Error when activate company:', error);
        throw error;
    }   
};

export const deactivateCompany = async (company) => {

    try{
        const response = await api.get(`/flous/deactivate-company/${company}`);
        return response;

    } catch (error){
        console.error('Error when deactivate company:', error);
        throw error;
    }   
};

export const deleteCompany = async (company) => {

    try{
        const response = await api.get(`/flous/delete-company/${company}`);
        return response;

    } catch (error){
        console.error('Error when deactivate company:', error);
        throw error;
    }   
};

/////////////////////////////////////////////////////////////////////
// Create new demo account
export const createDemoFromDemoRequestAccount = async (data) => {
    try{
        const response = await api.post('/flous/create-demo-account-from-demo-request/', data);
        return response;

    } catch (error){
        // console.error('Error when creating company:', error);
        throw error;
    }   
};


export const createNewCompanyAccount = async (data) => {
    try{
        const response = await api.post('/flous/create-company-account/', data);
        return response;

    } catch (error){
        throw error;
    }   
};


export const flousCustomersEvolution = async () => {

    try{
        const response = await api.get(`/flous/customers-evoluation`);
        return response;

    } catch (error){
        console.error('Error when getting enterprise evoluation :', error);
        throw error;
    }   
};
