import React, { useState, useEffect } from 'react';
import CountrySelect from '../../../../../general/CountrySelect';
import ProvinceSelect from '../../../../../general/ProvinceSelect';
import { countriesWithProvinces, provincesWithCities } from '../../../../../../services/utils';
import CitySelect from '../../../../../general/CitySelect';
import RequiredIndicator from '../../../../../general/requiredIndicator';

import EnglishQuestion from "../../../questions/englishQuestions.json";

const CompanyDetailsYesSection = (props) => {
  const [formData, setFormData] = useState(props.companyDetails);

  useEffect(() => {
    const updatedFormData = {
      ...formData,
      country: 'CAN',
    };
    setFormData(updatedFormData);
    props.sendCompanyDetails(updatedFormData);
  }, [props.ownership]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
    props.sendCompanyDetails(updatedFormData);
  };

  const handleCountryChange = (value) =>{
    const updatedFormData = {
      ...formData,
      country: value,
    };
    setFormData(updatedFormData);
    props.sendCompanyDetails(updatedFormData);
  };

  const handleProvinceStateChange = (e) =>{
    const { value } = e.target;
    const updatedFormData = {
      ...formData,
      provinceState: value,
    };
    setFormData(updatedFormData);
    props.sendCompanyDetails(updatedFormData);
  };

  const handleCityChange = (e) =>{
    const { value } = e.target;
    const updatedFormData = {
      ...formData,
      city: value,
    };
    setFormData(updatedFormData);
    props.sendCompanyDetails(updatedFormData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Company Details</p>
        <div className='row p-2'>
          <p className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.establishmentDate}<RequiredIndicator /></p>
          <div className="col-sm-6">
            <input 
              type="date" 
              className="form-control rounded-pill flous-input-color"
              name="establishmentDate"
              value={formData.establishmentDate}
              onChange={handleChange} max={new Date().toISOString().split("T")[0]}
            />
          </div>
        </div>
        <p className='p-2'>What is/will be the address of the company?</p>
        {props.ownership && (<div className='row p-2'>
          <p htmlFor="country" className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.country}</p>
          <div className="col-sm-6">
            <CountrySelect value={formData.country} onChange={handleCountryChange} required />
          </div>
        </div>)}
        <div className='row p-2'>
          <p htmlFor="provinceState" className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.provinceState}</p>
          <div className="col-sm-6">
            {countriesWithProvinces.includes(formData.country) ? 
              (<ProvinceSelect 
                value={formData.provinceState} 
                onChange={handleProvinceStateChange} 
                country={formData.country} required
                />
              ):
              (<input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="provinceState" 
                name="provinceState" 
                value={formData.provinceState} 
                onChange={handleChange} 
                placeholder="Province / State"
              />
              )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="city" className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.city}<RequiredIndicator /></p>
          <div className="col-sm-6">
            {countriesWithProvinces.includes(formData.country) ? 
              (<CitySelect 
                value={formData.city} 
                onChange={handleCityChange} 
                province={formData.provinceState} 
                required
                />
              ):
              (
                <input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="city" 
                name="city" 
                value={formData.city} 
                onChange={handleChange} 
                placeholder="City"
              />
              )}
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="streetNumber" className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.streetNumber}</p>
          <div className="col-sm-6">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="streetNumber" 
              name="streetNumber" 
              value={formData.streetNumber} 
              onChange={handleChange} 
              placeholder="Street number"
            />
          </div>
        </div>
        <div className='row p-2'>
          <p htmlFor="postalCode" className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.postalCode}</p>
          <div className="col-sm-6">
            <input 
              type="text" 
              className="form-control rounded-pill flous-input-color" 
              id="postalCode" 
              name="postalCode" 
              value={formData.postalCode} 
              onChange={handleChange} 
              placeholder="Postal code"
            />
          </div>
        </div>
        {formData.provinceState === 'BC' && (
        <>
          <div className='row p-2'>
            <p htmlFor="haveVisitBC" className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.haveVisitBC}<RequiredIndicator /></p>
            <div className="col-sm-6">
              <input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="haveVisitBC" 
                name="haveVisitBC" 
                value={formData.haveVisitBC} 
                onChange={handleChange} 
                placeholder="Yes / No"
              />
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="visitedCity" className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.visitedCity}<RequiredIndicator /></p>
            <div className="col-sm-6">
              <input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="visitedCity" 
                name="visitedCity" 
                value={formData.visitedCity} 
                onChange={handleChange} 
                placeholder=""
              />
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="visitDate" className="col-sm-6 col-form-label">{EnglishQuestion.entrepreunarialExperience.visitDate}<RequiredIndicator /></p>
            <div className="col-sm-6">
              <input 
                type="date" 
                className="form-control rounded-pill flous-input-color" 
                id="visitDate" 
                name="visitDate" 
                value={formData.visitDate} 
                onChange={handleChange} max={new Date().toISOString().split("T")[0]}
                placeholder=""
              />
            </div>
          </div>
        </>) }
      </div>
    </div>
  );
};

export default CompanyDetailsYesSection;