import React from 'react';
import FlagComponent from '../../general/FlagComponent';

const AgentCustomersResultsItem = ({ item, userType}) => {

  const pageType = "result"

  return (
      <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px' }}>
        <div className='row'>
            <div className='col-sm col-6 mt-2 text-center'><FlagComponent cca3={item.country} /></div>
            <div className='col-sm col-6 mt-2 text-center'>{item.date}</div>
            <div className='col-sm col-6 mt-2 text-center'>{item.title}</div>
            <div className='col-sm col-6 mt-2 text-center'>
              <a href={`/${userType}/customers/${pageType}/${item.id}`} className="btn flous-bg-menu">
                <i className="nav-icon fas fa-eye"></i>
              </a>
            </div>
        </div>
      </div>
  );
}

export default AgentCustomersResultsItem;