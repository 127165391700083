import React from 'react';

const WelcomePage = () => {
  return (
    <div className='relative overflow-hidden bg-[linear-gradient(270deg,#7FA1F8_0%,#FDFEFE_88%)] min-h-screen'>
      <div className="top-[20px]  bottom-0">
        <img src={`${process.env.PUBLIC_URL}/flous/bg/login-bg.png`} alt="" className="w-[900px] ml-[35%] sm:ml-[60%]   object-cover z-0 " />
      </div>
      <div className='absolute top-0 left-0 right-0 bottom-0 p-2'>
        <div className="flex justify-start sm:ml-5  mt-3">
          <a href="/">
            <img
              src={`${process.env.PUBLIC_URL}/flous/logos/trans_1.svg`}
              alt="Flous Logo"
              className="brand-image"
              style={{ opacity: 0.8, height: '150px', width: 'auto' }}
            />
          </a>
        </div>
        <div className="flex justify-center items-start min-h-screen pt-10"> {/* Ajustement de l'alignement vers le haut */}
          <div className="col-lg-6 mx-auto">
            <div className="card card-outline elevation-2 p-5" style={{ borderRadius: '15px' }}>
              <div className="card-header text-center">
                <h3 className="flous-police-one text-black">Welcome to MyFlous! </h3>
              </div>
              <div className="card-body text-center">
                <a
                  href="/login"
                  className="btn btn-lg flous-btn-primary rounded-pill col-lg-8 mx-auto text-bold"
                >
                  Log in with Email
                </a>
                <p className="mt-3">or</p>
                <a
                  href="/chooseactor"
                  className="btn btn-lg flous-btn-gradient rounded-pill col-lg-8 mx-auto text-bold"
                >
                  Create an account
                </a>
                <p className="mt-3">
                  <a href="/forgotpassword" className="text-center">
                    Forgot password?
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
