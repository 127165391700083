import React, {useState, useEffect} from 'react';
import formData from './forms/formsData';
import FlousFileItem from './FlousFileItem';
import PaginationSection from '../Layout/PaginationSection';
import { getCurrentItems } from '../../services/users/FormsServices';
import CountrySelect from '../general/CountrySelect';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import GoToBackButton from '../general/GoBackLink';
import Select from 'react-select';

export default function FlousFiles () {
  const [flousfiles, setFlousfiles] = useState(formData.flousfiles);

  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [country, setCountry] = useState('');

  const handleCountryChange = (e) =>{
    setCountry(e.target.value);
  }

  const companies = [
    { value: 'A', label: 'Company A' },
    { value: 'B', label: 'Company B' },
    // Ajoutez d'autres compagnies ici
  ];

  const languages = [
    { value: 'FR', label: 'French' },
    { value: 'EN', label: 'English' },
    { value: 'ES', label: 'Espanish' },
    // Ajoutez d'autres langues ici
  ];

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };
  
  const currentItems = getCurrentItems(flousfiles, page, pageSize);

  const filterItems = () => {
    console.log(selectedCompany);
    console.log(country);
    console.log(selectedLanguage);
    const filtered = formData.flousfiles.filter(item => {
      const matchesCompany = selectedCompany ? item.company_name === selectedCompany : true;
      const matchesCountry = country ? item.country_cca3 === country : true;
      console.log(item.languages);
      const matchesLanguage = selectedLanguage
        ? item.languages.split(', ').includes(selectedLanguage)
        : true;
      console.log(matchesLanguage);
      return matchesCompany && matchesCountry && matchesLanguage;
    });
    setFlousfiles(filtered);
  };

  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="mb-2">
            <GoToBackButton />
            <div className="col-sm-6">
              <h1 className="flous-police-one flous-input-color m-0">Flous Files</h1>
            </div>
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </div>
      {/* /.content-header */}

      <div className="row">
          <div className="col mx-3">
            <div className="card elevation-2" style={{ borderRadius: '15px' }}>
              <div className="card-header shadow">
                    <div className="row">
                      <div className="col-sm col-12">
                        <div className="form-group">
                          <Select
                            name="company"
                            classNamePrefix="react-select"
                            value={selectedCompany ? companies.find(company => company.value === selectedCompany) : { value: '', label: 'Company' }}
                            onChange={(selectedOption) => setSelectedCompany(selectedOption ? selectedOption.value : '')}
                            options={companies}
                            placeholder="Company"
                            isSearchable
                            isClearable
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm col-12 mb-2">
                        <CountrySelect onChange={handleCountryChange} value={country} required />  
                      </div>
                      <div className="col-sm col-12">
                        <div className="form-group">
                          <Select
                            name="langue"
                            classNamePrefix="react-select"
                            value={selectedLanguage ? languages.find(language => language.value === selectedLanguage) : { value: '', label: 'Language' }}
                            onChange={(selectedOption) => setSelectedLanguage(selectedOption ? selectedOption.value : '')}
                            options={languages}
                            placeholder="Language"
                            isSearchable
                            isClearable
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                                borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                              }),
                            }}
                          />
                        </div>
                      </div>
                      <div onClick={filterItems} className='col-sm-2 col-6 btn flous-input-color'><i className="nav-icon fas fa-search"></i> Search</div>
                      <div className='col-sm-1 col-6 btn flous-input-color'><i className="nav-icon fas fa-sort"></i> Sort</div>
                    </div>
              </div>
              {/* /.card-header */}
              <div className="card-body p-2">
                <div className='flous-bg-thirty m-2' style={{ borderRadius: '15px' }}>
                    <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                        <div className='row text-bold'>
                            <div className='col-sm col-12 mt-2 text-center'>Compagnie Name</div>
                            <div className='col-sm col-6 mt-2 text-center'>Type</div>
                            <div className='col-sm col-6 mt-2 text-center'>Location</div>
                            <div className='col-sm col-6 mt-2 text-center'>Language Spoken</div>
                            <div className='col-sm col-6 mt-2 text-center'>Contact</div>
                            <div className='col-sm col-12 mt-2 text-center'>Digital Platform</div>
                            <div className='col-sm col-12 mt-2 text-center'>Actions</div>
                        </div>
                    </div>
                    {currentItems.length > 0 ? (
                      currentItems.map(item => (
                        <FlousFileItem  key={item.id} item={item} />
                      ))
                    ) : (
                      <NoItemsMessage />
                    )}
                </div>
              </div>
              {/* /.card-body */}
              <PaginationSection
                page={page}
                pageSize={pageSize}
                totalItems={flousfiles.length}
                onPageChange={onPageChange}
              />
            </div>
            {/* /.card */}
          </div>
        </div>
    </>
  );
};