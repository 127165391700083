import React from 'react';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function CompanyActivitiesResumeSection(props) {
  // Define the company activities information
  const companyActivities = {
    companyDescription: props.companyActivites.companyActivity, //  
    involvesPaydayLoans: props.companyActivites.paydayLoans, //  
    involvesExplicitContent: props.companyActivites.explicitContent, //  
    involvesRealEstate: props.companyActivites.realEstate //  
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Company Activities</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputCompanyDescription" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.businessSector}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputCompanyDescription" value={companyActivities.companyDescription} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputPaydayLoans" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.paydayLoans}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputPaydayLoans" value={companyActivities.involvesPaydayLoans} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputExplicitContent" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.explicitContent}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputExplicitContent" value={companyActivities.involvesExplicitContent} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputRealEstate" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.realEstate}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputRealEstate" value={companyActivities.involvesRealEstate} />
          </div>
        </div>
      </div>
    </div>
  );
}
