import React from 'react';
import CountryShow from '../../../../general/countryShow';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function PersonalInfoResumeSection(props) {
  // Define the personal information
  const genderIdentity = props.personalDetail.genderIdentity; //  
  const nationality = props.personalDetail.nationality; //  
  const maritalStatus = props.personalDetail.maritalStatus; //   
  const preferredLanguage = props.preferredLanguage; //  

  const countryName = CountryShow({ value: nationality });

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Personal Information</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputGenderIdentity" className="col-sm-3 col-form-label">{EnglishQuestion.personalInfo.genderIdentity}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputGenderIdentity" value={genderIdentity} />
          </div>
          <label htmlFor="inputNationality" className="col-sm-3 col-form-label">{EnglishQuestion.personalInfo.nationality}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputNationality" value={countryName} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputMaritalStatus" className="col-sm-3 col-form-label">{EnglishQuestion.personalInfo.maritalStatus}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputMaritalStatus" value={maritalStatus} />
          </div>
          <label htmlFor="inputPreferredLanguage" className="col-sm-3 col-form-label">{EnglishQuestion.personalInfo.preferredLanguage}</label>
          <div className="col-sm-3">
            <input type="text" className="form-control" id="inputPreferredLanguage" value={preferredLanguage} />
          </div>
        </div>
      </div>
    </div>
  );
}
