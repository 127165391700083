
import api from '../api';

let isLoading = false;

export const saveFormDataStructure = async (data) => {
    try{
        const response = await api.post('/user/save/formdatastructure/', data);
        return response.data;

    } catch (error){
        console.error('Error when fetching data:', error);
        throw error;
    }   
};

export const createNewForm = async (evaluationCountry) => {
    try{
        const response = await api.get(`/user/save/createnewform/${evaluationCountry}/`);
        return response;

    } catch (error){
        console.error('Error when creating new form:', error);
        throw error;
    }   
};

export const UpdateForm = async (data) => {
    try{
        const response = await api.post('/user/save/updateform/', data);
        return response;

    } catch (error){
        console.error('Error when updating form:', error);
        throw error;
    }   
};

export const getFormOwner = async (formCode) => {
    try{
        const response = await api.get(`/user/form/get-owner/${formCode}/`);
        return response;

    } catch (error){
        throw error;
    }   
};



export const getExistingFormData = async (formCode) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/user/get-form/${formCode}`);
        return response;

    } catch (error){
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const fetchUserFormsData = async () => {

    try{
        const response = await api.get('/user/get-all-forms');
        return response.data;

    } catch (error){
        console.error('Error when fetching data:', error);
        throw error;
    }   
};

/////////////////////////////////////////////////////////////////////////
// Delete functions

export const DeleteForm = async (EvaluationCode) => {

    try{
        const response = await api.get(`/user/delete-form/${EvaluationCode}`);
        return response;

    } catch (error){
        console.error('Error when deleting Evaluation:', error);
        throw error;
    }   
};

/////////////////////////////////////////////////////////////////////////
// Duplicate functions
export const DuplicateFormFromAdvisor = async (EvaluationCode) => {

    const isAdvisor = 'Yes'

    try{
        const response = await api.get(`/general/duplicate-form/${EvaluationCode}/${isAdvisor}`);
        return response;

    } catch (error){
        console.error('Error when duplicating Form:', error);
        throw error;
    }   
};


// Duplicate functions
export const DuplicateFormFromCustomer = async (EvaluationCode) => {

    const isAdvisor = 'No'

    try{
        const response = await api.get(`/general/duplicate-form/${EvaluationCode}/${isAdvisor}`);
        return response;

    } catch (error){
        console.error('Error when duplicating Form:', error);
        throw error;
    }   
};



////////////////////////////////////////////////////////////////////////
// Mapping functions

export const mapStateToDescription = (state) => {
    const stateDescriptions = {
      CR: "CREATED",
      IP: "IN PROGRESS",
      AB: "ABANDONED",
      CP: "COMPLETED",
      PS: "PARSED",
      DL: "DELETED",
      EV: "EVALUATED",
    };
  
    return stateDescriptions[state] || state; 
};


export const getCurrentItems = (items, page, pageSize) => {
    const startIndex = (page - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return items.slice(startIndex, endIndex);
  };