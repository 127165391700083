import React from 'react';
import FlousMessageForm from './messageFormSection';

export default function UserProfileSection({user}) {
      
    return <div id='profile'>
        <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
            <div className='text-center text-bold'>Personal Information</div>
            <hr/>
            <div className='mx-3'>
                <dl className="row">
                    <dt className="col-sm-5">User Name</dt>
                    <dd className="col-sm-7">{user.first_name} {user.last_name}</dd>
                    <hr/>
                    <dt className="col-sm-5">Email</dt>
                    <dd className="col-sm-7">{user.email}</dd>
                    <hr/>
                    <dt className="col-sm-5">Phone</dt>
                    <dd className="col-sm-7">{user.cell_phone_number}</dd>
                    <hr/>
                    <dt className="col-sm-5">Profile</dt>
                    {(user.role === 'Customer') ? (
                    <dd className="col-sm-7">{user.self_analyst ? 'Self Analyst' : user.role}</dd>
                    ) : (<dd className="col-sm-7">{user.role}</dd>)}
                </dl>
            </div>
        </div>
        <FlousMessageForm/>
    </div>;
}