import React, { useState, useEffect } from 'react';

const isDevMode = process.env.NODE_ENV !== 'production'

const socketURL = isDevMode ? 'localhost:8000' : 'backend.myflous.com'

const NotificationComponent = () => {
    const [notifications, setNotifications] = useState([]);
    const [socketConnected, setSocketConnected] = useState(false);

    useEffect(() => {
        // Creating a WebSocket connection
        const socket = new WebSocket(`ws://${socketURL}/ws/notifications/`);

        socket.onopen = () => {
            console.log("WebSocket connected");
            setSocketConnected(true);
        };

        // Manage received messages
        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setNotifications((prevNotifications) => [
                ...prevNotifications,
                data.notification,
            ]);
        };

        socket.onclose = () => {
            console.log("WebSocket disconnected");
            setSocketConnected(false);
        };

        socket.onerror = (error) => {
            console.error("WebSocket error: ", error);
        };

        // Close WebSocket connection when component is disassembled
        return () => socket.close();
    }, []);

    return (
        <div>
            <h2>Notifications</h2>
            <div style={{ marginBottom: "1rem", color: socketConnected ? "green" : "red" }}>
                WebSocket status: {socketConnected ? "Connected" : "Disconnected"}
            </div>
            <ul>
                {notifications.map((notification, index) => (
                    <li key={index}>
                        <strong>{notification.date}</strong>: {notification.content}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default NotificationComponent;
