import React from 'react';
import { mapStateToDescription } from '../../../../services/users/FormsServices';

export default function FormDetailIntroduction(props) {
  

  return (
    <div id='evaluation'>
      <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <h4 className='text-bold'>General Information</h4>
        <hr />
        <div className='mx-3'>
          <div className="form-group row">
            <label htmlFor="inputCountry" className="col-sm-3 col-form-label">Form Title : </label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputCountry" value={props.formTitle} />
            </div>
            <label htmlFor="inputEvaluationNumber" className="col-sm-3 col-form-label">Form code :</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputEvaluationNumber" value={props.formCode} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputCountry" className="col-sm-3 col-form-label">Evaluation Country :</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputCountry" value={props.evaluationCountry} />
            </div>
            <label htmlFor="inputEvaluationNumber" className="col-sm-3 col-form-label">Creation date :</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputEvaluationNumber" value={props.creationDate} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputDate" className="col-sm-3 col-form-label">Last update/Completed date :</label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputDate" value={props.updateCompletedDate} />
            </div>
            <label htmlFor="inputTotalProg" className="col-sm-3 col-form-label">Status </label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputTotalProg" value={mapStateToDescription(props.formStatus)} />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="inputDate" className="col-sm-3 col-form-label">Is co application with my partner : </label>
            <div className="col-sm-3">
              <input type="text" className="form-control" id="inputDate" value={props.isCoApplication ? 'Yes': 'No'} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
