import React from 'react';
import FlagComponent from '../../general/FlagComponent';

const AgentRequestsItem = ({ item }) => {

    return (
        <div key={item.id} className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px' }}>
          <div className='row'>
              <div className='col-sm col-12 mt-2 text-center'>{item.requestDate}</div>
              <div className='col-sm col-6 mt-2 text-center'>{item.firstName}</div>
              <div className='col-sm col-6 mt-2 text-center'>{item.lastName}</div>
              <div className='col-sm col-6 mt-2 text-center'><FlagComponent cca3={item.country} /></div>
              <div className='col-sm col-6 mt-2 text-center'>
                <div className={`btn ${item.status === 'Approved' ? 'flous-bg-menu' : 'btn-secondary'}`}>
                  {item.status}
                </div>
              </div>
              <div className='col-sm-3 col-12 mt-2 text-center'>
                <a href={ '#'/*`/agent/customers/${item.id}`*/} className="btn flous-bg-secondary mr-2"><i className="nav-icon fas fa-eye"></i></a>
                <button
                  type="button"
                  className="btn flous-bg-primary mr-2"
                  data-toggle="modal"
                  data-target="#acceptModal"
                >
                  Accept
                </button>
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-toggle="modal"
                  data-target="#rejectModal"
                >
                  Reject
                </button>
              </div>
          </div>
        </div>
    );
}

export default AgentRequestsItem;