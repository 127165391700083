import React, {useEffect, useState} from 'react';
import PaginationSection from '../Layout/PaginationSection';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import { getCurrentItems } from '../../services/users/FormsServices';
import GoToBackButton from '../general/GoBackLink';
import FlousDemoRequestItem from './item/FlousDemoRequestItem';
import { fetchFlousGetDemoRequests } from '../../services/flous/inboxServices';
import CreateNewCompanyModal from './modal/createNewCompanyAccount';

export default function FlousDemoRequestPage() {

  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleUpdateRequest = (request) =>{
    setSelectedRequest(request);
  }

  const handleCloseModal = () =>{
    setSelectedRequest(null);
    setShowModal(false);
  }

  useEffect( () => {
    let isMounted = true;
    setLoading(true);

    const getMessages = async () => {
        try {
          const response = await fetchFlousGetDemoRequests();
          if (isMounted) {
            setRequests(response.data.data);
          }
          setLoading(false);
        } catch (error) {
          if (isMounted){
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)) {
              setError(error.response.data.error);
            }
          }
        }
    };

    getMessages();

    return () => {
      isMounted = false;
    };

  }, []);
    
      
    const [page, setPage] = useState(1);
    const pageSize = 5;
    const onPageChange = (page) => {
        setPage(page + 1);
    };
    
    const currentItems = getCurrentItems(requests, page, pageSize);

    return (
        <div className="content">
          <div className="container-fluid">
            <div className="content-header">
              <div className="container-fluid">
                <div className="mb-2">
                  <GoToBackButton />
                  <div className="col-sm-6">
                    <h1 className="m-0 flous-police-one flous-input-color">Demo Requests</h1>
                  </div>
                </div>
              </div>
            </div>
    
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="card elevation-2" style={{ borderRadius: '15px' }}>
                    <div className="card-header">
                        <div className="row">
                          <div className='col-sm-2 col-6 btn flous-input-color'><i className="nav-icon fas fa-sort"></i> Sort</div>
                        </div>
                    </div>
                    <div className="card-body flous-bg-thirty">
                      <div className='' style={{ borderRadius: '15px' }}>
                        <div className='card-header p-2 mb-2 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                            <div className='row text-bold'>
                                <div className='col-sm-2 col-6 mt-2 text-center'>Name</div>
                                <div className='col-sm-2 col-6 mt-2 text-center'>Occupation</div>
                                <div className='col-sm-2 col-6 mt-2 text-center'>Email</div>
                                <div className='col-sm-2 col-6 mt-2 text-center'>Phone Number</div>
                                <div className='col-sm-2 col-12 mt-2 text-center'>Status</div>
                                <div className='col-sm-2 col-12 mt-2 text-center'>Actions</div>
                            </div>
                        </div>
                      {currentItems.length > 0 ? (
                        currentItems.map((request) => (
                          <FlousDemoRequestItem request={request} handleSetRequest={() => handleUpdateRequest(request)}/>
                        ))
                      ) : (
                        <NoItemsMessage />
                      )}
                      </div>
                      
                        <CreateNewCompanyModal 
                          request={selectedRequest}
                        />
                    </div>
                    <PaginationSection
                          page={page}
                          pageSize={pageSize}
                          totalItems={requests.length}
                          onPageChange={onPageChange}
                        />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}