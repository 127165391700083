import React from 'react';
import CompanyAddressResumeSection from './section_joboffer/companyAddressSection';
import JobDetailResumeSection from './section_joboffer/jobDetailSection';
import CompanyInfoResumeSection from './section_joboffer/companyInfoSection';
import ProfessionalDetailsResumeSection from './section_joboffer/professionalDetailSection';

export default function JobOfferResumeSection(props) {
  
  return (
    <div id='joboffer'>
      <h3 className='flous-police-one flous-input-color text-center'>Job Offer</h3>
      {props.jobOffer.jobOffer === 'Yes' && (
      <>
      <CompanyAddressResumeSection companyDetail={props.jobOffer.companyDetail} />
      <JobDetailResumeSection jobDetail={props.jobOffer.jobDetail} professionalDetail={props.jobOffer.professionalDetail} />
      <ProfessionalDetailsResumeSection professionalDetail={props.jobOffer.professionalDetail} />
      <CompanyInfoResumeSection jobCompensation={props.jobOffer.jobCompensation} companyInfo={props.jobOffer.companyInfo} />
      </>
      )}
      {props.jobOffer.jobOffer !== 'Yes' && (
        <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
        <div className='text-center'>You don't have Job offer</div>
        </div>
      )}
    </div>
  );
}