import React, { useEffect, useState } from 'react';
import FlagComponent from '../general/FlagComponent';
import { FirmTypesConverter, languageConverter } from '../../services/utils';
import GoToBackButton from '../general/GoBackLink';
import { BASE_URL } from '../../services/constants';
import { getCompanyData } from '../../services/flous/ProfesionnalServices';
import LoadingOverlay from '../general/LoadingOverlay';
import { useNavigate } from 'react-router-dom';

const FlousFilesDetail = (props) => {

  const code = props.code;
  console.log(code);
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(code);
    let isMounted = true;
    setLoading(true);

    const getCompanyDetail = async () => {
      try {
        const response = await getCompanyData(code);
        if (isMounted) {
          setCompany(response.data.data);
          setLoading(false);
        }
      } catch (error) {
        if (isMounted){
          setLoading(false);
          if (error.response && [400, 500].includes(error.response.status)) {
            //navigate(`/user/asking`);
            //setError(error);
          }
        }
      }
    };

    getCompanyDetail();

    return () => {
      console.log("Cleaning ...")
      isMounted = false;
    };

  }, [code]);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="mb-2">
            <GoToBackButton />
            <div className="col-sm-6">
              <h1 className="flous-police-one flous-input-color m-0">Flous Files</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="card elevation-2 m-3 p-3" style={{ borderRadius: '15px' }}>
          <div className="row">
            <div className="col-md-3 mr-5">
              {/* Profile Image */}
              <div className="card elevation-1" style={{ borderRadius: '15px' }}>
                <div className="card-body box-profile">
                  <div className="text-center">
                    <div className="flex justify-content-center">
                    {company.logoUrl ? (
                        <img src={`${BASE_URL}${company.logoUrl}`} className="border rounded-circle" alt="User Image" width="200" height="200"/>
                    ):(
                        <img src={`${process.env.PUBLIC_URL}/flous/logos/logo_2.svg`} className="border rounded-circle" alt="User Image" width="200" height="200"/>
                    )}
                    </div>
                  </div>
                  <h3 className="profile-username text-center text-bold">{company.name}</h3>
                  <p className="text-center">{FirmTypesConverter(company.firmType)}</p>
                </div>
              </div>
              <h4 className='flous-input-color text-bold'>Languages spoken</h4>
              <div>Expliquez les erreurs de la console à l’aide de Copilot dans Edge : cliquez sur
         pour expliquer une erreur.</div>
              <div className=''>
                {company.languages && company.languages.map(language => (
                  <div key={language} className='card btn'>{language} - {languageConverter(language)}</div>
                ))}
              </div>
            </div>
            <div className='col-md-8'>
              <h4 className='text-bold flous-input-color pt-4'>About Us</h4>
              <p>{company.about}<div>Expliquez les erreurs de la console à l’aide de Copilot dans Edge : cliquez sur
              pour expliquer une erreur.</div></p>
              <h4 className='text-bold pt-4'>Services Offered</h4>
              <div>Expliquez les erreurs de la console à l’aide de Copilot dans Edge : cliquez sur
         pour expliquer une erreur.</div>
              <ul className='row flous-input-color'>
                {company.services && company.services.map(service => (
                  <li key={service} className='col-4'>{service}</li>
                ))}
              </ul>
              <hr />
              <h4 className='text-bold flous-input-color pt-4'>Contact Information</h4>
                <div className='row'>
                  <div className='col-md'>
                    <div className='col btn card'>
                      <div className='row'>
                        <div className='col-4 text-bold text-left'>Location: </div>
                        <div className='col text-left'><FlagComponent cca3={company.location} /></div>
                      </div>
                    </div>
                    <div className='col btn card'>
                      <div className='row'>
                        <div className='col-4 text-bold text-left'>Phone: </div>
                        <div className='col text-left'>{company.phone ? company.phone: "+0 000 000 0000"}</div>
                      </div>
                    </div>
                    <div className='col btn card'>
                      <div className='row'>
                        <div className='col-4 text-bold text-left'>Email: </div>
                        <div className='col text-left'>{company.email}</div>
                      </div>
                    </div>
                  </div>
                  <div className='col-md'>
                  <div className='col btn card'>
                      <div className='row'>
                        <div className='col-4 text-bold text-left'>LinkedIn: </div>
                        <div className='col text-left'>{company.linkedin}</div>
                      </div>
                    </div>
                    <div className='col btn card'>
                      <div className='row'>
                        <div className='col-4 text-bold text-left'>Website: </div>
                        <div className='col text-left'>{company.website}</div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          {/* /.card-body */}
          <div className="d-flex justify-content-center mb-3">
            <button type="submit" className="col-sm-4 btn flous-bg-primary btn-block">Request Meeting</button>
          </div>
        </div>
        {/* /.card */}
      </div>
    </>
  );
}

export default FlousFilesDetail;