import React from 'react';

const InfoBoxPrimaryLink = ({ value, text, href='#', link='More info' }) => {
  return (
    <div className="small-box elevation-2 flous-bg-menu" style={{ borderRadius: '15px' }}>
      <div className="inner">
        <h3 className='flous-police-one'>{value}</h3>
        <p>{text}</p>
      </div>
      <div className="icon">
        <i className="ion ion-pie-graph"></i>
      </div>
      <a href={href} className="small-box-footer" style={{ borderRadius: '0px 0px 15px 15px' }}>
        {link} <i className="fas fa-arrow-circle-right"></i>
      </a>
    </div>
  );
};

export default InfoBoxPrimaryLink;