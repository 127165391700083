import React, {useState, useEffect} from 'react';
import FlousMessageForm from './messageFormSection';
import { GetAdvisorCompanyDetail } from '../../services/agents/AccountDetails'

export default function EnterpriseAccountSection({user}) {

    const [data, setData] = useState({})
    const [error, setError] = useState('')

    useEffect(()=>{
        let isMounted = true;
        const getCompanyData = async () =>{
            try {
                const response = await GetAdvisorCompanyDetail(user.code);
                if (isMounted){
                    if (response && response.status === 200){
                        setData(response.data.data);
                        isMounted = false;
                    }
                }
            } catch (error){
                
                if (isMounted){
                    if (error.response && [400, 500].includes(error.response.status)){
                        setError(error.response.data.error);
                        isMounted = false;
                    }
                }
            }
        }

        getCompanyData();

        return () => {
          isMounted = false;
        };

    }, [user]);

    return <div id='Account'>
        <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
            <div className='text-center text-bold'>Personal Information</div>
            <hr/>
            <div className='mx-3'>
                <dl className="row">
                    <dt className="col-sm-5">User Name</dt>
                    <dd className="col-sm-7">{user.first_name} {user.last_name}</dd>
                    <hr/>
                    <dt className="col-sm-5">Company Name</dt>
                    <dd className="col-sm-7">{data.companyName? data.companyName: 'Undefined'} </dd>
                    <hr/>
                    <dt className="col-sm-5">Register Number</dt>
                    <dd className="col-sm-7">{data.registerNumber? data.registerNumber: '-------------'} </dd>
                    <hr/>
                    <dt className="col-sm-5">Immigration Consultant Licence Number</dt>
                    <dd className="col-sm-7">{data.consultantLicence? data.consultantLicence: 'xxxxxxxxx'} </dd>
                    <hr/>
                    <dt className="col-sm-5">Lawyer Licence Number</dt>
                    <dd className="col-sm-7">{data.LawyerLicence? data.LawyerLicence: 'xxxxxxxxx'} </dd>
                    <hr/>
                    <dt className="col-sm-5">Email</dt>
                    <dd className="col-sm-7">{user.email}</dd>
                    <hr/>
                    <dt className="col-sm-5">Phone Number</dt>
                    <dd className="col-sm-7">{user.cell_phone_number? user.cell_phone_number : '-------------'}</dd>
                    <hr/>
                    <dt className="col-sm-5">Role</dt>
                    <dd className="col-sm-7">{user.self_analyst ? 'Self Analyst' : (user.role === 'Customer'? 'Client' : (user.role === 'AssociateAdmin'? 'Administrator' : user.role))}</dd>
                </dl>
            </div>
        </div>
        <FlousMessageForm/>
    </div>;
}