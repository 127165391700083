import React, { useState, useEffect } from 'react';
import { getCurrentItems } from '../../services/users/FormsServices';
import AgentRequestsItem from '../agent/item/AgentRequestsItem';
import PaginationSection from '../Layout/PaginationSection';
import CountrySelect from '../general/CountrySelect';
import NoItemsMessage from '../general/NoItemsToDisplaysItem';
import GoToBackButton from '../general/GoBackLink';
import Select from 'react-select';

export default function EntrepriseCustomersRequest() {
  const [searchParams, setSearchParams] = useState({
    country: '',
    city: '',
    language: '',
    status: ''
  });

  const customers = [
    {
      id: '0009',
      firstName: 'TAPSOBA',
      lastName: 'Abdoul Kader',
      country: 'CAN',
      requestDate: '26 / 05 / 2024',
      status: 'Approved'
    },
    {
      id: '0010',
      firstName: 'OUEDRAOGO',
      lastName: 'Charlotte',
      country: 'CAN',
      requestDate: '26 / 05 / 2024',
      status: 'Approved'
    }
  ];

  /*const [customers, setCustomers] = useState(customers_old);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useOutletContext();*/

  const [page, setPage] = useState(1);
  const pageSize = 5;
  const onPageChange = (page) => {
    setPage(page + 1);
  };
  
  const currentItems = getCurrentItems(customers, page, pageSize);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchParams((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    //filterItems();
    // Logic for searching customers based on searchParams
    console.log(searchParams);
  };

  /*const filterItems = async () => {
    let isMounted = true;
    if (user.id) {
        try {
          const data = await fetchCustomersRequestsDataFromAdvisors();
          if (isMounted) {
            if (!searchParams.country && !searchParams.status) {
              setCustomers(data);
              setLoading(false);
              return;
            }
            const filteredItems = data.filter((item) => {
              const matchesCountry = searchParams.country ? item.country_cca3 === searchParams.country : true;
              const matchesStatus = searchParams.status ? item.state === searchParams.status : true;
              return matchesCountry && matchesStatus;
            });
            setCustomers(filteredItems);
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setError(error);
            setLoading(false);
            throw error;
          }
        }
      }
  };*/

  return (
    <>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="mb-2">
            <GoToBackButton />
            <div className="col-sm-6">
              <h1 className="m-0 flous-police-one flous-input-color">Clients Requests</h1>
            </div>
          </div>
        </div>
      </div>
      {/* /.content-header */}

      {/* Main content */}
      <section className="content">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col">
              <div className="row">
                <div className="col">
                  <div className="card elevation-2">
                    <div className="card-header">
                      <div>
                        <h3 className="card-title"></h3>
                      </div>
                      <form onSubmit={handleSearchSubmit}>
                        <div className="row">
                          <div className="col-sm col-12 mb-2">
                            <CountrySelect onChange={handleSearchChange} required />  
                          </div>
                          <div className="col-sm col-12">
                            <div className="form-group">
                              <Select
                                name="status"
                                value={searchParams.status ? { value: searchParams.status, label: searchParams.status } : null}
                                onChange={(selectedOption) =>
                                  handleSearchChange({
                                    target: { name: 'status', value: selectedOption?.value || '' },
                                  })
                                }
                                options={[
                                  { value: 'Approved', label: 'Approved' },
                                  { value: 'Rejected', label: 'Rejected' },
                                ]}
                                placeholder="Select Status"
                                isSearchable={false}
                                styles={{
                                  control: (provided) => ({
                                    ...provided,
                                    width: '100%',
                                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                                  }),
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-sm-2 col-6">
                            <button type="submit" className="btn flous-input-color">
                              <i className="nav-icon fas fa-search"></i> Search
                            </button>
                          </div>
                          <div className='col-sm-1 col-6 btn flous-input-color'><i className="nav-icon fas fa-sort"></i> Sort</div>
                        </div>
                      </form>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body p-2">
                      <div className='flous-bg-thirty m-2' style={{ borderRadius: '15px' }}>
                          <div className='card-header p-3 mb-3 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                              <div className='row text-bold'>
                                  <div className='col-sm col-12 mt-2 text-center'>Request Date</div>
                                  <div className='col-sm col-6 mt-2 text-center'>First Name</div>
                                  <div className='col-sm col-6 mt-2 text-center'>Last Name</div>
                                  <div className='col-sm col-6 mt-2 text-center'>Country</div>
                                  <div className='col-sm col-6 mt-2 text-center'>Status</div>
                                  <div className='col-sm-3 col-12 mt-2 text-center'>Actions</div>
                              </div>
                          </div>
                          {currentItems.length > 0 ? (
                            currentItems.map((customer) => (
                            <AgentRequestsItem  key={customer.id} item={customer} />
                            ))
                          ) : (
                            <NoItemsMessage />
                          )}
                      </div>
                    </div>
                    <PaginationSection
                      page={page}
                      pageSize={pageSize}
                      totalItems={customers.length}
                      onPageChange={onPageChange}
                    />
                  </div>
                  {/* /.card */}
                </div>
              </div>
            </div>
            {/* /.row */}
          </div>
          {/* /.container-fluid */}
        </div>
        </section>
        {/* /.content */}
      </>
    );
};