import React, {useEffect, useState} from 'react';
import InfoBoxPrimaryLink from '../general/InfoBoxPrimaryLink';
import InfoBoxThirtyLink from '../general/InfoBoxThirtyLink';
import UserRetentionCard from './chart/FlousUsersRetentionChart';
import MonthlyActiveUserDashboadChart from './chart/FlousMonthlyUserActiveChart';
import MonthlyRevenueDashboadChart from './chart/FlousMonthlyRevenueChart';
import MonthlyActiveUserGeneralDashboadChart from './chart/FlousMonthlyActiveUserGeneralChart';
import { useOutletContext } from 'react-router-dom';
import LoadingOverlay from '../general/LoadingOverlay';
import { toast } from 'react-toastify';
import { FlousActiveUsers, FlousChurnRate, FlousDashBordStates, FlousMonthlyRevenue, FlousRevenueGrowth } from '../../services/general/DashbordServices';

export default function EntrepriseDashboard () {

  const [infoBoxData, setInfoBoxData] = useState({
    monthlyRevenue: "$11,457",
    revenueGrowth: "11.5%",
    activeUsers: "22,246",
    churnRate: "13.78%"
  });

  // State pour les données des graphiques (séparés par composants)
  const [monthlyGeneralData, setMonthlyGeneralData] = useState({
    agent: [,,,,,,,,,,,],  // Data variée pour agent
    enterprise: [,,,,,,,,,,,],  // Data variée pour enterprise
    agentRepeat: [,,,,,,,,,,,],  // Data pour agent (répété mais différente)
    enterpriseRepeat: [,,,,,,,,,,,],  // Data pour enterprise (répété mais différente)
    labels: []
  });

  const [monthlyRevenueData, setMonthlyRevenueData] = useState({
    active: [,,,,,,,,,,,],  // Data pour les utilisateurs actifs
    inactive: [,,,,,,,,,,,],  // Data pour les utilisateurs inactifs
    labels: []
  });

  const [userRetentionData, setUserRetentionData] = useState({
    agent: [,,,,,,,,,,,],  // Taux de rétention pour agent
    enterprise: [,,,,,,,,,,,]  // Taux de rétention pour enterprise
  });

  const [monthlyActiveUserData, setMonthlyActiveUserData] = useState({
    solo: [,,,,,,,,,,,],  // Données pour utilisateurs solo
    repeat: [,,,,,,,,,,,]  // Données pour utilisateurs récurrents
  });

  const {user} = useOutletContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect( () => {
    let isMounted = true;

    const getFlousDashbordStates = async () => {
      if (user.id) {
        try {
          //const response_infoBox = await FlousDashBordStates();
          const response_monthlyGeneralData = await FlousMonthlyRevenue();
          const response_monthlyRevenueData = await FlousRevenueGrowth();
          //const response_userRetentionData = await FlousActiveUsers();
          //const response_monthlyActiveUserData = await FlousChurnRate();
          if (isMounted) {
            //setInfoBoxData(response_infoBox.data.data);
            console.log(response_monthlyGeneralData.data.data)
            const generalData = {
              agent: response_monthlyGeneralData.data.data.agent,
              enterprise: response_monthlyGeneralData.data.data.enterprise,
              agentRepeat: response_monthlyGeneralData.data.data.agentRepeat,
              enterpriseRepeat: response_monthlyGeneralData.data.data.enterpriseRepeat,
              labels: response_monthlyGeneralData.data.data.labels
            }
            console.log(generalData)
            setMonthlyGeneralData(generalData);
            setMonthlyRevenueData(response_monthlyRevenueData.data.data);
            //setUserRetentionData(response_userRetentionData.data.data);
            //setMonthlyActiveUserData(response_monthlyActiveUserData.data.data);
            setLoading(false);
          }
        } catch (error) {
          // console.error('Error when preparing data:', error);
          if (isMounted){
            setError(error.response.data.error);
            setLoading(false);
            if (error.response && [400, 500].includes(error.response.status)){
              toast.error(error.response.data.error);
              isMounted = false;
            }
          }
        }
      }
    };

    getFlousDashbordStates();

    return () => {
      isMounted = false;
    };

  }, []);

  if (loading) {
    return <LoadingOverlay />;
  }

  if (error) {
    return <div>Error : {error.message}</div>;
  }

  return (
    <div>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 flous-police-one flous-input-color">Dashboard</h1>
            </div>
          </div>
        </div>
      </div>
      {/* /.content-header */}

      <div className="content">
        <div className="container-fluid">
          {/* Small boxes (Stat box) */}
          <div className='card p-2 px-5 elevation-2' style={{ borderRadius: '15px' }}>
            <div className="row">
              <div className="col-lg-3 col-6">
                <InfoBoxPrimaryLink
                  value={infoBoxData.monthlyRevenue}
                  text="Monthly recurring revenue"
                  link="More info"
                  href="#"
                />
              </div>
              <div className="col-lg-3 col-6">
                <InfoBoxThirtyLink
                  value={infoBoxData.revenueGrowth}
                  text="Monthly Revenue Growth"
                  link="More info"
                  href="#"
                />
              </div>
              <div className="col-lg-3 col-6">
                <InfoBoxPrimaryLink
                  value={infoBoxData.activeUsers}
                  text="Active Users"
                  link="More info"
                  href="/flous/self"
                />
              </div>
              <div className="col-lg-3 col-6">
                <InfoBoxThirtyLink
                  value={infoBoxData.churnRate}
                  text="Churn rate"
                  link="More info"
                  href="#"
                />
              </div>
            </div>
          </div>
          {/* /.row */}

          <div className="row">
            <div className="col">
              <MonthlyActiveUserGeneralDashboadChart elementId="monthly-general"  agent={monthlyGeneralData.agent} enterprise={monthlyGeneralData.enterprise}  agentRepeat={monthlyGeneralData.agentRepeat} enterpriseRepeat={monthlyGeneralData.enterpriseRepeat} labels={monthlyGeneralData.labels} />
              {/* /.card */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col">
              <MonthlyRevenueDashboadChart elementId="monthly-revenue" active={monthlyRevenueData.active} inactive={monthlyRevenueData.inactive} labels={monthlyRevenueData.labels} />
              {/* /.card */}
            </div>
            <div className="col-md-6 col">
              <UserRetentionCard elementId="user-retention" agent={userRetentionData.agent} enterprise={userRetentionData.enterprise} />
              {/* /.card */}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <MonthlyActiveUserDashboadChart elementId="monthly-activeUser" solo={monthlyActiveUserData.solo} repeat={monthlyActiveUserData.repeat} />
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};