import React from 'react';

import EnglishQuestion from "../../../forms/questions/englishQuestions.json";

export default function EntreExpeResumeSection(props) {
  // Define the entrepreneurial experience information
  const entrepreneurialExperience = {
    isOwnerOrPartner: props.entrepreunarialExperience.ownership, //  
    entrepreneurialGoal: props.entrepreunarialExperience.newcompany //   (options: "Start a new company", "Undertake an existing company", "None")
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
      <div className='text-bold text-center mb-3'>Entrepreneurial Experience</div>
      <div className='mx-3'>
        <div className="form-group row">
          <label htmlFor="inputIsOwnerOrPartner" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.ownership}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputIsOwnerOrPartner" value={entrepreneurialExperience.isOwnerOrPartner} />
          </div>
        </div>
        {props.entrepreunarialExperience.newcompany === 'No' && (
        <div className="form-group row">
          <label htmlFor="inputEntrepreneurialGoal" className="col-sm-6 col-form-label">
          {EnglishQuestion.entrepreunarialExperience.newcompany}
          </label>
          <div className="col-sm-6">
            <input type="text" className="form-control" id="inputEntrepreneurialGoal" value={entrepreneurialExperience.entrepreneurialGoal} />
          </div>
        </div>
        )}
      </div>
    </div>
  );
}
