import React from 'react';
import EducationInCountryInfoResumeSection from './section_education/educationInCountrySection';
import EducationOutsideCountryInfoResumeSection from './section_education/educationOutsideCountrySection';
import SpecialProgramResumeSection from './section_education/specialProgramSection';

export default function EducationResumeSection(props) {

  return (
    <div id='language'>
      <h3 className='flous-police-one flous-input-color text-center'>Education</h3>
      {props.educationInCountry.educationInCountry === 'Yes' && (
        <EducationInCountryInfoResumeSection allEducationInCountry={props.allEducationInCountry} />
      )}
      <SpecialProgramResumeSection specialProgram={props.specialProgram} />
      {props.educationOutsideCountry.educationOutsideCountry === 'Yes' && (
        <EducationOutsideCountryInfoResumeSection allEducationOutsideCountry={props.allEducationOutsideCountry} />
      )}
    </div>
  );
}
