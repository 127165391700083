import React, { useState, useEffect } from 'react';
import NotableAchievements from './section_p_expertise/NotableAchievementsSection';
import ImpactofExpertise from './section_p_expertise/ImpactsofExpertiseSection';
import { toast, ToastContainer } from 'react-toastify';
import ProfessionalExpertise from './section_p_expertise/ProfessionalExpertise';

const ProfessionalExpertiseSection = (props) => {
  const [professionalExpertise, setProfessionalExpertise] = useState(props.professionalExpertise.professionalExpertise);
  const [notableAchievements, setNotableAchievements] = useState(props.professionalExpertise.notableAchievements);
  const [impactofExpertise, setImpactofExpertise] = useState(props.professionalExpertise.impactofExpertise);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const professionalExpertiseParent = {
        professionalExpertise,
        notableAchievements,
        impactofExpertise
    };
    props.sendProfessionalExpertise(professionalExpertiseParent);
    props.onValidate('professionalExpertise', false);
  }, [professionalExpertise, notableAchievements, impactofExpertise, props]);

  const receivedExpertiseBlock = (data) => {setProfessionalExpertise(data);};
  const receivedNotableAchievements = (data) => {setNotableAchievements(data);};
  const receivedImpactofExpertise = (data) => {setImpactofExpertise(data);};

  const pressNext = () => {
    props.sendNext("Job offer");
  };

  const saveForm = () => {
    props.onSave();
    toast.success('Your form has been successfully saved !');
  };

  const checkWorkedSameProfession = () =>{
    if (professionalExpertise.workedSameProfession === '' || professionalExpertise.exceptionalExpertise === ''){
      toast.error("Please select options in the blocks below to start !");
      setErrors(prevErrors =>({
        ...prevErrors,
        option: "Please select options  in the blocks below to start !"
      }));
      return false;
    }else if (professionalExpertise.exceptionalExpertise === 'Yes') {
      delete errors.option;
      if (notableAchievements.recognition === 'Yes'){
        if (impactofExpertise.impact !== ''){
          delete errors.option;
          return true;
        }
      }else if (notableAchievements.recognition === 'No'){
        delete errors.option;
        return true;
      } else {
        // toast.error("Please complete all blocks below in the section !");
        setErrors(prevErrors =>({
          ...prevErrors,
          option: "Please complete all blocks below in the section !"
        }));
        return false;
      }
    } else{
      delete errors.option;
      return true;
    }
  }

  const validateSection = () => {
    props.onValidate('professionalExpertise', false);

    if (checkWorkedSameProfession()){
      toast.success('This section has been successfully validated !');
      setErrors({});
      props.onValidate('professionalExpertise', true);
    }else{
      toast.error("Please complete all blocks below in the section !");
      setErrors(prevErrors =>({
        ...prevErrors,
        option: "Please complete all blocks below in the section !"
      }));
      props.onValidate('professionalExpertise', false);
    }
  };

  return (
    <fieldset id="step7">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul>
      <div className="form-card">
        <ProfessionalExpertise sendExpertiseBlock={receivedExpertiseBlock} professionalExpertise={professionalExpertise} />
        {professionalExpertise.exceptionalExpertise ==='Yes' && (
          <div>
            <NotableAchievements sendNotableAchievements={receivedNotableAchievements} notableAchievements={notableAchievements} />
            {notableAchievements.recognition === 'Yes' && (
              <ImpactofExpertise sendImpactofExpertise={receivedImpactofExpertise} impactofExpertise={impactofExpertise} />
            )}
          </div>
        )}
        
        <div className='d-flex justify-content-center'>
          <div className='col-sm-10'>
            <div className='d-flex justify-content-between'>
              <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save the form" onClick={saveForm}/>
              <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Next" onClick={pressNext}/>
            </div>
          </div>
        </div>
          <div className='d-flex justify-content-center'>
            <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value="Validate this section" onClick={validateSection}/>
          </div>
      </div>
      <ToastContainer />
    </fieldset>
  );
};

export default ProfessionalExpertiseSection;