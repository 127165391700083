import React, { useState, useEffect } from 'react';
import JobDetailSection from './section_p_experience/JobDetailSection';
import CompanyInfoSection from './section_p_experience/CompanyInfoSection';
import JobSpecificsSection from './section_p_experience/JobSpecificsSection';
import JobAddressSection from './section_p_experience/JobAddressSection';
import { toast, ToastContainer } from 'react-toastify';
import RequiredIndicator from '../../../general/requiredIndicator';

import EnglishQuestion from "../questions/englishQuestions.json";

const ProfessionalExperienceSection = (props) => {
  const [professionalExperience, setProfessionalExperience] = useState(props.professionalExperience.professionalExperience);
  const [allProfessionalExperience, setAllProfessionalExperience] = useState(props.allProfessionalExperience);
  const [jobDetail, setJobDetail] = useState(props.professionalExperience.jobDetail);
  const [companyInfo, setCompanyInfo] = useState(props.professionalExperience.companyInfo);
  const [jobSpecifics, setJobSpecifics] = useState(props.professionalExperience.jobSpecifics);
  const [jobAddress, setJobAddress] = useState(props.professionalExperience.jobAddress);
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const { value } = event.target;
    setProfessionalExperience(value);
    const professionalExperienceParent = {
      professionalExperience: value,
      jobDetail,
      // jobDuration,
      companyInfo,
      jobSpecifics,
      jobAddress
    };
    props.sendProfessionalExperience(professionalExperienceParent);
    setErrors({});
  };

  useEffect(() => {
    const professionalExperienceParent = {
        professionalExperience,
        jobDetail,
        companyInfo,
        jobSpecifics,
        jobAddress
    };
    props.sendProfessionalExperience(professionalExperienceParent);
    props.sendAllProfessionalExperience(allProfessionalExperience);
    props.onValidate('professionalExperience', false);
  }, [allProfessionalExperience, professionalExperience, 
    jobDetail, companyInfo, jobSpecifics, 
    jobAddress, props]);

  const handleAllProfession = () => {

    if (check()) {
      setErrors({});
      const professionalExperienceParent = {
        jobDetail,
        companyInfo,
        jobSpecifics,
        jobAddress
      };
      setAllProfessionalExperience([...allProfessionalExperience, professionalExperienceParent]);
    
      /* setJobDetail({
        jobTitle: '',
        selfEmployed: '',
        currentJob: '',
        startDate: '',
        endDate: '',
        authorization: '',
        obtainingAuthorization: '',
        jobInAIOrIT: ''
      });
  
      setCompanyInfo({
        companyName: '',
        hoursPerWeek: '',
        hourlyRate: '',
      });
      setJobSpecifics({
        paydayLoans: '',
        explicitContent: '',
        realEstate: ''
      });
      setJobAddress({
        country: '',
        provinceState: '',
        city: '',
        streetNumber: '',
        postalCode: ''
      }); */
    }
  }

  const receivedJobDetail = (data) => {setJobDetail(data);};
  const receivedCompanyInfo = (data) => {setCompanyInfo(data);};
  const receivedJobSpecifics = (data) => {setJobSpecifics(data);};
  const receivedJobAddress = (data) => {setJobAddress(data);};

  const pressNext = () => {
    props.sendNext("Professional Expertise");
  };

  const saveForm = () => {
    props.onSave();
    toast.success('Your form has been successfully saved !')
  };

  const checkJobDetails = () => {
    if ((jobDetail.jobTitle === '' || jobDetail.selfEmployed === ''  || jobDetail.currentJob === ''
       || jobDetail.startDate === '' || jobDetail.jobInAIOrIT === '') || 
       (jobDetail.currentJob === 'No' &&  jobDetail.endDate === '')){
        toast.error("Please complete the Job Details block !");
        setErrors(prevErrors =>({
          ...prevErrors,
          jobDetail: "Please complete the Job Details block !"
        }));
        return false;
    } else if ((jobDetail.isRegulated && jobDetail.authorization === '') || 
      ((jobDetail.isRegulated && jobDetail.authorization === 'No') && jobDetail.obtainingAuthorization === '') ) {
      toast.error("Please complete the Job Details block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        jobDetail: "Please complete the Job Details block !"
      }));
      return false;
    }
    else{
      delete errors.jobDetail;
      return true;
    }
  }

  const checkCompanyInformation = () => {
    if (companyInfo.companyName === '' || companyInfo.hoursPerWeek === '' || companyInfo.hourlyRate === '') {
      toast.error("Please complete the Company information block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        companyInfo: "Please complete the Company information block !"
      }));
      return false;
    }else {
      delete errors.companyInfo;
      return true;
    }
  }

  const checkJobSpecifications = () => {
    if (jobSpecifics.paydayLoans === '' || jobSpecifics.explicitContent === '' || jobSpecifics.realEstate === '') {
      toast.error("Please complete the Job Specifications block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        jobSpecifications: "Please complete the Job Specifications block !"
      }));
      return false;
    }else {
      delete errors.jobSpecifications;
      return true;
    }
  }

  const checkJobAddress = () => {
    if (jobAddress.country === '' || jobAddress.provinceState === '' || 
      jobAddress.city === '') {
      toast.error("Please complete the Job Address block !");
      setErrors(prevErrors =>({
        ...prevErrors,
        jobAddress: "Please complete the Job Address block !"
      }));
      return false;
    }else {
      delete errors.jobAddress;
      return true;
    }
  }

  const check = () =>{
    if (checkJobDetails() && checkCompanyInformation() && 
    checkJobSpecifications() && checkJobAddress()){
      return true
    } else {
      return false
    }
  }

  const validateSection = () => {
    props.onValidate('professionalExperience', false);
    if (professionalExperience === 'No') {
      props.onValidate('professionalExperience', true);
      toast.success('This section has been successfully validated !');
      setErrors({});
    } else if (professionalExperience === 'Yes') {
      if (allProfessionalExperience.length !== 0) {
        props.onValidate('professionalExperience', true);
        toast.success('This section has been successfully validated !');
        setErrors({});
      } else{
        toast.error("Please, you should add at least one experience !");
        setErrors(prevErrors =>({
          ...prevErrors,
          option: "Please, you should add at least one experience !"
        }));
        props.onValidate('professionalExperience', false);
      }
    } else {
      toast.error("Please select an option to start !");
      setErrors(prevErrors =>({
        ...prevErrors,
        option: "Please select an option to start !"
      }));
      props.onValidate('professionalExperience', false);
    }
  };

  return (
    <fieldset id="step6">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul> 
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
          <p className='text-bold text-center'>Professional Experience</p>
          <div className='row p-2'>
            <p className="col-form-label text-center">{EnglishQuestion.professionalExperience.professionalExperience}<RequiredIndicator /></p>
            <div className="form-check ml-5">
              <input 
                type="radio"
                className="form-check-input"
                value="Yes"
                onChange={handleChange}
                checked={professionalExperience === 'Yes'}
              />
              <p className="form-check-label">Yes</p>
            </div>
            <div className="form-check ml-5">
              <input 
                type="radio"
                className="form-check-input"
                value="No"
                onChange={handleChange}
                checked={professionalExperience === 'No'}
              />
              <p className="form-check-label">No</p>
            </div>
          </div>
          {professionalExperience === 'Yes' && (
            <div>
              <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px 15px 0px 0px' }}>
                <div className='row'>
                  <div className='col-sm col-6 mt-2 text-center'>CNP</div>
                  <div className='col-sm col-6 mt-2 text-center'>Company Name</div>
                  <div className='col-sm col-6 mt-2 text-center'>Start Date</div>
                  <div className='col-sm col-6 mt-2 text-center'>Is Current Job</div>
                  <div className='col-sm col-6 mt-2 text-center'>Actions</div>
                </div>
              </div>
              <div>
                {allProfessionalExperience && allProfessionalExperience.length > 0 ? (
                  allProfessionalExperience.map((profession, index) => (
                    <div className='card-header p-1 mb-1 shadow bg-white' style={{ borderRadius: '15px' }} key={index}>
                      <div className='row'>
                        <div className='col-sm col-6 mt-2 text-center'>{profession.jobDetail.jobTitle}</div>
                        <div className='col-sm col-6 mt-2 text-center'>{profession.companyInfo.companyName}</div>
                        <div className='col-sm col-6 mt-2 text-center'>{profession.jobDetail.startDate}</div>
                        <div className='col-sm col-6 mt-2 text-center'>{profession.jobDetail.currentJob ? "Yes" : "No"}</div>
                        <div className='col-sm col-6 mt-2 text-center'>
                          <button
                            className="btn btn-danger"
                            onClick={() => setAllProfessionalExperience(allProfessionalExperience.filter((_, i) => i !== index))}
                          >
                            <i className="nav-icon fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='text-center'>No experience on file</div>
                )}
              </div>
            </div>          
          )}
        </div>
        {professionalExperience === 'Yes' && (
            <div>
              <JobAddressSection error={errors.jobAddress ? errors.jobAddress : ''}
                sendJobAddress={receivedJobAddress} jobAddress={jobAddress} />
              <JobDetailSection error={errors.jobDetail ? errors.jobDetail : ''}
                evaluationCountry={props.evaluationCountry}
              sendJobDetail={receivedJobDetail} jobDetail={jobDetail}  />
              {/* 
              <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                <JobDurationSection sendJobDuration={receivedJobDuration} jobDuration={jobDuration} jobDetail={jobDetail}
                />
              </div>*/}
              <CompanyInfoSection error={errors.companyInfo ? errors.companyInfo : ''}
                sendCompanyInfo={receivedCompanyInfo} companyInfo={companyInfo} />
              <JobSpecificsSection error={errors.jobSpecifications ? errors.jobSpecifications : ''}
                sendJobSpecifics={receivedJobSpecifics} jobSpecifics={jobSpecifics}  />
            </div>
        )}
        {professionalExperience === 'Yes' && (
                <div className='d-flex justify-content-center mt-2 m-2'>
                  <button type='button' className='col-sm-10 btn btn-block btn-secondary rounded-pill' onClick={handleAllProfession}>Save and Add another</button>
                </div>
              )}
        <div className='d-flex justify-content-center'>
          <div className='col-sm-10'>
            <div className='d-flex justify-content-between'>
              <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save the form" onClick={saveForm}/>
              <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Next" onClick={pressNext}/>
            </div>
          </div>
          </div>
          <div className='d-flex justify-content-center'>
            <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value="Validate this section" onClick={validateSection}/>
          </div>
        </div>
      <ToastContainer />
    </fieldset>
  );
};

export default ProfessionalExperienceSection;
