import api from '../api';

let isLoading = false;

export const fetchSoloData = async () => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-all-solo`);
        return response;

    } catch (error){
        console.error('Error when getting all solo data:', error);
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const getSoloData = async (solo) => {

    if (isLoading) return null;
    isLoading = true;

    try{
        const response = await api.get(`/flous/get-solo/${solo}`);
        return response;

    } catch (error){
        console.error('Error when getting one solo data:', error);
        throw error;
    } finally {
        isLoading = false;
    }  
};

export const activateSolo = async (solo) => {

    try{
        const response = await api.get(`/flous/activate-solo/${solo}`);
        return response;

    } catch (error){
        console.error('Error when activate solo:', error);
        throw error;
    }   
};

export const deactivateSolo = async (solo) => {

    try{
        const response = await api.get(`/flous/deactivate-solo/${solo}`);
        return response;

    } catch (error){
        console.error('Error when deactivate solo:', error);
        throw error;
    }   
};