import React, { useEffect, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import { getFormOwner } from '../../../../../services/users/FormsServices';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const ContactInfo = (props) => {
  const [contactInfo, setContactInfo] = useState(props.contactInfo);
  const [owner, setOwner] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    dateOfBirth: ''
  })

  const [loading, setLoading] = useState(false);

  useEffect(() => {

    const handleOwner = async(code) => {
      setLoading(true);
      try{
        const response = await getFormOwner(code);
  
        if (response.status === 200){
          console.log(response.data)
          setOwner(
            {
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              email: response.data.email,
              phone: response.data.phone && response.data.phone.trim() !== '' 
              ? response.data.phone 
              : contactInfo.phone,
              dateOfBirth: response.data.dateOfBirth
            });
          const updatedFormData = {
            ...contactInfo,
            email: response.data.email,
            phone: response.data.phone && response.data.phone.trim() !== '' 
              ? response.data.phone 
              : contactInfo.phone
          }
          setContactInfo(updatedFormData);
          props.sendContactInfo(updatedFormData);
        }
  
        // await new Promise((resolve) => setTimeout(resolve, 500));
        setLoading(false);
  
      }  catch (error){
        setLoading(false);
        if (error.response && [400, 500].includes(error.response.status)){
          // toast.error(error.response.data.error);
        }
      }
    };

    handleOwner(props.formCode);
    
  }, []);

  const handleEmailChange = (e) => {
    const {name, value} = e.target;
    const updatedFormData = {
      ...contactInfo,
      email: value,
    }
    setContactInfo(updatedFormData);
    props.sendContactInfo(updatedFormData);
  };

  const handleChange = (value) => {
    const updatedFormData = {
      ...contactInfo,
      phone: value,
    }
    setContactInfo(updatedFormData);
    props.sendContactInfo(updatedFormData);
  };

  return (
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Contact Information</p>
          <div className='row p-2'>
            <p htmlFor="email" className="col-sm-4 col-form-label">{EnglishQuestion.personalInfo.email}<RequiredIndicator /></p>
            <div className="col-sm-8">
              <input
                type="email"
                className="form-control rounded-pill flous-input-color"
                id="email"
                name="email"
                placeholder="Email Address"
                value={props.actor === 'Principal'? owner.email: contactInfo.email}
                onChange={handleEmailChange}
              />
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="phone" className="col-sm-4 col-form-label">{EnglishQuestion.personalInfo.phone}<RequiredIndicator /></p>
            <div className="col-sm-8">
            <PhoneInput 
              defaultCountry={'ca'} 
              value={props.actor === 'Principal' ? owner.phone : contactInfo.phone} 
              onChange={handleChange}
              containerStyle={{ width: '100%' }}
              inputStyle={{ 
                width: '100%', 
                borderRadius: '0px 20px 20px 0px' // Vous pouvez ajuster la valeur pour plus ou moins d'arrondi
              }}
            />
            </div>
          </div>
        </div>
      </div>
  );
}

export default ContactInfo;