import './App.css';
import React, { Suspense, useContext } from 'react';
import ProtectedRoute from './components/ProtectedRoute';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { AuthContext } from './contexts/AuthContext';

import BaseLayout from './components/Layout/BaseLayout';

import AuthLayoutPage from './components/auth/AuthBaseLayout';
import WelcomePage from './components/auth/WelcomePage';
import LoginPage from './components/auth/LoginPage';
import ChooseActorPage from './components/auth/ChooseActorPage';
import ForgotPasswordPage from './components/auth/ForgotPasswordPage';
import CreateAccountPage from './components/auth/CreateAccountPage';
import ResetPassword from './components/auth/ResetPassword';

import FlousFiles from './components/client/FlousFilePage';
import UserDashboard from './components/client/UserDashBord';
import ProgramResultsDetails from './components/client/results/ProgramResultsDetails';
import AgentProfile from './components/agent/agentProfile';
import UserResultsDetails from './components/client/results/UserResultsDetail';
import AgentCustomers from './components/agent/AgentCustomers';
import AgentCustomerDetail from './components/agent/AgentCustomersDetail';
import AgentCustomersFiles from './components/agent/AgentCustomersFiles';
import FilesDetails from './components/agent/AgentFilesResults';
import AgentProgramDetail from './components/agent/AgentProgramDetail';
import AgentCustomersRequest from './components/agent/AgentCustomersRequest';
import AgentDashboard from './components/agent/AgentDashbord';
import EntrepriseDashboard from './components/enterprise/EntrepriseDashboard';
import EntrepriseProfile from './components/enterprise/EnterpriseProfile';
import EntrepriseAdvisors from './components/enterprise/EntrepriseAdvisors';
import EntrepriseAdvisorsDetail from './components/enterprise/EntrepriseAdvisorsDetail';
import EntrepriseCustomers from './components/enterprise/EntrepriseCustomers';
import EntrepriseCustomersList from './components/enterprise/EntrepriseCustomersList';
import EntrepriseCustomerDetail from './components/enterprise/EntrepriseCustomersDetail';
import EntrepriseCustomersRequest from './components/enterprise/EntrepriseCustomersRequest';
import FlousDashboard from './components/flous/FlousDashboard';
import FlousCompanies from './components/flous/FlousCompagnies';
import FlousCompagniesDetail from './components/flous/FlousCompagniesDetail';
import FlousSelfSufficient from './components/flous/FlousSelfSufficients';
import FlousSelfSufficientList from './components/flous/FlousSelfSufficientsList';
import FlousCompagniesList from './components/flous/FlousCompagniesList';
import UserProfilePage from './components/client/userProfilePage';
import UserForms from './components/client/forms/userFormsPage';
import UserResults from './components/client/results/userResultsPage';
import FlousFilesDetail from './components/client/FlousFileDetail';
import NotFound from './components/NotFound';
import CreateEditFormPage from './components/client/forms/CreateEditFormPage';
import FlousInboxPage from './components/flous/FlousInboxPage';
import FlousCustomersMessagesPage from './components/flous/FlousCustomersMessagesPage';
import FlousCustomersOldMessagesPage from './components/flous/FlousCustomersOldMessagesPage';
import UserMessagesPage from './components/profile/userMessagesSection';
import FlousDemoRequestPage from './components/flous/FlousDemoRequestPage';
import DataFileUpload from './components/client/forms/saveData/savePrincipalStateData';
import FlousBlogsPage from './components/flous/FlousBlogsPage';
import FlousCreateBlogsPage from './components/flous/modal/FlousCreateBlogPage';
import EntrepriseAdvisorsCustomers from './components/enterprise/EnterpriseAdvisorCustomers';
import NotificationComponent from './components/general/NotificationComponent';



const App = () =>{

  const { user } = useContext(AuthContext);


  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            
            <Route path="*" element={<Navigate to="/notfound" replace />} />
            <Route path="/notfound" element={<NotFound />} />
            
            <Route path="/notifications" element={<NotificationComponent /> }  />
            
            <Route path="/" element={<AuthLayoutPage children={ <WelcomePage />} /> }  />
            <Route path="/login" element={<AuthLayoutPage children={ <LoginPage />} /> }  />
            <Route path="/chooseactor" element={<AuthLayoutPage children={ <ChooseActorPage />} /> }  />
            <Route path="/forgotpassword" element={<AuthLayoutPage children={ <ForgotPasswordPage />} /> }  />
            <Route path="/auth/reset-password/:uidb64/:token" element={<AuthLayoutPage children={ <ResetPassword />} /> }  />
            <Route path="/auth/reset_password" element={<AuthLayoutPage children={ <ResetPassword />} /> }  />
            <Route path="/createaccount/:actor" element={<AuthLayoutPage children={ <CreateAccountPage />} /> }  />

            <Route element={<ProtectedRoute role="Customer" userData={user} selfAnalyst={false} typePackage={'Other'} />}>
              <Route path="/client" element={<BaseLayout children={ <UserForms/>} u={ "client"} p={ "forms"} />} />
              <Route path="/client/profile" element={<BaseLayout children={ <UserProfilePage/>}  u={ "client"} p={ "profile"} />} />
              <Route path="/client/forms" element={<BaseLayout children={ <UserForms/>} u={ "client"} p={ "forms"} />} />
              <Route path="/client/form/:newform/:rawSurveyCode/" element={<BaseLayout children={ <CreateEditFormPage />} u={ "client"} p={ "forms"} />} />
              <Route path="/client/detail/:pageType/:code" element={<BaseLayout children={ <UserResultsDetails />} u={ "client"} p={""} />} />
            </Route>

            <Route element={<ProtectedRoute role="Customer" userData={user} selfAnalyst={true} typePackage={'Other'}/>}>
              <Route path="/user" element={<BaseLayout children={ <UserForms/>} u={ "user"} p={ "forms"} />} />
              <Route path="/user/dashboard" element={<BaseLayout children={ <UserDashboard/>} u={ "user"} p={ "dashbord"} />} />
              <Route path="/user/profile" element={<BaseLayout children={ <UserProfilePage/>}  u={ "user"} p={ "profile"} />} />
              <Route path="/user/forms" element={<BaseLayout children={ <UserForms/>} u={ "user"} p={ "forms"} />} />
              <Route path="/user/form/:newform/:rawSurveyCode/" element={<BaseLayout children={ <CreateEditFormPage />} u={ "user"} p={ "forms"} />} />
              {/*<Route path="/user/form/:rawSurveyCode" element={<BaseLayout children={ <FormDetailPage />} u={ "user"} p={ "forms"}/>} />*/}
              <Route path="/user/asking" element={<BaseLayout children={ <FlousFiles/>} u={ "user"} p={ "asking"} />} />
              <Route path="/user/asking/:id" element={<BaseLayout children={ <FlousFilesDetail/>} u={ "user"} p={ "asking"} />} />
              <Route path="/user/results" element={<BaseLayout children={ <UserResults/>} u={ "user"} p={ "results"} />} />
              <Route path="/user/detail/:pageType/:code" element={<BaseLayout children={ <UserResultsDetails />} u={ "user"} p={""} />} />
              <Route path="/user/result/detail/:scenario_code" element={<BaseLayout children={ <ProgramResultsDetails/>} u={ "user"} p={ "results"} />} />

              <Route path="/user/inbox" element={<BaseLayout children={ <UserMessagesPage user={"user"}/>} u={ "user"} p={ "inbox"} />} />
              <Route path="/user/inbox/:msg" element={<BaseLayout children={ <UserMessagesPage user={"user"}/>} u={ "user"} p={ "inbox"} />} />
            </Route>

            <Route element={<ProtectedRoute role="Advisor" userData={user} selfAnalyst={false} typePackage={'Other'} />}>
              <Route path="/agent" element={<BaseLayout children={ <AgentDashboard/>} u={ "agent"} p={ "dashbord"} />} />
              <Route path="/agent/dashboard" element={<BaseLayout children={ <AgentDashboard/>} u={ "agent"} p={ "dashbord"} />} />
              <Route path="/agent/profile" element={<BaseLayout children={ <AgentProfile/>} u={ "agent"} p={ "profile"} />} />
              <Route path="/agent/customers" element={<BaseLayout children={ <AgentCustomers/>} u={ "agent"} p={ "customers"} />} />
              <Route path="/agent/customer/:advisorCode/:customerCode" element={<BaseLayout children={ <AgentCustomerDetail/>} u={ "agent"} p={ "customers"} />} />

              <Route path="/agent/customer/form/:newform/:rawSurveyCode" element={<BaseLayout children={ <CreateEditFormPage />} u={ "agent"} p={ "customers"} />} />
              <Route path="/agent/customer/view/:pageType/:code" element={<BaseLayout children={ <UserResultsDetails />} u={ "agent"} p={""} />} />
              <Route path="/agent/customer/view/result/scenario/:scenario_code" element={<BaseLayout children={ <ProgramResultsDetails/>} u={ "agent"} p={ "results"} />} />
              
              {/*
              <Route path="/agent/customers/:id/:pageType/:code" element={<BaseLayout children={ <UserResultsDetails/>} u={ "agent"} p={ "customers"} />} />

              
              <Route path="/agent/files" element={<BaseLayout children={ <AgentCustomersFiles/>} u={ "agent"} p={ "files"} />} />
              <Route path="/agent/files/:id" element={<BaseLayout children={ <FilesDetails/>} u={ "agent"} p={ "files"} />} />
              <Route path="/agent/files/:fileid/prog/:progid" element={<BaseLayout children={ <AgentProgramDetail/>} u={ "agent"} p={ "files"} />} />
              <Route path="/agent/customers/request" element={<BaseLayout children={ <AgentCustomersRequest/>} u={ "agent"} p={ "request"} />} />
              */}

              <Route path="/agent/inbox" element={<BaseLayout children={ <UserMessagesPage user={"agent"}/>} u={ "agent"} p={ "inbox"} />} />
              <Route path="/agent/inbox/:msg" element={<BaseLayout children={ <UserMessagesPage user={"agent"}/>} u={ "agent"} p={ "inbox"} />} />
            </Route>

            <Route element={<ProtectedRoute role="AssociateAdmin" userData={user} selfAnalyst={false} typePackage={'Professional'} />}>
              <Route path="/professional" element={<BaseLayout children={ <AgentDashboard/>} u={ "professional"} p={ "dashbord"} />} />
              <Route path="/professional/dashboard" element={<BaseLayout children={ <AgentDashboard/>} u={ "professional"} p={ "dashbord"} />} />
              <Route path="/professional/profile" element={<BaseLayout children={ <EntrepriseProfile/>} u={ "professional"} p={ "profile"} />} />
              <Route path="/professional/customers" element={<BaseLayout children={ <AgentCustomers/>} u={ "professional"} p={ "customers"} />} />
              <Route path="/professional/customer/:code" element={<BaseLayout children={ <EntrepriseCustomerDetail />} u={ "professional"} p={ "customers"} />} />
              <Route path="/professional/customer/form/:newform/:rawSurveyCode" element={<BaseLayout children={ <CreateEditFormPage />} u={ "professional"} p={ "customers"} />} />
              <Route path="/professional/customer/view/:pageType/:code" element={<BaseLayout children={ <UserResultsDetails />} u={ "professional"} p={""} />} />
              <Route path="/professional/customer/view/result/program/:scenario_code" element={<BaseLayout children={ <ProgramResultsDetails/>} u={ "agent"} p={ "results"} />} />

              <Route path="/professional/customers/request" element={<BaseLayout children={ <EntrepriseCustomersRequest/>} u={ "professional"} p={ "request"} />} />

              <Route path="/professional/inbox" element={<BaseLayout children={ <UserMessagesPage user={"enterprise"}/>} u={ "professional"} p={ "inbox"} />} />
              <Route path="/professional/inbox/:msg" element={<BaseLayout children={ <UserMessagesPage user={"enterprise"}/>} u={ "professional"} p={ "inbox"} />} />
            </Route>

            <Route element={<ProtectedRoute role="AssociateAdmin" userData={user} selfAnalyst={false} typePackage={'Enterprise'} />}>
              <Route path="/enterprise" element={<BaseLayout children={ <EntrepriseDashboard/>} u={ "enterprise"} p={ "dashbord"} />} />
              <Route path="/enterprise/dashboard" element={<BaseLayout children={ <EntrepriseDashboard/>} u={ "enterprise"} p={ "dashbord"} />} />
              <Route path="/enterprise/profile" element={<BaseLayout children={ <EntrepriseProfile/>} u={ "enterprise"} p={ "profile"} />} />
              <Route path="/enterprise/advisors" element={<BaseLayout children={ <EntrepriseAdvisors/>} u={ "enterprise"} p={ "advisors"} />} />
              <Route path="/enterprise/advisor/:code" element={<BaseLayout children={ <EntrepriseAdvisorsDetail/>} u={ "enterprise"} p={ "advisors"} />} />
              <Route path="/enterprise/advisor/:code/customers" element={<BaseLayout children={ <EntrepriseAdvisorsCustomers/>} u={ "enterprise"} p={ "advisors"} />} />
              <Route path="/enterprise/customers" element={<BaseLayout children={ <EntrepriseCustomers/>} u={ "enterprise"} p={ "customers"} />} />
              <Route path="/enterprise/customers/list" element={<BaseLayout children={ <EntrepriseCustomersList/>} u={ "enterprise"} p={ "customers"} />} />
              <Route path="/enterprise/customer/:code" element={<BaseLayout children={ <EntrepriseCustomerDetail/>} u={ "enterprise"} p={ "customers"} />} />
              <Route path="/enterprise/customer/form/:newform/:rawSurveyCode" element={<BaseLayout children={ <CreateEditFormPage />} u={ "enterprise"} p={ "customers"} />} />
              <Route path="/enterprise/customer/view/:pageType/:code" element={<BaseLayout children={ <UserResultsDetails />} u={ "enterprise"} p={""} />} />
              <Route path="/enterprise/customer/view/result/scenario/:scenario_code" element={<BaseLayout children={ <ProgramResultsDetails/>} u={ "agent"} p={ "results"} />} />

              <Route path="/enterprise/customers/request" element={<BaseLayout children={ <EntrepriseCustomersRequest/>} u={ "enterprise"} p={ "request"} />} />

              <Route path="/enterprise/inbox" element={<BaseLayout children={ <UserMessagesPage user={"enterprise"}/>} u={ "enterprise"} p={ "inbox"} />} />
              <Route path="/enterprise/inbox/:msg" element={<BaseLayout children={ <UserMessagesPage user={"enterprise"}/>} u={ "enterprise"} p={ "inbox"} />} />
            </Route>

            <Route element={<ProtectedRoute role="Flous" userData={user} selfAnalyst={false}  typePackage={'Other'} />}>
              <Route path="/flous" element={<BaseLayout children={ <FlousDashboard/>} u={ "flous"} p={ "dashbord"} />} />
              <Route path="/flous/dashboard" element={<BaseLayout children={ <FlousDashboard/>} u={ "flous"} p={ "dashbord"} />} />
              <Route path="/flous/compagnies" element={<BaseLayout children={ <FlousCompanies/>} u={ "flous"} p={ "compagnies"} />} />
              <Route path="/flous/compagnies/list" element={<BaseLayout children={ <FlousCompagniesList/>} u={ "flous"} p={ "compagnies"} />} />
              <Route path="/flous/company/detail/:code" element={<BaseLayout children={ <FlousCompagniesDetail/>} u={ "flous"} p={ "compagnies"} />} />
              <Route path="/flous/self" element={<BaseLayout children={ <FlousSelfSufficient/>} u={ "flous"} p={ "self"} />} />
              <Route path="/flous/self/list" element={<BaseLayout children={ <FlousSelfSufficientList/>} u={ "flous"} p={ "self"} />} />

              <Route path="/flous/inbox" element={<BaseLayout children={ <FlousInboxPage/>} u={ "flous"} p={ "inbox"} />} />
              <Route path="/flous/inbox/message/:id" element={<BaseLayout children={ <FlousCustomersMessagesPage/>} u={ "flous"} p={ "inbox"} />} />
              <Route path="/flous/inbox/message/old/:id" element={<BaseLayout children={ <FlousCustomersOldMessagesPage/>} u={ "flous"} p={ "inbox"} />} />

              <Route path="/flous/demorequest" element={<BaseLayout children={ <FlousDemoRequestPage/>} u={ "flous"} p={ "demo"} />} />

              <Route path="/flous/customer/:advisorCode/:customerCode" element={<BaseLayout children={ <AgentCustomerDetail/>} u={ "flous"} p={ "self"} />} />

              <Route path="/flous/save-data-structure" element={<DataFileUpload />} />

              <Route path="/flous/blogs" element={<BaseLayout children={ <FlousBlogsPage/>} u={ "flous"} p={ "blogs"} />} />
              <Route path="/flous/blogs/create" element={<BaseLayout children={ <FlousCreateBlogsPage/>} u={ "flous"} p={ "blogs"} />} />
            </Route>

          </Routes>
      
      </Suspense>
    </Router>
  );
}

export default App;
