import React, { useEffect, useState } from 'react';
import IdentificationSection from './section_pi/IdentificationSection';
import PersonalDetailSection from './section_pi/PersonnalDetailSection';
import PreferredLanguage from './section_pi/PreferredLanguageSection';
import ContactInfo from './section_pi/ContactInfoSection';
import AddInfoSection from './section_pi/AddInfoSection';
import { toast } from 'react-toastify';
import Select from 'react-select';
import RequiredIndicator from '../../../general/requiredIndicator';

import EnglishQuestion from "../questions/englishQuestions.json"

const PersonalInformation = (props) => {
  const [evaluationCountry, setEvaluationCountry] = useState(props.evaluationCountry);
  const [formTitle, setFormTitle] = useState(props.formTitle);
  const [identification, setIdentification] = useState(props.personalInformation.identification);
  const [personalDetail, setPersonalDetail] = useState(props.personalInformation.personalDetail);
  const [preferredLanguage, setPreferredLanguage] = useState(props.personalInformation.preferredLanguage);
  const [contactInfo, setContactInfo] = useState(props.personalInformation.contactInfo);
  const [addInfo, setAddInfo] = useState(props.personalInformation.addInfo);
  const [errors, setErrors] = useState({});

  const countryOptions = [
    { value: '', label: 'Please select an option' },
    { value: 'Canada', label: 'Canada' },
    // { value: 'New Zealand', label: 'New Zealand' },
    // { value: 'Australia', label: 'Australia' }
  ];

  const handleCountryChange = (event) => {
    const {value} = event.target
    setEvaluationCountry(value)
    setErrors({});
  };

  const handleFormTitleChange = (event) => {
    const {value} = event.target
    setFormTitle(value);
    setErrors({});
  };

  useEffect(()=>{
    props.sendFormTitle(formTitle);
    props.sendevaluationCountry(evaluationCountry);
    props.onValidate('personalInformation', false);
  }, [evaluationCountry, formTitle, props])

  const pressNext = ()=> {
    props.sendNext("Family Members");
  };

  const saveForm = () => {
    props.onSave();
    toast.success('Your form has been successfully saved !')
  };

  const checkIdentification = () =>{
    if (identification.firstname === '' || identification.lastname === '' ||
       identification.dateofbirth === '' || identification.countryofbirth === '' || identification.cityofbirth === '') {
        setErrors(prevErrors =>({
          ...prevErrors,
          identification: "Please complete the Identification block !"
        }));
        toast.error("Please complete the Identification block !");
        return false;
       }else {
        delete errors.identification;
        return true;
       }
  }

  const checkPersonalDetail = () =>{
    if (personalDetail.dependantChildren === '' || personalDetail.genderIdentity === ''|| personalDetail.nationality === '' ||
        (props.actor === 'Principal' && personalDetail.maritalStatus === '')
      ) {
        toast.error("Please complete the Personal Detail block !");
        setErrors(prevErrors =>({
          ...prevErrors,
          personalDetail: "Please complete the Personal Detail block !"
        }));
        return false;
    } else {
      delete errors.personalDetail;
      return true;
    }
  }

  const checkContactInfo = () =>{
    if (contactInfo.email === '' || contactInfo.phone === '') {
        toast.error("Please complete the Contact Informations block !");
        setErrors(prevErrors =>({
          ...prevErrors,
          contactInfo: "Please complete the Contact Informations block !"
        }));
        return false;
       }else {
        delete errors.contactInfo;
        return true;
       }
  }

  const checkAddInfo = () =>{
    if (addInfo.netWorth === '' || addInfo.provincialNomination === '' || addInfo.earningsHistory === '') {
        toast.error("Please complete the Additional Informations block !");
        setErrors(prevErrors =>({
          ...prevErrors,
          addInfo: "Please complete the Additional Informations block !"
        }));
        return false;
       }else {
        delete errors.addInfo;
        return true;
       }
  }

  const checkPreferredLanguage = () =>{
    if (preferredLanguage === '') {
        toast.error("Please complete the Preferred Language block !");
        setErrors(prevErrors =>({
          ...prevErrors,
          preferredLanguage: "Please complete the Preferred Language block !"
        }));
        return false;
       }else {
        delete errors.preferredLanguage;
        return true;
       }
  }

  const validateSection = () => {

    props.onValidate('personalInformation', false);

    if ((props.actor === 'Partner' && 
        (checkIdentification() && checkPersonalDetail() && checkContactInfo())) || 
      (props.actor === 'Principal' && 
        (checkIdentification() && checkPersonalDetail() &&
        checkPreferredLanguage() && checkContactInfo() && checkAddInfo()
      ))){
        props.onValidate('personalInformation', true);
        toast.success('This section has been successfully validated !');
        setErrors({});
    } else {
      props.onValidate('personalInformation', false);
    }
    
  };
  
  useEffect(() => {
    const personalInformation = {
      identification,
      personalDetail,
      preferredLanguage,
      contactInfo,
      addInfo,
    }
    props.sendPersonalInformation(personalInformation); 
    // props.onValidate('personalInformation', false);
  }, [identification, personalDetail, preferredLanguage, contactInfo, addInfo, props]);

  const receivedIdentification = (data) => {setIdentification(data);};
  const receivedPersonalDetail = (data) => {setPersonalDetail(data);};
  const receivedPreferredLanguage = (data) => {setPreferredLanguage(data);};
  const receivedContactInfo = (data) => {setContactInfo(data);};
  const receivedAddInfo = (data) => {setAddInfo(data);};

  return (
    <fieldset id="step1">
      <ul style={{ color: 'red' }}>
        {Object.values(errors).map((value, index) => {
            if (value !== '') {
                return <li key={index}>{value}</li>;
            }
            return null;
        })}
      </ul>
      <div className="form-card">
        {props.actor === 'Principal' && (
          <>
            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
            <p>{EnglishQuestion.personalInfo.country} <RequiredIndicator /></p>
            <Select
              id="country"
              name="evaluation_country"
              classNamePrefix="react-select"
              className="react-select-container rounded-pill flous-input-color"
              value={countryOptions.find(option => option.value === evaluationCountry)}
              onChange={selectedOption => handleCountryChange({ target: { name: 'evaluation_country', value: selectedOption? selectedOption.value: ''} })}
              options={countryOptions}
              placeholder="Please select an option"
              isSearchable
              styles={{
                control: (provided) => ({
                  ...provided,
                  width: '100%',
                  borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                  borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                }),
              }}
            />
          </div>
          
          {props.evaluationCountry !== '' && (
            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
              <p>{EnglishQuestion.personalInfo.title}<RequiredIndicator /></p>
              <input 
                type="text" 
                className="form-control rounded-pill flous-input-color" 
                id="formTitle" 
                name="formTitle" 
                placeholder="Please enter your form title here !"
                value={props.formTitle}
                onChange={handleFormTitleChange}
              />
            </div>
          )}
        </>
        )}
        {props.evaluationCountry === '' ? <div className='h1'>Please select an option</div> :
            <div>
                < IdentificationSection error={errors.identification ? errors.identification : ''}
                sendIdentification={receivedIdentification} 
                identification={identification} actor={props.actor} 
                formCode={props.formCode}
                />
                < PersonalDetailSection  error={errors.personalDetail ? errors.personalDetail : ''}
                sendPersonalDetail={receivedPersonalDetail} 
                personalDetail={personalDetail} actor={props.actor} 
                />
                < ContactInfo  error={errors.contactInfo ? errors.contactInfo : ''}
                  sendContactInfo={receivedContactInfo} 
                  contactInfo={contactInfo} actor={props.actor}
                  formCode={props.formCode}
                />
                {props.actor === 'Principal' && (
                  <>
                  < PreferredLanguage  error={errors.preferredLanguage ? errors.preferredLanguage : ''}
                  sendPreferredLanguage={receivedPreferredLanguage} 
                  preferredLanguage={preferredLanguage} 
                  />
                  < AddInfoSection  error={errors.addInfo ? errors.addInfo : ''}
                  evaluationCountry={ props.evaluationCountry } 
                  sendAddInfo={receivedAddInfo} addInfo={addInfo}
                  />
                  </>
                )}
                <div className='d-flex justify-content-center'>
                  <div className='col-sm-10'>
                    <div className='d-flex justify-content-between'>
                      <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save the form" onClick={saveForm}/>
                      <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Next" onClick={pressNext}/>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value="Validate this section" onClick={validateSection}/>
                </div>
            </div>

         }
        
      </div>
    </fieldset>
  );
};

export default PersonalInformation;
