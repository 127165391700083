import React, { useEffect, useState } from 'react';
import TopMenu from './TopMenu';
import LeftMenu from './LeftMenu';
import Footer from './Footer';
import { useOutletContext, useParams } from 'react-router-dom';

const BaseLayout = ({ children, u, p, onLogout }) => {
  const [userprofile, setUserprofile] = useState(u); // ou "agent", "entreprise", "flous"
  const [page, setPage] = useState(""); 
  const { pageType } = useParams();

  useEffect(() =>{
    if (p === "") {
      if (pageType==="form"){
        setPage("forms");
      } else if (pageType==="result"){
        setPage("results");
      }
    } else {
      setPage(p)
    }
  }, [])

  const {user} = useOutletContext();

  return (
    <div className="hold-transition sidebar-mini flous-police-two">
      <div className="wrapper">
        <TopMenu userprofile={userprofile}/>
        <LeftMenu userprofile={userprofile} page={page} onLogout={onLogout} userData={user} />
        <div className="content-wrapper" style={{ backgroundColor: '#FFFFFF' }}>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default BaseLayout;
