import React, { useState, useEffect } from 'react';
import OwnerShipDetailSection from './section_ee/yes/OwnerShipDetailSection';
import CompanyActivitesSection from './section_ee/yes/CompanyActivitiesSection';
import SupportbySpecializedO from './section_ee/yes/SupportbySpecializedOrganizationSection';
import CompanyFinancySection from './section_ee/yes/CompanyFinancySection';
import RoleAndExperienceSection from './section_ee/yes/RoleandExperienceSection';
import CompanyDetailsYesSection from './section_ee/yes/CompanyDetailYesSection';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InnovativeActivities from './section_ee/yes/InnovativeActivitiesSection';
import RequiredIndicator from '../../../general/requiredIndicator';

import EnglishQuestion from "../questions/englishQuestions.json";
import ManagementExperienceSection from './section_ee/yes/managementExperience';

const EntrepreunarialExperience = (props) => {
  const [entrepreunarialExperience, setFormData] = useState(props.entrepreunarialExperience.entrepreunarialExperience);
  const [ownershipDetail, setOwnershipDetail] = useState(props.entrepreunarialExperience.ownershipDetail);
  const [companyActivites, setCompanyActivites] = useState(props.entrepreunarialExperience.companyActivites);
  const [supportby, setSupportby] = useState(props.entrepreunarialExperience.supportby);
  const [innovativeActivities, setInnovativeActivities] = useState(props.entrepreunarialExperience.innovativeActivities);
  const [companyFinances, setCompanyFinances] = useState(props.entrepreunarialExperience.companyFinances);
  const [roleAndExperience, setRoleAndExperience] = useState(props.entrepreunarialExperience.roleAndExperience);
  const [companyDetails, setCompanyDetails] = useState(props.entrepreunarialExperience.companyDetails);
  const [errors, setErrors] = useState({});

  const [isSameEExperience, setIsSameEExperience] = useState(props.isSameEExperience);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
        ...entrepreunarialExperience,
        [name]: value,
      };
    setFormData(updatedData);
    const entrepreunarialExperienceParent = {
      entrepreunarialExperience: updatedData,
      ownershipDetail,
      companyActivites,
      supportby,
      companyFinances,
      roleAndExperience,
      companyDetails
    };
    props.sendEntrepreunarialExperience(entrepreunarialExperienceParent);
    setErrors({});
  };

  useEffect(() => {
    const entrepreunarialExperienceParent = {
        entrepreunarialExperience,
        ownershipDetail,
        companyActivites,
        supportby,
        innovativeActivities,
        companyFinances,
        roleAndExperience,
        companyDetails
    };
    props.sendEntrepreunarialExperience(entrepreunarialExperienceParent);
    props.onValidate('entrepreunarialExperience', false);
  }, [entrepreunarialExperience, ownershipDetail, companyActivites, supportby, innovativeActivities, companyFinances, roleAndExperience, companyDetails, props]);

  const receivedOwnershipDetail = (data) => {setOwnershipDetail(data);};
  const receivedCompanyActivites = (data) => {setCompanyActivites(data);};
  const receivedSupportby = (data) => {setSupportby(data);};
  const receivedInnovativeActivities = (data) => {setInnovativeActivities(data);};
  const receivedCompanyFinances = (data) => {setCompanyFinances(data);};
  const receivedRoleAndExperience = (data) => {setRoleAndExperience(data);};
  const receivedCompanyDetails = (data) => {setCompanyDetails(data);};

  const pressNext = () => {
    props.sendNext("Summary");
  };
  const sendEE = (event)=> {
    const { name, value } = event.target;
    props.sendIsSameEExperience(value);
    setIsSameEExperience(value);
    setErrors({});
    props.onValidate('entrepreunarialExperience', false);
  };

  const saveForm = () => {
    props.onSave();
    toast.success('Your form has been successfully saved !')
  };

  const checkManagementExperience = () =>{

    if (props.entrepreunarialExperience.ownershipDetail.managementStartDate === '' || props.entrepreunarialExperience.ownershipDetail.managementEndDate === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        ownershipSection: "Please complete the Ownership Details section !"
      }));
      toast.error('Please complete the Ownership Details section !!');
      return false;
    } else {
        delete errors.ownershipSection;
        return true;
    }
  }
  
  const checkOwnerShipDetails = () =>{

    if (props.entrepreunarialExperience.ownershipDetail.companyName === '' ||
      props.entrepreunarialExperience.ownershipDetail.ownershipPercentage === '' || props.entrepreunarialExperience.ownershipDetail.personalEquityInvestment === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        ownershipSection: "Please complete the Ownership Details section !"
      }));
      toast.error('Please complete the Ownership Details section !!');
      return false;
    } else {
        delete errors.ownershipSection;
        return true;
    }
  }

  const checkCompanyActivites = () =>{

    if (props.entrepreunarialExperience.companyActivites.companyActivity === '' || props.entrepreunarialExperience.companyActivites.paydayLoans === '' ||
      props.entrepreunarialExperience.companyActivites.explicitContent === '' || props.entrepreunarialExperience.companyActivites.realEstate === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        companyActivites: "Please complete the Company Activities section !"
      }));
      toast.error('Please complete the Company Activities section !!');
      return false;
    } else {
        delete errors.companyActivites;
        return true;
    }
  }

  const checkSupportBy = () =>{

    if (props.entrepreunarialExperience.supportby.receivedServiceOffer === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        supportby: "Please complete the Support by Specified Organization section !"
      }));
      toast.error('Please complete the Support by Specified Organization section !!');
      return false;
    } else {
        delete errors.supportby;
        return true;
    }
  }

  const checkInnovativeActivities = () =>{

    if (props.entrepreunarialExperience.innovativeActivities.innovative === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        innovativeActivities: "Please complete the Innovative Activities block !"
      }));
      toast.error('Please complete the Innovative Activities block !!');
      return false;
    } else {
        delete errors.innovativeActivities;
        return true;
    }
  }

  const checkCompanyFinances = () =>{

    if (props.entrepreunarialExperience.companyFinances.companyValue === '' || props.entrepreunarialExperience.companyFinances.startupExpenses === '' ||
      props.entrepreunarialExperience.companyFinances.operatingExpenses === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        operatingExpenses: "Please complete the Company Finances section !"
      }));
      toast.error('Please complete the Company Finances section !!');
      return false;
    } else {
        delete errors.operatingExpenses;
        return true;
    }
  }

  const checkRoleAndExperience = () =>{

    if (props.entrepreunarialExperience.roleAndExperience.position === '' || props.entrepreunarialExperience.roleAndExperience.startDate === '' ||
      props.entrepreunarialExperience.roleAndExperience.operatingPeriod === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        roleAndExperience: "Please complete the Role and Experience section !"
      }));
      toast.error('Please complete the Role and Experience section !!');
      return false;
    } else {
        delete errors.roleAndExperience;
        return true;
    }
  }

  const checkCompanyDetails = () =>{

    if (props.entrepreunarialExperience.companyDetails.establishmentDate === '' || // props.entrepreunarialExperience.companyDetails.country === '' ||
      props.entrepreunarialExperience.companyDetails.provinceState === '' || props.entrepreunarialExperience.companyDetails.city === ''){
      setErrors(prevErrors =>({
        ...prevErrors,
        companyDetails: "Please complete the Company Details section !"
      }));
      toast.error('Please complete the Company Details section !!');
      return false;
    } else {
        delete errors.companyDetails;
        return true;
    }
  }

  const checkTakeOverBid = () => {
    if (entrepreunarialExperience.newcompany === "Undertake an existing company" && entrepreunarialExperience.takeoverBid === ""){
      setErrors(prevErrors =>({
        ...prevErrors,
        takeoverBid: "Please complete the Takeover Bid section !"
      }));
      toast.error('Please complete the Takeover Bid section !!');
    }else {
      delete errors.takeoverBid;
      return true
    }
  }

  const bCheck = async () =>{

    const isValid = Object.keys(errors).length === 0;

      if (isValid) {
        props.onValidate('entrepreunarialExperience', true);
        toast.success('This section has been successfully validated !!');
      } else {
        props.onValidate('entrepreunarialExperience', false);
        // toast.error('Please correct the errors above !');
      }
  }

  const check = async () =>{

    props.onValidate('entrepreunarialExperience', false);

    if (entrepreunarialExperience.ownership === ''){
      props.onValidate('entrepreunarialExperience', false);
      toast.error('Please select an option to start !');
    }
    else if (entrepreunarialExperience.ownership === 'No' && entrepreunarialExperience.newcompany === "" ){
      props.onValidate('entrepreunarialExperience', true);
      toast.success('This section has been successfully validated !');

    }
    else if (entrepreunarialExperience.ownership === 'Yes'){

      if (checkManagementExperience() && checkOwnerShipDetails() && checkCompanyActivites() &&
      checkSupportBy() && checkCompanyFinances() && checkRoleAndExperience() && checkCompanyDetails()) {
        await bCheck();
      }
      
    }
    else if (entrepreunarialExperience.ownership === 'No' && entrepreunarialExperience.newcompany !== ""){

      if (checkManagementExperience() && checkTakeOverBid() && checkOwnerShipDetails() && checkCompanyActivites() &&
      checkCompanyFinances() && checkRoleAndExperience() && checkCompanyDetails() && 
      checkSupportBy() && checkInnovativeActivities()) {
        await bCheck();
      }
    }
  }

  const validateSection = async () => {

    if (props.actor === 'Partner'){
        if (props.isSameEExperience === "Yes"){
          props.onValidate('entrepreunarialExperience', true);
          toast.success('This section has been successfully validated !!');
        } else {
          await check();
        }
    }
    else {
      await check();
    }
  };

  const renderPartnerCompanyQuestion = () => (
    <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div className='row p-2'>
        <p className='col-sm-8 col-form-label'>Is this the same company as your partner?</p>
        <div className='col-sm-4'>
          <div className='row'>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input rounded-pill"
                id="isSameEExperienceYes"
                name="isSameEExperience"
                value="Yes"
                onChange={sendEE}
                checked={props.isSameEExperience === "Yes"}
              />
              <label className="form-check-label" htmlFor="isSameEExperienceYes">Yes</label>
            </div>
            <div className="col form-check">
              <input 
                type="radio"
                className="form-check-input rounded-pill"
                id="isSameEExperienceNo"
                name="isSameEExperience"
                value="No"
                onChange={sendEE}
                checked={props.isSameEExperience === "No"}
              />
              <label className="form-check-label" htmlFor="isSameEExperienceNo">No</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <fieldset id="step3">
      <ul style={{ color: 'red' }}>
          {Object.values(errors).map((value, index) => {
              if (value !== '') {
                  return <li key={index}>{value}</li>;
              }
              return null;
          })}
      </ul>
      <ManagementExperienceSection error={errors.ownershipSection ? errors.ownershipSection : ''}
        sendOwnershipDetail={receivedOwnershipDetail} ownershipDetail={ownershipDetail}  />
      <div className="form-card">
          <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
          <p className='text-bold text-center'>Entrepreneurial Experience</p>
          <div className='row p-2'>
            <p className="col-form-label text-center">{EnglishQuestion.entrepreunarialExperience.ownership}<RequiredIndicator /></p>
            <div className="form-check ml-5">
              <input 
                type="radio"
                className="form-check-input"
                name="ownership"
                value="Yes"
                onChange={handleChange}
                checked={entrepreunarialExperience.ownership === "Yes"}
              />
              <p className="form-check-label">Yes</p>
            </div>
            <div className="form-check ml-5">
              <input 
                type="radio"
                className="form-check-input"
                name="ownership"
                value="No"
                onChange={handleChange}
                checked={entrepreunarialExperience.ownership === "No"}
              />
              <p className="form-check-label">No</p>
            </div>
          </div>
        </div>
        {entrepreunarialExperience.ownership ==='Yes' && (
            <div>
                {(props.actor === 'Partner' && props.principalEE.entrepreunarialExperience.ownership === entrepreunarialExperience.ownership) && renderPartnerCompanyQuestion()}
                {(
                  (props.actor === 'Principal') ||
                  (props.actor === 'Partner' && props.principalEE.entrepreunarialExperience.ownership !== entrepreunarialExperience.ownership) ||
                  (props.actor === 'Partner' && props.principalEE.entrepreunarialExperience.ownership === entrepreunarialExperience.ownership && isSameEExperience === 'No')
                ) && (
                  <>
                    <CompanyDetailsYesSection error={errors.companyDetails ? errors.companyDetails : ''}
                       sendCompanyDetails={receivedCompanyDetails} companyDetails={companyDetails} />
                    <OwnerShipDetailSection error={errors.ownershipSection ? errors.ownershipSection : ''}
                       titre={'Ownership Details'}
                      sendOwnershipDetail={receivedOwnershipDetail} ownershipDetail={ownershipDetail}  />
                    <CompanyActivitesSection error={errors.companyActivites ? errors.companyActivites : ''}
                       sendCompanyActivities={receivedCompanyActivites} companyActivites={companyActivites} />
                    <SupportbySpecializedO error={errors.supportby ? errors.supportby : ''}
                      evaluationCountry={props.evaluationCountry}
                      sendSupportBySpecializedO={receivedSupportby} supportby={supportby}   />
                    <CompanyFinancySection error={errors.operatingExpenses ? errors.operatingExpenses : ''}
                       sendCompanyFinances={receivedCompanyFinances} companyFinances={companyFinances} />
                    <RoleAndExperienceSection error={errors.roleAndExperience ? errors.roleAndExperience : ''}
                       sendRoleAndExperience={receivedRoleAndExperience} roleAndExperience={roleAndExperience} />
                  </>
                )}
            </div>
        )}
        {entrepreunarialExperience.ownership ==='No' && (
          <div>
            <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
            <p className='text-bold text-center'>Entrepreneurial Experience</p>
            <div className='p-2'>
                <p className="col-form-label">{EnglishQuestion.entrepreunarialExperience.newcompany} {props.evaluationCountry}?<RequiredIndicator /></p>
                <div className="form-check ml-5">
                <input 
                    type="radio"
                    className="form-check-input"
                    name="newcompany"
                    value="Start a new company"
                    onChange={handleChange}
                    checked={entrepreunarialExperience.newcompany === "Start a new company"}
                />
                <label className="form-check-label">Start a new company</label>
                </div>
                <div className="form-check ml-5">
                <input 
                    type="radio"
                    className="form-check-input"
                    name="newcompany"
                    value="Undertake an existing company"
                    onChange={handleChange}
                    checked={entrepreunarialExperience.newcompany === "Undertake an existing company"}
                />
                <label className="form-check-label">Undertake an existing company</label>
                </div>
                <div className="form-check ml-5">
                <input 
                    type="radio"
                    className="form-check-input"
                    name="newcompany"
                    value=""
                    onChange={handleChange}
                    checked={entrepreunarialExperience.newcompany === ""}
                />
                <label className="form-check-label">None</label>
                </div>
            </div>
            </div>
            {entrepreunarialExperience.newcompany !== '' && (
              <div>
                  {props.actor === 'Partner' && renderPartnerCompanyQuestion()}
                  {((props.actor === 'Principal') || (props.actor === 'Partner' && isSameEExperience === 'No')) && (
                    <>
                      {entrepreunarialExperience.newcompany === 'Undertake an existing company' && (
                          <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
                          <p className='text-bold text-center'>Takeover Bid</p>
                          <div className='row p-2'>
                            <p className="col-sm-10 col-form-label">
                            {EnglishQuestion.entrepreunarialExperience.takeoverBid} {props.evaluationCountry}?<RequiredIndicator />
                            </p>
                            <div className="col-sm-2">
                              <div className='row'>
                                <div className="col form-check">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id="takeoverBidYes"
                                    name="takeoverBid"
                                    value="Yes"
                                    onChange={handleChange}
                                    checked={entrepreunarialExperience.takeoverBid === "Yes"}
                                  />
                                  <label className="form-check-label" htmlFor="takeoverBidYes">Yes</label>
                                </div>
                                <div className="col form-check">
                                  <input
                                    type="radio"
                                    className="form-check-input"
                                    id="takeoverBidNo"
                                    name="takeoverBid"
                                    value="No"
                                    onChange={handleChange}
                                    checked={entrepreunarialExperience.takeoverBid === "No"}
                                  />
                                  <label className="form-check-label" htmlFor="takeoverBidNo">No</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <CompanyDetailsYesSection error={errors.companyDetails ? errors.companyDetails : ''}
                        sendCompanyDetails={receivedCompanyDetails} companyDetails={companyDetails} />
                      <InnovativeActivities error={errors.innovativeActivities ? errors.innovativeActivities : ''}
                        evaluationCountry={props.evaluationCountry}
                          sendInnovativeActivities={receivedInnovativeActivities} innovativeActivities={innovativeActivities}   />
                      <OwnerShipDetailSection error={errors.ownershipSection ? errors.ownershipSection : ''}
                        titre={'Company Details'}
                        sendOwnershipDetail={receivedOwnershipDetail} ownershipDetail={ownershipDetail}  />
                      <CompanyActivitesSection error={errors.companyActivites ? errors.companyActivites : ''}
                        sendCompanyActivities={receivedCompanyActivites} companyActivites={companyActivites} />
                      <SupportbySpecializedO error={errors.supportby ? errors.supportby : ''}
                        evaluationCountry={props.evaluationCountry}
                        sendSupportBySpecializedO={receivedSupportby} supportby={supportby}   />
                      <CompanyFinancySection error={errors.operatingExpenses ? errors.operatingExpenses : ''}
                        sendCompanyFinances={receivedCompanyFinances} companyFinances={companyFinances} />
                      <RoleAndExperienceSection error={errors.roleAndExperience ? errors.roleAndExperience : ''}
                        sendRoleAndExperience={receivedRoleAndExperience} roleAndExperience={roleAndExperience} />
                    </>
                  )}
              </div>
          )}</div>
        )}
        
            <div className='d-flex justify-content-center'>
                  <div className='col-sm-10'>
                    <div className='d-flex justify-content-between'>
                      <input type="button" name="save" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Save the form" onClick={saveForm}/>
                      <input type="button" name="next" className="col-6 btn flous-btn-gradient rounded-pill mx-1" value="Next" onClick={pressNext}/>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-center'>
                  <input type="button" name="validate" className="col-sm-6 btn flous-btn-primary rounded-pill mt-2 mx-2" value="Validate this section" onClick={validateSection}/>
                </div>
            </div>
            <ToastContainer />
    </fieldset>
  );
};

export default EntrepreunarialExperience;