import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { SendMessageToFlous } from '../../services/general/ProfileServices';

function FlousMessageForm() {
  const [formData, setFormData] = useState({
    subject: '',
    messageType: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.subject || !formData.messageType || !formData.message) {
      toast.error("Please complete the form to get in touch with us !")
      return;
    } else {
      try {
        const response = await SendMessageToFlous(formData);
        if (response && response.status === 200) {
          toast.success(response.data.message);
          setFormData({
            subject: '',
            messageType: '',
            message: ''
          });
        }
      } catch (error) {
        if (error.response && [400, 401, 500].includes(error.response.status)){
          toast.error(error.response.data.error);
        }
      }
    }
  };

  return (
    <div className='flous-bg-thirty p-3 elevation-2' style={{ borderRadius: '15px' }}>
      <div className='d-flex justify-content-left mb-3'>
        <div className="image">
          <img src={`${process.env.PUBLIC_URL}/flous/logos/trans_1.svg`} alt="Flous logo" className="rounded-circle" width="70" height="70" />
        </div>
        <div className='mt-2'>
          <h5 className='flous-police-one flous-input-color'>Flous Message</h5>
          <div>Get in contact with us!</div>
        </div>
      </div>
      <div className='row p-2'>
        <div className='col-sm-6 mt-2'>
          <div  className='mb-1'>Type of message</div>
          <div className='row'>
            <div className="col form-check">
              <input
                type="radio"
                className="form-check-input rounded-pill"
                id="subjectUrgent"
                name="messageType"
                value="URG"
                checked={formData.messageType === 'URG'}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="subjectUrgent">Urgent</label>
            </div>
            <div className="col form-check">
              <input
                type="radio"
                className="form-check-input rounded-pill"
                id="subjectTechnical"
                name="messageType"
                value="TEC"
                checked={formData.messageType === 'TEC'}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="subjectTechnical">Technical</label>
            </div>
            <div className="col form-check">
              <input
                type="radio"
                className="form-check-input rounded-pill"
                id="subjectComment"
                name="messageType"
                value="COM"
                checked={formData.messageType === 'COM'}
                onChange={handleChange}
              />
              <label className="form-check-label" htmlFor="subjectComment">Comment/Suggestion</label>
            </div>
          </div>
        </div>
        <div className='col-sm-6'>
          <div>Subject of message: </div>
          <div className="col">
            <input
              type="text"
              className="form-control"
              id="subjectUrgent"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <textarea
          className="form-control"
          rows="3"
          placeholder="Enter ..."
          name="message"
          value={formData.message}
          onChange={handleChange}
        ></textarea>
      </div>
      <div className="d-flex justify-content-center">
        <button type="button" className="col-4 btn flous-btn-gradient btn-block rounded-pill" onClick={handleSubmit}>
          Submit
        </button>
      </div>
      <ToastContainer />
    </div>
  );
}

export default FlousMessageForm;