import React from 'react';
import IdentificationResumeSection from './section_pi/IdentificationSection';
import PersonalInfoResumeSection from './section_pi/personalInfoSection';
import ContactInfoResumeSection from './section_pi/contactInfoSection';
import AdditionalInfoResumeSection from './section_pi/additionalInfoSection';

export default function PersonalInfoResume(props) {

  return (
    <div id='evaluation'>
      <h3 className='flous-police-one flous-input-color text-center'>Personal Information</h3>
      <IdentificationResumeSection identification={props.personalInformation.identification}/>
      <PersonalInfoResumeSection personalDetail={props.personalInformation.personalDetail}  preferredLanguage={props.personalInformation.preferredLanguage} />
      <ContactInfoResumeSection contactInfo={props.personalInformation.contactInfo} />
      <AdditionalInfoResumeSection addInfo={props.personalInformation.addInfo} />
    </div>
  );
}
