import React, { useState, useEffect } from 'react';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const JobSpecificsSection = (props) => {
  const [formData, setFormData] = useState(props.jobSpecifics);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedData);
    props.sendJobSpecifics(updatedData);
  };

  return (
    <div className="form-card">
      <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
      <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Job Specifications</p>
        <div className='row p-2'>
          <p className="col-sm-10 col-form-label">{EnglishQuestion.professionalExperience.paydayLoans}<RequiredIndicator /></p>
          <div className="col-sm-2">
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="paydayLoansYes"
                  name="paydayLoans"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.paydayLoans === "Yes"}
                />
                <label className="form-check-label" htmlFor="paydayLoansYes">Yes</label>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="paydayLoansNo"
                  name="paydayLoans"
                  value="No"
                  onChange={handleChange}
                  checked={formData.paydayLoans === "No"}
                />
                <label className="form-check-label" htmlFor="paydayLoansNo">No</label>
              </div>
            </div>
          </div>
        </div>

        <div className='row p-2'>
          <p className="col-sm-10 col-form-label">{EnglishQuestion.professionalExperience.explicitContent}<RequiredIndicator /></p>
          <div className="col-sm-2">
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="explicitContentYes"
                  name="explicitContent"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.explicitContent === "Yes"}
                />
                <label className="form-check-label" htmlFor="explicitContentYes">Yes</label>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="explicitContentNo"
                  name="explicitContent"
                  value="No"
                  onChange={handleChange}
                  checked={formData.explicitContent === "No"}
                />
                <label className="form-check-label" htmlFor="explicitContentNo">No</label>
              </div>
            </div>
          </div>
        </div>

        <div className='row p-2'>
          <p className="col-sm-10 col-form-label">{EnglishQuestion.professionalExperience.realEstate}<RequiredIndicator /></p>
          <div className="col-sm-2">
            <div className='row'>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="realEstateYes"
                  name="realEstate"
                  value="Yes"
                  onChange={handleChange}
                  checked={formData.realEstate === "Yes"}
                />
                <label className="form-check-label" htmlFor="realEstateYes">Yes</label>
              </div>
              <div className="col form-check">
                <input 
                  type="radio"
                  className="form-check-input"
                  id="realEstateNo"
                  name="realEstate"
                  value="No"
                  onChange={handleChange}
                  checked={formData.realEstate === "No"}
                />
                <label className="form-check-label" htmlFor="realEstateNo">No</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobSpecificsSection;