import React, { useEffect, useState } from 'react';
import CountrySelect from '../../../../general/CountrySelect'
import { sortDictionaryByValues } from '../../../../../services/utils';
import api from '../../../../../services/api';
import Select from 'react-select';
import RequiredIndicator from '../../../../general/requiredIndicator';

import EnglishQuestion from "../../questions/englishQuestions.json";

const PersonalDetailSection = (props) => {
  const [formData, setFormData] = useState(
    props.personalDetail);
    const [genders, setGenders] = useState([]);
    const [maritalStatus, setMaritalStatus] = useState([]);
  
    useEffect(() => {
      const fetchGender = async () =>{
          const response = await api.get('/gender/');
          const sorted = sortDictionaryByValues(response.data);
          const options = getOptions(sorted);
          setGenders(options);
      };
      //fetchGender();
    }, []);

  useEffect(() => {
    const fetchGender = async () =>{
        const response = await api.get('/gender/');
        const sorted = sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setGenders(options);
    };
    fetchGender();

    const fetchMaritalStatus = async () =>{
        const response = await api.get('/civilstatus/');
        const sorted = response.data //sortDictionaryByValues(response.data);
        const options = getOptions(sorted);
        setMaritalStatus(options);
    };
    fetchMaritalStatus();

  }, []);

  const getOptions = (data) => {
    return Object.entries(data).map(([key, val]) => ({
      value: key,
      label: val,
    }));
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
  };

  const handleNationalityChange = (e) =>{
    const { name, value } = e.target;
    const updatedFormData = {
      ...formData,
      nationality: value,
    };
    setFormData(updatedFormData);
  };

  const handleMaritalStatusChange = (event) =>{
    const {value} = event.target
    const updatedFormData = {
      ...formData,
      maritalStatus: value,
      personIncluded: ''
    };
    setFormData(updatedFormData);
  }

  useEffect(()=>{
    props.sendPersonalDetail(formData);
    
  }, [formData])

  const allowedMaritalStatuses = ['Common_law_partner', 'Married'];
  const identity = ['Divorced', 'Widow', 'Separated'];

  return (
      <div className="form-card">
        <div className="form-group flous-bg-thirty p-3 px-4 mb-4 shadow" style={{ borderRadius: '15px' }}>
        <div style={{ color: 'red' }}>{props.error !== '' ? 'Please complete this section' : ''}</div>
        <p className='text-bold text-center'>Personal Detail</p>
          <div className='row p-2'>
            <p htmlFor="genderIdentity" className='col-sm-4'>{EnglishQuestion.personalInfo.genderIdentity}<RequiredIndicator /></p>
            <div className='col-sm-8'>
              <Select
                id="genderIdentity"
                name="genderIdentity"
                className="react-select-container rounded-pill flous-input-color"
                classNamePrefix="react-select"
                value={genders.find(option => option.value === formData.genderIdentity)}
                onChange={(selectedOption) => handleChange({ target: { name: 'genderIdentity', value: selectedOption ? selectedOption.value : '' } })}
                options={genders}
                placeholder="Please select an option"
                isSearchable
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                  }),
                }}
                required
              />
            </div>
          </div>
          <div className='row p-2'>
            <p htmlFor="nationality" className="col-sm-4 col-form-label">{EnglishQuestion.personalInfo.nationality}<RequiredIndicator /></p>
            <div className="col-sm-8">
              <CountrySelect value={formData.nationality} onChange={handleNationalityChange} required />
            </div>
          </div>
          {props.actor === 'Principal' && (
          <div className='row p-2'>
            <p htmlFor="maritalStatus" className='col-sm-4'>{EnglishQuestion.personalInfo.maritalStatus}<RequiredIndicator /></p>
            <div className='col-sm-8'>
              <Select
                id="maritalStatus"
                name="maritalStatus"
                className="react-select-container rounded-pill flous-input-color"
                classNamePrefix="react-select"
                value={maritalStatus.find(option => option.value === formData.maritalStatus)}
                onChange={(selectedOption) => handleMaritalStatusChange({ target: { name: 'maritalStatus', value: selectedOption ? selectedOption.value : '' } })}
                options={maritalStatus}
                placeholder="Please select an option"
                isSearchable
                isClearable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: '100%',
                    borderRadius: '20px', // Ajustez la valeur pour l'arrondissement
                    borderColor: '#ccc',  // Vous pouvez également ajuster la couleur de la bordure si nécessaire
                  }),
                }}
                required
              />
            </div>
          </div>
          )}
          {(props.actor === 'Principal' && allowedMaritalStatuses.includes(formData.maritalStatus)) && (
            <div className='row p-2'>
            <p className='col-sm-8 col-form-label'>Is your partner accompanying you ?</p>
            <div className='col-sm-4'>
              <div className='row'>
                <div className="col form-check">
                  <input 
                    type="radio"
                    className="form-check-input rounded-pill"
                    id="personIncludedYes"
                    name="personIncluded"
                    value="Yes"
                    onChange={handleChange}
                    checked={formData.personIncluded === "Yes"}
                  />
                  <label className="form-check-label" htmlFor="personIncludedYes">Yes</label>
                </div>
                <div className="col form-check">
                  <input 
                    type="radio"
                    className="form-check-input rounded-pill"
                    id="personIncludedNo"
                    name="personIncluded"
                    value="No"
                    onChange={handleChange}
                    checked={formData.personIncluded === "No"}
                  />
                  <label className="form-check-label" htmlFor="personIncludedNo">No</label>
                </div>
              </div>
            </div>
          </div>
          )}
          {(props.actor === 'Principal' && identity.includes(formData.maritalStatus)) && (
            <>
            <div className='row p-2'>
              <p htmlFor="firstname" className="col-sm-4 col-form-label">{EnglishQuestion.personalInfo.firstname}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="text" 
                  className="form-control rounded-pill flous-input-color" 
                  id="firstname" 
                  name="firstname" 
                  placeholder="First Name"
                  value={formData.firstname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='row p-2'>
              <p htmlFor="lastname" className="col-sm-4 col-form-label">{EnglishQuestion.personalInfo.lastname} <RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="text" 
                  className="form-control rounded-pill flous-input-color" 
                  id="lastname" 
                  name="lastname" 
                  placeholder="Last Name"
                  value={formData.lastname}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='row p-2'>
              <p htmlFor="dateofbirth" className="col-sm-4 col-form-label">{EnglishQuestion.personalInfo.dateofbirth}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  className="form-control rounded-pill flous-input-color" 
                  id="dateofbirth" 
                  name="dateofbirth" 
                  placeholder="DD/MM/YYYY"
                  value={formData.dateofbirth}
                  onChange={handleChange} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            </>
          )}
          {(props.actor === 'Principal' && (formData.maritalStatus === "Separated")) && (
            <>
            <div className='row p-2'>
              <p htmlFor="relationStart" className="col-sm-4 col-form-label">{EnglishQuestion.personalInfo.relationStart}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  className="form-control rounded-pill flous-input-color" 
                  id="relationStart" 
                  name="relationStart" 
                  placeholder="DD/MM/YYYY"
                  value={formData.relationStart}
                  onChange={handleChange} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            </>
          )}
          {(props.actor === 'Principal' && (formData.maritalStatus === "Divorced" || formData.maritalStatus === "Widow")) && (
            <>
            <div className='row p-2'>
              <p htmlFor="marriageDate" className="col-sm-4 col-form-label">{EnglishQuestion.personalInfo.marriageDate}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  className="form-control rounded-pill flous-input-color" 
                  id="marriageDate" 
                  name="marriageDate" 
                  placeholder="DD/MM/YYYY"
                  value={formData.marriageDate}
                  onChange={handleChange} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            </>
          )}
          {(props.actor === 'Principal' && (formData.maritalStatus === "Separated" || formData.maritalStatus === "Divorced")) && (
            <>
            <div className='row p-2'>
              <p htmlFor="relationEnd" className="col-sm-4 col-form-label">{EnglishQuestion.personalInfo.relationEnd}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  className="form-control rounded-pill flous-input-color" 
                  id="relationEnd" 
                  name="relationEnd" 
                  placeholder="DD/MM/YYYY"
                  value={formData.relationEnd}
                  onChange={handleChange} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            </>
          )}
          {(props.actor === 'Principal' && (formData.maritalStatus === "Widow")) && (
            <>
            <div className='row p-2'>
              <p htmlFor="spouseDeath" className="col-sm-4 col-form-label">{EnglishQuestion.personalInfo.spouseDeath}<RequiredIndicator /></p>
              <div className="col-sm-8">
                <input 
                  type="date" 
                  className="form-control rounded-pill flous-input-color" 
                  id="spouseDeath" 
                  name="spouseDeath" 
                  placeholder="DD/MM/YYYY"
                  value={formData.spouseDeath}
                  onChange={handleChange} max={new Date().toISOString().split("T")[0]}
                />
              </div>
            </div>
            </>
          )}
        </div>
      </div>
  );
};

export default PersonalDetailSection;
