import React from 'react';

export default function UserPricingSection() {

    return <div id='pricing'>
        <div className='row'>
        <div className='col'>
            <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
            Number of Updates Available : 0
            </div>
        </div>
        <div className='col'>
            <div className='flous-bg-thirty p-3 elevation-2 mb-3' style={{ borderRadius: '15px' }}>
            Updates expiration date : DD/MM/YYYY
            </div>
        </div>
        </div>
        <div className='row mt-1'>
        <div className='col'>
            <div className='flous-bg-thirty p-3 elevation-2 mb-3 text-center' style={{ borderRadius: '15px' }}>
            <h3 className='flous-police-one flous-input-color'>New Form</h3>
            <div className='btn btn-light btn-block elevation-1 text-bold'>$15</div>
            <ul>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <div className='flous-input-color m-3'>Purchase credits to start an immigration. Access our plateform and received specialized advise to begin your journey in a new destination</div>
            <div className="col-8 btn flous-btn-gradient rounded-pill mx-1">Purchase new form</div>
            </div>
        </div>
        <div className='col'>
            <div className='flous-bg-thirty p-3 elevation-2 mb-3 text-center' style={{ borderRadius: '15px' }}>
            <h3 className='flous-police-one flous-input-color'>Updates</h3>
            <div className='btn btn-light btn-block elevation-1 text-bold'>1 update / $10</div>
            <div className='btn btn-light btn-block elevation-1 text-bold'>3 updates / $15</div>
            <ul>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <div className='flous-input-color m-3'>Purchase credits to start an immigration. Access our plateform and received specialized advise to begin your journey in a new destination</div>
            <div className="col-8 btn flous-btn-gradient rounded-pill mx-1">Purchase new form</div>
            </div>
        </div>
        </div>
    </div>;
}