import React from 'react';
import EntreExpeResumeSection from './section_expentre/entreexp_section';
import TakeoverBidResumeSection from './section_expentre/takeoverbid_section';
import CompanyDetailsResumeSection from './section_expentre/companyDetail_Section';
import CompanyActivitiesResumeSection from './section_expentre/companyActivities_section';
import CompanyFinanceResumeSection from './section_expentre/companyFinance_section';
import RoleAndExperienceResumeSection from './section_expentre/roleAndExperience_section';
import CompanyDetailsTwoResumeSection from './section_expentre/companyDetailTwo_section';

export default function EntrepreneurialExperienceResumeSection(props) {
  
  return (
    <div id='joboffer'>
      <h3 className='flous-police-one flous-input-color text-center'>Experience Entrepreneurial</h3>
      <EntreExpeResumeSection entrepreunarialExperience={props.entrepreunarialExperience.entrepreunarialExperience} />
      {props.entrepreunarialExperience.entrepreunarialExperience.newcompany === 'Undertake an existing company' && (
        <TakeoverBidResumeSection takeoverBid={props.entrepreunarialExperience.takeoverBid} />
      )}
      <CompanyDetailsResumeSection ownershipDetail={props.entrepreunarialExperience.ownershipDetail} />
      <CompanyActivitiesResumeSection companyActivites={props.entrepreunarialExperience.companyActivites} />
      <CompanyFinanceResumeSection companyFinances={props.entrepreunarialExperience.companyFinances} />
      <RoleAndExperienceResumeSection roleAndExperience={props.entrepreunarialExperience.roleAndExperience} />
      <CompanyDetailsTwoResumeSection companyDetails={props.entrepreunarialExperience.companyDetails} />
    </div>
  );
}